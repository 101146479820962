interface IProps {
    clickHandler(): void;
    imgSrc: string;
    text: string;
}

type Props = IProps;

//! STATE NEVER USED
// interface State {
//     isLoggedIn?: string;
//     lessonMaxLength: number;
//     isNewsFlash: boolean;
//     grimentzZinalModal: boolean;
//     neuchatelRegionModal: boolean;
//     path?: string;
// }

function BottomItem(props: Props) {
    const { clickHandler, imgSrc, text } = props;
    return (
        /* eslint-disable-next-line
                jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */
        <div className="__bottombar-item-parent" onClick={clickHandler}>
            <img className="__bottombar-item-icon" src={imgSrc} alt={text} />
            <p className="__bottombar-item-title">{text}</p>
        </div>
    );
}
export default BottomItem;
