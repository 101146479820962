/* eslint-disable react/react-in-jsx-scope */
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { LESSON_FILTERS, OPTIONS_DEPENDENCES } from '../../resources/constants';
import { decimalToTime, getLessonNameFromType } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import {
    Option,
    ParticipantInfo, SelectionDetail
} from '../../types/booking';
import Expandable from '../common/expandable';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    details?: SelectionDetail;
    participants: any;
    options: any[];
    remarks: any;
    totalPrice: number;
    [key: string]: any;
}

function calculateTotalOptionsPrice(options: Option[]): number {
    const initialPrice = 0;
    const totalOptionsPrice = options.reduce((accumulator, currentValue) => {
        if (currentValue.qty !== -1) {
            return accumulator + (currentValue.qty * (typeof currentValue.price === 'string' ? parseFloat(currentValue.price) : currentValue.price));
        }
        return accumulator + (typeof currentValue.total === 'string' ? parseFloat(currentValue.total) : currentValue.total);
    }, initialPrice);
    return totalOptionsPrice;
}

class Selection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { details } = this.props;

        this.state = {
            details,
            participants: [],
            options: [],
            remarks: '',
            totalPrice: details ? details.price : 0,
        };

        this.formatParticipants = this.formatParticipants.bind(this);
        this.formatOptions = this.formatOptions.bind(this);
        this.formatRemarks = this.formatRemarks.bind(this);
    }

    componentDidMount() {
        const {
            details, participantsInfos, selectedOptions, remarks,
        } = this.props;
        this.setState({ details, totalPrice: details ? details.price : 0 });
        this.setState({ participants: this.formatParticipants(participantsInfos) });
        const optionsPrice = calculateTotalOptionsPrice(selectedOptions);
        this.setState({
            options: this.formatOptions(selectedOptions),
            totalPrice: (details ? details.price : 0) + optionsPrice,
        });
        this.setState({ remarks: this.formatRemarks(remarks) });
    }

    componentDidUpdate(prevProps: Props) {
        const {
            details, participantsInfos, selectedOptions, remarks,
        } = this.props;
        if (prevProps.details !== details) {
            this.setState(
                {
                    details,
                    totalPrice: details ? details.price : 0,
                },
            );
        }

        if (prevProps.participantsInfos !== participantsInfos) {
            this.setState({ participants: this.formatParticipants(participantsInfos) });
        }

        if (prevProps.selectedOptions !== selectedOptions) {
            const optionsPrice = calculateTotalOptionsPrice(selectedOptions);
            this.setState({
                options: this.formatOptions(selectedOptions),
                totalPrice: (details ? details.price : 0) + optionsPrice,
            });
        }

        if (prevProps.remarks !== remarks) {
            this.setState({ remarks: this.formatRemarks(remarks) });
        }
    }

    formatParticipants(participants: ParticipantInfo[] | undefined) {
        const { intl } = this.props;
        if (participants && participants.length !== 0) {
            return participants.map((participant, i: number) => {
                const idx = i;
                return (
                    <div key={`formatParticipant_${idx}`}>
                        {
                            i !== 0 ? <div className="__booking-selection-divider" /> : null
                        }
                        {
                            Object.keys(participant).map((key) => {
                                let name = participant[key];
                                if (['p_id', 'Id'].includes(key)) return null;
                                if (typeof participant[key] === 'object') name = participant[key].name;
                                else if (key === 'birthdate' && moment(participant[key]).isValid()) {
                                    name = moment(participant[key]).format('DD.MM.YYYY');
                                }

                                return (
                                    <span className="__booking-selection-text" key={`participant_${key}`}>
                                        <p className="__booking-selection-key">
                                            {intl.formatMessage({ id: `booking.participant.${key}` })}
                                            :
                                        </p>
                                        <p className="__booking-selection-value">{name}</p>
                                    </span>
                                );
                            })
                        }
                    </div>
                );
            });
        } return null;
    }

    /* eslint-disable-next-line class-methods-use-this */
    formatOptions(options: Option[]) {
        return options.map((o: Option) => (
            <span className="__booking-selection-text" key={o.id}>
                <p className="__booking-selection-key">{`${o.text} (${o.price}${o.type === OPTIONS_DEPENDENCES.PERCENT ? '%' : '.-'}${o.qty !== -1 ? ` * ${o.qty}` : ''}) = ${(typeof o.total === 'string' ? parseFloat(o.total) : o.total).toFixed(2)}`}</p>
            </span>
        ));
    }

    /* eslint-disable-next-line class-methods-use-this */
    formatRemarks(remarksParam: string) {
        let remarks = remarksParam;
        if (remarks.length > 100) {
            remarks = `${remarks.slice(0, 100)} ...`;
        }

        return (<p className="__booking-selection-remarks">{remarks}</p>);
    }

    render() {
        const { currentLesson, intl, selectedInstructor } = this.props;
        const {
            details, participants, options, remarks, totalPrice,
        } = this.state;
        let days: any = '';
        let hours = '';
        let hoursTotal = '';
        let hoursLesson = 0;
        let totalHoursLesson = 0;
        let time = 0;
        if (isEmpty(details)) {
            return null;
        }
        switch (currentLesson?.lesson_type) {
            case LESSON_FILTERS.GROUP_LESSON:
                days = details.days;
                hoursLesson = (details.hours && details.hours.hoursLesson)
                    ? details.hours.hoursLesson : details.nbMinutes / 60;
                totalHoursLesson = (details.hours && details.hours.totalHoursLesson)
                    ? details.hours.totalHoursLesson : (details.nbMinutes / 60) * days;
                hours = decimalToTime(hoursLesson, intl.formatMessage) + intl.formatMessage({ id: 'booking.hoursDay' });
                hoursTotal = decimalToTime(totalHoursLesson, intl.formatMessage, false) + intl.formatMessage({ id: 'booking.hoursTotal' });
                break;
            case LESSON_FILTERS.PRIVATE_LESSON:
                days = details.days[details.selectedDayIndex];
                time = moment.duration(details.hours.replace(intl.formatMessage({ id: 'booking.h' }), ':')).asHours() * parseInt(days, 10);
                hours = details.hours + intl.formatMessage({ id: 'booking.hoursDay' });
                hoursTotal = `${decimalToTime(time, intl.formatMessage, false)} ${intl.formatMessage({ id: 'booking.hoursTotal' })}`;
                break;
            default:
        }

        let durationSplit = null;
        if (details.endTimes) {
            durationSplit = details.endTimes[details.selectedEndTimeIndex].split;
            if (durationSplit) durationSplit = durationSplit.substring(6, 19).replace('+', ' - ');
        }
        if (currentLesson == null) {
            Sentry.captureMessage('Error undefined - missing currentLesson');
            return null;
        }

        return (
            <div className="__booking-selection-parent">
                <div className="__booking-selection-content">
                    <Title className="__booking-selection-title">{intl.formatMessage({ id: 'booking.yourSelection' })}</Title>
                    <div className="__booking-selection-body">
                        <Expandable headerClasses="__booking-selection-expandable" containerClasses="__booking-selection-expandable-container" title={intl.formatMessage({ id: 'booking.product' })} plus defaultOpened>
                            <div>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {getLessonNameFromType(
                                            currentLesson.lesson_type,
                                            intl.formatMessage,
                                        )}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">
                                        {days}
                                        {' '}
                                        {intl.formatMessage({ id: 'booking.days' })}
                                    </p>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.hours' })}
                                        :
                                    </p>
                                    <span>
                                        <p className="__booking-selection-value">{hours}</p>
                                        <p className="__booking-selection-value">{hoursTotal}</p>
                                    </span>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.startDate' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">
                                        {intl.formatDate(
                                            moment(details.startDate).toDate(),
                                            { year: 'numeric', month: 'short', day: '2-digit' },
                                        )}
                                    </p>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.endDate' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">
                                        {intl.formatDate(
                                            moment(details.endDate).toDate(),
                                            { year: 'numeric', month: 'short', day: '2-digit' },
                                        )}
                                    </p>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.startTime' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">{moment(details.times[details.selectedStartTimeIndex].startTime).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}</p>
                                </span>
                                {
                                    durationSplit
                                        ? (
                                            <span className="__booking-selection-text">
                                                <p className="__booking-selection-key">
                                                    {intl.formatMessage({ id: 'booking.durationSplit' })}
                                                    :
                                                </p>
                                                <p className="__booking-selection-value">{durationSplit}</p>
                                            </span>
                                        )
                                        : null
                                }
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.meetingSmall' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">{details.meetingPoints[details.selectedMeetingPointIndex].name}</p>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.Participants' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">{details.participants}</p>
                                </span>
                                <span className="__booking-selection-text">
                                    <p className="__booking-selection-key">
                                        {intl.formatMessage({ id: 'booking.level' })}
                                        :
                                    </p>
                                    <p className="__booking-selection-value">{details.level && details.level.name.length > 0 ? details.level.name : intl.formatMessage({ id: 'booking.noLevel' })}</p>
                                </span>
                            </div>
                        </Expandable>
                        {
                            participants && participants.length > 0
                                ? (
                                    <Expandable headerClasses="__booking-selection-expandable" containerClasses="__booking-selection-expandable-container" title={intl.formatMessage({ id: 'booking.participantsDetails' })} plus defaultOpened>
                                        {participants}
                                    </Expandable>
                                )
                                : null
                        }
                        {
                            options && options.length > 0
                                ? (
                                    <Expandable headerClasses="__booking-selection-expandable" containerClasses="__booking-selection-expandable-container" title={intl.formatMessage({ id: 'booking.options' })} plus defaultOpened>
                                        {options}
                                    </Expandable>
                                )
                                : null
                        }
                        {
                            Object.keys(selectedInstructor).length > 0 ? (
                                <Expandable headerClasses="__booking-selection-expandable" containerClasses="__booking-selection-expandable-container" title={intl.formatMessage({ id: 'booking.remarks' })} plus defaultOpened>
                                    <span className="__booking-selection-text">
                                        <p className="__booking-selection-key">
                                            {intl.formatMessage({ id: 'booking.freeInstructors' })}
                                            :
                                        </p>
                                        <p className="__booking-selection-value">{`${selectedInstructor.Name} ${selectedInstructor.Firstname}`}</p>
                                    </span>
                                </Expandable>
                            )
                                : null
                        }
                        {
                            remarks && remarks.props.children.length > 0
                                ? (
                                    <Expandable headerClasses="__booking-selection-expandable" containerClasses="__booking-selection-expandable-container" title={intl.formatMessage({ id: 'booking.remarks' })} plus defaultOpened>
                                        {remarks}
                                    </Expandable>
                                )
                                : null
                        }
                    </div>
                    <div className="__booking-selection-footer">
                        <Title className="__booking-selection-title">
                            {intl.formatMessage({ id: 'booking.total' })}
                        </Title>
                        <Title className="__booking-selection-title">
                            {totalPrice.toFixed(2)}
                        </Title>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    details: store.booking.selectedLessonDetail,
    currentLesson: store.booking.currentLesson,
    participantsInfos: store.booking.participantsInfos,
    selectedOptions: store.booking.selectedOptions,
    remarks: store.booking.remarks,
    selectedInstructor: store.booking.selectedInstructor,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Selection));
