/* eslint-disable no-nested-ternary */
import { Alert } from '@mui/material';
import qs from 'qs';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import '../../resources/css/resetPassword.css';
import emailImage from '../../resources/img/global/email.svg';
import {
    changeResetPasswordMessage,
    changeResetPasswordToken,
    getResetPasswordToken,
    resetPassword
} from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import TextField from '../common/textfield';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    email?: string;
    password?: string;
    display: boolean;
    token?: string;
    message?: string;
    openSnackbar: boolean;
    confirmClicked: boolean;
    errors?: {
        email?: string;
        password?: string;
    };
    [key: string]: any;
}

class ResetPassword extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: undefined,
            password: undefined,
            display: false,
            token: undefined,
            message: undefined,
            openSnackbar: false,
            confirmClicked: false,
            errors: {
                email: undefined,
                password: undefined,
            },
        };
    }

    componentDidMount() {
        const { location, runGetResetPasswordToken } = this.props;
        const { search } = location;
        if (!search) this.goToHome();
        const token: any = qs.parse(search, {
            ignoreQueryPrefix: true,
            comma: true,
        });
        runGetResetPasswordToken(token.id);
        this.setState({ token: token.id });
    }

    componentDidUpdate(prevProps: Props) {
        const {
            resetPasswordMessage,
            resetPasswordTokenEmail,
            resetPasswordTokenExists,
        } = this.props;
        const { confirmClicked } = this.state;

        if (prevProps.resetPasswordTokenExists == undefined && resetPasswordTokenExists != undefined) {
            if (resetPasswordTokenExists !== false) {
                this.setState({ display: true, email: resetPasswordTokenEmail });
            } else {
                this.setState({ display: true, email: resetPasswordTokenEmail });
            }
        }

        if (confirmClicked === true && resetPasswordMessage !== '') {
            let message;
            const succeded = false;
            switch (resetPasswordMessage) {
                case 'ok':
                    message = '';
                    break;
                case 'wrong email':
                    message = 'resetPassword.wrongEmail';
                    break;
                case 'expired':
                    message = 'resetPassword.expired';
                    break;
            }
            this.setState({ message, openSnackbar: !succeded, confirmClicked: false });
        }
    }

    componentWillUnmount() {
        const { runChangeResetPasswordMessage, runChangeResetPasswordToken } = this.props;
        runChangeResetPasswordToken();
        runChangeResetPasswordMessage();
    }

    handleFocus = (name: string) => this.setState({ errors: { [name]: undefined } });

    goToHome = () => {
        const { history, match } = this.props;
        return history.replace(`/${match.params.lang}`);
    };

    onChange = (event: any, name: any) => this.setState({ [name]: event.target.value });

    onClick = () => {
        const { runResetPassword } = this.props;
        const { email, password, token } = this.state;
        if (email && password && token && !this.checkInputs()) {
            this.setState({ confirmClicked: true });
            runResetPassword(email, password, token);
        }
    };

    onKeyDown = (event: any) => {
        if (event.key === 'Enter') this.onClick();
    };

    checkInputs = () => {
        const { intl } = this.props;
        const { email, password } = this.state;
        const errors: any = {
            email: undefined,
            password: undefined,
        };

        if (!email || email.length <= 0) {
            errors.email = intl.formatMessage({ id: 'login.emailRequired' });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = intl.formatMessage({ id: 'login.emailNotValid' });
        }

        if (!password || password.length <= 0) {
            errors.password = intl.formatMessage({ id: 'login.passwordRequired' });
        } else if (password.length < 8) {
            errors.password = intl.formatMessage({ id: 'login.passwordMinimumCharacters' });
        }

        this.setState({ errors });

        return errors.email || errors.password;
    };

    render() {
        const {
            intl, resetPasswordMessage, resetPasswordTokenError,
        } = this.props;
        const {
            display, openSnackbar, message, email, password, errors,
        } = this.state;

        return (
            <Container headerBase={intl.formatMessage({ id: 'login.forgotPasswordTitle' })}>
                {
                    resetPasswordTokenError !== null
                        ? resetPasswordTokenError === 'TokenExpired'
                            ? <Alert severity="error"><FormattedMessage id="login.tokenExpired" /></Alert>
                            : resetPasswordTokenError === 'TokenAlreadyUsed'
                                ? <Alert severity="error"><FormattedMessage id="login.tokenAlreadyUsed" /></Alert>
                                : <Alert severity="error">{resetPasswordTokenError}</Alert>
                        : display
                            ? resetPasswordMessage === 'ok'
                                ? <p className="__reset-password-success">{intl.formatMessage({ id: 'resetPassword.success' })}</p>
                                : (
                                    <div className="__reset-password-parent" onKeyDown={this.onKeyDown}>
                                        <Snackbar
                                            open={openSnackbar}
                                            variant="error"
                                            message={message ? intl.formatMessage({ id: message }) : ''}
                                            onClose={() => this.setState({ openSnackbar: false })}
                                            timer={5000}
                                        />
                                        <Title><FormattedMessage id="login.forgotPasswordTitle" /></Title>
                                        <span className="__reset-password-textfield-container">
                                            <TextField
                                                disabled
                                                value={email}
                                                onChange={(event) => this.onChange(event, 'email')}
                                                type="email"
                                                placeholder={intl.formatMessage({ id: 'email' })}
                                                image={emailImage}
                                                onFocus={() => this.handleFocus('email')}
                                                error={errors?.email}
                                            />
                                        </span>
                                        <span className="__reset-password-textfield-container">
                                            <TextField
                                                value={password}
                                                onChange={(event) => this.onChange(event, 'password')}
                                                type="password"
                                                placeholder={intl.formatMessage({ id: 'password' })}
                                                onFocus={() => this.handleFocus('password')}
                                                error={errors?.password}
                                            />
                                        </span>
                                        <div className="__reset-password-button">
                                            <Button onClick={this.onClick}>
                                                <FormattedMessage id="resetPassword.button" />
                                            </Button>
                                        </div>
                                    </div>
                                )
                            : <LoadingCircle />
                }
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    resetPasswordTokenExists: store.user.resetPasswordTokenExists,
    resetPasswordTokenEmail: store.user.resetPasswordTokenEmail,
    resetPasswordTokenError: store.user.resetPasswordTokenError,
    resetPasswordMessage: store.user.resetPasswordMessage,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runGetResetPasswordToken: (token: string) => dispatch(getResetPasswordToken(token)),
    runChangeResetPasswordToken: () => dispatch(changeResetPasswordToken()),
    runChangeResetPasswordMessage: () => dispatch(changeResetPasswordMessage()),
    runResetPassword: (email: string, password: string, token: string) => dispatch(resetPassword(email, password, token))
    // runSetImagesForLessons: (image: string[]) => runsetImagesForLessons(image)),
    // runChangeMyAccountCurrentTab: (currentTab: string) => runchangeMyAccountCurrentTab(currentTab)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(connector(ResetPassword));
