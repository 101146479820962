import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import Container from '../common/container';

type Props = RouterProps & WrappedComponentProps;

function PaymentFailed(props: Props) {
    const { intl, history, match } = props;
    return (
        <Container headerBase={intl.formatMessage({ id: 'order.order' })}>
            <div className="__order-parent">
                <p className="__order-text">{intl.formatMessage({ id: 'order.fail' })}</p>
                <Button buttonClasses="__order-button" onClick={() => history.push(`/${match.params.lang}/cart`)}>{intl.formatMessage({ id: 'booking.goToCart' })}</Button>
            </div>
        </Container>
    );
}

export default withRouter(injectIntl(PaymentFailed));
