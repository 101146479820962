import { NewsFeedState, StoreAction } from '../types';

export const NEWSFEED_INIT_STATE: NewsFeedState = {
    data: [],
    isNewsfeedLoading: false,
    page: 0,
    onEndReached: false,
    isLikeLoading: false,
    currentPost: {},
    comments: [],
    likes: [],
};

export const TYPE_INIT_NEWSFEED = ('NEWSFEDD_TYPE_INIT_NEWSFEED');
export const TYPE_GET_NEWSFEED = ('NEWSFEDD_TYPE_GET_NEWSFEED');
export const TYPE_GET_NEWSFEED_SUCCESS = ('NEWSFEDD_TYPE_GET_NEWSFEED_SUCCESS');
export const TYPE_GET_NEWSFEED_FAILURE = ('NEWSFEDD_TYPE_GET_NEWSFEED_FAILURE');

export const TYPE_LOAD_CURRENT_POST = ('NEWSFEDD_TYPE_LOAD_CURRENT_POST');
export const TYPE_LIKE_POST = ('NEWSFEDD_TYPE_LIKE_POST');
export const TYPE_LIKE_POST_SUCCESS = ('NEWSFEDD_TYPE_LIKE_POST_SUCCESS');
export const TYPE_LIKE_POST_FAILURE = ('NEWSFEDD_TYPE_LIKE_POST_FAILURE');

export const TYPE_COMMENTS_INIT = ('NEWSFEDD_TYPE_COMMENT_INIT');
export const TYPE_COMMENT_SUCCESS = ('NEWSFEDD_TYPE_COMMENT_SUCCESS');
export const TYPE_COMMENT_FAILURE = ('NEWSFEDD_TYPE_COMMENT_FAIL');

export const TYPE_COMMENT_SEND_INIT = ('NEWSFEDD_type-cmt-send-init');
export const TYPE_COMMENT_SEND_SUCCESS = ('NEWSFEDD_type-cmt-send-success');
export const TYPE_COMMENT_SEND_FAILURE = ('NEWSFEDD_type-cmt-send-fail');

export const TYPE_LIKE_INIT = ('NEWSFEDD_LIKE_INIT');
export const TYPE_LIKE_SUCESS = ('NEWSFEDD_LIKE_SUCESS');
export const TYPE_LIKE_FAILURE = ('NEWSFEDD_LIKE_FAIL');
export const ERROR_UNDEFINED = ('NEWSFEDD_ERROR_UNDEFINED');

export const newsfeed = (state: NewsFeedState = NEWSFEED_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    let newData = null;
    switch (action.type) {
        case TYPE_INIT_NEWSFEED:
            return NEWSFEED_INIT_STATE;
        case TYPE_GET_NEWSFEED:
            return {
                ...state,
                isNewsfeedLoading: true,
            };
        case TYPE_GET_NEWSFEED_SUCCESS:
            return {
                ...state,
                isNewsfeedLoading: false,
                data: state.page > 0 ? state.data.concat(action.data) : action.data,
                page: state.page + 1,
                onEndReached: action.data.length === 0,
            };
        case TYPE_GET_NEWSFEED_FAILURE:
            return {
                ...state,
                isNewsfeedLoading: false,
            };
        case TYPE_LOAD_CURRENT_POST:
            return {
                ...state,
                currentPost: action.currentPost,
            };
        case TYPE_LIKE_POST:
            return {
                ...state,
                isLikeLoading: true,
                postId: action.postId,
            };
        case TYPE_LIKE_POST_SUCCESS:
            newData = state.data.slice();
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === action.postId) {
                    if (action.likeActive) {
                        newData[i].has_liked = true;
                        newData[i].aggregate.like += 1;
                    } else {
                        newData[i].has_liked = false;
                        if (newData[i].aggregate.like > 0) { newData[i].aggregate.like -= 1; }
                    }
                }
            }

            return {
                ...state,
                data: newData,
                isLikeLoading: false,
            };
        case TYPE_LIKE_POST_FAILURE:
            return {
                ...state,
                isLikeLoading: false,
            };
        case TYPE_COMMENTS_INIT:
            return {
                ...state,
                comments: [],
            };
        case TYPE_COMMENT_SUCCESS:
            return {
                ...state,
                comments: action.data,
            };
        case TYPE_COMMENT_SEND_SUCCESS: {
            const comments = state.comments.slice();
            comments.unshift(action.data);
            newData = state.data.slice();
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === action.postId) {
                    newData[i].aggregate.comment += 1;
                }
            }
            return {
                ...state,
                comments,
                data: newData,
            };
        }
        case TYPE_LIKE_INIT:
            return {
                ...state,
                like: [],
            };
        case TYPE_LIKE_SUCESS:
            return {
                ...state,
                like: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
