import { CSSProperties } from 'react';
import '../../resources/css/fields.css';
import LoadingCircle from './loadingCircle';

/**
 * Component that represent a button
 *
 * props:
 *  classNameContainer: className for the container
 *  classNameContainer: className for the button
 *  onClick: function to fire when click on button
 */
interface IProps {
    containerClasses?: string;
    buttonClasses?: string;
    children: any;
    disabled?: boolean;
    loading?: boolean;
    onClick: (e: any) => void;
    buttonStyle?: CSSProperties
}

type Props = IProps;

function Button(props: Props) {
    const {
        containerClasses, buttonClasses, onClick, children, disabled, loading, buttonStyle, ...fwdProps
    } = props;
    const bStyle: CSSProperties = buttonStyle || {};
    return (
        <span className={`__button-container ${containerClasses || ''}`}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading, no-nested-ternary */}
            <button style={bStyle} className={`__button ${buttonClasses || ''}`} type="submit" {...fwdProps} onClick={onClick} disabled={disabled !== undefined ? disabled : loading !== undefined ? loading : false}>
                {
                    loading
                        ? (
                            <span className="__button-loading">
                                <LoadingCircle width="24px" height="24px" />
                            </span>
                        )
                        : null
                }
                {children}
            </button>
        </span>
    );
}

export default Button;
