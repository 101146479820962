import { MenuItem } from '@mui/material';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { LESSON_FILTERS } from '../../resources/constants';
import axiosapi from '../../store/actions/axiosapi';
import { ApplicationState } from '../../store/types';
import {
    LessonDetail, Level, MeetingPoint, Time
} from '../../types/booking';
import BookingLessonDetails from './bookingLessonDetails';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    lessonDetail: LessonDetail;
    onClickSelect(state: State): void;
    currentLevel: Level;
    bigScreen: boolean;
    isCheckAvailabilityLoading: boolean;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    lessonDuration: number;
    startDate: string;
    endDate: string;
    infoDays: string;
    infoBeginDays: string;
    infoEndDays: string;
    infoNumberDaysMin: string;
    infoNumberDaysMax: string;
    price: number;
    days: number[];
    times: Time[];
    meetingPoints: MeetingPoint[];
    participants: string;
    nbMinutes: number;
    daysOption: any;
    startTimesOption: any;
    meetingPointsOption: any;
    totalHours: string | number;
    selectedDayIndex: number;
    selectedStartTimeIndex: number;
    selectedEndTimeIndex: number;
    selectedMeetingPointIndex: number;
    hours?: string;
}

class PrivateLessonDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { lessonDetail } = this.props;

        this.state = {
            lessonDuration: lessonDetail.nb_minutes,
            startDate: lessonDetail.startDate,
            endDate: lessonDetail.endDate,
            infoDays: lessonDetail.info_days,
            infoBeginDays: lessonDetail.info_begin_days,
            infoEndDays: lessonDetail.info_end_days,
            infoNumberDaysMin: lessonDetail.info_number_days_min,
            infoNumberDaysMax: lessonDetail.info_number_days_max,
            price: lessonDetail.price,
            days: lessonDetail.days,
            times: lessonDetail.times,
            meetingPoints: lessonDetail.meetingPoints,
            participants: lessonDetail.participants,
            nbMinutes: lessonDetail.nb_minutes,
            daysOption: [],
            startTimesOption: [],
            meetingPointsOption: [],
            totalHours: 0,
            selectedDayIndex: 0,
            selectedStartTimeIndex: 0,
            selectedEndTimeIndex: 0,
            selectedMeetingPointIndex: lessonDetail.meetingPoints.length > 1
                ? -1 : 0, // if there is only one meeting point select this one
        };

        this.handleClickButtonSelect = this.handleClickButtonSelect.bind(this);
        this.handleChangeDay = this.handleChangeDay.bind(this);
        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeMeetingPoint = this.handleChangeMeetingPoint.bind(this);
        this.formatDaysOptions = this.formatDaysOptions.bind(this);
        this.formatStartTimeOptions = this.formatStartTimeOptions.bind(this);
        this.formatEndDateAndHours = this.formatEndDateAndHours.bind(this);
    }

    componentDidMount() {
        const dateHours = this.formatEndDateAndHours();
        this.setState({
            daysOption: this.formatDaysOptions(),
            startTimesOption: this.formatStartTimeOptions(),
            meetingPointsOption: this.formatMeetingPointsOptions(),
            endDate: dateHours.endDate,
            hours: dateHours.hours,
            totalHours: dateHours.totalHours,
        });
        this.getNewPrice(undefined, dateHours.endDate);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { lessonDetail } = this.props;
        if (!isEqual(prevProps.lessonDetail, lessonDetail)) {
            this.setState({
                lessonDuration: lessonDetail.nb_minutes,
                startDate: lessonDetail.startDate,
                endDate: lessonDetail.endDate,
                infoDays: lessonDetail.info_days,
                infoBeginDays: lessonDetail.info_begin_days,
                infoEndDays: lessonDetail.info_end_days,
                infoNumberDaysMin: lessonDetail.info_number_days_min,
                infoNumberDaysMax: lessonDetail.info_number_days_max,
                price: lessonDetail.price,
                days: lessonDetail.days,
                times: lessonDetail.times,
                meetingPoints: lessonDetail.meetingPoints,
                participants: lessonDetail.participants,
                nbMinutes: lessonDetail.nb_minutes,
                daysOption: [],
                startTimesOption: [],
                meetingPointsOption: [],
                totalHours: 0,
                selectedDayIndex: 0,
                selectedStartTimeIndex: 0,
                selectedEndTimeIndex: 0,
                selectedMeetingPointIndex: lessonDetail.meetingPoints.length > 1
                    ? -1 : 0, // if there is only one meeting point select this one
            }, () => {
                const dateHours = this.formatEndDateAndHours();
                this.setState({
                    daysOption: this.formatDaysOptions(),
                    startTimesOption: this.formatStartTimeOptions(),
                    meetingPointsOption: this.formatMeetingPointsOptions(),
                    endDate: dateHours.endDate,
                    hours: dateHours.hours,
                    totalHours: dateHours.totalHours,
                });
                this.getNewPrice(undefined, dateHours.endDate);
            });
        }
    }

    handleChangeDay(event: any) {
        const dayIndex = parseInt(event.target.value, 10);
        const dateHours = this.formatEndDateAndHours(dayIndex);
        this.setState({
            selectedDayIndex: dayIndex,
            endDate: dateHours.endDate,
            hours: dateHours.hours,
            totalHours: dateHours.totalHours,
            price: -1,
        }, () => this.getNewPrice());
    }

    handleChangeStartTime(event: any) {
        this.setState(
            { selectedStartTimeIndex: parseInt(event.target.value, 10) },
            () => {
                this.getNewPrice();
            },
        );
    }

    handleChangeMeetingPoint(event: any) {
        this.setState({ selectedMeetingPointIndex: parseInt(event.target.value, 10) });
    }

    handleClickButtonSelect() {
        const { onClickSelect } = this.props;
        onClickSelect(this.state);
    }

    getNewPrice(dayIndex?: number, endDate?: string, startTimeIndex?: number) {
        const { currentLesson, currentLevel } = this.props;
        const {
            startDate,
            days,
            selectedDayIndex,
            participants,
            times,
            selectedStartTimeIndex,
            selectedEndTimeIndex,
            endDate: stateEndDate
        } = this.state;
        const startTime = moment(times[startTimeIndex || selectedStartTimeIndex].startTime).format('[0001-01-01T]HH:mm:ss');
        const endTime = moment(times[startTimeIndex || selectedStartTimeIndex].endTimes[selectedEndTimeIndex].time).format('[0001-01-01T]HH:mm:ss');
        if (currentLesson == null) {
            Sentry.captureMessage('Error undefined - missing currentLesson');
            return;
        }
        const body = {
            lessonId: currentLesson.id,
            levelId: currentLevel.id,
            fromDate: startDate,
            toDate: endDate || stateEndDate,
            startTime,
            endTime, // TODO: confirm utility of endTime??
            nbDays: days[dayIndex !== undefined ? dayIndex : selectedDayIndex],
            nbParticipants: participants,
        };

        axiosapi.post('/booking-corner/price/', body)
            .then((response) => {
                this.setState({ price: response.data === 'price_undefined' ? -2 : response.data });
            })
            .catch(() => this.setState({ price: -2 }));
    }

    formatEndDateAndHours(dayIndex?: number) {
        const {
            infoDays, infoEndDays, startDate, days, selectedDayIndex, lessonDuration,
        } = this.state;
        const { intl } = this.props;
        let formatedEndDate = moment();
        if (infoDays !== '0123456' || infoEndDays !== '0123456') {
            let tmpEndDate = moment(startDate);
            for (let i = 0; i < (days[dayIndex !== undefined
                ? dayIndex : selectedDayIndex] - 1); i++) {
                tmpEndDate = moment(tmpEndDate).add(1, 'day');
                while (!infoDays.includes((tmpEndDate.isoWeekday() - 1).toString())) {
                    tmpEndDate = moment(tmpEndDate).add(1, 'day');
                }
            }
            formatedEndDate = moment(tmpEndDate);
        } else {
            formatedEndDate = moment(startDate).add((days[dayIndex !== undefined ? dayIndex : selectedDayIndex] - 1), 'days');
        }
        const endDate = formatedEndDate.format('YYYY-MM-DD[T]HH:mm:ss');
        let hoursInMinute = lessonDuration;
        const hours = moment.utc(moment.duration(hoursInMinute, 'minutes').asMilliseconds()).format(`H[${intl.formatMessage({ id: 'booking.h' })}]mm`);
        hoursInMinute *= days[dayIndex !== undefined ? dayIndex : selectedDayIndex];

        // Version 1.0
        // let totalHours = moment.utc(moment.duration(
        //     hoursInMinute,
        //     "minutes"
        // ).asMilliseconds()).format(`H[${intl.formatMessage({ id: 'booking.h' })}]mm`);

        // Version2.0 - Hours > 24h
        const hoursAndMinutes = moment.duration(hoursInMinute, 'minutes').asHours();
        const onlyHours = Math.trunc(hoursAndMinutes);
        let onlyMinutes: any = Math.round(
            (hoursAndMinutes % 1) * 60,
        );
        if (onlyMinutes === 0) onlyMinutes = '00';
        const totalHours = onlyHours + intl.formatMessage({ id: 'booking.h' }) + onlyMinutes;
        // End
        return { endDate, hours, totalHours };
    }

    formatDaysOptions() {
        const { days } = this.state;
        return days.map((day, i: number) => {
            const idx = i;
            return (<MenuItem key={`day_${idx}`} value={idx}>{day}</MenuItem>);
        });
    }

    formatStartTimeOptions() {
        const { times } = this.state;
        const { intl } = this.props;
        return times.map((time, i) => {
            const idx = i;
            const startTime = moment(time.startTime).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`);
            return (<MenuItem key={`start_time_${idx}`} value={idx}>{startTime}</MenuItem>);
        });
    }

    formatMeetingPointsOptions() {
        const { meetingPoints: meetingPointsState } = this.state;
        const { intl } = this.props;
        const meetingPoints: any = meetingPointsState.map((meeting: MeetingPoint, i: number) => {
            const idx = i;
            return (<MenuItem key={`meeting${idx}`} value={idx}>{meeting.name}</MenuItem>);
        });
        if (meetingPoints.length > 1) meetingPoints.unshift(<MenuItem key="meeting-disabled" value={-1} disabled>{intl.formatMessage({ id: 'booking.meetingSmall' })}</MenuItem>);
        return meetingPoints;
    }

    render() {
        const {
            intl, currentLesson, currentLevel, bigScreen, isCheckAvailabilityLoading,
        } = this.props;
        const {
            lessonDuration: lessonDurationState,
            startDate: startDateState,
            endDate,
            infoDays,
            infoBeginDays,
            infoEndDays,
            infoNumberDaysMax,
            infoNumberDaysMin,
            totalHours,
            participants,
            days,
            daysOption,
            times,
            startTimesOption,
            meetingPoints,
            meetingPointsOption,
            price,
            selectedDayIndex,
            selectedMeetingPointIndex,
            selectedStartTimeIndex,
        } = this.state;
        let lessonDuration = moment.utc(moment.duration(lessonDurationState, 'minutes').asMilliseconds()).format('H[:]mm');
        lessonDuration += intl.formatMessage({ id: `booking.hour${lessonDuration[0] > '1' ? 's' : ''}` });
        const startDate = moment(startDateState).format('DD.MM.YYYY');
        if (currentLesson == null) {
            Sentry.captureMessage('Error undefined - missing currentLesson');
            return null;
        }
        return (
            <BookingLessonDetails
                lesson={{
                    id: currentLesson.id,
                    type: LESSON_FILTERS.PRIVATE_LESSON,
                    titleNumber: lessonDuration,
                    startDate,
                    endDate: moment(endDate).format('DD.MM.YYYY'),
                    infoDays,
                    infoBeginDays,
                    infoEndDays,
                    infoNumberDaysMin,
                    infoNumberDaysMax,
                    hours: totalHours,
                    participants,
                    level: currentLevel,
                    firstParameters: days,
                    firstParameterOptions: daysOption,
                    secondParameters: times.map((t) => t.startTime),
                    secondParameterOptions: startTimesOption,
                    firstParameterText: intl.formatMessage({ id: 'booking.daysLength' }),
                    secondParameterText: intl.formatMessage({ id: 'booking.startTime' }),
                    meetingPoints,
                    meetingPointOptions: meetingPointsOption,
                    price,
                }}
                selectedFirstParameter={selectedDayIndex}
                handleChangeFirstParameter={this.handleChangeDay}
                selectedSecondParameter={selectedStartTimeIndex}
                handleChangeSecondParameter={this.handleChangeStartTime}
                selectedMeetingPointIndex={selectedMeetingPointIndex}
                handleChangeMeetingPoint={this.handleChangeMeetingPoint}
                handleClickButtonSelect={this.handleClickButtonSelect}
                bigScreen={bigScreen}
                isCheckAvailabilityLoading={isCheckAvailabilityLoading}
            />
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentLesson: store.booking.currentLesson,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(PrivateLessonDetails));
