/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { ReactSVG } from 'react-svg';
import EmptySVG from '../../resources/img/products/checkbox-empty.svg';
import CheckedSVG from '../../resources/img/products/checkbox-fill.svg';

interface IProps {
    labelClasses?: string;
    inputClasses?: string;
    inputFirst?: boolean;
    checked: boolean;
    disabled?: boolean;
    className?: string;
    label: React.ReactNode;
    name?: string;
    onChange?: () => void;
}

type Props = IProps;

class Checkbox extends React.Component<Props> {
    render() {
        const {
            labelClasses,
            label,
            checked,
            disabled,
            className,
            onChange,
            inputClasses,
            inputFirst,
            name
        } = this.props;
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        const Label = <label className={`__checkbox-label ${labelClasses || ''}`}>{label}</label>;
        const Input = <input className={`__checkbox-input ${inputClasses || ''}`} type="checkbox" />;
        const Icon = checked ? <ReactSVG className={`__checkbox-svg ${disabled ? '__checkbox-svg-disabled' : ''}`} src={CheckedSVG} /> : <ReactSVG className={`__checkbox-svg ${disabled ? '__checkbox-svg-disabled' : ''}`} src={EmptySVG} />;

        return (
            inputFirst
                ? (
                /* eslint-disable-next-line
                jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */
                    <div className={`__checkbox-parent ${disabled ? '__checkbox-parent-disabled' : ''} ${className || ''}`} onClick={onChange}>
                        {Icon}
                        {Input}
                        {Label}
                    </div>
                )
                : (
                /* eslint-disable-next-line
                jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */
                    <div className={`__checkbox-parent ${className || ''}`} onClick={onChange}>
                        {Label}
                        {Input}
                        {Icon}
                    </div>
                )
        );
    }
}

export default Checkbox;
