import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Bronze from '../../resources/img/loyalty/bronze.svg';
import Diamond from '../../resources/img/loyalty/diamond.svg';
import Gold from '../../resources/img/loyalty/gold.svg';
import Silver from '../../resources/img/loyalty/silver.svg';
import { ApplicationState } from '../../store/types';
import Title from '../common/title';

interface LoyaltyIProps {
    loyalty: {
        id: number;
        totalSpending: number;
        loyalty: string;
        points: number;
    };
    school?: {
        id: number;
        name: string;
    };
}
interface LoyaltyState {
    level: (string | number | undefined)[];
}

type LoyaltyProps = LoyaltyIProps & WrappedComponentProps;

class LoyaltyCard extends React.Component<LoyaltyProps, LoyaltyState> {
    constructor(props: LoyaltyProps) {
        super(props);

        this.state = {
            level: this.getLevelInfos(),
        };
    }

    /**
   * Get level informations
   * [currentLevelImage, nextLevelImage, nextLevelTotal]
   */
    getLevelInfos = () => {
        const { loyalty } = this.props;
        switch (loyalty.loyalty) {
            case 'bronze':
                return [Bronze, Silver, 5000];
            case 'silver':
                return [Silver, Gold, 10000];
            case 'gold':
                return [Gold, Diamond, 20000];
            case 'diamond':
                return [Diamond];
            default:
                return [undefined, Bronze, 0];
        }
    };

    getLevelCompletion = () => {
        const { level } = this.state;
        const { loyalty } = this.props;
        const { id, totalSpending } = loyalty;

        const bar = document.getElementById(`account-loyalty-card-${id}`);
        const completion = document.getElementById(`account-loyalty-card-completion-${id}`);

        if (bar && completion) {
            const total = bar.getBoundingClientRect().width;
            if (level && level.length >= 3 && level[2] !== undefined) {
                return `${(totalSpending * total) / Number(level[2])}px`;
            }
            return '0px';
        }

        return '0px';
    };

    render() {
        const { loyalty, school } = this.props;
        const { level } = this.state;
        return (
            <div className="__account-loyalty-card">
                <span className="__account-loyalty-card-header">
                    <p className="__account-loyalty-card-title">
                        {loyalty.loyalty && loyalty.loyalty.charAt(0).toUpperCase() + loyalty.loyalty.slice(1)}
                    </p>
                    <span className="flex-align">
                        <p>{`CHF ${loyalty.points?.toFixed(2)}`}</p>
                        {/* <LocalPlayIcon style={{ marginLeft: '5px', color: "#e4cb1c" }} /> */}
                    </span>
                </span>
                {level.length > 0 && (
                    <img
                        className="__account-loyalty-card-image"
                        src={String(level[0])}
                        alt={school?.name}
                        title={school?.name}
                    />
                )}
                {school && <p className="__account-loyalty-card-school">{school.name}</p>}
                {level.length > 1 && (
                    <div className="flex-align" style={{ justifyContent: 'flex-end' }}>
                        <div
                            id={`account-loyalty-card-${loyalty.id}`}
                            className="__account-loyalty-card-level-bar"
                        >
                            <div
                                id={`account-loyalty-card-completion-${loyalty.id}`}
                                className="__account-loyalty-card-level-bar-completion"
                                style={{ width: this.getLevelCompletion() }}
                            />
                        </div>
                        <img
                            className="__account-loyalty-card-level-image"
                            src={String(level[1])}
                            alt={school?.name}
                            title={school?.name}
                        />
                        <p style={{ whiteSpace: 'nowrap', fontSize: '18px' }}>
                            {level.length >= 3 && level[2] !== undefined
                                ? (Number(level[2]) - loyalty.totalSpending).toFixed(2)
                                : 0}
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
const LoyaltyCardIntl = injectIntl(LoyaltyCard);

type ReduxProps = ConnectedProps<typeof connector>;

interface CustomerLoyaltyIProps {}

type CustomerLoyaltyProps = CustomerLoyaltyIProps & ReduxProps & WrappedComponentProps;

function CustomerLoyalty(props: CustomerLoyaltyProps) {
    const { intl, customerLoyalty, schools } = props;
    const history = useHistory();
    const { lang } = useParams<{ lang: string | undefined }>();

    return (
        <div>
            <div>
                <Title className="__account-loyalty-title">
                    {intl.formatMessage({ id: 'account.customerLoyalty.title1' })}
                </Title>
                {customerLoyalty && customerLoyalty.length > 0 ? (
                    <div className="__account-loyalty-cards">
                        {customerLoyalty
              && customerLoyalty.map((l: any) => (
                  <LoyaltyCardIntl
                      key={`account-loyalty-${l.companyId}`}
                      loyalty={l}
                      school={schools ? schools.find((s) => s.id === l.companyId) : undefined}
                  />
              ))}
                    </div>
                ) : (
                    <div className="__account-loyalty-no-points">
                        {intl.formatMessage({ id: 'account.customerLoyalty.noPoint' })}
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                                jsx-a11y/no-noninteractive-element-interactions */}
                        <p
                            className="__account-loyalty-no-points-link"
                            onClick={() => history.push(`/${lang}/products`)}
                        >
                            {intl.formatMessage({ id: 'account.customerLoyalty.noPoint.link' })}
                        </p>
                    </div>
                )}
            </div>
            <br />
            <br />
            <div>
                <Title className="__account-loyalty-title">
                    {intl.formatMessage({ id: 'account.customerLoyalty.title2' })}
                </Title>
                <div>
                    <div className="__account-loyalty-section">
                        <img className="__account-loyalty-image" src={Bronze} alt="Bronze" title="Bronze" />
                        <p>{intl.formatMessage({ id: 'account.customerLoyalty.bonus' }, { percent: 3 })}</p>
                    </div>
                    <div className="__account-loyalty-section">
                        <img className="__account-loyalty-image" src={Silver} alt="Silver" title="Silver" />
                        <span>
                            <p>{intl.formatMessage({ id: 'account.customerLoyalty.bonus' }, { percent: 6 })}</p>
                            <p>
                                {intl.formatMessage({ id: 'account.customerLoyalty.reach' }, { amount: "5'000" })}
                            </p>
                        </span>
                    </div>
                    <div className="__account-loyalty-section">
                        <img className="__account-loyalty-image" src={Gold} alt="Gold" title="Gold" />
                        <span>
                            <p>{intl.formatMessage({ id: 'account.customerLoyalty.bonus' }, { percent: 10 })}</p>
                            <p>
                                {intl.formatMessage({ id: 'account.customerLoyalty.reach' }, { amount: "10'000" })}
                            </p>
                        </span>
                    </div>
                    <div className="__account-loyalty-section">
                        <img className="__account-loyalty-image" src={Diamond} alt="Diamond" title="Diamond" />
                        <span>
                            <p>{intl.formatMessage({ id: 'account.customerLoyalty.bonus' }, { percent: 12 })}</p>
                            <p>
                                {intl.formatMessage({ id: 'account.customerLoyalty.reach' }, { amount: "20'000" })}
                            </p>
                        </span>
                    </div>
                    <div className="__account-loyalty-explanations">
                        <p>{intl.formatMessage({ id: 'account.customerLoyalty.explanations1' })}</p>
                        <p>{intl.formatMessage({ id: 'account.customerLoyalty.explanations2' })}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (store: ApplicationState) => ({
    customerLoyalty: store.setting.userData.customerLoyalty,
    schools: store.setting.schools,
});

const connector = connect(mapStateToProps);

export default injectIntl(connector(CustomerLoyalty));
