/* eslint-disable no-nested-ternary */
import { Alert, Dialog } from '@mui/material';
import * as Sentry from '@sentry/browser';
import moment, { Moment } from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router';
import {
    GOOGLE_STORAGE_API_CGA, LESSON_FILTERS, QUESTIONS_TYPES, WEBEVOLUTIONS_SAFERPAY_SCHOOLS
} from '../../resources/constants';
import '../../resources/css/cart.css';
import axiosapi from '../../store/actions/axiosapi';
import {
    getBCClient, getCountries, getInputClient, getLanguages, setInputClient
} from '../../store/actions/settings';
import { changeCart, openSignUpForm } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { LessonCart, Option, Question } from '../../types/booking';
import {
    CheckBookingRequest, LessonToBook, ParticipantRequest, TotalPriceRequest, TotalPriceResponse
} from '../../types/cart';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import Title from '../common/title';
import OrderApi from '../order/api/order_api';
import CartLesson from './cartLesson';
import DiscountModal from './discountModal';
import SettingsModal from './settingsModal';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

function toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
    if (typeof state[key] === 'boolean') {
        if (value !== undefined) {
            return { [key]: value } as Pick<State, T>;
        }

        return { [key]: !state[key] } as Pick<State, T>;
    }
    throw new Error(`Property ${key} is not a boolean`);
}

interface State {
    sentryCustomerLoyalty: boolean;
    formatedItems: React.ReactNode;
    totalPrice: number;
    totalPriceBC?: TotalPriceResponse;
    payNow: boolean;
    hasNoUserData: boolean;
    accept: boolean;
    itemToDelete: any;
    // unavailableLessons: Lesson[];
    checking: boolean;
    familyDiscount: number;
    promoDiscount: number;
    subTotal: number;
    internetDiscount: number;
    openSettingsModal: boolean;
    openDiscountModal: boolean;
    discountSuccess: boolean;
    // anchorEl: any;
    checkFailed: boolean;
    checked: boolean;
    // inputClient: any;
    request?: CheckBookingRequest;
    fetchedInputClient: boolean;
    usePoints: boolean;
    errorMessage: string;
    // codeDiscount: any;
    codeDiscountDisplayError: boolean;
    codeDiscountAmount: any;
    codeDiscountCode: any;
    codeDiscountWait: number;
    codeDiscountDoAgain: boolean;
    coupon: {
        codeVerified?: boolean;
        code?: string;
        error: boolean;
        balance: number;
        value: number;
    };
    isParticipantError: boolean;
    displayParticipantError: boolean;
    loyaltyPointsToUse: number;
    bookingCheckOnlyFailed?: boolean;
}

class Cart extends React.Component<Props, State> {
    static codeDiscountRequest = async (
        client_id: string,
        code: string,
        level_id: string,
        lesson_id: number,
        lesson_from: Moment,
        lesson_to: Moment,
        lesson_duration_hours: number = 0.0,
        lesson_duration_days: number = 0.0,
        school__external_id: string | undefined = undefined
    ) => {
        const response = await axiosapi.get(`/code_discount/?client_id=${client_id}&school__external_id=${school__external_id}&code=${code}&level_id=${level_id}&lesson_id=${lesson_id}&lesson_from=${lesson_from}&lesson_to=${lesson_to}&lesson_duration_hours=${lesson_duration_hours}&lesson_duration_days=${lesson_duration_days}`);
        return response.data.results[0];
    };

    static timeToDecimal = (time: string): number => {
        let t = time;
        t += '';
        const arr = t.split('h');
        if (t.length < 2) return parseFloat(t);
        const dec = (parseInt(arr[1], 10) / 6) * 10;
        // const dec: any = parseInt((arr[1] / 6) * 10, 10); // KROK Removed parseInt()

        return parseFloat(`${parseInt(arr[0], 10)}.${dec < 10 ? '0' : ''}${dec}`);
    };

    static roundHalf = (num: number): number => Math.round(num * 2) / 2;

    static calculateTotalPrice = (currentCart: LessonCart[]) => {
        const totalPrice = currentCart.reduce((accumulator, currentValue) => accumulator + currentValue.price + (typeof currentValue.totalOptionsPrice === 'string' ? parseFloat(currentValue.totalOptionsPrice) : currentValue.totalOptionsPrice), 0);
        return totalPrice;
    };

    items: LessonCart[];

    constructor(props: Props) {
        super(props);

        const { intl } = this.props;

        this.state = {
            sentryCustomerLoyalty: false,
            formatedItems: [],
            totalPrice: 0, // total price
            totalPriceBC: undefined, // Booking Corner total price object
            payNow: false,
            hasNoUserData: false,
            accept: false,
            itemToDelete: null,
            // unavailableLessons: [],
            checking: false,
            familyDiscount: 0,
            internetDiscount: 0,
            promoDiscount: 0,
            subTotal: 0,
            openSettingsModal: false,
            openDiscountModal: false,
            discountSuccess: false,
            // anchorEl: undefined,
            checkFailed: false,
            checked: false,
            // inputClient: null,
            request: undefined,
            fetchedInputClient: false,
            usePoints: false,
            errorMessage: intl.formatMessage({ id: 'order.checkFailed' }),
            // codeDiscount: undefined,
            codeDiscountDisplayError: false,
            codeDiscountAmount: [],
            codeDiscountCode: undefined,
            codeDiscountWait: 0,
            codeDiscountDoAgain: false,
            coupon: {
                codeVerified: undefined,
                code: undefined,
                error: false,
                balance: 0.0,
                value: 0.0,
            },
            isParticipantError: false,
            displayParticipantError: false,
            loyaltyPointsToUse: 0,
        };

        this.items = [];

        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.handleClickHome = this.handleClickHome.bind(this);
        this.formatItems = this.formatItems.bind(this);
        this.checkAccept = this.checkAccept.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const {
            dispatch, languages, language, bcClient, countries,
        } = this.props;
        document.getElementById('top')?.scrollIntoView();

        if (Object.keys(languages).length === 0) {
            dispatch(getLanguages(language));
        }

        if (Object.keys(countries).length === 0) {
            dispatch(getCountries(language));
        }

        if (!bcClient && Authenticator.isLoggedIn()) {
            dispatch(getBCClient(language));
        }

        // let currentCart = Cookie.get('cart');
        const jsonCurrentCart = localStorage?.getItem('cart');
        const currentCart: LessonCart[] = JSON.parse(jsonCurrentCart || '[]');
        if (currentCart !== null) {
            this.items = currentCart;
            this.items.map((item, i) => {
                const itemx = item;
                itemx.checkId = i;
                return itemx;
            });
            const cdAmount = new Array(this.items.length).fill(0);
            this.setState({
                formatedItems: this.formatItems(currentCart),
                totalPrice: Cart.calculateTotalPrice(currentCart),
                codeDiscountAmount: cdAmount,
            });
        }
    }

    componentDidUpdate() {
        const {
            currentSchool,
            match,
            history,
            userData,
            bcClient,
            inputClient: inputClientProps,
            dispatch,
        } = this.props;
        const {
            checking, checked, checkFailed, fetchedInputClient,
        } = this.state;
        // if the current school as not online purchase redirect to landing
        if (currentSchool && !currentSchool.online_purchase) {
            history.replace(`/${match.params.lang}/`);
        }

        if (this.items.length === 0) return;

        // if (codeDiscountUpdatePrice && codeDiscountCode) {
        //     this.setState({ codeDiscountUpdatePrice: false });
        //     this.checkBooking();
        // }

        if (!checking && !checked && !checkFailed && Object.keys(userData).length > 0 && bcClient) {
            this.setState({ checking: true }, () => this.checkBooking());
        }

        if (!inputClientProps && !fetchedInputClient) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const company_id = this.items[0].school_id.external_id;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { department_id } = this.items[0].school_id;
            this.setState({ fetchedInputClient: true }, () => {
                dispatch(getInputClient(company_id, department_id));
            });
        }
        /* else if (inputClientProps
            && Object.keys(inputClientProps).length > 0
            && !inputClientProps) {
            const inputClient: any = [];
            Object.keys(inputClient).forEach((key) => {
                if (key.includes("Required")
                && inputClient[key] === true) {
                    inputClient.push(key.replace("Required", "").toLowerCase());
                }
            });
            this.setState({ inputClient });
        } */
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(setInputClient(undefined));
    }

    /* Handler */

    handleClickDelete(event: any, item: any) {
        if (this.items.length > 0) {
            this.setState({
                itemToDelete: item,
                // anchorEl: event.currentTarget,
            });
        }
    }

    handleDelete() {
        const { dispatch } = this.props;
        const { codeDiscountAmount, itemToDelete } = this.state;
        const item = itemToDelete;
        const items = [...this.items];
        const index = items.indexOf(item);
        if (index > -1) {
            items.splice(index, 1);
            codeDiscountAmount.splice(index, 1);
        }
        this.items = items;
        // reconstruct formated Items otherwise bad references inside
        this.setState({
            codeDiscountAmount,
            formatedItems: [],
            totalPrice: Cart.calculateTotalPrice(items),
            itemToDelete: null,
            codeDiscountDoAgain: true,
        }, () => {
            this.setState({
                formatedItems: this.formatItems(items),
                checking: false,
                checked: false,
                checkFailed: false,
            });
        });

        // Cookie.set('cart', JSON.stringify(items), { domain: getDomain() });
        localStorage?.setItem('cart', JSON.stringify(items));

        dispatch(changeCart());
    }

    handleClickHome() {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}`);
    }

    handleClickOrder = () => {
        const { dispatch } = this.props;
        const { isParticipantError } = this.state;
        const isLoggedIn = Authenticator.isLoggedIn();
        if (isParticipantError) {
            this.setState({ displayParticipantError: true });
        } else if (isLoggedIn) {
            this.toggleOpenModal('openSettingsModal');
        } else {
            dispatch(openSignUpForm());
        }
    };

    handleSettingsCheck = async () => {
        const { userData } = this.props;
        const user = userData;
        const { request, coupon } = this.state;
        if (request) {
            // const allUniqueParticipants: ParticipantRequest[] = [];
            // const participantGeneratedId = -1;
            request.Client = {
                CommonId: parseInt(user.cid, 10),
                Email: user.user.email,
                Name: user.user.last_name ? user.user.last_name : '',
                Firstname: user.user.first_name ? user.user.first_name : '',
                Address: user.address ? user.address : '',
                AddressLocal: user.address_local ? user.address_local : '',
                Zip: user.zip ? user.zip : '',
                Town: user.town ? user.town : '',
                Phone: user.phone ? user.phone : '',
                Mobile: user.phone ? user.phone : '',
                Sex: 'm',
                LanguageId: user.language,
                CountryId: user.country,
                // Participants: bcClient.Participants ? bcClient.Participants : [],
            };

            // request.Bookings.forEach((b) => {
            //     // allUniqueParticipants.push(b["Participants"]);
            //     const bookingg = b;
            //     bookingg.Participants = bookingg.Participants.map((p) => {
            //         const participantt = p;
            //         if (!Number.isNaN(participantt.Id) && (participantt.Id || 0) < 0) {
            //             const same = allUniqueParticipants.find(
            //                 (ap) => (
            //                     ap.Firstname?.toLowerCase() === participantt.firstname.toLowerCase()
            //                 && (participantt.name === undefined
            //                     || ap.Name?.toLowerCase() === participantt.name.toLowerCase())
            //                 ),
            //             );

            //             if (same) {
            //                 participantt.Id = same.Id;
            //                 participantt.p_id = participantt.Id;
            //             } else {
            //                 participantt.Id = (participantGeneratedId--).toString();
            //                 participantt.p_id = participantt.Id;
            //                 allUniqueParticipants.push(participantt);
            //             }
            //         }
            //         return participantt;
            //     });
            // });

            request.couponCode = coupon.codeVerified;
            request.couponAmount = coupon.balance;

            const response = await OrderApi.bookingCheckOnly(request);

            if (response.success) {
                this.setState(
                    { request, openSettingsModal: false },
                    () => this.setState({ payNow: true }),
                );
            } else {
                this.setState({ bookingCheckOnlyFailed: true, openSettingsModal: false });
            }
        }
    };

    /* Check booking */

    // checkBooking = async () => {
    //     const { currentSchool } = this.props;
    //     if (this.items.length <= 0) return;
    //     let participantGeneratedId = -1;
    //     const participantsGeneratedsId = [];

    //     const bookings = this.items.map((lesson, i) => {
    //         const fromTime = moment(lesson.time.from_time);
    //         const toTime = moment(lesson.time.to_time.time);

    //         return {
    //             lessonId: lesson.lesson_id,
    //             levelId: lesson.level.id,
    //             ProductId: lesson.external_id,
    //             ActivityId: lesson.activity_id,
    //             CategoryId: lesson.level.external_id,
    //             From: moment(lesson.date.from_date).set('hours', fromTime.hours()).set('minutes', fromTime.minutes()).set('seconds', fromTime.seconds())
    //                 .format('YYYY-MM-DDTHH:mm:ss'),
    //             To: moment(lesson.date.to_date).set('hours', toTime.hours()).set('minutes', toTime.minutes()).set('seconds', toTime.seconds())
    //                 .format('YYYY-MM-DDTHH:mm:ss'),
    //             MeetingPointId: lesson.meeting_point.id,
    //             NbDays: lesson.days,
    //             NbMinutes: toTime.diff(fromTime, 'minutes'),
    //             NbParticipants: lesson.participants,
    //             Participants: lesson.participants_infos.map((p) => {
    //                 Object.keys(p).map((k) => {
    //                     if (p[k].value) p[k] = p[k].value;
    //                     // if (!p.p_id && this.props.bcClient.Participants && this.props.bcClient.Participants.length > 0) {
    //                     //     let p_id = this.checkParticipant(p);
    //                     //     if (p_id) p.p_id = p_id;
    //                     // }
    //                 });
    //                 if (p.hasOwnProperty('p_id') && isNaN(p.Id)) {
    //                     p.Id = p.p_id;
    //                     // delete p["p_id"];
    //                 } else {
    //                     let gId;
    //                     for (const pgId of participantsGeneratedsId) {
    //                         if (pgId.Firstname.toLowerCase() === p.firstname.toLowerCase() && (p.name === undefined || pgId.Name.toLowerCase() === p.name.toLowerCase())) {
    //                             gId = pgId.Id;
    //                         }
    //                     }
    //                     if (gId === undefined) {
    //                         gId = (participantGeneratedId--).toString();
    //                         if (p.name) {
    //                             participantsGeneratedsId.push({ Id: gId, Firstname: p.firstname, Name: p.name });
    //                         } else {
    //                             participantsGeneratedsId.push({ Id: gId, Firstname: p.firstname });
    //                         }
    //                     }
    //                     p.Id = gId;
    //                     p.p_id = gId;
    //                 }
    //                 return p;
    //             }),
    //             Options: lesson.options.map((o) => ({ OptionId: o.id, Price: o.total, Quantity: o.qty < 0 ? undefined : o.qty })),
    //             Answers: lesson.questions ? lesson.questions.map((q) => {
    //                 const inputValue = (q.type === QUESTIONS_TYPES.DATE || q.type === QUESTIONS_TYPES.DATETIME || q.type === QUESTIONS_TYPES.TIME) ? undefined : q.answer.toString();
    //                 const dateTimeValue = (q.type === QUESTIONS_TYPES.DATE || q.type === QUESTIONS_TYPES.DATETIME || q.type === QUESTIONS_TYPES.TIME) ? q.answer : undefined;
    //                 if (dateTimeValue === undefined) {
    //                     return ({ QuestionId: q.id, Type: q.type, InputValue: inputValue });
    //                 }
    //                 return ({ QuestionId: q.id, Type: q.type, DateTimeValue: dateTimeValue });
    //             }) : [],
    //             Remark: lesson.remarks.length > 0 ? lesson.remarks : undefined,
    //             InstructorId: lesson.instructor ? lesson.instructor.Id : undefined,
    //             InstructorPriority: lesson.instructor ? lesson.instructor.priority : undefined,
    //             Price: lesson.price + lesson.totalOptionsPrice,
    //             checkId: lesson.checkId,
    //             SchoolId: lesson.school_id.external_id,
    //             DepartmentId: lesson.school_id.department_id,
    //         };
    //     });

    //     let loyaltyPoints = this.state.usePoints ? this.getLoyaltyPoints() : 0;
    //     const codeDiscount = this.amountCodeDiscount();
    //     // const loyaltyPoints = 0;

    //     const checkBookingRequest = {
    //         Bookings: bookings,
    //         FidelityDiscount: loyaltyPoints,
    //         CodeDiscount: codeDiscount,
    //         Account: 'BOOKING',
    //         TransactionType: 'VISA',
    //         CheckOnly: true,
    //     };

    //     const totalPriceRequest = {
    //         FidelityDiscount: loyaltyPoints,
    //         CouponAmount: this.state.coupon.balance,
    //         CodeDiscount: codeDiscount,
    //         Bookings: bookings,
    //     };

    //     // check booking
    //     const response = await OrderApi.checkBooking(checkBookingRequest);

    //     let totalPrice = await OrderApi.totalPrice(totalPriceRequest, this.props.language);
    //     if (loyaltyPoints > 0 && totalPrice.ToPay < 0) {
    //         loyaltyPoints += (totalPrice.ToPay - 1);
    //         totalPriceRequest.FidelityDiscount = loyaltyPoints;
    //         checkBookingRequest.FidelityDiscount = loyaltyPoints;
    //         totalPrice = await OrderApi.totalPrice(totalPriceRequest, this.props.language);
    //     }

    //     checkBookingRequest.TotalPrice = totalPrice.Sum;
    //     checkBookingRequest.TransactionPrice = totalPrice.ToPay;

    //     if (response.checkIds && response.checkIds.length > 0) {
    //         this.checkFailed(response.checkIds);
    //     }
    //     if (response.globalId) {
    //         this.setState({ checkFailed: true, checking: false, errorMessage: this.getErrorMessage(response) });
    //     } else {
    //         this.setState({
    //             loyaltyPointsToUse: loyaltyPoints,
    //             request: checkBookingRequest,
    //             checking: false,
    //             checkFailed: false,
    //             checked: true,
    //             subTotal: totalPrice.Sum,
    //             familyDiscount: totalPrice.FamilyDiscount,
    //             internetDiscount: totalPrice.InternetDiscount,
    //             promoDiscount: totalPrice.PromoDiscount,
    //             totalPrice: totalPrice.ToPay
    //         }, () => {
    //             // TODO: Custom code to Anzere (Passeport Vacances APA) !IMPORTANT => Désactiver pour l'hiver
    //             if (currentSchool !== undefined && currentSchool.int_id === '11' && this.state.codeDiscountDoAgain) {
    //                 this.setState({ codeDiscountDoAgain: false }, () => this.checkBooking());
    //             }
    //         });
    //     }

    //     this.setState({ totalPriceBC: totalPrice });
    // };

    checkBooking = async () => {
        const { usePoints, coupon, codeDiscountDoAgain } = this.state;
        const { currentSchool, language } = this.props;
        if (this.items.length <= 0) return;
        let participantGeneratedId = -1;
        const participantsGeneratedsId: ParticipantRequest[] = [];
        const bookings: LessonToBook[] = this.items.map((lesson) => {
            const fromTime = moment(lesson.time.from_time);
            const toTime = moment(lesson.time.to_time.time);

            return {
                lessonId: lesson.lesson_id,
                levelId: lesson.level?.id,
                ProductId: lesson.external_id,
                ActivityId: lesson.activity_id,
                CategoryId: lesson.level?.external_id,
                From: moment(lesson.date.from_date).set('hours', fromTime.hours()).set('minutes', fromTime.minutes()).set('seconds', fromTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(lesson.date.to_date).set('hours', toTime.hours()).set('minutes', toTime.minutes()).set('seconds', toTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: lesson.meeting_point.id,
                NbDays: lesson.days,
                NbMinutes: toTime.diff(fromTime, 'minutes'),
                NbParticipants: Number(lesson.participants),
                Participants: lesson.participants_infos.map((participant) => {
                    const p = participant;

                    Object.keys(p).forEach((k) => {
                        if (p[k].value) p[k] = p[k].value;
                        // if (!p.p_id && this.props.bcClient.Participants && this.props.bcClient.Participants.length > 0) {
                        //     let p_id = this.checkParticipant(p);
                        //     if (p_id) p.p_id = p_id;
                        // }
                    });
                    if (Object.prototype.hasOwnProperty.call(p, 'p_id') && isNaN(p.Id)) {
                        p.Id = p.p_id;
                        // delete p["p_id"];
                    } else {
                        let gId;
                        for (const pgId of participantsGeneratedsId) {
                            if (pgId.Firstname?.toLowerCase() === p.firstname.toLowerCase() && (p.name === undefined || pgId.Name?.toLowerCase() === p.name.toLowerCase())) {
                                gId = pgId.Id;
                            }
                        }
                        if (gId === undefined) {
                            gId = (participantGeneratedId--).toString();
                            if (p.name) {
                                participantsGeneratedsId.push({ Id: gId, Firstname: p.firstname, Name: p.name });
                            } else {
                                participantsGeneratedsId.push({ Id: gId, Firstname: p.firstname });
                            }
                        }
                        p.Id = gId;
                        p.p_id = gId;
                    }
                    return p;
                }),
                Options: lesson.options.map((o: Option) => ({
                    OptionId: o.id,
                    Price: (typeof o.total === 'string' ? parseFloat(o.total) : o.total),
                    Quantity: o.qty < 0 ? undefined : o.qty,
                })),
                Answers: lesson.questions ? lesson.questions.map((q: Question) => {
                    const inputValue = (q.type === QUESTIONS_TYPES.DATE
                        || q.type === QUESTIONS_TYPES.DATETIME
                        || q.type === QUESTIONS_TYPES.TIME)
                        ? undefined : q.answer?.toString();
                    const dateTimeValue = (q.type === QUESTIONS_TYPES.DATE
                        || q.type === QUESTIONS_TYPES.DATETIME
                        || q.type === QUESTIONS_TYPES.TIME)
                        ? q.answer : undefined;
                    if (dateTimeValue === undefined) {
                        return ({ QuestionId: q.id, Type: q.type, InputValue: inputValue });
                    }
                    return ({ QuestionId: q.id, Type: q.type, DateTimeValue: dateTimeValue });
                }) : [],
                Remark: lesson.remarks.length > 0 ? lesson.remarks : undefined,
                InstructorId: lesson.instructor ? lesson.instructor.Id : undefined,
                InstructorPriority: lesson.instructor ? lesson.instructor.priority : undefined,
                Price: lesson.price + (typeof lesson.totalOptionsPrice === 'string' ? parseFloat(lesson.totalOptionsPrice) : lesson.totalOptionsPrice),
                checkId: lesson.checkId,
                SchoolId: lesson.school_id.external_id,
                DepartmentId: lesson.school_id.department_id,
            };
        });

        let loyaltyPoints = usePoints ? this.getLoyaltyPoints() : 0;
        const codeDiscount = this.amountCodeDiscount();
        // const loyaltyPoints = 0;

        const checkBookingRequest: CheckBookingRequest = {
            Bookings: bookings,
            FidelityDiscount: loyaltyPoints,
            CodeDiscount: codeDiscount,
            Account: 'BOOKING',
            TransactionType: 'VISA',
            CheckOnly: true,
        };

        const totalPriceRequest: TotalPriceRequest = {
            FidelityDiscount: loyaltyPoints,
            CouponAmount: coupon.balance,
            CodeDiscount: codeDiscount,
            Bookings: bookings,
        };

        // check booking
        const response = await OrderApi.checkBooking(checkBookingRequest);

        let totalPrice: TotalPriceResponse = await OrderApi.totalPrice(totalPriceRequest, language);
        if (loyaltyPoints > 0 && totalPrice.ToPay < 0) {
            loyaltyPoints += (totalPrice.ToPay - 1);
            totalPriceRequest.FidelityDiscount = loyaltyPoints;
            checkBookingRequest.FidelityDiscount = loyaltyPoints;
            totalPrice = await OrderApi.totalPrice(totalPriceRequest, language);
        }

        checkBookingRequest.TotalPrice = totalPrice.Sum;
        checkBookingRequest.TransactionPrice = totalPrice.ToPay;

        if (response.checkIds && response.checkIds.length > 0) {
            this.checkFailed(response.checkIds);
        }
        if (response.globalId) {
            this.setState({
                checkFailed: true,
                checking: false,
                errorMessage: this.getErrorMessage(response),
            });
        } else {
            this.setState({
                loyaltyPointsToUse: loyaltyPoints,
                request: checkBookingRequest,
                checking: false,
                checkFailed: false,
                checked: true,
                subTotal: totalPrice.Sum,
                familyDiscount: totalPrice.FamilyDiscount,
                internetDiscount: totalPrice.InternetDiscount,
                promoDiscount: totalPrice.PromoDiscount,
                totalPrice: totalPrice.ToPay,
            }, () => {
                // TODO: Custom code to Anzere (Passeport Vacances APA)
                // !IMPORTANT => Désactiver pour l'hiver
                if (currentSchool !== undefined && currentSchool.int_id === '11' && codeDiscountDoAgain) {
                    this.setState({ codeDiscountDoAgain: false }, () => this.checkBooking());
                }
            });
        }

        this.setState({ totalPriceBC: totalPrice });
    };

    checkFailed = (checkIds: number[]) => {
        this.items = this.items.map((itemx, i) => {
            const item = itemx;
            let bool = false;
            for (let j = 0; j < checkIds.length; ++j) {
                if (checkIds[j] === i) {
                    bool = true;
                    break;
                }
            }
            if (bool) {
                item.disabled = true;
            }
            return item;
        });

        this.setState({
            formatedItems: this.formatItems(this.items),
            checkFailed: true,
            checking: false,
        });
    };

    getErrorMessage = (response: any) => {
        const { intl } = this.props;
        if (response.reason) {
            const split = response.reason.split(' ');
            const message = `${split[1]} ${split[2]}`;
            return intl.formatMessage({ id: 'order.checkFailedWithInstructor' }, { instructor: message });
        }
        return intl.formatMessage({ id: 'order.checkFailed' });
    };

    toggleUsePoints = () => {
        this.setState((state) => ({
            usePoints: !state.usePoints,
            checking: true,
            openDiscountModal: false,
            discountSuccess: true,
        }), () => this.checkBooking());
    };

    /* Other */

    toggleOpenModal = (name: string) => this.setState(
        (state) => toggleStateKey(name as keyof State, state),
    );

    goHome = () => {
        const { history, language } = this.props;
        history.push(`/${language}/products`);
    };

    getLoyaltyPoints = () => {
        const { userData } = this.props;
        const { sentryCustomerLoyalty } = this.state;
        let schoolId: string;
        if (this.items.length > 0) {
            schoolId = this.items[0].school_id.external_id;
        }
        // get customer loyalty
        const customerLoyalty = userData.customerLoyalty ? userData.customerLoyalty.filter(
            (c: any) => c.schoolId === schoolId,
        ) : undefined;
        if (customerLoyalty !== undefined && customerLoyalty.length === 1) {
            const { points } = customerLoyalty[0];
            // if there are more points than the total price,
            // set the points reduction to the total price
            // if(totalPrice < 0) {
            //     points += totalPrice;
            // }
            // if (points > totalPrice) {
            //     points = totalPrice;
            // }
            return points;
        } if (customerLoyalty !== undefined
            && customerLoyalty.length > 1
            && sentryCustomerLoyalty === false) {
            this.setState({ sentryCustomerLoyalty: true }, () => {
                Sentry.captureMessage(`Double Loyalty user ${userData?.user?.id}, school ${schoolId}`);
            });
            return undefined;
        }
        return undefined;
    };

    couponRequest = (value: any) => {
        const { currentSchool } = this.props;
        const { coupon } = this.state;
        OrderApi.coupon({
            schoolId: currentSchool?.int_id,
            departmentId: currentSchool?.department_id,
            code: value,
        })
            .then((response) => {
                if (response.balance > 0) {
                    coupon.error = false;
                    coupon.codeVerified = value;
                    coupon.value = response.value;
                    coupon.balance = response.balance;
                    this.setState({
                        coupon,
                        openDiscountModal: false,
                        discountSuccess: true,
                    }, () => this.checkBooking());
                } else {
                    coupon.error = true;
                    coupon.balance = 0.0;
                    coupon.value = 0.0;
                    coupon.codeVerified = undefined;
                    this.setState({ coupon }, () => this.checkBooking());
                }
            })
            .catch(() => {
                coupon.error = true;
                coupon.balance = 0.0;
                coupon.value = 0.0;
                coupon.codeVerified = undefined;
                this.setState({ coupon }, () => this.checkBooking());
            });
    };

    applyCodeDiscount = (value:string) => {
        const { userData, currentSchool } = this.props;

        const { codeDiscountAmount } = this.state;
        this.items.map(async (lesson, i) => {
            let lessonHours = 0.0;

            if (lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON && typeof lesson.hours !== 'string') {
                lessonHours = Cart.timeToDecimal(String(lesson.hours.totalHoursLesson));
            } else if (typeof lesson.hours === 'string') {
                lessonHours = Cart.timeToDecimal(lesson.hours);
            }

            const discount = await Cart.codeDiscountRequest(
                userData.user.id,
                value,
                lesson.level?.id,
                lesson.lesson_id,
                lesson.date.from_date,
                lesson.date.to_date,
                lessonHours,
                lesson.days,
                currentSchool ? currentSchool.int_id.toString() : undefined,
            );
            codeDiscountAmount[i] = discount;
            if (discount) {
                this.setState((state) => ({
                    codeDiscountCode: discount.code ? discount.code : state.codeDiscountCode,
                    checking: true,
                    codeDiscountAmount,
                    codeDiscountDisplayError: true,
                    codeDiscountWait: state.codeDiscountWait + 1
                }), () => {
                    const { codeDiscountWait } = this.state;

                    if (codeDiscountWait === this.items.length) {
                        this.checkBooking();
                        this.setState((stateX) => ({
                            codeDiscountWait: 0,
                            openDiscountModal: !stateX.codeDiscountCode,
                            discountSuccess: !!stateX.codeDiscountCode
                        }));
                    }
                });
            } else {
                this.setState((state) => ({
                    checking: true,
                    codeDiscountAmount,
                    codeDiscountDisplayError: true,
                    codeDiscountWait: state.codeDiscountWait + 1
                }), () => {
                    const { codeDiscountWait } = this.state;

                    if (codeDiscountWait === this.items.length) {
                        this.checkBooking();
                        this.setState((stateX) => ({
                            codeDiscountWait: 0,
                            openDiscountModal: !stateX.codeDiscountCode,
                            discountSuccess: !!stateX.codeDiscountCode
                        }));
                    }
                });
            }
        });
    };

    amountCodeDiscount = () => {
        const { codeDiscountAmount, familyDiscount } = this.state;
        const { currentSchool } = this.props;
        let amount = 0.0;

        codeDiscountAmount.forEach((cd: any, i: number) => {
            if (cd && cd.amount) {
                amount += cd.amount;
            } else if (cd && cd.percent) {
                // TODO: Custom code to Anzere (Passeport Vacances APA)
                // !IMPORTANT => Désactiver pour l'hiver
                if (currentSchool !== undefined && currentSchool.int_id === '11') {
                    const nbElements = this.items.length;
                    let discountByElements = 0;
                    if (nbElements !== 0) {
                        discountByElements = familyDiscount / (nbElements * 1.0);
                    }
                    amount += parseFloat((((
                        this.items[i].price - discountByElements) * cd.percent) / 100.0
                    ).toFixed(2));
                } else {
                    amount += parseFloat(((this.items[i].price * cd.percent) / 100.0).toFixed(2));
                }
            }
        });

        // TODO: Custom code to Anzere (Passeport Vacances APA)
        // !IMPORTANT => Désactiver pour l'hiver
        if (currentSchool !== undefined && currentSchool.int_id === '11') {
            return Cart.roundHalf(amount);
        }
        return amount;
    };

    resetErrorCoupon = () => {
        const { coupon } = this.state;
        if (coupon.error) {
            coupon.error = false;
            this.setState({ coupon });
        }
    };

    resetErrorDiscount = () => {
        const { codeDiscountDisplayError } = this.state;
        if (codeDiscountDisplayError) {
            this.setState({ codeDiscountDisplayError: false });
        }
    };

    checkAccept() {
        this.setState((state) => ({ accept: !state.accept }));
    }

    formatItems(lessons: LessonCart[]) {
        let isParticipantError = false;
        const tmpLessons = lessons.map((lesson, idx) => {
            const i = idx;
            lesson.participants_infos.length !== lesson.participants && (isParticipantError = true);
            return (
                <CartLesson
                    key={`item_${i}`}
                    lesson={lesson}
                    type={lesson.lesson_type}
                    onDelete={this.handleClickDelete}
                    disabled={lesson.disabled || false}
                />
            );
        });
        this.setState({ isParticipantError });

        return tmpLessons;
    }

    render() {
        const {
            userData, schools, history, intl, currentSchool, match, bcClient, language,
        } = this.props;
        const loyaltyPoints = this.getLoyaltyPoints();
        const {
            loyaltyPointsToUse,
            payNow,
            checkFailed,
            totalPriceBC,
            totalPrice,
            internetDiscount,
            promoDiscount,
            hasNoUserData,
            displayParticipantError,
            request,
            usePoints,
            codeDiscountCode,
            openSettingsModal,
            checked,
            coupon,
            checking,
            codeDiscountDisplayError,
            openDiscountModal,
            itemToDelete,
            discountSuccess,
            errorMessage,
            bookingCheckOnlyFailed,
            formatedItems,
            accept,
            subTotal,
            familyDiscount,
        } = this.state;
        // const loyaltyPoints = 0;

        // go to order init page if payNow is true
        if (payNow) {
            if (Object.keys(userData).length === 0) {
                this.setState({ hasNoUserData: true });
            } else {
                return (
                    <Redirect
                        push
                        to={{
                            pathname: `/${match.params.lang}/order/init`,
                            state: {
                                request,
                                email: userData.user.email,
                                points: usePoints ? loyaltyPointsToUse : 0,
                                codeDiscountAmount: this.amountCodeDiscount(),
                                codeDiscountCode,
                                clientId: userData && userData.user ? userData.user.id : '',
                            },
                        }}
                    />
                );
            }
        }

        let tncPath;
        if (this.items.length > 0) {
            tncPath = schools.filter((s) => s.int_id === this.items[0].school_id.external_id);
            tncPath = tncPath.length > 0 ? tncPath[0].tnc_path : undefined;
        }

        const checkBoxLabel = this.items.length > 0 && schools.length > 0 ? (
            <span className="__cart-checkbox-label">
                {intl.formatMessage({ id: 'order.checkBoxMessage1' })}
                <a
                    href={tncPath}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({ id: 'help.cga' })}
                </a>
                {intl.formatMessage({ id: 'order.checkBoxMessage2' })}
                <a
                    href={`${GOOGLE_STORAGE_API_CGA}Reglement-General-sur-la-Protection-des-Donnees.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {intl.formatMessage({ id: 'order.jdpr' })}
                </a>
                <span className="__cga-toline">
                    {intl.formatMessage({ id: 'order.checkBoxMessage3' }, { amount: totalPrice.toFixed(2) })}
                </span>
            </span>
        ) : <LoadingCircle />;

        let currentSchoolName = '';

        if (currentSchool) {
            currentSchoolName = currentSchool.name;
        } else if (this.items[0]) {
            currentSchoolName = this.items[0].school_id.name;
        }
        return (
            <Container
                headerBase={`${intl.formatMessage({ id: 'header.cart' })} → ${currentSchoolName}`}
            >
                <div className="__cart-parent">
                    {
                        openSettingsModal
                        && (
                            <SettingsModal
                                open={openSettingsModal}
                                onClose={() => this.toggleOpenModal('openSettingsModal')}
                                onCheck={this.handleSettingsCheck}
                                buttonText={intl.formatMessage({ id: checked ? 'order.pay' : 'order.check' })}
                            />
                        )
                    }
                    <DiscountModal
                        checkCoupon={this.couponRequest}
                        disabledCoupon={checking || !bcClient}
                        errorCoupon={coupon.error && !coupon.codeVerified}
                        resetErrorCoupon={this.resetErrorCoupon}
                        checkDiscount={this.applyCodeDiscount}
                        disabledDiscount={checking || !bcClient}
                        errorDiscount={codeDiscountDisplayError && !codeDiscountCode}
                        resetErrorDiscount={this.resetErrorDiscount}
                        checkFidelity={this.toggleUsePoints}
                        disabledFidelity={
                            checking
                            || !bcClient
                            || loyaltyPoints <= 0
                            || loyaltyPoints === undefined
                        }
                        fidelityPoints={
                            loyaltyPoints <= 0
                                || loyaltyPoints === undefined
                                ? 0 : -loyaltyPoints
                        }
                        fidelityActivated={loyaltyPoints !== undefined}
                        open={openDiscountModal}
                        onClose={() => this.toggleOpenModal('openDiscountModal')}
                    />
                    <Dialog
                        open={Boolean(itemToDelete)}
                        onClose={() => this.setState({ itemToDelete: null })}
                    >
                        <div className="__cart-dialog">
                            <Title>{intl.formatMessage({ id: 'order.deleteTitle' })}</Title>
                            <p className="__cart-dialog-text">{intl.formatMessage({ id: 'order.deleteMessage' })}</p>
                            <div className="__cart-dialog-buttons">
                                <Button buttonClasses="__cart-dialog-button" containerClasses="__cart-dialog-button-container" onClick={() => this.setState({ itemToDelete: null })}>{intl.formatMessage({ id: 'cancel' })}</Button>
                                <Button buttonClasses="__cart-dialog-button-red" containerClasses="__cart-dialog-button-container" onClick={this.handleDelete}>{intl.formatMessage({ id: 'yes' })}</Button>
                            </div>
                        </div>
                    </Dialog>
                    <Snackbar
                        variant="success"
                        open={discountSuccess}
                        message={intl.formatMessage({ id: 'order.discountSuccess' })}
                        timer={2500}
                        onClose={() => this.setState({ discountSuccess: false })}
                    />
                    <Snackbar
                        variant="warning"
                        open={checkFailed}
                        message={errorMessage}
                    />
                    <Snackbar
                        variant="warning"
                        open={bookingCheckOnlyFailed}
                        message={(
                            /* eslint-disable-next-line
                            jsx-a11y/click-events-have-key-events,
                            jsx-a11y/no-static-element-interactions */
                            <span style={{ cursor: 'pointer' }} onClick={() => history.push(`/${language}/account/settings`)}>
                                {intl.formatMessage({ id: 'order.bookingCheckOnlyFailed' })}
                                <p style={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'order.bookingCheckOnlyFailedLink' })}</p>
                            </span>
                        )}
                        timer={5000}
                        onClose={() => this.setState({ bookingCheckOnlyFailed: false })}
                    />
                    {
                        this.items.length > 0
                            ? (
                                <div className="__cart-content">
                                    {formatedItems}
                                </div>
                            )
                            : (
                                <div className="__cart-no-items-container">
                                    <Title className="__cart-no-items">{intl.formatMessage({ id: 'order.noItems' })}</Title>
                                    <Button buttonClasses="__cart-no-items-button" containerClasses="__cart-no-items-button-container" onClick={this.goHome}>
                                        {intl.formatMessage({ id: 'order.continueShop' })}
                                    </Button>
                                </div>
                            )
                    }
                    {
                        this.items.length > 0
                            ? (
                                <div className="__cart-footer">
                                    {
                                        this.items && this.items.length > 0
                                            ? (
                                                <div className="__cart-important-parent">
                                                    {
                                                        WEBEVOLUTIONS_SAFERPAY_SCHOOLS.indexOf(
                                                            parseInt(
                                                                this.items[0].school_id.external_id,
                                                                10,
                                                            ),
                                                        ) < 0
                                                        && (
                                                            <p className="__cart-important-primary">
                                                                {intl.formatMessage({ id: 'order.important2' })}
                                                                {' '}
                                                                Booking Corner
                                                            </p>
                                                        )
                                                    }
                                                    <p className="__cart-important-secondary">{intl.formatMessage({ id: 'order.important5' })}</p>
                                                    <p className="__cart-important-secondary">{intl.formatMessage({ id: 'order.important4' })}</p>
                                                </div>
                                            )
                                            : null
                                    }
                                    <div className="__cart-important-parent">
                                        <Alert
                                            sx={{
                                                background: '#FFF4E5 !important'
                                            }}
                                            style={{ textAlign: 'left', fontSize: '16px', fontFamily: 'Montserrat' }}
                                            severity="warning"
                                        >
                                            {intl.formatMessage({ id: 'order.importantTwint' })}
                                        </Alert>
                                    </div>

                                    {/* <div className="__cart-important-parent">
                                    <Alert
                                        style={
                                            {
                                                textAlign: "left",
                                                fontSize: '16px',
                                                fontFamily: 'Montserrat'
                                            }
                                        }
                                        severity="info"
                                    >
                                        {intl.formatMessage(
                                            { id: 'order.importantTwint' }
                                            )
                                        }
                                    </Alert>
                                </div> */}
                                    <div className="__cart-footer-checkbox">
                                        <div>
                                            <Checkbox
                                                inputFirst
                                                checked={accept}
                                                onChange={this.checkAccept}
                                                label={checkBoxLabel}
                                                className=""
                                                disabled={false}
                                                inputClasses=""
                                                labelClasses=""
                                            />
                                        </div>
                                    </div>
                                    <div className="__cart-footer-bottom">
                                        <div className="__cart-footer-bottom-left">
                                            <Button buttonClasses="__cart-button-continue" containerClasses="__cart-button-continue-container" onClick={this.goHome}>
                                                {intl.formatMessage({ id: 'order.continueShop' })}
                                            </Button>
                                        </div>
                                        <div className="__cart-footer-bottom-right">
                                            <div className="__cart-footer-price">
                                                {
                                                    subTotal > 0
                                                    && (
                                                        <p className="__cart-price-subtotal">
                                                            {intl.formatMessage({ id: 'order.subTotal' })}
                                                            :
                                                            {' '}
                                                            {subTotal.toFixed(2)}
                                                            {' '}
                                                            CHF
                                                        </p>
                                                    )
                                                }
                                                {
                                                    familyDiscount > 0 && (
                                                        totalPriceBC
                                                            && totalPriceBC.FamilyDiscountText
                                                            ? (
                                                                <p className="__cart-discount">
                                                                    {`${totalPriceBC.FamilyDiscountText}: -${familyDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                            : (
                                                                <p className="__cart-discount">
                                                                    {`${intl.formatMessage({ id: 'order.familyDiscount' })}: -${familyDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                    )
                                                }
                                                {
                                                    internetDiscount > 0 && (
                                                        totalPriceBC
                                                            && totalPriceBC.InternetDiscountText
                                                            ? (
                                                                <p className="__cart-discount">
                                                                    {`${totalPriceBC.InternetDiscountText}: -${internetDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                            : (
                                                                <p className="__cart-discount">
                                                                    {`${intl.formatMessage({ id: 'order.internetDiscount' })}: -${internetDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                    )
                                                }
                                                {
                                                    promoDiscount > 0 && (
                                                        totalPriceBC
                                                            && totalPriceBC.PromoDiscountText
                                                            ? (
                                                                <p className="__cart-discount">
                                                                    {`${totalPriceBC.PromoDiscountText}: -${promoDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                            : (
                                                                <p className="__cart-discount">
                                                                    {`${intl.formatMessage({ id: 'order.promoDiscount' })}: -${promoDiscount.toFixed(2)} CHF`}
                                                                </p>
                                                            )
                                                    )
                                                }
                                                {
                                                    codeDiscountCode && (
                                                        <p className="__cart-discount">
                                                            {`${intl.formatMessage({ id: 'order.codeDiscount' })}: "${codeDiscountCode}": -${this.amountCodeDiscount()} CHF`}
                                                        </p>
                                                    )
                                                }
                                                {
                                                    coupon.codeVerified && (
                                                        <p className="__cart-discount">
                                                            {`${intl.formatMessage({ id: 'order.codeDiscount' })} "${coupon?.codeVerified}": -${coupon?.balance} CHF`}
                                                        </p>
                                                    )
                                                }
                                                {
                                                    usePoints && (
                                                        totalPriceBC
                                                            && totalPriceBC.FidelityDiscountText
                                                            ? (
                                                                <p className="__cart-discount">
                                                                    {`${totalPriceBC.FidelityDiscountText}: -${loyaltyPointsToUse} CHF`}
                                                                </p>
                                                            )
                                                            : (
                                                                <p className="__cart-discount">
                                                                    {`${intl.formatMessage({ id: 'order.loyaltyPoints' })}: -${loyaltyPointsToUse} CHF`}
                                                                </p>
                                                            )
                                                    )
                                                }
                                                {/* {
                                                // undefined &&
                                                userData.user && !codeDiscountCode &&
                                                <div className="__cart-code-discount-container">
                                                    <Button
                                                        disabled={
                                                            checking
                                                            || !bcClient
                                                            || codeDiscount === ''
                                                            || codeDiscount === undefined
                                                        }
                                                buttonClasses="__cart-button-apply-code-discount"
                                    containerClasses="__cart-button-apply-code-discount-container"
                                                        onClick={this.applyCodeDiscount
                                                    >
                                                        {
                                                            intl.formatMessage(
                                                                { id: 'order.applyCodeDiscount' }
                                                            )
                                                        }
                                                    </Button>
                                                    <TextField
                                                        containerClasses={
                                                            codeDiscountDisplayError
                                                            && !codeDiscountCode
                                                            ? "__cart-code-discount-red"
                                                            : "__cart-code-discount"
                                                        }
                                                        value={codeDiscount || ''}
                                                        onChange={
                                                            (e) =>
                                                            this.setState(
                                                                { codeDiscount: e.target.value }
                                                                )
                                                            }
                                                        placeholder={
                                                            intl.formatMessage(
                                                            { id: 'order.placeholderCodeDiscount' }
                                                                )
                                                            }
                                                    />
                                                </div>
                                            }
                                            {codeDiscountDisplayError
                                                && !codeDiscountCode &&
                                                <p
                                                style={
                                                    {
                                                        color: 'var(--secondary-color)',
                                                        textAlign: 'right',
                                                        marginTop: '5px'
                                                    }
                                                }>
                                                {intl.formatMessage(
                                                    { id: 'order.codeDiscountError' })
                                                }
                                                </p>
                                            }
                                            {
                                                // undefined &&
                                                userData.user && !coupon.codeVerified &&
                                                <div className="__cart-code-discount-container">
                                                    <Button
                                                        disabled={
                                                            checking
                                                            || !bcClient
                                                            || coupon.code === ''
                                                            || coupon.code === undefined
                                                        }
                                                buttonClasses="__cart-button-apply-code-discount"
                                    containerClasses="__cart-button-apply-code-discount-container"
                                                        onClick={this.couponRequest}
                                                    >
                                                        {intl.formatMessage(
                                                            { id: 'order.applyCodeDiscount' })
                                                        }
                                                    </Button>
                                                    <TextField
                                                        containerClasses={
                                                            codeDiscountDisplayError
                                                            && !codeDiscountCode
                                                            ? "__cart-code-discount-red"
                                                            : "__cart-code-discount"}
                                                        value={coupon.code || ''}
                                                        onChange={(e) => {
                                                            let coupon = coupon;
                                                            coupon.code = e.target.value;
                                                            this.setState({ coupon })
                                                        }}
                                                        placeholder={
                                                            intl.formatMessage(
                                                        { id: 'order.placeholderCodeDiscount' }
                                                            )
                                                        }
                                                    />
                                                </div>
                                            }
                                            {coupon.error && !coupon.codeVerified &&
                                            <p style={
                                                {
                                                    color: 'var(--secondary-color)',
                                                    textAlign: 'right',
                                                    marginTop: '5px'
                                                    }
                                                }>
                                                {intl.formatMessage(
                                                    { id: 'order.codeDiscountError' })
                                                }</p>} */}

                                                <p className="__cart-price-total">
                                                    {intl.formatMessage({ id: 'booking.total' })}
                                                    :
                                                    {' '}
                                                    {totalPrice.toFixed(2)}
                                                    {' '}
                                                    CHF
                                                </p>
                                            </div>
                                            <div className="__cart-button">
                                                <Button disabled={coupon.codeVerified !== undefined || this.amountCodeDiscount() !== 0.0 || usePoints} buttonClasses="__cart-button-discount-modal" containerClasses="__cart-button-continue-container" onClick={() => this.setState({ openDiscountModal: true })}>
                                                    {intl.formatMessage({ id: 'order.openDiscountModal' })}
                                                </Button>
                                                <Button
                                                    buttonClasses="__cart-button-pay"
                                                    containerClasses=""
                                                    onClick={this.handleClickOrder}
                                                    loading={hasNoUserData || checking}
                                                    disabled={
                                                        !accept
                                                        || hasNoUserData
                                                        || schools.length <= 0
                                                        || checking
                                                        || checkFailed
                                                        || (!checked && Authenticator.isLoggedIn() !== undefined)
                                                    }
                                                >
                                                    {intl.formatMessage({ id: checking ? 'order.checking' : 'order.pay' })}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                    }
                </div>

                <Snackbar
                    variant="error"
                    open={displayParticipantError}
                    onClose={() => this.setState({ displayParticipantError: false })}
                    timer={5000}
                    message={intl.formatMessage({ id: 'order.nbParticipantsError' })}
                />
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    codeDiscount: store.products.codeDiscount,
    userData: store.setting.userData,
    schools: store.setting.schools,
    language: store.translation.language,
    countries: store.setting.countries,
    languages: store.setting.languages,
    bcClient: store.setting.bcClient,
    inputClient: store.setting.inputClient,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Cart));
