import { Skeleton } from '@mui/material';
import HeaderImage from './headerImage';
import Menubar from './menubar';
import Topbar from './topbar';

interface IProps {
    image: string;
    withPlaceholder?: boolean;
    children: any;
    className: string;
    texts?: string[];
    schoolName?: string;
}

// const defaultProps = {
//     withPlaceholder: false,
//     texts: [''],
//     schoolName: '',
// };
type Props = IProps;

function Header(props: Props) {
    const {
        className, image, withPlaceholder = undefined, schoolName = '', children, texts = [],
    } = props;
    return (
        <>
            <Topbar />
            <Menubar />
            <div className={`__header-parent ${className}`}>
                {
                    image ? <HeaderImage image={image} texts={texts} schoolName={schoolName} />
                        : withPlaceholder && <Skeleton className="__header-skeleton" variant="rectangular" width="100%" height={550} animation={false} />
                }
                {
                    children && (
                        <div className="__header-base">
                            {children}
                        </div>
                    )
                }
            </div>
        </>
    );
}

// Header.defaultProps = defaultProps;

export default Header;
