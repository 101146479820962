import { Moment } from 'moment';
import { getCurrentVersion } from '../../resources/utils';
import { UserRequestData } from '../../types/settings';
import {
    TYPE_GET_SETTING_SKI_SCHOOLS,
    TYPE_GET_SETTING_SKI_SCHOOLS_FAILURE,
    TYPE_GET_SETTING_SKI_SCHOOLS_SUCCESS,
    TYPE_SAVE_SETTING_SKI_SCHOOL,
    TYPE_SAVE_SETTING_SKI_SCHOOL_FAILURE,
    TYPE_SAVE_SETTING_SKI_SCHOOL_SUCCESS,
    TYPE_SETTING_CHANGE_PASSWORD,
    TYPE_SETTING_CHANGE_PASSWORD_FAILURE,
    TYPE_SETTING_CHANGE_PASSWORD_SUCCESS,
    TYPE_SETTING_EDIT_NOTIFICATION,
    TYPE_SETTING_EDIT_NOTIFICATION_FAILURE,
    TYPE_SETTING_EDIT_NOTIFICATION_SUCCESS,
    TYPE_SETTING_EDIT_PROFILE,
    TYPE_SETTING_EDIT_PROFILE_FAILURE,
    TYPE_SETTING_EDIT_PROFILE_SUCCESS,
    TYPE_SETTING_GET_BC_CLIENT,
    TYPE_SETTING_GET_COUNTRIES,
    TYPE_SETTING_GET_COUNTRIES_FAILURE,
    TYPE_SETTING_GET_COUNTRIES_SUCCESS,
    TYPE_SETTING_GET_INPUT_CLIENT,
    TYPE_SETTING_GET_LANGUAGES,
    TYPE_SETTING_GET_LANGUAGES_FAILURE,
    TYPE_SETTING_GET_LANGUAGES_SUCCESS,
    TYPE_SETTING_GET_USER_DATA,
    TYPE_SETTING_GET_USER_DATA_FAILURE,
    TYPE_SETTING_GET_USER_DATA_SUCCESS,
    TYPE_SETTING_SET_INPUT_CLIENT
} from '../reducers/settings';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';
import { API_ROUTES, get, post } from './api';
import axiosapi from './axiosapi';

export const getSkiSchools = () => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_SETTING_SKI_SCHOOLS,
    });

    dispatch(get(
        `${API_ROUTES.getSchools}?version=${getCurrentVersion()}`,
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_SETTING_SKI_SCHOOLS_SUCCESS,
                data: responseJson,
            });
        },
        (error: any) => {
            dispatch({
                type: TYPE_GET_SETTING_SKI_SCHOOLS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};
export const getUserDetails = () => (dispatch: any) => {
    dispatch({
        type: TYPE_SETTING_GET_USER_DATA,
    });

    dispatch(get(
        API_ROUTES.getUserData,
        (responseJson: any) => {
            dispatch({
                type: TYPE_SETTING_GET_USER_DATA_SUCCESS,
                userData: responseJson,
            });
        },
        (error: any) => {
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
            dispatch({
                type: TYPE_SETTING_GET_USER_DATA_FAILURE,
            });
        },
    ));
};

export const saveClientSchool = (companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_SAVE_SETTING_SKI_SCHOOL,
    });

    const requestData = {
        company_id: companyId,
    };

    dispatch(post(
        API_ROUTES.getSchools,
        requestData,
        (responseJson: any) => {
            dispatch({
                type: TYPE_SAVE_SETTING_SKI_SCHOOL_SUCCESS,
                data: responseJson,
            });
            dispatch(getUserDetails());
        },
        (error: any) => {
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
            dispatch({
                type: TYPE_SAVE_SETTING_SKI_SCHOOL_FAILURE,
            });
        },
    ));
};

export const changeClientPassword = (oldPassword: any, newPassword: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SETTING_CHANGE_PASSWORD,
    });

    const requestData = {
        old_password: oldPassword,
        new_password: newPassword,
    };

    dispatch(post(
        API_ROUTES.changePassword,
        requestData,
        (responseJson: any) => {
            dispatch({
                type: TYPE_SETTING_CHANGE_PASSWORD_SUCCESS,
                data: responseJson,
            });
            dispatch(getUserDetails());
        },
        (error: any) => {
            let errorMsg: string;
            switch (error) {
                case 'Incorrect old password':
                    errorMsg = 'error.wrongOldPassword';
                    break;
                default:
                    errorMsg = 'error.title';
                    break;
            }
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
                message: errorMsg,
            });
            dispatch({
                type: TYPE_SETTING_CHANGE_PASSWORD_FAILURE,
            });
        },
    ));
};

/**
 * Pass null for params not required to edit
 * @param firstName
 * @param lastName
 * @param phone
 * @param birthdate
 * @param address
 * @returns {Function}
 */
export const editProfile = (
    firstName: string,
    lastName: string,
    phone: string,
    birthdate: Moment,
    address: string,
    town: string,
    zip: string,
    country: string,
    language: string,
    addressLocal: string,
) => (dispatch: any) => {
    dispatch({
        type: TYPE_SETTING_EDIT_PROFILE,
    });

    const requestData: UserRequestData = {};

    if (firstName) requestData.first_name = firstName;
    if (lastName) requestData.last_name = lastName;
    if (phone) requestData.phone = phone;
    if (birthdate) requestData.birthdate = birthdate;
    if (address) requestData.address = address;
    if (town) requestData.town = town;
    if (zip) requestData.zip = zip;
    if (country) requestData.country = country;
    if (language) requestData.language = language;
    if (addressLocal) requestData.address_local = addressLocal;

    dispatch(post(
        API_ROUTES.editUserProfile,
        requestData,
        (responseJson: any) => {
            dispatch({
                type: TYPE_SETTING_EDIT_PROFILE_SUCCESS,
                data: responseJson,
            });
            dispatch(getUserDetails());
        },
        (error: any) => {
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
            dispatch({
                type: TYPE_SETTING_EDIT_PROFILE_FAILURE,
            });
        },
    ));
};

/**
 * Sets the notification preference for client
 * @param lessonNotifState boolean: true/false
 * @returns {Function}
 */
export const editNotificationSettings = (lessonNotifState: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SETTING_EDIT_NOTIFICATION,
    });

    const requestData: { lesson_notif: boolean } = {
        lesson_notif: lessonNotifState,
    };

    dispatch(post(
        API_ROUTES.editNotification,
        requestData,
        (responseJson: any) => {
            dispatch({
                type: TYPE_SETTING_EDIT_NOTIFICATION_SUCCESS,
                data: responseJson,
            });
            dispatch(getUserDetails());
        },
        (error: any) => {
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
            dispatch({
                type: TYPE_SETTING_EDIT_NOTIFICATION_FAILURE,
            });
        },
    ));
};

export const getLanguages = (language: string) => (dispatch: any) => {
    const headers = {
        headers: { 'Accept-Language': language },
    };

    dispatch({
        type: TYPE_SETTING_GET_LANGUAGES,
    });

    axiosapi.post('/booking-corner/languages/', null, headers)
        .then(
            (response: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_LANGUAGES_SUCCESS,
                    data: response.data,
                });
            },
        ).catch(
            (error: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_LANGUAGES_FAILURE,
                });
                dispatch({
                    type: TYPE_CHANGE_ERROR_CATCHED,
                    data: true,
                });
            },
        );
};

export const getCountries = (language: string) => (dispatch: any) => {
    const headers = {
        headers: { 'Accept-Language': language },
    };

    dispatch({
        type: TYPE_SETTING_GET_COUNTRIES,
    });

    axiosapi.post('/booking-corner/countries/', null, headers)
        .then(
            (response: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_COUNTRIES_SUCCESS,
                    data: response.data,
                });
            },
        )
        .catch(
            (error: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_COUNTRIES_FAILURE,
                });
                dispatch({
                    type: TYPE_CHANGE_ERROR_CATCHED,
                    data: true,
                });
            },
        );
};

export const getBCClient = (language: string) => (dispatch: any) => {
    dispatch(
        get(
            `${API_ROUTES.bcClient}?lang=${language}`,
            (responseJson: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_BC_CLIENT,
                    data: responseJson.client,
                });
            },
            (error: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_BC_CLIENT,
                    data: null,
                });
                dispatch({
                    type: TYPE_CHANGE_ERROR_CATCHED,
                    data: true,
                });
            },
        ),
    );
};

export const getInputClient = (companyId: string, departmentId?: string) => (dispatch: any) => {
    dispatch(
        get(
            `${API_ROUTES.inputClient}?company_id=${companyId}${(departmentId && departmentId !== undefined) ? `&department_id=${departmentId}` : ''}`,
            (responseJson: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_INPUT_CLIENT,
                    data: responseJson,
                });
            },
            (error: any) => {
                dispatch({
                    type: TYPE_SETTING_GET_INPUT_CLIENT,
                    data: {},
                });
            },
        ),
    );
};

export const setInputClient = (data: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SETTING_SET_INPUT_CLIENT,
        data,
    });
};

export const saveClientSchoolWithExternalId = (
    externalId: string,
    departmentId: string | undefined = undefined,
) => (dispatch: any) => {
    dispatch(post(
        API_ROUTES.changeSchoolWithExternalId,
        { external_id: externalId, department_id: departmentId },
        () => {
            dispatch(getUserDetails());
        },
        (error: any) => {
            throw new Error(`${error}`);
        },
    ));
};
