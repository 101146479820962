import { MenuItem } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import { Participant, ParticipantValue } from '../../types/booking';
import DatePickerField from '../common/datePickerField';
import Numberfield from '../common/numberfield';
import SelectField from '../common/selectField';
import TextField from '../common/textfield';

// type ReduxProps = ConnectedProps<typeof connector>; // KROK Removed ReduxProps

interface IProps {
    participant: Participant
    participantID: number;

    onChangeIfEmpty(): void;
}

type Props = IProps; // & RouterProps & WrappedComponentProps; /* & ReduxProps */

interface State {
    canChangeParticipantData: boolean;
    error?: any;
    value: string;
    label: string;
}

class ParticipantField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { participant } = this.props;

        this.state = {
            canChangeParticipantData: true,
            error: null,
            value: participant.fieldType === 'select' ? '-1' : '',
            label: `${participant.fieldLabel}${participant.required ? ' *' : ''}`,
        };

        this.setError = this.setError.bind(this);
        this.unSetError = this.unSetError.bind(this);
        this.handleChangeTextField = this.handleChangeTextField.bind(this);
        this.handleChangeNumberField = this.handleChangeNumberField.bind(this);
        this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);
        this.getField = this.getField.bind(this);
        this.dateOnFocus = this.dateOnFocus.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    handleChangeTextField(event: any) {
        const { onChangeIfEmpty } = this.props;
        const { value } = this.state;
        this.setState({ value: event.target.value }, () => {
            if (value === '' || value === undefined) {
                onChangeIfEmpty();
            }
        });
    }

    handleChangeNumberField(event: any) {
        const { value } = event.target;
        if (value !== '' && !/^[0-9]+$/.test(value)) return;
        // if (value !== "" && ((min && value < min) || (max && value > max))) return;
        this.setState({ value });
    }

    handleChangeDatePicker(value: any) {
        // if(moment("01/01/1900", "DD/MM/YYYY").startOf('day').diff(value, 'day') >= 1) {
        //     this.setState({error: "Before 1900"})
        // }else if (moment().startOf('day').diff(value, 'day') <= 0) {
        //     this.setState({error: "Future"})

        // }else {
        //     this.setState({value: value, error: null});
        // }
        this.setState({ value, error: null });
    }

    handleChangeSelect(event: any) {
        this.setState({
            value: event.target.value,
            canChangeParticipantData: false,
        });
    }

    setError(text: string) {
        this.setState({ error: text });
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    setValue = (value: any, type: string, name?: string) => {
        const { participant } = this.props;
        if (!type) this.setState({ value });
        switch (type) {
            case 'text':
                this.handleChangeTextField({ target: { value } });
                break;
            case 'number':
                this.handleChangeNumberField({ target: { value } });
                break;
            case 'date':
                this.handleChangeDatePicker(value);
                break;
            case 'select': {
                if (name) {
                    let newValue = value;
                    for (const v of participant.values) {
                        if (v[name] === value) {
                            newValue = v.value;
                            break;
                        }
                    }
                    this.handleChangeSelect(({ target: { value: `${newValue}` } }));
                }
                break;
            }
        }
        this.unSetError();
    };

    getField() {
        const { participant, participantID } = this.props;
        const {
            canChangeParticipantData, value: valueState, label, error,
        } = this.state;
        let options = null;

        switch (participant.fieldType) {
            case 'date':
                return (
                    <DatePickerField
                        disabled={!canChangeParticipantData}
                        value={valueState && moment(valueState).isValid() ? moment(valueState) : undefined}
                        placeholder={label}
                        onChange={this.handleChangeDatePicker}
                        error={error}
                        disableFuture
                        shouldDisableDate={(day: Moment) => moment('01/01/1920', 'DD/MM/YYYY').startOf('day').diff(day, 'day') >= 1}
                        format="DD/MM/YYYY"
                        onFocus={this.dateOnFocus}
                    />
                );
            case 'select':
                options = participant.values.map((value: ParticipantValue, i: number) => {
                    const idx = i;
                    return (<MenuItem key={`participant_field_select_${value.label}${idx}`} value={value.value}>{value.label}</MenuItem>);
                });
                options.unshift(<MenuItem key="participant_field_select_disabled" value={-1} disabled>{label}</MenuItem>);
                return (
                    <SelectField
                        border
                        height="42px"
                        value={valueState || '-1'}
                        fontSize="16px"
                        padding="0px 15px 0px 0px"
                        onChange={this.handleChangeSelect}
                        error={error}
                        onFocus={this.unSetError}
                    >
                        {options}
                    </SelectField>
                );
            case 'text':
                return (
                    <TextField
                        disabled={!canChangeParticipantData}
                        className="__booking-participantField-textfield"
                        id={`${participant.fieldName}_${participantID}`}
                        value={valueState}
                        required={participant.required}
                        onChange={this.handleChangeTextField}
                        placeholder={label}
                        error={error}
                        onFocus={this.unSetError}
                    />
                );
            case 'number':

                return (
                    <div className="__booking-participantField-numberfield-parent">
                        <p className="__booking-participantField-numberfield-text">{label}</p>
                        <Numberfield
                            disabled={!canChangeParticipantData}
                            className="__booking-participantField-numberfield"
                            value={valueState}
                            onChange={this.handleChangeNumberField}
                            right
                            required={participant.required}
                            placeholder={label}
                            // {participant.minAge ? participant.minAge : 0}
                            min={1}// min={participant.minAge ? participant.minAge : 0}
                            // max={participant.maxAge ? participant.maxAge : undefined}
                            asText
                            forceValue
                            error={error}
                            onFocus={this.unSetError}
                        />
                    </div>
                );
            default:
                return null;
        }
    }

    dateOnFocus() {
        this.unSetError();
    }

    unSetError() {
        this.setState({ error: null });
    }

    render() {
        return this.getField();
    }
}

// const mapStateToProps = (store: ApplicationState) => ({

// });
// const connector = connect(mapStateToProps);
// KROK Removed those 2 lines above
// const connector = connect();

export default ParticipantField;
// export default ParticipantField; KROK Added withRouter and injectIntl
