import { Alert } from '@mui/material';
import { isEqual } from 'lodash';
import qs from 'qs';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router';
import { GOOGLE_STORAGE_API_CGA, WEBEVOLUTIONS_SAFERPAY_SCHOOLS } from '../../resources/constants';
import '../../resources/css/cart.css';
import {
    getBCClient, getCountries, getInputClient, getLanguages, setInputClient
} from '../../store/actions/settings';
import { openSignUpForm } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Lesson } from '../../types/booking';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import Title from '../common/title';
import OrderApi from '../order/api/order_api';
import ClaimLesson from './claimLesson';
import SettingsModal from './settingsModal';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    formatedItems: any;
    totalPrice: number;
    // totalPriceBC: any;
    payNow: boolean;
    hasNoUserData: boolean;
    accept: boolean;
    // unavailableLessons: Lesson[];
    checking: boolean;
    // familyDiscount: number;
    // internetDiscount: number;
    // promoDiscount: number;
    // subTotal: number;
    openSettingsModal: boolean;
    openNoLoginModal: boolean;
    // anchorEl: any;
    checkFailed: boolean;
    openCheckFailed: boolean;
    checked: boolean;
    inputClient: any;
    request: any;
    fetchedInputClient: boolean;
    errorMessage: string;
    isParticipantError: boolean;
    displayParticipantError: boolean;
    loadingClaim: boolean;
    code: string;
    noLogin: boolean;
    bookingCheckOnlyFailed?: boolean;
}

function toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
    if (typeof state[key] === 'boolean') {
        if (value !== undefined) {
            return { [key]: value } as Pick<State, T>;
        }

        return { [key]: !state[key] } as Pick<State, T>;
    }
    throw new Error(`Property ${key} is not a boolean`);
}

class Claim extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { intl } = this.props;

        this.state = {
            formatedItems: undefined,
            totalPrice: 0, // total price
            // totalPriceBC: undefined, // Booking Corner total price object
            payNow: false,
            hasNoUserData: false,
            accept: false,
            // unavailableLessons: [],
            checking: false,
            // familyDiscount: 0,
            // internetDiscount: 0,
            // promoDiscount: 0,
            // subTotal: 0,
            openSettingsModal: false,
            openNoLoginModal: false,
            // anchorEl: undefined,
            checkFailed: false,
            openCheckFailed: false,
            checked: false,
            inputClient: null,
            request: {},
            fetchedInputClient: false,
            errorMessage: intl.formatMessage({ id: 'order.checkFailed' }),
            isParticipantError: false,
            displayParticipantError: false,
            loadingClaim: false,
            code: '',
            noLogin: false,
        };

        this.formatItems = this.formatItems.bind(this);
        this.checkAccept = this.checkAccept.bind(this);
    }

    componentDidMount() {
        const {
            languages, language, countries, bcClient, location, dispatch,
        } = this.props;
        document.getElementById('top')?.scrollIntoView(); // KROK Added ? after document.getElementById('top')

        if (Object.keys(languages).length === 0) {
            dispatch(getLanguages(language));
        }

        if (Object.keys(countries).length === 0) {
            dispatch(getCountries(language));
        }

        if (!bcClient && Authenticator.isLoggedIn()) {
            dispatch(getBCClient(language));
        }

        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
            comma: true,
        });
        if (query.login && query.login === 'no') {
            this.toggleOpenModal('openNoLoginModal');
        }
    }

    componentDidUpdate(prevProps:Props) {
        const {
            formatedItems,
            checked,
            loadingClaim, inputClient:
            inputClientState,
            fetchedInputClient,
        } = this.state;
        const {
            currentSchool,
            match,
            history,
            location,
            language,
            userData,
            intl,
            inputClient:
            inputClientProps,
            dispatch,
        } = this.props;
        // if the current school as not online purchase redirect to landing
        if (currentSchool && !currentSchool.online_purchase) {
            history.replace(`/${match.params.lang}/`);
        }

        if ((!checked || !isEqual(userData, prevProps.userData)) && !loadingClaim && currentSchool !== undefined) {
            const query = qs.parse(location.search, {
                ignoreQueryPrefix: true,
                comma: true,
            });
            if (query.id) {
                this.setState({ loadingClaim: true });
                if (query.login && query.login === 'no') {
                    OrderApi.claimPaymentNoLogin({
                        id: query.id,
                        schoolId: currentSchool.int_id,
                        departmentId: currentSchool.department_id,
                        lang: language,
                    })
                        .then((response) => {
                            if (response.Code) {
                                this.claimSuccess(response, true);
                            } else if (response.error) {
                                this.claimError(response.error);
                            } else {
                                this.setDefaultError();
                            }
                        })
                        .catch(() => {
                            this.setDefaultError();
                        });
                } else if (Authenticator.isLoggedIn()) {
                    OrderApi.claimPayment({
                        id: query.id,
                        schoolId: currentSchool.int_id,
                        departmentId: currentSchool.department_id,
                        lang: language,
                        commonId: userData.cid,
                        email: userData.user.email,
                    })
                        .then((response) => {
                            if (response.Code) {
                                this.claimSuccess(response, false);
                            } else if (response.error) {
                                this.claimError(response.error);
                            } else {
                                this.setDefaultError();
                            }
                        })
                        .catch(() => {
                            this.setDefaultError();
                        });
                } else {
                    OrderApi.claimPayment({
                        id: query.id,
                        schoolId: currentSchool.int_id,
                        departmentId: currentSchool.department_id,
                        lang: language,
                        beforeLogin: true,
                    })
                        .then((response) => {
                            if (response.Code) {
                                this.claimSuccess(response, false);
                            } else if (response.error) {
                                this.claimError(response.error);
                            } else {
                                this.setDefaultError();
                            }
                        })
                        .catch(() => {
                            this.setDefaultError();
                        });
                }
            } else {
                this.setDefaultError(intl.formatMessage({ id: 'order.claim.error.sessionId' }));
            }
        }
        // if (!Authenticator.isLoggedIn()) {
        //     isSignUpVisible === false && dispatch(openSignUpForm());
        // } else {
        if (!formatedItems || formatedItems.length === 0 || !currentSchool) return;

        // Temporary
        // if (!checking && !checked && !checkFailed
        //  && Object.keys(userData).length > 0 && bcClient) {
        //     this.setState({ checking: true }, () => this.checkBooking());
        // }

        if (!inputClientProps && !fetchedInputClient) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const schoolId = currentSchool.int_id;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { department_id } = currentSchool;
            this.setState({ fetchedInputClient: true }, () => {
                dispatch(
                    getInputClient(
                        schoolId,
                        department_id,
                    ),
                );
            });
        } else if (inputClientProps
            && Object.keys(inputClientProps).length > 0 && !inputClientState) {
            const inputClient: any = [];
            Object.keys(inputClientProps).forEach((key) => {
                if (key.includes('Required') && inputClientProps[key] === true) { inputClient.push(key.replace('Required', '').toLowerCase()); }
            });
            this.setState({ inputClient });
        }
        // }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(setInputClient(undefined));
    }

    claimSuccess = (response: any, noLogin: any) => {
        const bookings = response.Bookings ? response.Bookings : [];
        const { request } = this.state;
        request.Client = response.Client;
        const boooks = bookings.map((itemx: any, i: number) => {
            const item = itemx;
            item.checkId = i;
            return item;
        });
        this.setState({
            formatedItems: this.formatItems(boooks),
            checkFailed: false,
            openCheckFailed: false,
            checked: true,
            // subTotal: response.TotalPrice,
            // familyDiscount: response.FamilyDiscount,
            // internetDiscount: response.InternetDiscount,
            // promoDiscount: response.PromoDiscount,
            totalPrice: response.ToPay,
            code: response.Code,
            loadingClaim: false,
            noLogin,
            request,
        });
    };

    claimError = (error: string) => {
        const { intl } = this.props;
        if (error === 'Nothing to pay') {
            this.setDefaultError(intl.formatMessage({ id: 'order.claim.error.nothingToPay' }));
        } else if (error === 'Bad claim payment ID') {
            this.setDefaultError(intl.formatMessage({ id: 'order.claim.error.badClaimId' }));
        } else if (error === 'Booking not found') {
            this.setDefaultError(intl.formatMessage({ id: 'order.claim.error.bookingNotFound' }));
        } else if (error === 'Bad client') {
            this.setDefaultError(intl.formatMessage({ id: 'order.claim.error.badClient' }));
        } else {
            this.setDefaultError();
        }
    };

    setDefaultError = (messageProps?: string) => {
        const { intl } = this.props;
        let message;
        if (messageProps === undefined) {
            message = intl.formatMessage({ id: 'order.claim.error' });
        } else {
            message = messageProps;
        }
        this.setState({
            formatedItems: [],
            checked: true,
            loadingClaim: false,
            errorMessage: message,
            checkFailed: true,
            openCheckFailed: true,
        });
    };

    handleClickOrder = () => {
        const { dispatch } = this.props;
        const { isParticipantError, noLogin } = this.state;
        const isLoggedIn = Authenticator.isLoggedIn();
        if (isParticipantError) {
            this.setState({ displayParticipantError: true });
        } else if (noLogin) {
            this.handleSettingsCheck();
        } else if (isLoggedIn) {
            this.toggleOpenModal('openSettingsModal');
        } else {
            dispatch(openSignUpForm());
        }
    };

    handleSettingsCheck = async () => {
        const {
            request, noLogin, totalPrice, code,
        } = this.state;
        const { userData, currentSchool } = this.props;
        if (!noLogin) {
            const user = userData;
            request.Client.CommonId = parseInt(user.cid, 10);
            request.Client.Email = user.user.email;
            request.Client.Name = user.user.last_name ? user.user.last_name : '';
            request.Client.Firstname = user.user.first_name ? user.user.first_name : '';
            request.Client.Address = user.address ? user.address : '';
            request.Client.AddressLocal = user.address_local ? user.address_local : '';
            request.Client.Zip = user.zip ? user.zip : '';
            request.Client.Town = user.town ? user.town : '';
            request.Client.Phone = user.phone ? user.phone : '';
            request.Client.Mobile = user.phone ? user.phone : '';
            request.Client.Sex = 'm';
            request.Client.LanguageId = user.language;
            request.Client.CountryId = user.country;
        }

        request.toPay = totalPrice;
        request.code = code;
        request.schoolId = currentSchool?.int_id;
        request.departmentId = currentSchool?.department_id;

        this.checkBooking(
            () => this.setState(
                {
                    request,
                    openSettingsModal: false,
                },
                () => this.setState({ payNow: true }),
            ),
            () => this.setState({
                bookingCheckOnlyFailed: true,
                openSettingsModal: false,
            }),
        );
        // // const response = await OrderApi.bookingCheckOnly(request);
        // this.setState({
        // request,
        // openSettingsModal: false },
        // () => this.setState({ payNow: true }));
    };

    /* Check booking */

    checkBooking = (callBackSucces: any = undefined, callBackError: any = undefined) => {
        const {
            formatedItems, noLogin, totalPrice, code,
        } = this.state;
        const { userData, currentSchool } = this.props;
        if (!formatedItems || formatedItems.length <= 0) return;

        const { request } = this.state;
        if (!userData) return;

        if (!noLogin) {
            request.Client.CommonId = parseInt(userData.cid, 10);
            request.Client.Email = userData.user.email;
            request.Client.Name = userData.user.last_name ? userData.user.last_name : '';
            request.Client.Firstname = userData.user.first_name ? userData.user.first_name : '';
            request.Client.Address = userData.address ? userData.address : '';
            request.Client.AddressLocal = userData.address_local ? userData.address_local : '';
            request.Client.Zip = userData.zip ? userData.zip : '';
            request.Client.Town = userData.town ? userData.town : '';
            request.Client.Phone = userData.phone ? userData.phone : '';
            request.Client.Mobile = userData.phone ? userData.phone : '';
            request.Client.Sex = 'm';
            request.Client.LanguageId = userData.language;
            request.Client.CountryId = userData.country;
            // request['Client']["Participants"] = bcClient?.Participants
            //  ?bcClient.Participants : []
        }

        request.TransactionPrice = totalPrice;
        request.Code = code;
        request.schoolId = currentSchool?.int_id;
        request.departmentId = currentSchool?.department_id;

        OrderApi.checkNewPayment(request)
            .then((response) => {
                if (response.success) {
                    this.setState({ request });
                    if (callBackSucces) callBackSucces();
                } else {
                    this.setState({ checkFailed: true, openCheckFailed: true });
                    this.setDefaultError();
                    if (callBackError) callBackError();
                }
            })
            .catch(() => {
                this.setState({ checkFailed: true, openCheckFailed: true });
                this.setDefaultError();
                if (callBackError) callBackError();
            });
    };

    /* Other */

    toggleOpenModal = (name: any) => this.setState(
        (state) => toggleStateKey(name as keyof State, state),
    );

    goHome = () => {
        const { history, language } = this.props;
        history.push(`/${language}/products`);
    };

    checkAccept() {
        this.setState((state) => ({ accept: !state.accept }));
    }

    formatItems(lessons: Lesson[]) {
        const { currentSchool } = this.props;
        // let isParticipantError = false;
        const tmpLessons = lessons.map((lesson: Lesson, i: number) => {
            const idx = i;
            // const calculatedNbParticipants = lesson.Participants
            //      ? lesson.Participants.length : 0;
            // if (calculatedNbParticipants !== parseInt(
            //     lesson.NbParticipants,
            //     10
            // )) isParticipantError = true;
            return (
                <ClaimLesson key={`item_${idx}`} lesson={lesson} schoolName={currentSchool ? currentSchool?.name : ' - '} />
            );
        });
        // this.setState({ isParticipantError: isParticipantError });

        return tmpLessons;
    }

    render() {
        const {
            formatedItems,
            loadingClaim,
            noLogin,
            request,
            checkFailed,
            errorMessage,
            payNow,
            openNoLoginModal,
            accept,
            totalPrice,
            hasNoUserData,
            checking,
            checked,
            openCheckFailed,
            openSettingsModal,
            bookingCheckOnlyFailed,
            displayParticipantError,
        } = this.state;
        const {
            currentSchool, userData, schools, intl, history, match, language,
        } = this.props;

        let clientId = '';

        if (noLogin) {
            clientId = request.Client.Id;
        } else if (userData && userData.user) {
            clientId = userData.user.id;
        }

        // go to order init page if payNow is true
        if (payNow) {
            if (!noLogin && Object.keys(userData).length === 0) {
                this.setState({ hasNoUserData: true });
            } else {
                return (
                    <Redirect
                        push
                        to={{
                            pathname: `/${match.params.lang}/order/claim`,
                            state: {
                                request,
                                email: noLogin ? request.Client.Email : userData.user.email,
                                noLogin,
                                clientId,
                            },
                        }}
                    />
                );
            }
        }

        let tncPath;
        if (currentSchool) {
            tncPath = schools.filter(
                (s: any) => Number(s.int_id) === Number(currentSchool.int_id),
            );
            tncPath = tncPath.length > 0 ? tncPath[0].tnc_path : undefined;
        }

        const checkBoxLabel = formatedItems?.length > 0 && schools.length > 0 ? (
            <span className="__cart-checkbox-label">
                {intl.formatMessage({ id: 'order.checkBoxMessage1' })}
                <a href={tncPath} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: 'help.cga' })}</a>
                {intl.formatMessage({ id: 'order.checkBoxMessage2' })}
                <a href={`${GOOGLE_STORAGE_API_CGA}Reglement-General-sur-la-Protection-des-Donnees.pdf`} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: 'order.jdpr' })}</a>
                <br />
                {intl.formatMessage({ id: 'order.checkBoxMessage3' }, { amount: totalPrice.toFixed(2) })}
            </span>
        ) : <LoadingCircle />;

        let returnElement;

        if (loadingClaim || !formatedItems) {
            returnElement = (
                <div className="__order-parent">
                    <p className="__order-text">{intl.formatMessage({ id: 'order.claim.loading' })}</p>
                    <LoadingCircle />
                </div>
            );
        } else if (checkFailed) {
            returnElement = (
                <div className="__cart-no-items-container">
                    <Title className="__cart-no-items">{errorMessage}</Title>
                </div>
            );
        } else if (formatedItems.length > 0) {
            returnElement = (
                <>
                    <div className="__cart-content">
                        {formatedItems}
                    </div>
                    <div className="__cart-footer">
                        {
                            currentSchool
                                ? (
                                    <div className="__cart-important-parent">
                                        {WEBEVOLUTIONS_SAFERPAY_SCHOOLS.indexOf(
                                            parseInt(
                                                currentSchool.int_id,
                                                10,
                                            ),
                                        ) < 0
                                            && (
                                                <>
                                                    <p className="__cart-important-primary">{intl.formatMessage({ id: 'order.important1' })}</p>
                                                    <p className="__cart-important-primary">{intl.formatMessage({ id: 'order.important2' })}</p>
                                                    <p className="__cart-important-info">Booking Corner</p>
                                                </>
                                            )}
                                        <p className="__cart-important-secondary">{intl.formatMessage({ id: 'order.important3' })}</p>
                                        <p className="__cart-important-secondary">{intl.formatMessage({ id: 'order.important4' })}</p>
                                        <p className="__cart-important-secondary">{intl.formatMessage({ id: 'order.important5' })}</p>
                                    </div>
                                )
                                : null
                        }
                        <div className="__cart-important-parent">
                            <Alert
                                sx={{
                                    background: '#FFF4E5 !important'
                                }}
                                style={{
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    background: '#FFF4E5 !important'
                                }}
                                severity="warning"
                            >
                                {intl.formatMessage({ id: 'order.importantTwint' })}
                            </Alert>
                            {
                                openNoLoginModal
                                && (
                                    <Alert
                                        style={{
                                            textAlign: 'left', width: '100%', marginTop: '10px', fontSize: '16px', fontFamily: 'Montserrat',
                                        }}
                                        severity="info"
                                    >
                                        {intl.formatMessage({ id: 'order.claim.noLoginModal.content' })}
                                    </Alert>
                                )
                            }
                        </div>
                        <div className="__cart-footer-checkbox">
                            <div>
                                <Checkbox
                                    inputFirst
                                    checked={accept}
                                    onChange={this.checkAccept}
                                    label={checkBoxLabel}
                                />
                            </div>
                        </div>
                        <div className="__cart-footer-bottom">
                            <div className="__cart-footer-bottom-right">
                                <div className="__cart-footer-price">
                                    {/* {
                                        subTotal > 0 &&
                                        <p
                                        className="__cart-price-subtotal">
                                        {intl.formatMessage({
                                            id: 'order.subTotal'
                                        })} {subTotal.toFixed(2)} CHF
                                        </p>
                                    }{
                                        familyDiscount > 0 && (
                            totalPriceBC && totalPriceBC.FamilyDiscountText ?
                                                <p className="__cart-discount">
                                                    {
                                        `${totalPriceBC.FamilyDiscountText}:
                                        -${familyDiscount.toFixed(2)} CHF`}
                                                </p>
                                                :
                                                <p className="__cart-discount">
                                                    {
                                                        `${intl.formatMessage({
                                                    id: 'order.familyDiscount'
                                                        })}:
                                            -${familyDiscount.toFixed(2)} CHF`}
                                                </p>
                                        )
                                    }
                                    {
                                        internetDiscount > 0 && (
                            totalPriceBC && totalPriceBC.InternetDiscountText ?
                                                <p className="__cart-discount">
                                                    {
                                    `${totalPriceBC.InternetDiscountText}:
                                        -${internetDiscount.toFixed(2)} CHF`}
                                                </p>
                                                :
                                                <p className="__cart-discount">
                                                    {`${intl.formatMessage({
                                                id: 'order.internetDiscount'
                                    })}: -${internetDiscount.toFixed(2)} CHF`}
                                                </p>
                                        )
                                    } */}
                                    {/* {
                                        subTotal > 0 &&
                                        <p
                                        className="__cart-price-subtotal">
                                        {intl.formatMessage({
                                            id: 'booking.total'
                                        })}:
                                        {subTotal.toFixed(2)} CHF
                                        </p>
                                    } */}
                                    <p className="__cart-price-total">
                                        {intl.formatMessage({ id: 'order.balance' })}
                                        :
                                        {' '}
                                        {totalPrice.toFixed(2)}
                                        {' '}
                                        CHF
                                    </p>
                                </div>
                                <div className="__cart-button">
                                    <Button buttonClasses="__cart-button-continue" containerClasses="__cart-button-continue-container" onClick={this.goHome}>
                                        {intl.formatMessage({ id: 'order.continueShop' })}
                                    </Button>
                                    <Button
                                        buttonClasses="__cart-button-pay"
                                        containerClasses=""
                                        onClick={this.handleClickOrder}
                                        loading={hasNoUserData || checking}
                                        disabled={
                                            !accept
                                            || hasNoUserData
                                            || schools.length <= 0
                                            || checking
                                            || checkFailed
                                            || (
                                                !checked
                                                && Authenticator.isLoggedIn() !== undefined
                                            )
                                            || totalPrice <= 0
                                        }
                                    >
                                        {intl.formatMessage({ id: checking ? 'order.checking' : 'order.pay' })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            returnElement = (
                <div className="__cart-no-items-container">
                    <Title className="__cart-no-items">{intl.formatMessage({ id: 'order.claim.noItems' })}</Title>
                    <Button buttonClasses="__cart-no-items-button" containerClasses="__cart-no-items-button-container" onClick={this.goHome}>
                        {intl.formatMessage({ id: 'order.continueShop' })}
                    </Button>
                </div>
            );
        }

        return (
            <Container
                headerBase={`${intl.formatMessage({ id: 'header.claim' })}${currentSchool ? ` → ${currentSchool.name}` : ''}`}
            >
                <div className="__cart-parent">
                    {
                        returnElement
                    }
                </div>
                {
                    openSettingsModal && (
                        <SettingsModal
                            open={openSettingsModal}
                            onClose={() => this.toggleOpenModal('openSettingsModal')}
                            onCheck={() => {
                                this.handleSettingsCheck();
                            }}
                            buttonText={intl.formatMessage({ id: checked ? 'order.pay' : 'order.check' })}
                        />
                    )
                }
                <Snackbar
                    variant="warning"
                    open={openCheckFailed}
                    timer={6000}
                    onClose={() => this.setState({ openCheckFailed: false })}
                    message={errorMessage}
                />
                <Snackbar
                    variant="warning"
                    open={bookingCheckOnlyFailed}
                    message={(
                        /* eslint-disable-next-line
                        jsx-a11y/no-static-element-interactions,
                        jsx-a11y/click-events-have-key-events */
                        <span style={{ cursor: 'pointer' }} onClick={() => history.push(`/${language}/account/settings`)}>
                            {intl.formatMessage({ id: 'order.bookingCheckOnlyFailed' })}
                            <p style={{ fontWeight: 'bold' }}>{intl.formatMessage({ id: 'order.bookingCheckOnlyFailedLink' })}</p>
                        </span>
                    )}
                    timer={5000}
                    onClose={() => this.setState({ bookingCheckOnlyFailed: false })}
                />
                <Snackbar
                    variant="error"
                    open={displayParticipantError}
                    onClose={() => this.setState({ displayParticipantError: false })}
                    timer={5000}
                    message={intl.formatMessage({ id: 'order.nbParticipantsError' })}
                />
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    userData: store.setting.userData,
    schools: store.setting.schools,
    language: store.translation.language,
    countries: store.setting.countries,
    languages: store.setting.languages,
    bcClient: store.setting.bcClient,
    inputClient: store.setting.inputClient,
    currentSchool: store.navigation.currentSchool,
    isSignUpVisible: store.user.isSignUpVisible,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Claim));
