/* eslint-disable
jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */
import {
    Badge,
    IconButton,
    SwipeableDrawer
} from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../resources/css/hamburger.css';
import AccountLogged from '../../resources/img/account-logged.png';
import Account from '../../resources/img/account-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import Cart from '../../resources/img/cart.svg';
import { getTranslation } from '../../resources/utils';
import { changeNavigationCurrentPage } from '../../store/actions/navigation';
import { changeMyAccountCurrentTab, openSignUpForm } from '../../store/actions/user';
import { toggleHamburger } from '../../store/actions/windowSize';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { LessonCart } from '../../types/booking';
import { RouterProps } from '../../types/generalTypes';
import { ShopNavigationMenu, ShopNavigationSubMenu } from '../../types/settings';
import { Tabs } from '../account/constants';
import Authenticator from '../authenticator';
import Expandable from './expandable';
import SideBar from './sideBar';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    loggedIn?: string;
}

/**
 * Component that represent the hamburger menu (only for smartphone)
 */
class Hamburger extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loggedIn: Authenticator.isLoggedIn(),
        };

        // bind methods
        this.handleClickOnProfile = this.handleClickOnProfile.bind(this);
        this.handleClickOnCart = this.handleClickOnCart.bind(this);
        this.handleSideBarClick = this.handleSideBarClick.bind(this);
    }

    handleClickOnCart() {
        const { history, match } = this.props;
        this.toggleDrawer();
        history.push(`/${match.params.lang}/cart`);
    }

    handleClickOnProfile() {
        this.toggleDrawer();
        const {
            history, match, runOpenSignUpForm, runChangeMyAccountCurrentTab,
        } = this.props;

        const isLoggedIn = Authenticator.isLoggedIn();
        if (isLoggedIn) {
            history.push(`/${match.params.lang}/account`);
            runChangeMyAccountCurrentTab(Tabs.mylessons);
        } else {
            runOpenSignUpForm();
        }
    }

    handleSideBarClick(tab: string) {
        this.toggleDrawer();
        const { runChangeMyAccountCurrentTab, history, language } = this.props;

        runChangeMyAccountCurrentTab(tab);
        history.push(`/${language}/account/${tab}`);
    }

    toggleDrawer = () => {
        const { runToggleHamburger } = this.props;
        runToggleHamburger();
    };

    handleClickMenu = (menu: any) => {
        const {
            language, match, runToggleHamburger, runChangeNavigationCurrentPage, history,
        } = this.props;
        const link = getTranslation(menu.translations, language);
        if (link.page.includes('http')) {
            window.location.href = `${link.page}`;
        } else {
            const { params } = match;
            runChangeNavigationCurrentPage(menu);
            history.push(`/${params.lang}/pages/${menu.title.replace(' ', '-')}`);
            runToggleHamburger();
        }
    };

    handleClickSubMenu = (menu: any, submenu: any) => {
        const {
            match, history, runToggleHamburger, runChangeNavigationCurrentPage,
        } = this.props;
        const { params } = match;
        history.push(`/${params.lang}/pages/${menu.title.replace(' ', '-')}/${submenu.title.replace(' ', '-')}`);
        runChangeNavigationCurrentPage(submenu);
        runToggleHamburger();
    };

    render() {
        const {
            firstName, lastName, companyName, intl, open, currentSchool, menus, language,
        } = this.props;
        const { loggedIn } = this.state;
        const accountImage = loggedIn ? AccountLogged : Account;

        const accountText = (
            loggedIn
                ? (
                    <div className="__hamburger-top-text-logged">
                        <p className="__hamburger-top-text">{`${firstName} ${lastName}`}</p>
                        {
                            companyName
                                ? <div className="__hamburger-top-text-divider" />
                                : null
                        }
                        {
                            companyName
                                ? <p className="__hamburger-top-text">{companyName}</p>
                                : null
                        }
                    </div>
                )
                : <p className="__hamburger-top-text">{intl.formatMessage({ id: 'header.connect' })}</p>
        );

        const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

        // let cart = Cookie.get('cart');
        const jsonCart = localStorage?.getItem('cart');
        const cart: LessonCart[] = JSON.parse(jsonCart || '[]');

        return (
            <SwipeableDrawer
                anchor="right"
                onOpen={this.toggleDrawer}
                open={open}
                onClose={this.toggleDrawer}
                disableBackdropTransition={!iOS}
                disableSwipeToOpen
                disableDiscovery={iOS}
                PaperProps={{
                    style: { maxWidth: '80%', height: '100%' },
                }}
            >
                <div className="__hamburger-parent">
                    <div className="__hamburger-content">
                        <div className="__hamburger-top-div">
                            {
                                (currentSchool && currentSchool.online_purchase)
                                && (
                                    <span className="__hamburger-top-icon">
                                        <IconButton onClick={this.handleClickOnCart} size="large">
                                            <Badge badgeContent={cart ? cart.length : 0} color="primary">
                                                <img src={Cart} alt={intl.formatMessage({ id: 'header.cart' })} className="__hamburger-top-image" />
                                            </Badge>
                                        </IconButton>
                                        <p className="__hamburger-top-text">{intl.formatMessage({ id: 'header.cart' })}</p>
                                    </span>
                                )
                            }
                            <span className="__hamburger-top-icon">
                                <IconButton
                                    onClick={this.handleClickOnProfile}
                                    style={{ width: 'fit-content', margin: 'auto' }}
                                    size="large"
                                >
                                    <img src={accountImage} alt={intl.formatMessage({ id: 'header.account' })} className="__hamburger-top-image" />
                                </IconButton>
                                {accountText}

                            </span>
                        </div>
                        {menus && menus.length > 0 && (
                            <div>
                                <div className="__hamburger-divider" />
                                <div className="__hamburger-menu-parent">
                                    {menus.map((m: any) => {
                                        const menuDisplay = m.translations.find(
                                            (t: any) => t.lang === language,
                                        );
                                        return (
                                            menuDisplay && menuDisplay.display && (
                                                <div key={`hamburger-menu-${m.id}`}>
                                                    {m.submenus.length > 0 ? (
                                                        <Expandable
                                                            title={getTranslation(
                                                                m.translations,
                                                                language,
                                                            ).title}
                                                            headerClasses="__hamburger-expandable-header"
                                                            containerClasses="__hamburger-expandable-container"
                                                        >
                                                            {m.submenus.map((s: any) => {
                                                                const subMenuDisplay = s.translations.find((t: any) => t.lang === language);
                                                                return (subMenuDisplay && subMenuDisplay.display && (
                                                                    <div
                                                                        key={`hamburger-submenu-${s.id}`}
                                                                        onClick={() => !s.isParent && this.handleClickSubMenu(m, s)}
                                                                        // onKeyDown={() => !s.isParent && this.handleClickSubMenu(m, s)}
                                                                        className="__hamburger-expandable-item"
                                                                    >
                                                                        <p style={s.isChild ? { paddingLeft: '20px' } : {}}>{getTranslation(s.translations, language) && getTranslation(s.translations, language).title}</p>
                                                                    </div>
                                                                ));
                                                            })}
                                                        </Expandable>
                                                    )
                                                        : <p className="__menubar-menu-button" onClick={() => this.handleClickMenu(m)}>{getTranslation(m.translations, language) && getTranslation(m.translations, language).title}</p>}
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {
                            loggedIn
                            && (
                                <div>
                                    <div className="__hamburger-divider" />
                                    <Expandable
                                        title={`${firstName} ${lastName}`}
                                        headerClasses="__hamburger-expandable-header"
                                        containerClasses="__hamburger-expandable-container"
                                    >
                                        <SideBar onClick={this.handleSideBarClick} />
                                    </Expandable>
                                </div>
                            )
                        }
                    </div>
                </div>
            </SwipeableDrawer>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    open: store.windowSize.hamburgerMenuOpen,
    firstName: store.setting.userData && store.setting.userData.user ? store.setting.userData.user.first_name : '',
    lastName: store.setting.userData && store.setting.userData.user ? store.setting.userData.user.last_name : '',
    companyName: store.setting.userData && store.setting.userData.company ? store.setting.userData.company.name : '',
    language: store.translation.language,
    menus: store.navigation.menus,
    currentSchool: store.navigation.currentSchool,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    // runSetImagesForLessons: (image: string[]) => dispatch(setImagesForLessons(image)),
    // runChangeMyAccountCurrentTab: (currentTab: string) => dispatch(changeMyAccountCurrentTab(currentTab)),
    runOpenSignUpForm: () => dispatch(openSignUpForm()),
    runChangeMyAccountCurrentTab: (currentTab: string) => dispatch(changeMyAccountCurrentTab(currentTab)),
    runChangeNavigationCurrentPage: (page: ShopNavigationMenu | ShopNavigationSubMenu) => dispatch(changeNavigationCurrentPage(page)),
    runToggleHamburger: () => dispatch(toggleHamburger()),

});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(withRouter(connector(Hamburger)));
