import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { booking, BOOKING_INIT_STATE } from './booking';
import { help, HELP_INIT_STATE } from './help';
import { landing, LANDING_INIT_STATE } from './landing';
import { messages, MESSAGE_INIT_STATE } from './message';
import { navigation, NAVIGATION_INIT_STATE } from './navigation';
import { newsfeed, NEWSFEED_INIT_STATE } from './newsfeed';
import { products, PRODUCTS_INIT_STATE } from './products';
import { setting, SETTINGS_INIT_STATE } from './settings';
import { translation, TRANSLATION_INIT_STATE } from './translation';
import { user, USER_INIT_STATE } from './user';
import { windowSize, WINDOW_SIZE_INIT_STATE } from './windowSize';

export const APP_INIT_STATE = {
    user: USER_INIT_STATE,
    newsfeed: NEWSFEED_INIT_STATE,
    message: MESSAGE_INIT_STATE,
    landing: LANDING_INIT_STATE,
    products: PRODUCTS_INIT_STATE,
    booking: BOOKING_INIT_STATE,
    windowSize: WINDOW_SIZE_INIT_STATE,
    translation: TRANSLATION_INIT_STATE,
    help: HELP_INIT_STATE,
    setting: SETTINGS_INIT_STATE,
    navigation: NAVIGATION_INIT_STATE,
};

//  The combining of reduxer is done in pawjs tapable
const rootReducer = combineReducers({
    user,
    newsfeed,
    message: messages,
    landing,
    form: reduxFormReducer,
    products,
    booking,
    windowSize,
    translation,
    help,
    setting,
    navigation,
});

export default rootReducer;
