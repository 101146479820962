import { Tabs } from '../../components/account/constants';
import { StoreAction, UserState } from '../types';

export const SignupForms = Object.freeze({
    signup: 0, login: 1, forgotPassword: 2, forgotMessage: 3,
});

export const USER_INIT_STATE: UserState = {
    count: 0,
    message: '',
    isRegisterVisible: false,
    isLoginVisible: false,
    isSignUpVisible: false,
    isLoading: false,
    signupVisibleForm: SignupForms.login,
    isErrorVisible: false,
    errorText: '',
    myAccountCurrentTab: Tabs.mylessons,
    openPWAModal: false,
    cartChanged: false,
    errorCatched: false,
    errorMessage: null,
    myLessons: [],
    myLessonDetails: {
        Price: 0,
        From: '',
        To: '',
        Prestation: '',
        NbDays: 0,
        MeetingPoint: '',
        Instructor: '',
        NbParticipants: 0,
        Participants: [],
        Options: [],
        Remark: '',
    },
    isMyLessonDetailsLoading: false,
    isMyLessonsLoading: null,
    resetPasswordTokenEmail: null,
    resetPasswordTokenExists: null,
    resetPasswordTokenError: null,
    resetPasswordMessage: '',
    comeFromSkiResortCounter: false,
    medals: [],
    isMedalsLoading: false,
    isLoadingImages: false,
    lessonsImages: [],
};

export const TYPE_INCREMENT = ('USER_INCREMENT_USER');
export const TYPE_DECREMENT = ('USER_DECREMENT_USER');
export const TYPE_MESSAGE = ('USER_MESSAGE_USER');
export const TYPE_INIT_REGISTER = ('USER_INIT_REGISTER');
export const TYPE_CLOSE_REGISTER = ('USER_CLOSE_REGISTER');
export const TYPE_REGISTER_USER = ('USER_TYPE_REGISTER_USER');
export const TYPE_REGISTER_USER_SUCCESS = ('USER_TYPE_REGISTER_USER_SUCCESS');
export const TYPE_REGISTER_USER_FAILURE = ('USER_TYPE_REGISTER_USER_FAILURE');
export const TYPE_LOGIN_USER = ('USER_TYPE_LOGIN_USER');
export const TYPE_LOGIN_USER_SUCCESS = ('USER_TYPE_LOGIN_USER_SUCCESS');
export const TYPE_LOGIN_USER_FAILURE = ('USER_TYPE_LOGIN_USER_FAILURE');
export const TYPE_SHOW_SIGN_UP = ('USER_TYPE_SHOW_SIGN_UP');
export const TYPE_HIDE_SIGN_UP = ('USER_TYPE_HIDE_SIGN_UP');
export const TYPE_SHOW_LOADER = ('USER_TYPE_SHOW_LOADER');
export const TYPE_HIDE_LOADER = ('USER_TYPE_HIDE_LOADER');
export const TYPE_CHANGE_SIGNUP_FORM = ('USER_TYPE_CHANGE_SIGNUP_FORM');
export const TYPE_SHOW_SIGNUP_ERROR = ('USER_TYPE_SHOW_SIGNUP_ERROR');
export const TYPE_HIDE_SIGNUP_ERROR = ('USER_TYPE_HIDE_SIGNUP_ERROR');
export const TYPE_MY_ACCOUNT_CURRENT_TAB = ('USER_MY_ACCOUNT_CURRENT_TAB');
export const TYPE_TOGGLE_OPEN_PWA_MODAL = ('USER_TOGGLE_OPEN_PWA_MODAL');
export const TYPE_CHANGE_CART = ('USER_CHANGE_CART');
export const TYPE_CHANGE_ERROR_CATCHED = ('USER_CHANGE_ERROR_CATCHED');
export const TYPE_FETCH_MY_LESSONS = ('USER_FETCH_MY_LESSONS');
export const TYPE_FETCH_MY_LESSONS_SUCCESS = ('USER_FETCH_MY_LESSONS_SUCCESS');
export const TYPE_FETCH_MY_LESSONS_FAILURE = ('USER_FETCH_MY_LESSONS_FAILURE');
export const TYPE_FETCH_MY_LESSON_DETAILS = ('USER_FETCH_MY_LESSON_DETAILS');
export const TYPE_FETCH_MY_LESSON_DETAILS_SUCCESS = ('USER_FETCH_MY_LESSON_DETAILS_SUCCESS');
export const TYPE_FETCH_MY_LESSON_DETAILS_FAILURE = ('USER_FETCH_MY_LESSON_DETAILS_FAILURE');
export const TYPE_GET_RESET_PASSWORD_TOKEN = ('USER_TYPE_GET_RESET_PASSWORD_TOKEN');
export const TYPE_GET_RESET_PASSWORD_TOKEN_ERROR = ('USER_TYPE_GET_RESET_PASSWORD_TOKEN_ERROR');
export const TYPE_SET_RESET_PASSWORD_MESSAGE = ('USER_TYPE_SET_RESET_PASSWORD_MESSAGE');
export const TYPE_SET_COME_FROM_SKI_RESORT_COUNTER = ('USER_TYPE_SET_COME_FROM_SKI_RESORT_COUNTER');
export const TYPE_FETCH_MEDALS = ('USER_FETCH_MEDALS');
export const TYPE_FETCH_MEDALS_SUCCESS = ('USER_FETCH_MEDALS_SUCCESS');
export const TYPE_FETCH_MEDALS_FAILURE = ('USER_FETCH_MEDALS_FAILURE');
export const TYPE_FETCH_LESSONS_IMAGES = ('USER_TYPE_FETCH_LESSONS_IMAGES');
export const TYPE_SET_LESSONS_IMAGES = ('USER_TYPE_SET_LESSONS_IMAGES');
export const ERROR_UNDEFINED = ('USER_ERROR_UNDEFINED');

export const user = (state: UserState = USER_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_INCREMENT:
            return {
                ...state,
                count: state.count + 1,
            };
        case TYPE_DECREMENT:
            return {
                ...state,
                count: state.count - 1,
            };
        case TYPE_MESSAGE:
            return {
                ...state,
                message: action.message,
            };
        case TYPE_INIT_REGISTER:
            // return {
            //     USER_INIT_STATE
            // };
            return { ...state };
        case TYPE_CLOSE_REGISTER:
            return {
                ...state,
                isRegisterVisible: false,
            };
        case TYPE_REGISTER_USER_SUCCESS:
            return {
                ...state,
                isSignUpVisible: false,
            };
        case TYPE_REGISTER_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case TYPE_SHOW_SIGN_UP:
            return {
                ...state,
                isSignUpVisible: true,
            };
        case TYPE_HIDE_SIGN_UP:
            return {
                ...state,
                isSignUpVisible: false,
            };
        case TYPE_SHOW_LOADER:
            return {
                ...state,
                isLoaderVisible: true,
            };
        case TYPE_HIDE_LOADER:
            return {
                ...state,
                isLoaderVisible: false,
            };
        case TYPE_CHANGE_SIGNUP_FORM:
            return {
                ...state,
                signupVisibleForm: action.data,
            };
        case TYPE_SHOW_SIGNUP_ERROR:
            return {
                ...state,
                errorText: action.error,
                isErrorVisible: true,
            };
        case TYPE_HIDE_SIGNUP_ERROR:
            return {
                ...state,
                errorText: '',
                isErrorVisible: false,
            };
        case TYPE_MY_ACCOUNT_CURRENT_TAB:
            return {
                ...state,
                myAccountCurrentTab: action.currentTab,
            };
        case TYPE_TOGGLE_OPEN_PWA_MODAL:
            return {
                ...state,
                openPWAModal: !state.openPWAModal,
            };
        case TYPE_CHANGE_CART:
            return {
                ...state,
                cartChanged: !state.cartChanged,
            };
        case TYPE_CHANGE_ERROR_CATCHED:
            return {
                ...state,
                errorCatched: action.data,
                errorMessage: action.message ? action.message : null,
            };
        case TYPE_FETCH_MY_LESSONS:
            return {
                ...state,
                isMyLessonsLoading: true,
            };
        case TYPE_FETCH_MY_LESSONS_SUCCESS:
            return {
                ...state,
                myLessons: action.data,
                isMyLessonsLoading: false,
            };
        case TYPE_FETCH_MY_LESSONS_FAILURE:
            return {
                ...state,
                isMyLessonsLoading: false,
            };
        case TYPE_FETCH_MY_LESSON_DETAILS:
            return {
                ...state,
                isMyLessonDetailsLoading: true,
            };
        case TYPE_FETCH_MY_LESSON_DETAILS_SUCCESS:
            return {
                ...state,
                myLessonDetails: action.data,
                isMyLessonDetailsLoading: false,
            };
        case TYPE_FETCH_MY_LESSON_DETAILS_FAILURE:
            return {
                ...state,
                isMyLessonDetailsLoading: false,
            };
        case TYPE_GET_RESET_PASSWORD_TOKEN:
            if (action.data) {
                return {
                    ...state,
                    resetPasswordTokenEmail: action.data.userEmail,
                    resetPasswordTokenExists: action.data.exists,
                };
            }
            return {
                ...state,
                resetPasswordTokenEmail: action.data,
                resetPasswordTokenExists: action.data,
            };

        case TYPE_GET_RESET_PASSWORD_TOKEN_ERROR:
            return {
                ...state,
                resetPasswordTokenError: action.data.message,
                resetPasswordTokenEmail: action.data.email,
                resetPasswordTokenExists: false,
            };
        case TYPE_SET_RESET_PASSWORD_MESSAGE:
            return {
                ...state,
                resetPasswordMessage: action.data,
            };
        case TYPE_SET_COME_FROM_SKI_RESORT_COUNTER:
            return {
                ...state,
                comeFromSkiResortCounter: action.data,
            };
        case TYPE_FETCH_MEDALS:
            return {
                ...state,
                isMedalsLoading: true,
            };
        case TYPE_FETCH_MEDALS_FAILURE:
            return {
                ...state,
                medals: [],
                isMedalsLoading: false,
            };
        case TYPE_FETCH_MEDALS_SUCCESS:
            return {
                ...state,
                medals: action.data,
                isMedalsLoading: false,
            };
        case TYPE_FETCH_LESSONS_IMAGES:
            return {
                ...state,
                isLoadingImages: true,
            };
        case TYPE_SET_LESSONS_IMAGES:
            return {
                ...state,
                isLoadingImages: false,
                lessonsImages: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
