import { Paper } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { MouseEventHandler } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
    IMAGES_ADDRESS, IMAGES_EXTENSION, IMAGES_EXTENSION_ALLIANCE, IMAGES_EXTENSION_BOOKING_FORMATION, IMAGES_EXTENSION_BS, VERSIONS
} from '../../resources/constants';
import '../../resources/css/accountLessons.css';
import placeholder from '../../resources/img/products/placeholder.jpg';
import { getCurrentVersion } from '../../resources/utils';
import { Lesson } from '../../types/booking';

interface IProps {
    lesson: Lesson;
    isUpcoming: boolean;
    handleOpenDetails: MouseEventHandler;
}

type Props = IProps & WrappedComponentProps;

interface State {
    image: string;
    from: Moment;
    to: Moment;
}

class MyLessonCard extends React.Component<Props, State> {
    image: HTMLImageElement;

    constructor(props: Props) {
        super(props);

        const { lesson } = this.props;

        this.state = {
            image: placeholder,
            from: moment(lesson.From),
            to: moment(lesson.To),
        };

        this.image = new Image();
        this.image.onerror = () => this.setState({ image: placeholder });
        this.image.onload = () => this.setState({ image: this.image.src });

        let imageAddress = '';
        const currentVersion = getCurrentVersion();
        if (currentVersion === VERSIONS.Swisskischool) {
            if (lesson.School.includes('Villars-sur-Ollon')) {
                imageAddress = `${IMAGES_ADDRESS}ess${lesson.School.replace('-sur-Ollon', '').replace(/'/g, '').replace(/ /g, '-').toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.ProductId}-${lesson.ActivityId}${IMAGES_EXTENSION}`;
            } else {
                imageAddress = `${IMAGES_ADDRESS + lesson.School.replace(/'/g, '').replace('ESS ', '').replace(/ /g, '-').toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.ProductId}-${lesson.ActivityId}${IMAGES_EXTENSION}`;
            }
        } else if (currentVersion === VERSIONS.BookingSport) {
            imageAddress = `${IMAGES_ADDRESS + lesson.School.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.ProductId}-${lesson.ActivityId}${IMAGES_EXTENSION_BS}`;
        } else if (currentVersion === VERSIONS.SkiSchoolAlliance) {
            imageAddress = `${IMAGES_ADDRESS + lesson.School.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.ProductId}-${lesson.ActivityId}${IMAGES_EXTENSION_ALLIANCE}`;
        } else {
            imageAddress = `${IMAGES_ADDRESS + lesson.School.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.ProductId}-${lesson.ActivityId}${IMAGES_EXTENSION_BOOKING_FORMATION}`;
        }

        this.image.src = imageAddress;
    }

    render() {
        const {
            isUpcoming, handleOpenDetails, lesson, intl,
        } = this.props;
        const { image, from, to } = this.state;
        return (
            <Paper className={isUpcoming ? '__lessons-card-upcoming' : '__lessons-card-completed'} elevation={0} square>
                <img className="__lessons-card-image" src={image} onClick={handleOpenDetails} alt={lesson.Prestation} title={lesson.Prestation} />
                <p className="__lessons-card-title">{lesson.Prestation}</p>
                <p className="__lessons-card-text">
                    {intl.formatDate(from.toDate(), { year: 'numeric', month: 'short', day: '2-digit' })}
                    {' '}
                    {intl.formatMessage({ id: 'from' })}
                    {' '}
                    {from.format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}
                </p>
                <p className="__lessons-card-text">
                    {intl.formatDate(to.toDate(), { year: 'numeric', month: 'short', day: '2-digit' })}
                    {' '}
                    {intl.formatMessage({ id: 'to' })}
                    {' '}
                    {to.format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}
                </p>
                <p className="__lessons-card-text">{`${intl.formatMessage({ id: 'products.location' })}: ${lesson.School}`}</p>
                {
                    isUpcoming
                        ? <p className="__lessons-card-details" onClick={handleOpenDetails}>{intl.formatMessage({ id: 'seeDetails' })}</p>
                        : null
                }
            </Paper>
        );
    }
}

export default injectIntl(MyLessonCard);
