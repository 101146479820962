import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../resources/css/newsfeed.css';
import { News } from '../../store/types';

interface IProps {
    readMoreAction?: () => void;
    data: News
    currentLang: string;
}

type Props = IProps;

interface State {

}

class NewsfeedCard extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     readMoreAction: undefined,
    // };

    // getNewsfeedTitle() {
    //     const { data } = this.props;
    //     return data.title.toString();
    // }

    // getNewsfeedDescription() {
    //     const { data } = this.props;
    //     let string = '';
    //     if (data.text) {
    //         if (data.text.desc) {
    //             string = data.text.desc.toString();
    //         } else {
    //             string = data.text;
    //         }
    //     }
    //     return string.length > 80 ? `${string.slice(0, 80)}...` : string;
    // }

    getNewsfeedTitle() {
        const { data, currentLang } = this.props;
        let { title } = data;
        for (const index in data.translations) {
            if (data.translations[index].lang_key === currentLang) {
                title = data.translations[index].title;
            }
        }
        return title.toString();
    }

    getNewsfeedDescription() {
        const { data, currentLang } = this.props;
        let desc = data.text;
        for (const index in data.translations) {
            if (data.translations[index].lang_key === currentLang) {
                desc = data.translations[index].desc;
            }
        }
        const string = desc.toString();
        return string.length > 80 ? `${string.slice(0, 80)}...` : string;
    }

    render() {
        const { data, readMoreAction } = this.props;
        return (
            <div className="__landing-newsfeed-card-parent">
                <span className="__landing-newsfeed-card-company-name">{data.company_name}</span>
                <p className="__landing-newsfeed-card-title">{this.getNewsfeedTitle()}</p>
                <p className="__landing-newsfeed-card-description">{this.getNewsfeedDescription()}</p>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */}
                <span className="__landing-newsfeed-card-read-more" onClick={readMoreAction}><FormattedMessage id="seeDetails" /></span>
            </div>
        );
    }
}
export default NewsfeedCard;
