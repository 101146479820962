import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import '../../resources/css/order.css';
import { RouterProps } from '../../types/generalTypes';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import OrderApi from './api/order_api';

interface IProps {

}

type Props = IProps & RouterProps & WrappedComponentProps;

interface State {

}

class OrderInit extends React.Component<Props, State> {
    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.request) {
            const { request } = history.location.state;
            let bookings = request.Bookings;
            const schoolId = bookings[0].SchoolId;
            const departmentId = bookings[0].DepartmentId;
            const { email } = history.location.state;
            bookings = bookings.map((booking: any) => ({
                lessonId: booking.lessonId,
                levelId: booking.levelId,
                fromDate: booking.From,
                toDate: booking.To,
                meetingPointId: booking.MeetingPointId,
                nbDays: booking.NbDays,
                nb_minutes: booking.NbMinutes,
                nbParticipants: booking.NbParticipants,
                participants: booking.Participants,
                options: booking.Options,
                answers: booking.Answers,
                remark: booking.Remark,
                instructorId: booking.InstructorId,
                instructorPriority: booking.InstructorPriority,
            }));

            localStorage?.setItem('pointsUsed', history.location.state.points);
            localStorage?.setItem('codeDiscountAmount', history.location.state.codeDiscountAmount);
            localStorage?.setItem('codeDiscount', history.location.state.codeDiscountCode);
            localStorage?.setItem('clientId', history.location.state.clientId || 'undefined');

            this.getPaymentUrl(
                email,
                bookings,
                schoolId,
                departmentId,
                request.Client,
                history.location.state.points,
                history.location.state.codeDiscountCode,
                request.CodeDiscount,
                request.couponCode,
                request.couponAmount,
            );
        }
    }

    async getPaymentUrl(
        email: any,
        bookings: any,
        schoolId: any,
        departmentId: any,
        client: any,
        points: any,
        codeDiscountCode: any,
        codeDiscountAmount: any,
        couponCode: any,
        couponAmount: any,
    ) {
        const { match } = this.props;
        const url = await OrderApi.initializePayment(email, bookings, client, match.params.lang ? match.params.lang : 'fr', schoolId, departmentId, points, codeDiscountCode, codeDiscountAmount, couponCode, couponAmount);
        window.location.href = url;
    }

    render() {
        const { intl } = this.props;
        return (
            <Container headerBase={intl.formatMessage({ id: 'order.order' })}>
                <div className="__order-parent">
                    <p className="__order-text">{intl.formatMessage({ id: 'order.init' })}</p>
                    <LoadingCircle />
                </div>
            </Container>
        );
    }
}

export default injectIntl(OrderInit);
