import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { comeFromSkiResortCounterChangePassword } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Error } from '../../types/common';
import Button from '../common/button';
import Modal from '../common/modal';
import TextField from '../common/textfield';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    newPassword?: string;
    oldPassword?: string;
    errors: Error;
    loading: boolean;
}

class LandingChangePassword extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newPassword: undefined,
            oldPassword: undefined,
            errors: {
                newPassword: undefined,
                oldPassword: undefined,
            },
            loading: false,
        };
    }

    componentDidUpdate() {
        const { isErrorVisible } = this.props;
        const { loading } = this.state;
        if (isErrorVisible && loading !== false) {
            this.setState({ loading: false });
        }
    }

    handleSubmit = () => {
        const { dispatch } = this.props;
        const { oldPassword, newPassword } = this.state;
        if (oldPassword && newPassword && !this.checkInputs()) {
            this.setState({ loading: true });
            dispatch(comeFromSkiResortCounterChangePassword(oldPassword, newPassword));
        }
    };

    onKeyDown = (event: any) => {
        if (event.key === 'Enter') this.handleSubmit();
    };

    checkInputs() {
        const { intl } = this.props;
        const { newPassword, oldPassword } = this.state;
        const errors: Error = {
            newPassword: undefined,
            oldPassword: undefined,
        };

        if (!newPassword || newPassword.length <= 0) {
            errors.newPassword = intl.formatMessage({ id: 'login.passwordRequired' })!;
        } else if (newPassword.length < 8) {
            errors.newPassword = intl.formatMessage({ id: 'login.passwordMinimumCharacters' });
        }

        if (!oldPassword || oldPassword.length <= 0) {
            errors.oldPassword = intl.formatMessage({ id: 'login.passwordRequired' });
        } else if (oldPassword.length < 8) {
            errors.oldPassword = intl.formatMessage({ id: 'login.passwordMinimumCharacters' });
        }

        this.setState({ errors });

        return errors.newPassword || errors.oldPassword;
    }

    render() {
        const {
            comeFromSkiResortCounter, intl, isErrorVisible, errorText,
        } = this.props;
        const {
            oldPassword, newPassword, loading, errors,
        } = this.state;
        return (
            <Modal containerClasses="__landing-change-password-parent" open={Boolean(comeFromSkiResortCounter)} onKeyDown={this.onKeyDown}>
                <Title className="__landing-change-password-title">{intl.formatMessage({ id: 'changePassword' })}</Title>
                <TextField
                    containerClasses="__landing-change-password-field"
                    value={oldPassword}
                    type="password"
                    placeholder={intl.formatMessage({ id: 'settings.oldPassword' })}
                    onFocus={() => this.setState({ errors: { oldPassword: undefined } })}
                    onChange={(event) => this.setState({ oldPassword: event.target.value })}
                    error={errors.oldPassword}
                />
                <TextField
                    value={newPassword}
                    type="password"
                    placeholder={intl.formatMessage({ id: 'settings.newPassword' })}
                    onFocus={() => this.setState({ errors: { newPassword: undefined } })}
                    onChange={(event) => this.setState({ newPassword: event.target.value })}
                    error={errors.newPassword}
                />
                {
                    isErrorVisible
                        ? <span className="__signup-error">{errorText}</span>
                        : null
                }
                <Button containerClasses="__landing-change-password-button" onClick={this.handleSubmit} loading={loading}>
                    {intl.formatMessage({ id: 'login.login' })}
                </Button>
            </Modal>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    comeFromSkiResortCounter: store.user.comeFromSkiResortCounter,
    isErrorVisible: store.user.isErrorVisible,
    errorText: store.user.errorText,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(LandingChangePassword));
