import { Tooltip as tt } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const Tooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#fbfbfc',
        color: 'black',
        fontFamily: 'Montserrat',
        fontSize: '14px',
    },
}))(tt);
export default Tooltip;
