/* eslint-disable no-nested-ternary */
import {
    Card, CardContent, Grid, MenuItem
} from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { QUESTIONS_TYPES } from '../../resources/constants';
import { setSelectedQuestions } from '../../store/actions/booking';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Question } from '../../types/booking';
import Button from '../common/button';
import CheckboxControl from '../common/checkboxControl';
import DatePickerField from '../common/datePickerField';
import LoadingCircle from '../common/loadingCircle';
import Numberfield from '../common/numberfield';
import SelectField from '../common/selectField';
import TextField from '../common/textfield';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
    handleExpandable(): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    formatedQuestions: any;
    numberfieldErrors: any;
    typeErrors: any;
}

class Questions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formatedQuestions: [],
            numberfieldErrors: undefined,
            typeErrors: undefined,
        };
    }

    componentDidMount() {
        this.createRef();
    }

    componentDidUpdate(prevProps: Props) {
        const { questions } = this.props;
        if (prevProps.questions !== questions) {
            this.createRef();
        }
    }

    handleQuestionCheckBox = (checkbox: any, id: number) => {
        const cbRef = this[`questioncb_${id}`].current;
        cbRef.checked = checkbox.checked;
    };

    handleClickNext = () => {
        const { questions, handleExpandable } = this.props;
        let error = false;
        const errors:any[] = [];
        const typeErrors:any[] = [];
        questions.forEach((question, i) => {
            // question.type = "S";
            // question.choices = "test|trop|petit";
            if (question.obligatory) {
                if (question.type === QUESTIONS_TYPES.BOOLEAN) {
                    if (this[`questioncb_${question.id}`] !== undefined && (!this[`questioncb_${question.id}`].current.checked)) {
                        errors.push(true);
                        typeErrors.push('empty');
                        error = true;
                    } else {
                        errors.push(false);
                        typeErrors.push('nothing');
                    }
                } else if (question.type === QUESTIONS_TYPES.DATE || question.type === QUESTIONS_TYPES.DATETIME || question.type === QUESTIONS_TYPES.TIME) {
                    if (this[`questiontf_${question.id}`] !== undefined && (this[`questiontf_${question.id}`].current.value === undefined || this[`questiontf_${question.id}`].current.value.length === 0)) {
                        errors.push(true);
                        typeErrors.push('empty');
                        error = true;
                    } else {
                        errors.push(false);
                        typeErrors.push('nothing');
                    }
                } else if (this[`questiontf_${question.id}`] !== undefined && (this[`questiontf_${question.id}`].current.state.value === undefined || this[`questiontf_${question.id}`].current.state.value.length === 0)) {
                    errors.push(true);
                    typeErrors.push('empty');
                    error = true;
                } else {
                    errors.push(false);
                    typeErrors.push('nothing');
                }
            } else {
                errors.push(false);
                typeErrors.push('nothing');
            }
            switch (question.type) {
                case QUESTIONS_TYPES.EMAIL:
                    if (!(this[`questiontf_${question.id}`] !== undefined && (this[`questiontf_${question.id}`].current.state.value === undefined || this[`questiontf_${question.id}`].current.state.value.length === 0))) {
                        const email = this[`questiontf_${question.id}`].current.state.value;
                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!re.test(String(email).toLowerCase())) {
                            errors[i] = true;
                            typeErrors[i] = 'format';
                            error = true;
                        }
                    }
                    break;
                default:
                    break;
            }
        });

        if (error) {
            this.setState({ numberfieldErrors: errors, typeErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
            return;
        }
        this.setState({ numberfieldErrors: undefined, typeErrors: undefined }, () => this.setState({ formatedQuestions: this.formatOptions() }));

        this.setSelection();
        handleExpandable();
    };

    setSelection = () => {
        const { questions, dispatch } = this.props;
        const selectedQuestions: Question[] = [];
        questions.forEach((q) => {
            // q.type = "S";
            const question: Question = {
                id: q.id, text: q.text, type: q.type, answer: undefined
            };
            if (question.type === QUESTIONS_TYPES.BOOLEAN) {
                if (this[`questioncb_${question.id}`] !== undefined) {
                    const value = this[`questioncb_${question.id}`].current.checked;
                    question.answer = value || false;
                }
                selectedQuestions.push(question);
            } else if (question.type === QUESTIONS_TYPES.DATE || question.type === QUESTIONS_TYPES.DATETIME || question.type === QUESTIONS_TYPES.TIME) {
                if (this[`questiontf_${question.id}`] !== undefined) {
                    const { value } = this[`questiontf_${question.id}`].current;
                    if (question.type === QUESTIONS_TYPES.DATE) {
                        let parsedDate = value.split('/');
                        if (parsedDate && parsedDate.length === 3) {
                            parsedDate = `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`;
                            question.answer = `${parsedDate}T00:00:00.000Z`;
                        }
                    } else if (question.type === QUESTIONS_TYPES.TIME) {
                        question.answer = `0000-00-00T${value}:00.000Z`;
                    } else {
                        const parsedDateTime = value.split(' ');
                        if (parsedDateTime && parsedDateTime.length === 2) {
                            let parsedDate = parsedDateTime[0];
                            const parsedTime = parsedDateTime[1];
                            parsedDate = parsedDate.split('/');
                            if (parsedDate && parsedDate.length === 3) {
                                parsedDate = `${parsedDate[2]}-${parsedDate[1]}-${parsedDate[0]}`;
                                question.answer = `${parsedDate}T${parsedTime}:00.000Z`;
                            }
                        }
                    }
                }
                selectedQuestions.push(question);
            } else {
                if (this[`questiontf_${question.id}`] !== undefined) {
                    const { value } = this[`questiontf_${question.id}`].current.state;
                    question.answer = value;
                }
                if (question.answer !== undefined && question.answer !== '') {
                    selectedQuestions.push(question);
                }
            }
        });

        dispatch(setSelectedQuestions(selectedQuestions));
    };

    // TODO this[`questiontf_${question.id}`] typescript

    createRef = () => {
        const { questions } = this.props;
        if (questions.length === 0) {
            this.setState({ formatedQuestions: <div>No questions available</div> });
            // TODO: pointless because if there is no question the expandable doesn't show up.
        } else {
            // create ref dynamically
            questions.forEach((question) => {
                this[`questioncb_${question.id}`] = React.createRef();
                this[`questiontf_${question.id}`] = React.createRef();
            });

            this.setState({ formatedQuestions: this.formatOptions() });
        }
    };

    renderTypeQuestions = (question: Question, i: number) => {
        const { numberfieldErrors: numberfieldErrorsState, typeErrors } = this.state;
        const { intl } = this.props;
        // question.type = "S";
        // question.choices = "test|trop|petit";
        switch (question.type) {
            case QUESTIONS_TYPES.INTEGER:
                return (
                    <Grid item xs={12}>
                        <Numberfield
                            regExp="^[0-9]+$"
                            right={false}
                            asText={false}
                            onFocus={() => {
                                const numberfieldErrors = numberfieldErrorsState ? [...numberfieldErrorsState] : undefined;
                                if (numberfieldErrors !== undefined) {
                                    numberfieldErrors[i] = false;
                                    this.setState({ numberfieldErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
                                }
                            }}
                            ref={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.DECIMAL:
                return (
                    <Grid item xs={12}>
                        <Numberfield
                            regExp="^\d*\.?\d*$"
                            right={false}
                            asText={false}
                            onFocus={() => {
                                const numberfieldErrors = numberfieldErrorsState ? [...numberfieldErrorsState] : undefined;
                                if (numberfieldErrors !== undefined) {
                                    numberfieldErrors[i] = false;
                                    this.setState({ numberfieldErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
                                }
                            }}
                            ref={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.TEXT:
                return (
                    <Grid item xs={12}>
                        <TextField
                            right={false}
                            asText={false}
                            onFocus={() => {
                                const numberfieldErrors = numberfieldErrorsState ? [...numberfieldErrorsState] : undefined;
                                if (numberfieldErrors !== undefined) {
                                    numberfieldErrors[i] = false;
                                    this.setState({ numberfieldErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
                                }
                            }}
                            ref={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.DATE:
                return (
                    <Grid item xs={12}>
                        <DatePickerField
                            pickerType="date"
                            // placeholder={label}
                            format="DD/MM/YYYY"
                            // onFocus={this.dateOnFocus}
                            inputRef={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.TIME:
                return (
                    <Grid item xs={12}>
                        <DatePickerField
                            pickerType="time"
                            // placeholder={label}
                            format="HH:mm"
                            // onFocus={this.dateOnFocus}
                            inputRef={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.DATETIME:
                return (
                    <Grid item xs={12}>
                        <DatePickerField
                            pickerType="datetime"
                            // placeholder={label}
                            format="DD/MM/YYYY HH:mm"
                            // onFocus={this.dateOnFocus}
                            inputRef={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.EMAIL:
                return (
                    <Grid item xs={12}>
                        <TextField
                            right
                            asText={false}
                            onFocus={() => {
                                const numberfieldErrors = numberfieldErrorsState ? [...numberfieldErrorsState] : undefined;
                                if (numberfieldErrors !== undefined) {
                                    numberfieldErrors[i] = false;
                                    this.setState({ numberfieldErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
                                }
                            }}
                            ref={this[`questiontf_${question.id}`]}
                            error={
                                numberfieldErrorsState && numberfieldErrorsState[i]
                                    ? typeErrors && typeErrors[i] === 'format'
                                        ? intl.formatMessage({ id: 'login.emailNotValid' })
                                        : intl.formatMessage({ id: 'booking.error' })
                                    : undefined
                            }
                        />
                    </Grid>
                );
            case QUESTIONS_TYPES.LIST: {
                const elements = question.choices?.split('|');
                if (elements && elements.length > 0) {
                    let content = [];

                    const choices = elements.map((choice, idx) => {
                        const choiceId = idx;
                        return <MenuItem key={`question-${question.id}-choice-${choiceId}`} value={choice}>{choice}</MenuItem>;
                    });
                    // Empty line first
                    // choices.unshift(<MenuItem key={`question-${question.id}-choice-default`} aria-label="None" value="" />)
                    content = [
                        <Grid key={`question-${question.id}-list-grid`} item xs={12}>
                            <SelectField
                                ref={this[`questiontf_${question.id}`]}
                            >
                                {choices}
                            </SelectField>
                        </Grid>
                    ];

                    numberfieldErrorsState && numberfieldErrorsState[i] && content.push(<span key={`question-${question.id}-list-error`} className="__textfield-error">{intl.formatMessage({ id: 'booking.atLeastOne' })}</span>);
                    return content;
                }
                return null;
            }
            default:
                return (
                    <Grid item xs={12}>
                        <TextField
                            right={false}
                            asText={false}
                            onFocus={() => {
                                const numberfieldErrors = numberfieldErrorsState ? [...numberfieldErrorsState] : undefined;
                                if (numberfieldErrors !== undefined) {
                                    numberfieldErrors[i] = false;
                                    this.setState({ numberfieldErrors }, () => this.setState({ formatedQuestions: this.formatOptions() }));
                                }
                            }}
                            ref={this[`questiontf_${question.id}`]}
                            error={numberfieldErrorsState && numberfieldErrorsState[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                        />
                    </Grid>
                );
        }
    };

    formatOptions = () => {
        const { questions, intl } = this.props;
        const { numberfieldErrors } = this.state;
        return questions.map((question, i) => {
            const idx = i;
            return ( // Question type = "S"
                <Grid key={`question-${question.id}-${idx}`} item xs={12} md={6}>
                    <Card elevation={2} style={{ backgroundColor: 'white', height: '100%' }}>
                        <CardContent>
                            <Grid container>
                                {
                                    question.type === QUESTIONS_TYPES.BOOLEAN ? (
                                        <Grid item xs={12}>
                                            <CheckboxControl
                                                classNameCheckBox="__booking-questions-checkbox"
                                                inputClasses="__booking-questions-checkbox-input"
                                                labelClasses="__booking-questions-checkbox-label"
                                                label={question.text}
                                                onChange={(checkbox) => this.handleQuestionCheckBox(checkbox, question.id)}
                                                inputRef={this[`questioncb_${question.id}`]}
                                                inputFirst
                                                error={numberfieldErrors && numberfieldErrors[i] ? intl.formatMessage({ id: 'booking.error' }) : undefined}
                                            />
                                        </Grid>
                                    )
                                        : null
                                }
                                {
                                    question.type === QUESTIONS_TYPES.BOOLEAN ? null
                                        : (
                                            <>
                                                <Grid item xs={12} style={{ marginBottom: '20px', color: 'var(--font-ternary-color)', fontSize: '16px' }}>
                                                    {question.text}
                                                </Grid>
                                                {this.renderTypeQuestions(question, i)}
                                            </>
                                        )
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            );
        });
    };

    [key: string]: any;

    render() {
        const { isQuestionsLoading, questions, intl } = this.props;
        const { formatedQuestions } = this.state;
        return (
            <div style={{ margin: '20px' }}>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item container spacing={2}>
                        {
                            isQuestionsLoading
                                ? <LoadingCircle />
                                : questions.length > 0
                                    ? formatedQuestions
                                    : intl.formatMessage({ id: 'booking.noQuestions' })
                        }
                    </Grid>
                    <Grid item container justifyContent="flex-end" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <div className="__booking-questions-button">
                                <Button onClick={this.handleClickNext}>
                                    {intl.formatMessage({ id: 'booking.next' })}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    lesson: store.booking.currentLesson,
    // options: store.booking.options,
    questions: store.booking.questions,
    isQuestionsLoading: store.booking.isQuestionsLoading,
    lessonDetails: store.booking.selectedLessonDetail,
    windowWidth: store.windowSize.width
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Questions));
