import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { SettingsItem, SettingsItemsList } from '../../types/account';
import { createSettingsState, updateSettingsDetails } from '../account/settings';
import SettingsCard from '../account/settingsCard';
import Button from '../common/button';
import Modal from '../common/modal';
import Snackbar from '../common/snackbar';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
    open: boolean;
    buttonText: string;
    onCheck(): void;
    onClose(): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    allSettings: SettingsItem[] | null;
    settings: SettingsItem[] | null;
    openSnackbar: boolean;
}

class SettingsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const state: SettingsItemsList | undefined = createSettingsState(this.props);

        this.state = {
            allSettings: state ? state.settings.details : null,
            settings: state?.settings.details ? state.settings.details.filter((s) => s.category !== 'addressLocal') : null,
            openSnackbar: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        // refresh settings if a value was updated
        if (prevProps !== this.props) {
            const state: SettingsItemsList | undefined = createSettingsState(this.props);
            if (state) {
                this.setState({
                    allSettings: state.settings.details,
                    settings: state.settings.details ? state.settings.details.filter((s) => s.category !== 'addressLocal') : null,
                });
            }
        }
    }

    updateSettings = (newValue: any, category: any) => {
        const { dispatch } = this.props;
        const { allSettings } = this.state;
        updateSettingsDetails(newValue, category, allSettings, dispatch);
    };

    checkInputs = () => {
        const { onCheck } = this.props;
        const { settings } = this.state;
        const dataReducer = (acc: any, d: any) => acc && (d !== undefined && d !== null
            && ((d._isAMomentObject && d.isValid())
                || (d.length && d.length > 0) || (d.id && d.id.length > 0)));
        const settingsReducer = (acc: any, s: any) => acc && s.datas.reduce(dataReducer, true);
        if (settings && settings.reduce(settingsReducer, true)) {
            onCheck();
        } else {
            this.setState({ openSnackbar: true });
        }
    };

    render() {
        const {
            open, onClose, intl, buttonText,
        } = this.props;
        const { openSnackbar, settings } = this.state;
        return (
            <Modal open={open} onClose={onClose}>
                <Snackbar
                    variant="error"
                    open={openSnackbar}
                    onClose={() => this.setState({ openSnackbar: false })}
                    timer={5000}
                    message={intl.formatMessage({ id: 'order.settingsNotFilled' })}
                />
                <div className="__cart-settings-content">
                    <Title className="__cart-settings-title">{intl.formatMessage({ id: 'order.settingsModal.title' })}</Title>
                    <div className="__cart-settings-info">
                        {
                            settings && settings.map((s) => <SettingsCard item={s} awesomeFunction={this.updateSettings} key={`cart-settings-${s.title}`} />)
                        }
                    </div>
                </div>
                <div className="__cart-settings-bottom">
                    <div className="__cart-settings-bottom-background" />
                    <div className="__cart-settings-buttons">
                        <Button containerClasses="__cart-settings-button-container" buttonClasses="__cart-settings-cancel" onClick={onClose}>
                            {intl.formatMessage({ id: 'cancel' })}
                        </Button>
                        <Button containerClasses="__cart-settings-button-container" buttonClasses="__cart-settings-order" onClick={this.checkInputs}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    userData: store.setting.userData,
    schools: store.setting.schools,
    countries: store.setting.countries,
    languages: store.setting.languages,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(SettingsModal));
