import { getCurrentVersion } from '../../resources/utils';
import { ShopNavigationMenu, ShopNavigationSubMenu } from '../../types/settings';
import {
    TYPE_NAVIGATION_CHANGE_CONTENT_HEIGHT,
    TYPE_NAVIGATION_CHANGE_CURRENT_PAGE,
    TYPE_NAVIGATION_CHANGE_CURRENT_SCHOOL,
    TYPE_NAVIGATION_CHANGE_DESTINATIONS,
    TYPE_NAVIGATION_CHANGE_GENERAL_MEDIA,
    TYPE_NAVIGATION_CHANGE_MENUS,
    TYPE_NAVIGATION_CHANGE_SPONSORS,
} from '../reducers/navigation';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';
import { API_ROUTES, get } from './api';

export const changeNavigationCurrentSchool = (school: any) => (
    { type: TYPE_NAVIGATION_CHANGE_CURRENT_SCHOOL, data: school }
);

export const getNavigationMenus = (schoolId: number) => (dispatch: any) => {
    dispatch(get(
        `${API_ROUTES.navigationMenus}?company_id=${schoolId}`,
        (response: any) => dispatch({ type: TYPE_NAVIGATION_CHANGE_MENUS, data: response }),
        () => dispatch({ type: TYPE_CHANGE_ERROR_CATCHED, data: true }),
    ));
};

export const changeNavigationCurrentPage = (page: ShopNavigationMenu | ShopNavigationSubMenu) => (
    { type: TYPE_NAVIGATION_CHANGE_CURRENT_PAGE, data: page }
);

export const changeNavigationContentHeight = (height: number) => (
    { type: TYPE_NAVIGATION_CHANGE_CONTENT_HEIGHT, data: height }
);

export const getSponsors = (companyId?: number) => (dispatch: any) => {
    dispatch(get(
        `${API_ROUTES.sponsors}?${companyId ? `company_id=${companyId}` : `version=${getCurrentVersion()}`}`,
        (response: any) => dispatch({ type: TYPE_NAVIGATION_CHANGE_SPONSORS, data: response }),
        () => dispatch({ type: TYPE_CHANGE_ERROR_CATCHED, data: true }),
    ));
};

export const getDestinations = () => (dispatch: any) => {
    dispatch(get(
        `${API_ROUTES.destinations}?version=${getCurrentVersion()}`,
        (response: any) => dispatch({ type: TYPE_NAVIGATION_CHANGE_DESTINATIONS, data: response }),
        () => dispatch({ type: TYPE_CHANGE_ERROR_CATCHED, data: true }),
    ));
};

export const getGeneralMedia = () => (dispatch: any) => {
    dispatch(get(
        `${API_ROUTES.generalMedia}?version=${getCurrentVersion()}`,
        (response: any) => dispatch({ type: TYPE_NAVIGATION_CHANGE_GENERAL_MEDIA, data: response }),
        () => dispatch({ type: TYPE_CHANGE_ERROR_CATCHED, data: true }),
    ));
};
