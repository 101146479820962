/* eslint-disable no-nested-ternary */
import FacebookIcon from '@mui/icons-material/Facebook';
import HomeOutlinedIcon from '@mui/icons-material/Home';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SMARTPHONE_WIDTH } from '../../resources/constants';
import {
    changeSubDomain, getVersionName, goToDefaultDomain
} from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import SelectField from './selectField';

/**
 * Component that represent the top bar
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = IProps & RouterProps & ReduxProps;

interface State { }

class Topbar extends React.Component<Props, State> {
    onChangeSchool = (event: any) => {
        const { destinations } = this.props;

        const value = parseInt(event.target.value, 10);
        if (value === -1) {
            goToDefaultDomain();
        } else {
            const destination = destinations?.find((d) => d.companyId === value);
            if (destination) { changeSubDomain(destination.subdomain); }
        }
    };

    redirectToHomePage() {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/`);
    }

    render() {
        const { currentSchool, windowWidth, destinations } = this.props;

        return (
            <div className="__topbar-parent">
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        currentSchool === undefined
                            ? windowWidth > SMARTPHONE_WIDTH
                                ? (
                                    <Select
                                        className="__school-choose __picker-school"
                                        variant="outlined"
                                        // containerClasses="__topbar-select"
                                        value={-1}
                                        onChange={this.onChangeSchool}
                                    >
                                        <MenuItem className="__school-choose-item" key="topbar-schools-default" value={-1}>{getVersionName()}</MenuItem>
                                        {destinations && destinations.map((d) => <MenuItem className="__school-choose-item" key={`topbar-schools-${d.companyId}`} value={d.companyId}>{d.title}</MenuItem>)}
                                    </Select>
                                )
                                : (
                                    <SelectField
                                        displayLabel
                                        containerClasses="__topbar-select __picker-school"
                                        value={-1}
                                        onChange={this.onChangeSchool}
                                    >
                                        <MenuItem key="topbar-schools-default" value={-1}>{getVersionName()}</MenuItem>
                                        {destinations && destinations.map((d) => <MenuItem key={`topbar-schools-${d.companyId}`} value={d.companyId}>{d.title}</MenuItem>)}
                                    </SelectField>
                                )
                            : null
                    }
                    {
                        windowWidth > SMARTPHONE_WIDTH && currentSchool
                        && (
                            <span className="__schools-info">
                                <a style={{ cursor: 'pointer' }} title={`Accueil ${currentSchool?.name}`} onClick={(e) => { e.preventDefault(); this.redirectToHomePage(); }}><HomeOutlinedIcon /></a>
                                {currentSchool.facebook && <a title={`Facebook ${currentSchool?.name}`} href={`${currentSchool?.facebook}`} target="_blank" rel="noreferrer"><FacebookIcon /></a>}
                                {currentSchool.instagram && <a title={`Instagram ${currentSchool?.name}`} href={`${currentSchool?.instagram}`} target="_blank" rel="noreferrer"><InstagramIcon /></a>}
                                {currentSchool.twitter && <a title={`Twitter ${currentSchool?.name}`} href={`${currentSchool?.twitter}`} target="_blank" rel="noreferrer"><TwitterIcon /></a>}
                                {currentSchool.youtube && <a title={`Youtube ${currentSchool?.name}`} href={`${currentSchool?.youtube}`} target="_blank" rel="noreferrer"><YouTubeIcon /></a>}
                            </span>
                        )
                    }
                </span>
                {
                    currentSchool
                    && (
                        <span className="__schools-info">
                            {
                                windowWidth <= SMARTPHONE_WIDTH
                                && (
                                    <>

                                        <a style={{ cursor: 'pointer' }} title={`Accueil ${currentSchool?.name}`} onClick={(e) => { e.preventDefault(); this.redirectToHomePage(); }}><HomeOutlinedIcon /></a>
                                        {currentSchool.youtube && <a title={`Youtube ${currentSchool?.name}`} href={`${currentSchool?.youtube}`} target="_blank" rel="noreferrer"><YouTubeIcon /></a>}
                                        {currentSchool.facebook && <a title={`Facebook ${currentSchool?.name}`} href={`${currentSchool?.facebook}`} target="_blank" rel="noreferrer"><FacebookIcon /></a>}
                                        {currentSchool.instagram && <a title={`Instagram ${currentSchool?.name}`} href={`${currentSchool?.instagram}`} target="_blank" rel="noreferrer"><InstagramIcon /></a>}
                                        {currentSchool.twitter && <a title={`Twitter ${currentSchool?.name}`} href={`${currentSchool?.twitter}`} target="_blank" rel="noreferrer"><TwitterIcon /></a>}
                                    </>
                                )
                            }
                            {
                                windowWidth <= SMARTPHONE_WIDTH
                                    ? (
                                        <>
                                            {currentSchool.email && <a title={`Mail ${currentSchool?.name}`} href={`mailto:${currentSchool?.email}`}><MailOutlineIcon /></a>}
                                            {currentSchool.phone && <a title={`Tel ${currentSchool?.name}`} href={`tel:${currentSchool?.phone}`}><PhoneIcon style={{ fontSize: '22px' }} /></a>}
                                        </>
                                    )
                                    : (
                                        <>
                                            {currentSchool.email && <a title={`Mail ${currentSchool?.name}`} href={`mailto:${currentSchool?.email}`}>{currentSchool?.email}</a>}
                                            {currentSchool.email ? <span>|</span> : ''}
                                            {currentSchool.phone && <a title={`Tel ${currentSchool?.name}`} href={`tel:${currentSchool?.phone}`}>{currentSchool?.phone}</a>}
                                        </>
                                    )
                            }
                        </span>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentSchool: store.navigation.currentSchool,
    windowWidth: store.windowSize.width,
    destinations: store.navigation.destinations,
});
const connector = connect(mapStateToProps);

export default connector(withRouter(Topbar));
