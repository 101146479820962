/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions,
jsx-a11y/no-static-element-interactions */
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import '../../resources/css/newsfeed.css';
import { getTranslation, getVersionName, ImgWithFallback } from '../../resources/utils';
import { Language } from '../../store/reducers/translation';
import { LessonCardData } from '../../types/landing';

interface IProps {
    schoolName?: string;
    data: LessonCardData;
    onCardClick(): void;
    language: string;
}

type Props = IProps & WrappedComponentProps;

// interface State {
//     isLoggedIn?: string;
//     lessonMaxLength: number;
//     isNewsFlash: boolean;
//     grimentzZinalModal: boolean;
//     neuchatelRegionModal: boolean;
//     path?: string;
// }

// const defaultProps = {
//     schoolName: '',
// };

function LessonCard(props: Props) {
    const {
        intl, data, schoolName, onCardClick, language
    } = props;
    const platform = getVersionName();
    const translation = language === Language.en ? data : getTranslation(data.translations, language);
    const title = translation && translation.name ? translation.name : data.name;

    const description = translation && translation.desc ? translation.desc : data.desc;

    // Prepare image to <ImgWithFallback />
    const imageSource = data.image;
    const re = /(?:\.([^.]+))?$/;
    const imageSourceExploded = re.exec(imageSource);
    const ext = imageSourceExploded != null ? imageSource[1] : '';
    const imageSrc = imageSource;
    const imageFallback = ext === 'webp' ? `${imageSource.slice(0, -4)}jpg` : imageSource;
    // End of prepare image to <ImgWithFallback />

    return (
        <div onClick={() => window.screen.width > 900 && onCardClick()}>
            <div className="__landing-lesson-card-image-container">
                <ImgWithFallback
                    onClick={() => onCardClick()}
                    src={imageSrc}
                    fallback={imageFallback}
                    className="__landing-lesson-card-image"
                    title={`${schoolName || platform}: ${title}`}
                    alt={`${title} - ${intl.formatMessage({ id: 'img.alt.landing.lesson' })} ${schoolName || platform} : ${intl.formatMessage({ id: 'img.alt.logo' })} ${platform}`}
                />
                <div className="__landing-lesson-card-text-content">
                    <div className="__landing-lesson-card-text-content-group">
                        <h3 onClick={() => window.screen.width > 900 && onCardClick()} className="__landing-lesson-card-title">
                            {title}
                            <ExpandMoreOutlinedIcon className="__landing-lesson-card-title-more" onClick={(e) => e.preventDefault()} />
                        </h3>
                        <p onClick={() => onCardClick()} className="__landing-newsfeed-card-description">
                            {description}
                        </p>
                        <p className="__landing-newsfeed-card-description-more" onClick={() => window.screen.width <= 900 && onCardClick()}><ArrowRightAltIcon style={{ fontSize: '30' }} /></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

// LessonCard.defaultProps = defaultProps;

export default injectIntl(LessonCard);
