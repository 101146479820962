import { Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { getSubDomain } from '../../resources/utils';
import { fetchNewsfeeds, initNewsfeed, likeCurrentPost } from '../../store/actions/newsfeed';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import NewsfeedCard from './newsfeedCard';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State { }

class Newsfeed extends React.Component<Props, State> {
    componentDidMount() {
        const { dispatch, currentSchool } = this.props;
        dispatch(initNewsfeed());

        if (!getSubDomain()) {
            dispatch(fetchNewsfeeds(0, 0));
        } else if (currentSchool) {
            dispatch(fetchNewsfeeds(0, currentSchool.id));
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { dispatch, currentSchool } = this.props;
        // fetch news when the current school is set
        if (!prevProps.currentSchool && currentSchool) {
            dispatch(fetchNewsfeeds(0, currentSchool.id));
        }
    }

    onLike(postId: number, like_active: any) {
        const { dispatch } = this.props;
        dispatch(likeCurrentPost(postId, like_active));
    }

    getNewsFeedItems() {
        const { data, currentLang } = this.props;
        const listItems: any = [];
        for (const item of data) {
            listItems.push(
                <Grid item xs={12} md={6} key={item.id.toString()}>
                    <NewsfeedCard
                        listing
                        data={item}
                        currentLang={currentLang}
                        onLike={() => this.onLike(item.id, !item.has_liked)}
                        onCardClick={(e: any) => this.itemClickHandler(e, item.id)}
                    />
                </Grid>,
            );
        }
        if (listItems) {
            return listItems;
        }
        return null;
    }

    itemClickHandler(event: any, id: number) {
        const { location, history } = this.props;
        const href = location.pathname;
        if (href[href.length - 1] === '/') {
            history.push(href + id);
        } else {
            history.push(`${href}/${id}`);
        }
    }

    render() {
        const items = this.getNewsFeedItems();
        const { isNewsfeedLoading, intl } = this.props;

        let returnElement;

        if (isNewsfeedLoading) {
            returnElement = (
                <LoadingCircle />
            );
        } else if (items && items.length > 0) {
            returnElement = (
                <Grid container spacing={3}>
                    {items}
                </Grid>
            );
        } else {
            returnElement = (
                <p className="__newsfeed-no-item">{intl.formatMessage({ id: 'newsfeed.noItem' })}</p>
            );
        }

        return (
            <Container headerBase="Newsfeed">
                <div className="__newsfeed-parent">
                    {returnElement}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    data: store.newsfeed.data,
    page: store.newsfeed.page,
    isNewsfeedLoading: store.newsfeed.isNewsfeedLoading,
    onEndReached: store.newsfeed.onEndReached,
    currentLang: store.translation.language,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Newsfeed));
