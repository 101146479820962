import { Paper } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import visibilityImage from '../../resources/img/global/visibility.svg';
import visibilityOffImage from '../../resources/img/global/visibility_off.svg';
import { decimalToTime } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { Lesson, Option } from '../../types/booking';
import Title from '../common/title';

/**
 * Component that represent a lesson item of the cart page
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    lesson: Lesson;
    schoolName: string;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    participantsOpen: boolean;
    optionsOpen: boolean;
    remarksOpen: boolean;
}

function reducerDurationLesson(p: any, c: any): number {
    return moment.duration(moment(c.End, 'YYYY-MM-DDTHH:mm:ss').diff(moment(c.Start, 'YYYY-MM-DDTHH:mm:ss'))).asHours() + p;
}

function toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
    if (typeof state[key] === 'boolean') {
        if (value !== undefined) {
            return { [key]: value } as Pick<State, T>;
        }

        return { [key]: !state[key] } as Pick<State, T>;
    }
    throw new Error(`Property ${key} is not a boolean`);
}

class ClaimLesson extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            participantsOpen: false,
            optionsOpen: false,
            remarksOpen: false,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    componentDidMount() {
    }

    toggleOpen(name: string) {
        this.setState((state) => toggleStateKey(name as keyof State, state));
    }

    render() {
        const {
            lesson, intl, schoolName, windowWidth,
        } = this.props;
        const { participantsOpen, optionsOpen, remarksOpen } = this.state;
        const bigScreen = windowWidth > 1000;
        const {
            From,
            To,
            Price,
            Remark,
            Prestation,
            NbDays,
            NbParticipants,
            Participants,
            Instructor,
            MeetingPoint,
            Options,
            Split,
            Times,
        } = lesson;

        const startDate = moment(From).format('DD.MM.YYYY');
        let startTime;
        let isNotEmptyStartTime = false;
        const endDate = moment(To).format('DD.MM.YYYY');
        let endTime;
        let isNotEmptyEndTime = false;

        let hours = '';
        let totalHours = '';
        let durationTotalLesson = 0.0;
        let durationLesson = 0.0;
        let isNotEmptyHours = false;

        if (Split && Times) {
            // durationLesson = Times.reduce((p: any, c: any) => moment.duration(moment(c.End, 'YYYY-MM-DDTHH:mm:ss').diff(moment(c.Start, 'YYYY-MM-DDTHH:mm:ss'))).asHours() + p, 0.0);
            durationLesson = Times.reduce(reducerDurationLesson, 0.0);
            durationTotalLesson = durationLesson * parseInt(NbDays, 10);
            isNotEmptyHours = (durationLesson !== 0);
            hours = `${decimalToTime(durationLesson, intl.formatMessage, false)}  ${intl.formatMessage({ id: 'booking.hoursDay' })}`;
            totalHours = `${decimalToTime(durationTotalLesson, intl.formatMessage, false)} ${intl.formatMessage({ id: 'booking.hoursTotal' })}`;
        } else {
            startTime = moment(From).format('HH[:]mm');
            isNotEmptyStartTime = (startTime !== '00:00');
            endTime = moment(To).format('HH[:]mm');
            isNotEmptyEndTime = (endTime !== '00:00');
            durationLesson = moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).asHours();
            durationTotalLesson = durationLesson * parseInt(NbDays, 10);
            isNotEmptyHours = (durationLesson !== 0);
            hours = `${decimalToTime(durationLesson, intl.formatMessage, false)}  ${intl.formatMessage({ id: 'booking.hoursDay' })}`;
            totalHours = `${decimalToTime(durationTotalLesson, intl.formatMessage, false)} ${intl.formatMessage({ id: 'booking.hoursTotal' })}`;
        }

        return (
            <Paper className="__booking-lesson-paper __cart-lesson-paper">
                <div className="__booking-lesson-parent">
                    <div className="__booking-lesson-header">
                        <Title className="__booking-lesson-title">
                            {Prestation}
                            :
                            {' '}
                            {NbDays + intl.formatMessage({ id: `booking.day${NbDays > 1 ? 's' : ''}` })}
                        </Title>
                    </div>
                    <div className="__booking-lesson-body">
                        <div className="__booking-lesson-1st">
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'products.location' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value __cart-lesson-location">{schoolName}</p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.startDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(startDate, 'DD.MM.YYYY').format('dd')}
                                    )
                                    {startDate}
                                </p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.endDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(endDate, 'DD.MM.YYYY').format('dd')}
                                    )
                                    {endDate}
                                </p>
                            </span>
                            {isNotEmptyHours && (
                                <span className="__booking-lesson-1st-info">
                                    <p className="__booking-lesson-1st-text">
                                        {intl.formatMessage({ id: 'booking.hours' })}
                                        :
                                    </p>
                                    <p className="__booking-lesson-1st-value">{hours}</p>
                                </span>
                            )}
                            {isNotEmptyHours && (
                                <span className="__booking-lesson-1st-info">
                                    <p className="__booking-lesson-1st-text">
                                        {intl.formatMessage({ id: 'booking.totalHours' })}
                                        :
                                    </p>
                                    <p className="__booking-lesson-1st-value">{totalHours}</p>
                                </span>
                            )}
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: `booking.Participant${NbParticipants > 1 ? 's' : ''}` })}
                                    :
                                </p>
                                <span className="__booking-lesson-1st-value">
                                    {NbParticipants}
                                    {NbParticipants > 0 && Participants?.length > 0 && <ReactSVG className="__cart-lesson-participants-visibility-grey" src={participantsOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('participantsOpen')} />}
                                </span>
                            </span>

                            {
                                participantsOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            {Participants?.map((participant: any, i: number) => {
                                                let nbFieldsDisplayed = 0;
                                                const idx = i;
                                                return (
                                                    <div className="__cart-lesson-expand-line" key={`participants_${idx}`}>
                                                        {'- '}
                                                        {Object.keys(participant).map((key) => {
                                                            // return null;
                                                            const value = participant[key];
                                                            let toDisplay;

                                                            if (key === 'Birthdate' && ((typeof value === 'string' && value !== '0001-01-01T00:00:00') || moment.isMoment(value))) {
                                                                nbFieldsDisplayed += 1;
                                                                toDisplay = intl.formatDate(moment(value).toDate(), { year: 'numeric', month: 'short', day: '2-digit' });
                                                            } else if (key === 'Name' || key === 'Firstname') {
                                                                nbFieldsDisplayed += 1;
                                                                toDisplay = value;
                                                            }
                                                            if (toDisplay) {
                                                                return (
                                                                    <p className="__cart-lesson-expand-value" key={`info_${idx}_${nbFieldsDisplayed}`}>
                                                                        {toDisplay}
                                                                        {nbFieldsDisplayed === 3 ? '' : ','}
                                                                    </p>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                    : null
                            }
                            {/* {
                                durationSplit ?
                                    <span
                                        className="__booking-lesson-1st-info"
                                    >
                                        <p
                                            className="__booking-lesson-1st-text"
                                        >
                                            {intl.formatMessage({
                                                id: 'booking.durationSplit'
                                            })}:
                                        </p>
                                        <p className="__booking-lesson-1st-value">
                                        {durationSplit}
                                        </p>
                                    </span>
                                    : null
                            } */}
                        </div>
                        <div className="__booking-lesson-divider" style={{ height: bigScreen ? 'auto' : '1px' }} />
                        <div className="__booking-lesson-2nd">
                            {/* <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                        {intl.formatMessage({ id: 'booking.level' })}:
                                    </p>
                                <p className="__booking-lesson-2nd-fixed-value">
                                    {lesson.level.name.length > 0
                                        ? lesson.level.name : intl.formatMessage({
                                            id: 'booking.noLevel'
                                        })}
                                    </p>
                            </div> */}
                            {
                                isNotEmptyStartTime
                                && (
                                    <div className="__booking-lesson-2nd-content">
                                        <p className="__booking-lesson-2nd-text">
                                            {intl.formatMessage({ id: 'booking.startTime' })}
                                            :
                                        </p>
                                        <p className="__booking-lesson-2nd-fixed-value">
                                            {
                                                startTime
                                            }
                                        </p>
                                    </div>
                                )
                            }
                            {
                                isNotEmptyEndTime
                                && (
                                    <div className="__booking-lesson-2nd-content">
                                        <p className="__booking-lesson-2nd-text">
                                            {intl.formatMessage({ id: 'booking.endTime' })}
                                            :
                                        </p>
                                        <p className="__booking-lesson-2nd-fixed-value">
                                            {
                                                endTime
                                            }
                                        </p>
                                    </div>
                                )
                            }
                            {
                                Times && Times.length > 0 && Times.map((t, id: number) => (
                                    <div key={`time-${t.Start}-${t.End}`} className="__booking-lesson-2nd-content">
                                        <p className="__booking-lesson-2nd-text">{id === 0 ? `${intl.formatMessage({ id: 'booking.hoursDetails' })}:` : null}</p>
                                        <p className="__booking-lesson-2nd-fixed-value">
                                            {
                                                `${moment(t.Start, 'YYYY-MM-DDTHH:mm:ss').format('HH[:]mm')} - ${moment(t.End, 'YYYY-MM-DDTHH:mm:ss').format('HH[:]mm')}`
                                            }
                                        </p>
                                    </div>
                                ))
                            }
                            {
                                MeetingPoint
                                && (
                                    <div className="__booking-lesson-2nd-content">
                                        <p className="__booking-lesson-2nd-text">
                                            {intl.formatMessage({ id: 'booking.meeting' })}
                                            :
                                        </p>
                                        <p className="__booking-lesson-2nd-fixed-value">{MeetingPoint || '-'}</p>
                                    </div>
                                )
                            }
                            {
                                Options.length > 0
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.options' })}
                                                :
                                            </p>
                                            <span className="__booking-lesson-2nd-fixed-value">
                                                {Options.length}
                                                <ReactSVG className="__cart-lesson-participants-visibility" src={optionsOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('optionsOpen')} />
                                            </span>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                optionsOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            {
                                                Options.map((o: Option, i: number) => {
                                                    const idx = i;
                                                    return (
                                                        <div className="__cart-lesson-expand-line" key={`options_${idx}`}>
                                                            <p className="__cart-lesson-expand-key">
                                                                -
                                                                {o.Text}
                                                                :
                                                                {' '}
                                                            </p>
                                                            <p className="__cart-lesson-expand-value">{`${o.Price.toFixed(2)}`}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    )
                                    : null
                            }
                            {
                                Instructor
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.freeInstructors' })}
                                                :
                                            </p>
                                            <p className="__booking-lesson-2nd-fixed-value">{`${Instructor}`}</p>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                Remark
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.remarks' })}
                                                :
                                            </p>
                                            <span className="__booking-lesson-2nd-fixed-value">
                                                <ReactSVG className="__cart-lesson-participants-visibility" src={remarksOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('remarksOpen')} />
                                            </span>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                remarksOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            <p className="__cart-lesson-expand-line">{Remark}</p>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div className="__booking-lesson-3rd">
                            <div>
                                <p className="__booking-lesson-3rd-price">
                                    CHF
                                    {' '}
                                    {Price}
                                    .-
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

// ClaimLesson.propTypes = {
// }; KROK Useless

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(ClaimLesson));
