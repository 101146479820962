import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import '../../resources/css/order.css';
import { isURL } from '../../resources/utils';
import { RouterProps } from '../../types/generalTypes';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import OrderApi from './api/order_api';

interface IProps {

}

type Props = IProps & WrappedComponentProps & RouterProps;

interface State {
    error: boolean;
    snackbarError: boolean;
    messageError: string;
}

class ClaimInit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { intl } = this.props;

        this.state = {
            error: false,
            snackbarError: false,
            messageError: intl.formatMessage({ id: 'order.checkFailed' }),
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.request) {
            const { request } = history.location.state;
            const { email } = history.location.state;
            const { noLogin } = history.location.state;
            localStorage?.setItem('clientId', history.location.state.clientId || 'undefined');

            this.getPaymentUrl(
                email,
                request.Client,
                request.toPay,
                request.code,
                request.schoolId,
                request.departmentId,
                noLogin,
            );
        }
    }

    async getPaymentUrl(
        email: any,
        client: any,
        toPay: any,
        code: any,
        schoolId: any,
        departmentId: any,
        noLogin: any,
    ) {
        const { match } = this.props;
        await OrderApi.initializeClaimPayment(email, client, toPay, code, schoolId, departmentId, noLogin, match.params.lang ? match.params.lang : 'fr')
            .then((response) => {
                if (response.error) {
                    this.setState({ error: true, snackbarError: true });
                } else if (isURL(response)) {
                    window.location.href = response;
                } else {
                    this.setState({ error: true, snackbarError: true });
                }
            })
            .catch(() => {
                this.setState({ error: true, snackbarError: true });
            });
    }

    render() {
        const { intl } = this.props;
        const { error, messageError, snackbarError } = this.state;
        return (
            <Container headerBase={intl.formatMessage({ id: 'order.order' })}>
                <div className="__order-parent">
                    {
                        error
                            ? <p className="__order-text">{messageError}</p>
                            : (
                                <>
                                    <p className="__order-text">{intl.formatMessage({ id: 'order.init' })}</p>
                                    <LoadingCircle />
                                </>
                            )
                    }
                </div>
                <Snackbar
                    variant="error"
                    open={snackbarError}
                    onClose={() => this.setState({ snackbarError: false })}
                    timer={5000}
                    message={messageError}
                />
            </Container>
        );
    }
}

export default injectIntl(ClaimInit);
