// enumeration for the different tabs
export const Tabs = Object.freeze({
    mylessons: 'mylessons',
    messages: 'messages',
    photos: 'photos',
    medals: 'medals',
    settings: 'settings',
    loyalty: 'loyalty',
    login: 'login',
});
export type TabsObjectKey = keyof typeof Tabs;
