import React from 'react';
import '../../resources/css/bottombar.css';
import Message from '../../resources/img/message.png';
import BottomItem from './bottomItem';

interface IProps {
    history: any;
}

type Props = IProps;

interface State { }

class BottomBar extends React.Component<Props, State> {
    componentDidMount() {

    }

    onClick(path: string) {
        const { history } = this.props;
        if (path !== window.location.pathname) {
            history.push(path);
        }
    }

    render() {
        return (
            <div className="__bottombar-parent">
                <div className="__bottombar-parent-mobile">
                    <BottomItem imgSrc={Message} text="Newsfeed" clickHandler={() => this.onClick('/newsfeed')} />
                    <BottomItem imgSrc={Message} text="Messages" clickHandler={() => this.onClick('/messages')} />
                </div>
                <div className="__bottombar-parent-desktop">
                    <BottomItem imgSrc={Message} text="Newsfeed" clickHandler={() => this.onClick('/newsfeed')} />
                    <BottomItem imgSrc={Message} text="Messages" clickHandler={() => this.onClick('/messages')} />
                </div>
            </div>
        );
    }
}
export default BottomBar;
