/* eslint-disable no-nested-ternary */
/* eslint-disable
jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VERSIONS } from '../../resources/constants';
import '../../resources/css/sitemap.css';
import paymentCardsPng from '../../resources/img/global/cards-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import paymentCards from '../../resources/img/global/cards-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import {
    changeSubDomain, getCurrentVersion, getVersionName, ImgWithFallback
} from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';

/**
* Component that represent the site map
* The footer part with the Destinations, Company, Support, Secure payment
*/
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State { }

class Sitemap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.goToHelpPage = this.goToHelpPage.bind(this);
    }

    goToHelpPage(suffix: string) {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/help/${suffix}`);
    }

    render() {
        // Prepare image to <ImgWithFallback />
        const {
            currentSchool, match, destinations, intl
        } = this.props;

        const imageSource = paymentCards;
        const re = /(?:\.([^.]+))?$/;
        const imageSourceExploded = re.exec(imageSource);
        const ext = imageSourceExploded != null ? imageSourceExploded[1] : '';
        const imageSrc = imageSource;
        const imageFallback = ext === 'webp' ? paymentCardsPng : imageSource;
        const description = currentSchool?.translations.find((t) => t.lang === match.params.lang);
        // End of prepare image to <ImgWithFallback />
        return (
            <>
                {
                    (getCurrentVersion() === VERSIONS.BookingSport) && currentSchool
                        ? (
                            <div className="__sitemap-parent-description">
                                <div className="__sitemap-column" style={{ maxWidth: '60%', textAlign: 'justify' }}>
                                    <p className="__sitemap-column-title">{currentSchool.name}</p>
                                    <div className="__sitemap-first-column">
                                        <p className="__sitemap-column-text __sitemap-column-text-not-link">{description?.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                        : getCurrentVersion() === VERSIONS.BookingFormation || getCurrentVersion() === VERSIONS.BookingSport
                            ? null
                            : (
                                <Accordion
                                    square
                                    className="__sitemap-parent-accordion"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            color: 'var(--font-input-color)'
                                        }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className="__sitemap-parent-title">
                                            <p className="__sitemap-column-title">{intl.formatMessage({ id: 'landing.destinations' })}</p>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="__sitemap-parent-destinations">
                                            {
                                                destinations && destinations.map((d) => (
                                                    <div key={`destination-sitemap-column-${d.companyId}-${d.id}`} className="__sitemap-column">
                                                        <p
                                                            onClick={() => changeSubDomain(d.subdomain)}
                                                            key={`sitemap-destinations-${d.companyId}`}
                                                            className="__sitemap-column-text"
                                                        >
                                                            {d.title}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                }
                <div className="__sitemap-parent">

                    <div className="__sitemap-column">
                        <p className="__sitemap-column-title"><FormattedMessage id="landing.company" /></p>
                        <ul className="__sitemap-column-ul">
                            <li className="__sitemap-column-li"><p onClick={() => this.goToHelpPage('cga')} className="__sitemap-column-text"><FormattedMessage id="landing.terms" /></p></li>
                            <li className="__sitemap-column-li"><p onClick={() => this.goToHelpPage('gr')} className="__sitemap-column-text"><FormattedMessage id="landing.privacy" /></p></li>
                        </ul>
                    </div>

                    <div className="__sitemap-column">
                        <p className="__sitemap-column-title"><FormattedMessage id="landing.support" /></p>
                        <ul className="__sitemap-column-ul">
                            <li className="__sitemap-column-li"><p onClick={() => this.goToHelpPage('contact')} className="__sitemap-column-text"><FormattedMessage id="landing.contact" /></p></li>
                            {/* <li className="__sitemap-column-li"><p onClick={() => this.goToHelpPage('faq')} className="__sitemap-column-text"><FormattedMessage id='landing.faq'/></p></li> */}
                        </ul>
                    </div>

                    <div className="__sitemap-column">
                        <p className="__sitemap-column-title"><FormattedMessage id="landing.securePayment" /></p>
                        <div>

                            <ImgWithFallback
                                src={imageSrc}
                                fallback={imageFallback}
                                className="__sitemap-payment-img"
                                alt={`credit cards - ${intl.formatMessage({ id: 'img.alt.logo' })} ${getVersionName()}`}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    destinations: store.navigation.destinations,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(withRouter(Sitemap)));
