import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import OrderApi from './api/order_api';

interface IProps {

}

type Props = IProps & RouterProps & WrappedComponentProps;

interface State {
    error: boolean;
    counter: number;
    next: boolean;
    response?: any;
}

class PaymentSuccess extends React.Component<Props, State> {
    interval: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            error: false,
            counter: 0,
            next: false,
            response: undefined,
        };
    }

    componentDidMount() {
        const { match } = this.props;
        this.checkPayment(match.params.sessionId);
    }

    componentDidUpdate() {
        const { match, history } = this.props;
        const { response, next } = this.state;
        if (next) {
            history.replace({
                pathname: `/${match.params.lang}/order/success`,
                state: {
                    bookingCode: response.bookingCode,
                    price: response.price,
                    commonId: response.commonId,
                },
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.interval);
    }

    async checkPayment(sessionId: string) {
        const { history, match } = this.props;
        const { counter } = this.state;
        try {
            const response = await OrderApi.checkPayment(sessionId);
            this.setState({ response, next: true });
        } catch (error: any) {
            if ((error.response.status !== 402 && error.response.status !== 400)) {
                clearTimeout(this.interval);
                history.replace(`/${match.params.lang}`);
            } else if (counter === 5) {
                clearTimeout(this.interval);
                this.setState({ error: true });
            } else {
                this.setState((state) => ({ counter: state.counter + 1 }), () => {
                    this.interval = setTimeout(
                        () => this.checkPayment(match.params.sessionId),
                        1500 * counter,
                    );
                });
            }
        }
    }

    render() {
        const { intl, history, match } = this.props;
        const { error } = this.state;
        return (
            <Container headerBase="Order">
                {
                    error
                        ? (
                            <div className="__order-parent">
                                <p className="__order-text-failed">{intl.formatMessage({ id: 'order.paymentSuccess.error' })}</p>
                                <Button buttonClasses="__order-button" onClick={() => history.replace(`/${match.params.lang}/cart`)}>{intl.formatMessage({ id: 'booking.goToCart' })}</Button>
                            </div>
                        )
                        : (
                            <div className="__order-parent">
                                <p className="__order-text">{intl.formatMessage({ id: 'order.paymentSuccess' })}</p>
                                <LoadingCircle />
                            </div>
                        )
                }
            </Container>
        );
    }
}

export default withRouter(injectIntl(PaymentSuccess));
