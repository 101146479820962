import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { hideSignupError } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import Button from '../common/button';
// import TextField from '../common/textfield';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch,
    onSubmit(state: any): void,
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    email?: string;
    password?: string;
    errors: {
        email?: string;
        password?: string;
    };
    loading: boolean;
    showPassword: boolean;
    [x: string]: any;
}

class LoginForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showPassword: false,
            email: undefined,
            password: undefined,
            errors: {
                email: undefined,
                password: undefined,
            },
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.checkInputs = this.checkInputs.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate() {
        const { isErrorVisible } = this.props;
        const { loading } = this.state;
        if (isErrorVisible && loading !== false) {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
    }

    handleFocus(name: string) {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
        this.setState({ errors: { [name]: null } });
    }

    handleChange(event: any, name: string) {
        this.setState({ [name]: event.target.value });
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        if (!this.checkInputs()) {
            this.setState({ loading: true });
            onSubmit(this.state);
        }
    }

    onKeyDown = (event: any) => {
        if (event.key === 'Enter') this.handleSubmit();
    };

    checkInputs() {
        const { intl } = this.props;
        const { email, password } = this.state;
        const errors: {
            email?: string;
            password?: string;
        } = {
            email: undefined,
            password: undefined,
        };
        if (!email || email.length <= 0) {
            errors.email = intl.formatMessage({ id: 'login.emailRequired' });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = intl.formatMessage({ id: 'login.emailNotValid' });
        }

        if (!password || password.length <= 0) {
            errors.password = intl.formatMessage({ id: 'login.passwordRequired' });
        } else if (password.length < 8) {
            errors.password = intl.formatMessage({ id: 'login.passwordMinimumCharacters' });
        }

        this.setState({ errors });

        return errors.email || errors.password;
    }

    render() {
        const { intl, isErrorVisible, errorText } = this.props;
        const {
            email, errors, password, loading, showPassword
        } = this.state;
        return (
            <div className="__signup-form" onKeyDown={this.onKeyDown}>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={email}
                        error={errors?.email ? errors?.email?.length > 0 : false}
                        helperText={errors.email}
                        type="email"
                        label={intl.formatMessage({ id: 'email' })}
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onFocus={() => this.handleFocus('email')}
                        onChange={(value) => this.handleChange(value, 'email')}
                    />
                </span>
                <span className="__signup-textfield-container">
                    <TextField
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={password}
                        error={errors.password ? errors.password?.length > 0 : false}
                        helperText={errors.password}
                        type={showPassword ? 'text' : 'password'}
                        label={intl.formatMessage({ id: 'password' })}
                        sx={{
                            '& .MuiFormLabel-root': {
                                color: 'var(--font-input-color)'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--font-input-color)'
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'var(--font-input-color)'
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{ paddingLeft: '0px', paddingRight: '0px' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState((prevState) => ({ showPassword: !prevState.showPassword }))}
                                        onMouseDown={(e) => e?.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onFocus={() => this.handleFocus('password')}
                        onChange={(value) => this.handleChange(value, 'password')}
                    />
                </span>
                {
                    isErrorVisible
                        ? <span className="__signup-error">{errorText}</span>
                        : null
                }
                <span className="__signup-button-container">
                    <Button onClick={this.handleSubmit} loading={loading}>
                        <FormattedMessage id="login.login" />
                    </Button>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    isErrorVisible: store.user.isErrorVisible,
    errorText: store.user.errorText,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(LoginForm));
