import { CardGiftcardOutlined, LoyaltyOutlined } from '@mui/icons-material';
import {
    BottomNavigation, BottomNavigationAction, Grid
} from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import Discount from '../../resources/img/discount.svg';
import { ApplicationState } from '../../store/types';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import Modal from '../common/modal';
import Snackbar from '../common/snackbar';
import TextField from '../common/textfield';
import Title from '../common/title';

export const DiscountTypes = Object.freeze({ coupon: 'coupon', fidelity: 'fidelity', discount: 'discount' });

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    errorCoupon: boolean;
    errorDiscount: boolean;
    fidelityPoints: any;
    disabledCoupon: boolean;
    disabledFidelity: boolean;
    disabledDiscount: boolean;
    containerClasses?: string;
    fidelityActivated: boolean;
    open: boolean;
    checkFidelity(): void;
    checkCoupon(code: string): void;
    checkDiscount(code: string): void;
    resetErrorCoupon(): void;
    resetErrorDiscount(): void;
    onClose(): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    openSnackbar: boolean;
    typeOfDiscount: string;
    couponCode: string;
    discountCode: string;
    usePoints: boolean;
}

class DiscountModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openSnackbar: false,
            typeOfDiscount: DiscountTypes.discount,
            couponCode: '',
            discountCode: '',
            usePoints: false,

        };
    }

    reset = (typeOfDiscount = true) => {
        const { resetErrorCoupon, resetErrorDiscount } = this.props;
        this.setState((state) => ({
            openSnackbar: false,
            typeOfDiscount: typeOfDiscount ? DiscountTypes.coupon : state.typeOfDiscount,
            couponCode: '',
            discountCode: '',
            usePoints: false,
        }));
        resetErrorCoupon();
        resetErrorDiscount();
    };

    renderConfirm = () => {
        const {
            intl,
            disabledCoupon,
            checkCoupon,
            checkFidelity,
            checkDiscount,
            disabledDiscount,
            disabledFidelity,
        } = this.props;
        const {
            typeOfDiscount, couponCode, discountCode, usePoints,
        } = this.state;
        switch (typeOfDiscount) {
            case DiscountTypes.coupon:
                return (
                    <Button containerClasses="__cart-settings-button-container" disabled={couponCode === '' || disabledCoupon} buttonClasses="__cart-settings-order" onClick={() => checkCoupon(couponCode)}>
                        {intl.formatMessage({ id: 'order.applyCodeDiscount' })}
                    </Button>
                );
            case DiscountTypes.fidelity:
                return (
                    <Button containerClasses="__cart-settings-button-container" disabled={!usePoints || disabledFidelity} buttonClasses="__cart-settings-order" onClick={checkFidelity}>
                        {intl.formatMessage({ id: 'order.applyCodeDiscount' })}
                    </Button>
                );
            case DiscountTypes.discount:
                return (
                    <Button containerClasses="__cart-settings-button-container" disabled={discountCode === '' || disabledDiscount} buttonClasses="__cart-settings-order" onClick={() => checkDiscount(discountCode)}>
                        {intl.formatMessage({ id: 'order.applyCodeDiscount' })}
                    </Button>
                );
            default:
                return null;
        }
    };

    renderTypeOfDiscount = () => {
        const {
            errorCoupon,
            resetErrorCoupon,
            errorDiscount,
            resetErrorDiscount,
            intl,
            disabledFidelity,
            fidelityPoints,
        } = this.props;
        const {
            typeOfDiscount, couponCode, discountCode, usePoints,
        } = this.state;
        switch (typeOfDiscount) {
            case DiscountTypes.coupon:
                return (
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                key={`${typeOfDiscount}-input-coupon`}
                                className={`${errorCoupon ? '__cart-code-discount-red' : ''}`}
                                style={{ width: '100%' }}
                                value={couponCode}
                                onChange={(e) => { resetErrorCoupon(); this.setState({ couponCode: e.target.value }); }}
                                // placeholder={intl.formatMessage({ id: 'order.placeholderCoupon' })}
                                placeholder="xxxx.xxxx.xxxx"
                            />
                        </Grid>
                        {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            {errorCoupon && <p style={{ color: 'var(--secondary-color)', textAlign: 'right', marginTop: '5px' }}>{intl.formatMessage({ id: 'order.codeDiscountError' })}</p>}
                        </Grid> */}
                    </Grid>
                );
            case DiscountTypes.discount:
                return (
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                key={`${typeOfDiscount}-input-discount`}
                                className={`${errorDiscount ? '__cart-code-discount-red' : ''}`}
                                style={{ width: '100%' }}
                                value={discountCode}
                                onChange={(e) => { resetErrorDiscount(); this.setState({ discountCode: e.target.value }); }}
                                placeholder={intl.formatMessage({ id: 'order.placeholderCodeDiscount' })}
                            />
                        </Grid>
                        {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            {errorDiscount && <p style={{ color: 'var(--secondary-color)', textAlign: 'right', marginTop: '5px' }}>{intl.formatMessage({ id: 'order.codeDiscountError' })}</p>}
                        </Grid> */}
                    </Grid>
                );
            case DiscountTypes.fidelity:
                return (
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Checkbox
                                inputFirst
                                disabled={disabledFidelity}
                                checked={usePoints}
                                onChange={() => this.setState({ usePoints: !usePoints })}
                                label={intl.formatMessage({ id: 'order.usePoints' }, { points: fidelityPoints })}
                            />
                        </Grid>
                    </Grid>

                // }
                );
            default:
                return null;
        }
    };

    render() {
        const {
            open, onClose, intl, fidelityActivated, containerClasses
        } = this.props;
        const { typeOfDiscount, openSnackbar } = this.state;
        return (
            <Modal open={open} onClose={onClose} containerClasses={`__discount-modal ${containerClasses ?? ''}`}>
                <Snackbar
                    variant="error"
                    open={openSnackbar}
                    onClose={() => this.setState({ openSnackbar: false })}
                    timer={5000}
                    message={intl.formatMessage({ id: 'order.settingsNotFilled' })}
                />
                <div>
                    <Title className="__cart-settings-title">{intl.formatMessage({ id: 'order.openDiscountModalTitle' })}</Title>
                    {/* <RadioGroup row aria-label="type-discount" name="type-discount" value={typeOfDiscount} onChange={(e) => this.setState({ typeOfDiscount: e.target.value })}>
                        <FormControlLabel
                            value={DiscountTypes.coupon}
                            control={<Radio />}
                            label={intl.formatMessage({ id: 'order.placeholderCoupon' })}
                            labelPlacement="top" />
                        <FormControlLabel
                            value={DiscountTypes.fidelity}
                            control={<Radio />}
                            label={intl.formatMessage({ id: 'order.placeholderFidelity' })}
                            labelPlacement="top" />
                        <FormControlLabel
                            value={DiscountTypes.discount}
                            control={<Radio />}
                            label={intl.formatMessage({ id: 'order.placeholderCodeDiscount' })}
                            labelPlacement="top" />
                    </RadioGroup> */}

                    <div className="__use-discount-content">
                        {/* <ButtonGroup orientation="vertical" className={`__button-group`} color="primary" aria-label="vertical outlined primary button group">
                            <ButtonMUI
                                className={`__button-group-button ${typeOfDiscount === DiscountTypes.discount ? "__button-group-button-selected" : ""}`}
                                onClick={() => { this.reset(false); this.setState({ typeOfDiscount: DiscountTypes.discount }) }}>
                                {intl.formatMessage({ id: 'order.placeholderCodeDiscount' })}
                            </ButtonMUI>
                            <ButtonMUI
                                className={`__button-group-button ${typeOfDiscount === DiscountTypes.coupon ? "__button-group-button-selected" : ""}`}
                                onClick={() => { this.reset(false); this.setState({ typeOfDiscount: DiscountTypes.coupon }) }}>
                                {intl.formatMessage({ id: 'order.placeholderCoupon' })}
                            </ButtonMUI>
                            {
                                fidelityActivated ?
                                    <ButtonMUI
                                        className={`__button-group-button ${typeOfDiscount === DiscountTypes.fidelity ? "__button-group-button-selected" : ""}`}
                                        onClick={() => { this.reset(false); this.setState({ typeOfDiscount: DiscountTypes.fidelity }) }}>
                                        {intl.formatMessage({ id: 'order.placeholderFidelity' })}
                                    </ButtonMUI>
                                    : null
                            }
                        </ButtonGroup> */}
                        <BottomNavigation
                            className="__discount-pick-discount-type"
                            value={typeOfDiscount}
                            onChange={(event, newValue) => {
                                this.reset(false);
                                this.setState({ typeOfDiscount: newValue });
                            }}
                            showLabels
                        >
                            <BottomNavigationAction
                                value={DiscountTypes.discount}
                                label={intl.formatMessage({ id: 'order.placeholderCodeDiscount' })}
                                icon={<ReactSVG className="__discount-code-discount-icon" src={Discount} />}
                            />
                            <BottomNavigationAction
                                value={DiscountTypes.coupon}
                                label={intl.formatMessage({ id: 'order.placeholderCoupon' })}
                                icon={<CardGiftcardOutlined />}
                            />
                            {
                                fidelityActivated
                                    ? (
                                        <BottomNavigationAction
                                            value={DiscountTypes.fidelity}
                                            label={intl.formatMessage({ id: 'order.placeholderFidelity' })}
                                            icon={<LoyaltyOutlined />}
                                        />
                                    )
                                    : null
                            }
                        </BottomNavigation>
                        <div className="__discount-content-field">
                            {
                                this.renderTypeOfDiscount()
                            }
                        </div>
                    </div>
                </div>
                <div className="__cart-settings-bottom">
                    <div className="__cart-settings-bottom-background" />
                    <div className="__cart-settings-buttons">
                        <Button
                            containerClasses="__cart-settings-button-container"
                            buttonClasses="__cart-settings-cancel"
                            onClick={() => {
                                this.reset();
                                onClose();
                            }}
                        >
                            {intl.formatMessage({ id: 'cancel' })}
                        </Button>
                        {
                            this.renderConfirm()
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    userData: store.setting.userData,
    countries: store.setting.countries,
    languages: store.setting.languages,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(DiscountModal));
