/* eslint-disable jsx-a11y/label-has-associated-control,
jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions,
jsx-a11y/label-has-for */
import { FormattedMessage } from 'react-intl';
import Title from '../common/title';

interface IProps {
    title: any;
    viewAll?: boolean;
    viewLessAction?: () => void;
    viewAllAction?: () => void;
    children: any;
}

type Props = IProps;

function Section(props: Props) {
    const {
        title, viewAll, viewLessAction, viewAllAction, children,
    } = props;

    let returnElement;

    if (viewAll !== undefined && viewAll !== false) {
        returnElement = (
            <label className="__landing-section-view-all" onClick={viewAllAction}><FormattedMessage id="viewAll" /></label>
        );
    } else if (viewLessAction != null) {
        returnElement = (
            <label className="__landing-section-view-all" onClick={viewLessAction}><FormattedMessage id="viewLess" /></label>
        );
    } else {
        returnElement = null;
    }

    return (
        <div className="__landing-section-parent">
            <div className="__landing-section-header">
                {
                    title
                    && (
                        <Title>
                            {title}
                        </Title>
                    )
                }
                {
                    returnElement
                }
            </div>
            <div className="__landing-section-content">
                {children}
            </div>
        </div>
    );
}

export default Section;
