import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Lesson } from '../../types/booking';
import Button from '../common/button';
import LoadingCircle from '../common/loadingCircle';
import Title from '../common/title';
import MyLessonCard from './myLessonCard';

interface IProps {
    isUpcoming: boolean;
    lessons: Lesson[];
    title: string;
    isMyLessonsLoading: boolean;
    handleOpenDetails(lesson: Lesson): void;
}

type Props = IProps & WrappedComponentProps;

interface State {
    viewAll: boolean;
}

class MyLessonsSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            viewAll: false,
        };

        // bind methods
    }

    /**
     * Handle action when view all button is actionned
     */
    viewAllOnClick = () => {
        this.setState((state) => ({ viewAll: !state.viewAll }));
    };

    render() {
        const {
            isUpcoming, lessons, title, isMyLessonsLoading, intl, handleOpenDetails,
        } = this.props;
        const { viewAll } = this.state;

        let returnElement;

        if (isMyLessonsLoading) {
            returnElement = (
                <LoadingCircle />
            );
        } else if (lessons.length > 0) {
            returnElement = (
                <div className="__lessons-content-listing">
                    {
                        lessons.slice(0, viewAll ? lessons.length : 4).map((lesson, index) => {
                            const idx = index;
                            return (<MyLessonCard lesson={lesson} isUpcoming={isUpcoming} handleOpenDetails={() => handleOpenDetails(lesson)} key={`my_lessons_${isUpcoming ? 'upcoming' : 'completed'}_${idx}`} />);
                        })
                    }
                </div>
            );
        } else if (isUpcoming) {
            returnElement = (
                <p className="__lessons-no-lesson">{intl.formatMessage({ id: 'account.mylessons.noLesson' })}</p>
            );
        } else {
            returnElement = null;
        }
        return (
            !isUpcoming && lessons.length === 0 ? null
                : (
                    <div className="__lessons-content">
                        <Title className="__lessons-content-title">{title}</Title>

                        {
                            returnElement
                        }
                        {
                            lessons && lessons.length > 4
                                ? (
                                    <div className="__lessons-button-container">
                                        <Button buttonClasses="__lessons-button" onClick={this.viewAllOnClick}>
                                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                            <label className="__lessons-button-text">{viewAll ? intl.formatMessage({ id: 'less' }) : intl.formatMessage({ id: 'viewAll' })}</label>
                                        </Button>
                                    </div>
                                )
                                : null
                        }
                    </div>
                )
        );
    }
}

export default injectIntl(MyLessonsSection);
