import { Grid } from '@mui/material';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SMARTPHONE_WIDTH } from '../../resources/constants';
import BackgroundImage from '../../resources/img/myaccount/header.jpg';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Container from '../common/container';
import SideBar from '../common/sideBar';
import CustomerLoyalty from './customerLoyalty';
// import MyLessons from './myLessons';
import {
    changeMyAccountCurrentTab,
    openSignUpForm,
    setImagesForLessons
} from '../../store/actions/user';
import { Tabs, TabsObjectKey } from './constants';
import Medals from './medals';
import MyLessons from './myLessons';
import PhotosLibrary from './photosLibrary';
import Settings from './settings';
// import Settings from './settings';

/**
 * Component that represent My Account page
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    content?: any;
    section?: any;
    currentTab?: string;
}

class MyAccount extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const {
            match, history, runChangeMyAccountCurrentTab, runOpenSignUpForm,
        } = this.props;

        // If the user is not logged in, open sign up form
        if (!Authenticator.isLoggedIn()) {
            this.state = {
                content: undefined,
                section: undefined,
            };
            // change url
            const url = `/${match.params.lang}/account/login`;
            if (match.params.tab) match.url.replace(match.params.tab, Tabs.login);
            history.replace(url);

            // open sign up form
            runOpenSignUpForm();

            return;
        }
        const tabParam: TabsObjectKey = match.params.tab as TabsObjectKey;
        const tab: string = tabParam ? Tabs[tabParam] : Tabs.mylessons;

        runChangeMyAccountCurrentTab(tab);
        const [content, section] = this.getCurrentTab(tab);

        this.state = {
            content,
            section,
        };

        // bind methods
    }

    componentDidUpdate(prevProps: any) {
        const {
            isLoginVisible, isSignUpVisible, match, history,
        } = this.props;
        if (
            !Authenticator.isLoggedIn()
      && (prevProps.isLoginVisible || prevProps.isSignUpVisible)
      && !isLoginVisible
      && !isSignUpVisible
        ) {
            history.push(`/${match.params.lang}/`);
        }
    }

    componentWillUnmount() {
        const { lessonsImages, runSetImagesForLessons } = this.props;
        if (lessonsImages.length > 0) {
            runSetImagesForLessons([]);
        }
    }

    handleSwitchTab = (tab: string) => {
        const { runChangeMyAccountCurrentTab } = this.props;
        runChangeMyAccountCurrentTab(tab);
        this.updateCurrentTab(tab);
    };

    getCurrentTab = (tab: string) => {
        const { intl } = this.props;
        let content = null;
        let section = '';
        switch (tab) {
            case Tabs.messages:
                content = this.displayMessagesTab();
                section = intl.formatMessage({ id: 'account.messages' });
                break;
            case Tabs.photos:
                content = this.displayPhotosTab();
                section = intl.formatMessage({ id: 'account.photos' });
                break;
            case Tabs.medals:
                content = this.displayMedalsTab();
                section = intl.formatMessage({ id: 'account.medals' });
                break;
            case Tabs.settings:
                content = this.displaySettingsTab();
                section = intl.formatMessage({ id: 'account.settings' });
                break;
            case Tabs.loyalty:
                content = this.displayCustomerLoyaltyTab();
                section = intl.formatMessage({ id: 'account.customerLoyalty' });
                break;
            case Tabs.login:
                if (!Authenticator.isLoggedIn()) {
                    content = <div />;
                    section = intl.formatMessage({ id: 'login.login' });
                    break;
                }
                break;
            case Tabs.mylessons:
                content = this.displayMyLessonsTab();
                section = intl.formatMessage({ id: 'account.mylessons' });
                break;
            default:
                Sentry.captureMessage(
                    'Entered default case where it should never goe because it does not work',
                );
                content = this.displayMyLessonsTab();
                section = intl.formatMessage({ id: 'account.mylessons' });
                break;
        }
        return [content, section];
    };

    updateCurrentTab = (tab: string) => {
        const [content, section] = this.getCurrentTab(tab);

        this.setState({
            section,
            content,
        });
    };

    /**
   * Display the content corresponding to my lessons tab
   */
    displayMyLessonsTab = () => {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.mylessons}`);
        return <MyLessons />;
    };

    /**
   * Display the content corresponding to messages tab
   */
    displayMessagesTab = () => {
        const { history, match, intl } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.messages}`);
        return (
            <div className="comingSoon">
                <p>{intl.formatMessage({ id: 'comingSoon' })}</p>
            </div>
        );
    // return (
    //     <Messages messages={array}/>
    // );
    };

    /**
   * Display the content corresponding to photos tab
   */
    displayPhotosTab = () => {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.photos}`);
        return <PhotosLibrary />;
    };

    /**
   * Display the content corresponding to medals tab
   */
    displayMedalsTab = () => {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.medals}`);
        return <Medals />;
    };

    /**
   * Display the content corresponding to settings tab
   */
    displaySettingsTab = () => {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.settings}`);
        return <Settings />;
    };

    displayCustomerLoyaltyTab = () => {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/account/${Tabs.loyalty}`);
        return <CustomerLoyalty />;
    };

    render() {
        const {
            intl, windowWidth,
        } = this.props;
        const { section, content } = this.state;
        return (
            <Container
                headerBase={`${intl.formatMessage({ id: 'account.myaccount' })} > ${section || 'NaN'}`}
                image={BackgroundImage}
            >
                <Grid container spacing={2} className="__account-parent">
                    <Grid xs={windowWidth > SMARTPHONE_WIDTH ? 10 : 12} item>
                        {content}
                    </Grid>
                    {windowWidth > SMARTPHONE_WIDTH ? (
                        <Grid item xs={2} className="__account-sidebar">
                            <SideBar onClick={(tab:string) => this.handleSwitchTab(tab)} />
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
    lessonsImages: store.user.lessonsImages,
    isLoginVisible: store.user.isLoginVisible,
    isSignUpVisible: store.user.isSignUpVisible,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runSetImagesForLessons: (image: string[]) => dispatch(setImagesForLessons(image)),
    runChangeMyAccountCurrentTab: (currentTab: string) => dispatch(changeMyAccountCurrentTab(currentTab)),
    runOpenSignUpForm: () => dispatch(openSignUpForm()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default injectIntl(withRouter(connector(MyAccount)));
