import { ProductsState, StoreAction } from '../types';

export const PRODUCTS_INIT_STATE: ProductsState = {
    products: [],
    schools: [],
    codeDiscount: undefined,
    isProductsLoading: false,
    isSchoolsLoading: false,
    isMoreProductsLoading: false,
    selectedProductsDate: '',
    hasNoMoreProducts: false,
};

export const TYPE_INIT_PRODUCTS = ('PRODUCTS_TYPE_INIT_PRODUCTS');

export const TYPE_GET_ALL_PRODUCTS = ('PRODUCTS_TYPE_GET_PRODUCTS');
export const TYPE_GET_ALL_PRODUCTS_SUCCESS = ('PRODUCTS_TYPE_GET_ALL_PRODUCTS_SUCCESS');
export const TYPE_GET_ALL_PRODUCTS_FAILURE = ('PRODUCTS_TYPE_GET_ALL_PRODUCTS_FAILURE');
export const TYPE_GET_MORE_PRODUCTS = ('PRODUCTS_TYPE_GET_MORE_PRODUCTS');
export const TYPE_GET_MORE_PRODUCTS_SUCCESS = ('PRODUCTS_TYPE_GET_MORE_PRODUCTS_SUCCESS');
export const TYPE_GET_MORE_PRODUCTS_FAILURE = ('PRODUCTS_TYPE_GET_MORE_PRODUCTS_FAILURE');
export const TYPE_RESET_NO_MORE_PRODUCTS = ('PRODUCTS_TYPE_RESET_NO_MORE_PRODUCTS');

export const TYPE_GET_SCHOOLS = ('PRODUCTS_TYPE_GET_SCHOOLS');
export const TYPE_GET_SCHOOLS_SUCCESS = ('PRODUCTS_TYPE_GET_SCHOOLS_SUCCESS');
export const TYPE_GET_SCHOOLS_FAILURE = ('PRODUCTS_TYPE_GET_SCHOOLS_FAILURE');

export const TYPE_GET_CODE_DISCOUNT_SUCCESS = ('PRODUCTS_TYPE_GET_CODE_DISCOUNT_SUCCESS');
export const TYPE_GET_CODE_DISCOUNT_FAILURE = ('PRODUCTS_TYPE_GET_CODE_DISCOUNT_FAILURE');

export const TYPE_SET_SELECTED_PRODUCTS_DATE = ('PRODUCTS_TYPE_SET_SELECTED_PRODUCTS_DATE');
export const ERROR_UNDEFINED = ('PRODUCTS_ERROR_UNDEFINED');

export const products = (state: ProductsState = PRODUCTS_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_INIT_PRODUCTS:
            return {
                ...PRODUCTS_INIT_STATE,
                schools: state.schools,
            };
        case TYPE_GET_ALL_PRODUCTS:
            return {
                ...state,
                isProductsLoading: true,
            };
        case TYPE_GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                isProductsLoading: false,
                products: action.products ? action.products : [],
            };
        case TYPE_GET_ALL_PRODUCTS_FAILURE:
            return {
                ...state,
                isProductsLoading: false,
                products: [],
            };
        case TYPE_GET_SCHOOLS:
            return {
                ...state,
                isSchoolsLoading: true,
            };
        case TYPE_GET_SCHOOLS_SUCCESS:
            return {
                ...state,
                isSchoolsLoading: false,
                schools: action.schools ? action.schools : [],
            };
        case TYPE_GET_SCHOOLS_FAILURE:
            return {
                ...state,
                isSchoolsLoading: false,
                schools: [],
            };
        case TYPE_GET_CODE_DISCOUNT_SUCCESS:
            return {
                ...state,
                codeDiscount: action.codeDiscount ? action.codeDiscount : {},
            };
        case TYPE_GET_CODE_DISCOUNT_FAILURE:
            return {
                ...state,
                codeDiscount: undefined,
            };
        case TYPE_SET_SELECTED_PRODUCTS_DATE:
            return {
                ...state,
                selectedProductsDate: action.selectedProductsDate,
            };
        case TYPE_GET_MORE_PRODUCTS:
            return {
                ...state,
                isMoreProductsLoading: true,
            };
        case TYPE_GET_MORE_PRODUCTS_SUCCESS:
            return {
                ...state,
                isMoreProductsLoading: false,
                products: action.products ? state.products.concat(action.products) : state.products,
                hasNoMoreProducts: action.products.length <= 0,
            };
        case TYPE_GET_MORE_PRODUCTS_FAILURE:
            return {
                ...state,
                isMoreProductsLoading: false,
            };
        case TYPE_RESET_NO_MORE_PRODUCTS:
            return {
                ...state,
                hasNoMoreProducts: false,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
