/* eslint-disable prefer-destructuring */
import MenuIcon from '@mui/icons-material/Menu';
import {
    Badge,
    IconButton
} from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import { SMARTPHONE_WIDTH, VERSIONS } from '../../resources/constants';
import AccountLogged from '../../resources/img/account-logged.png';
import AccountPng from '../../resources/img/account-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import Account from '../../resources/img/account-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import GermanFlagPng from '../../resources/img/allemand-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import GermanFlag from '../../resources/img/allemand-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import EnglishFlagPng from '../../resources/img/anglais-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import EnglishFlag from '../../resources/img/anglais-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import Cart from '../../resources/img/cart.svg';
import FrenchFlagPng from '../../resources/img/franc-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import FrenchFlag from '../../resources/img/franc-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import ItalianFlagPng from '../../resources/img/italian-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.png';
import ItalianFlag from '../../resources/img/italian-swiss-ski-school-ski-lesson-kids-cours-collectif-nendaz-siviez-veysonnaz-crans-montana-anzere-grimentz-zinal-villars-tzoumaz-verbier-cours-ski-unterricht-ecole-suisse-ski-valais-suisse-schweizere-schule-switzerland.webp';
import {
    getCurrentVersion, getTranslation, getVersionName, ImgWithFallback
} from '../../resources/utils';
import { changeNavigationCurrentPage } from '../../store/actions/navigation';
import changeLanguage from '../../store/actions/translation';
import { changeMyAccountCurrentTab, openSignUpForm } from '../../store/actions/user';
import { toggleHamburger } from '../../store/actions/windowSize';
import { Language } from '../../store/reducers/translation';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { LessonCart } from '../../types/booking';
import { Menu } from '../../types/common';
import { RouterProps } from '../../types/generalTypes';
import { Tabs } from '../account/constants';
import Authenticator from '../authenticator';
import Menus from './menu';
import Tooltip from './tooltip';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    lang?: string;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    openLanguageMenu: boolean;
    flags: any;
    visibleFlags: any;
    openMenus: any;
}

class Menubar extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     lang: '',
    // };

    constructor(props: Props) {
        super(props);

        const { match } = this.props;

        const flags = [
            [EnglishFlag, Language.en, EnglishFlagPng],
            [FrenchFlag, Language.fr, FrenchFlagPng],
            [GermanFlag, Language.de, GermanFlagPng],
            [ItalianFlag, Language.it, ItalianFlagPng],
        ];
        this.state = {
            openLanguageMenu: false,
            flags,
            visibleFlags: flags.filter((f) => f[1] !== match.params.lang),
            openMenus: [false, false, false, false, false, false],
        };

        // bind methods
        this.handleClickHamburger = this.handleClickHamburger.bind(this);
        this.redirectToHomePage = this.redirectToHomePage.bind(this);
        this.goToCart = this.goToCart.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(changeLanguage(match.params.lang));
    }

    componentDidUpdate() {
        const { currentSchool, schools, match } = this.props;
        const { flags: flagsState } = this.state;
        // Hardcoded available language according to school or platform
        // (swisskischool vs skischoolalliance)
        let flags = [];
        if (currentSchool && currentSchool.id === 24) {
            flags = [
                [EnglishFlag, Language.en, EnglishFlagPng],
                [FrenchFlag, Language.fr, FrenchFlagPng],
                [GermanFlag, Language.de, GermanFlagPng],
                [ItalianFlag, Language.it, ItalianFlagPng],
            ];
        } else if (currentSchool && currentSchool.id === 58) {
            flags = [
                [FrenchFlag, Language.fr, FrenchFlagPng],
            ];
        } else if (currentSchool && (currentSchool.id === 28 || currentSchool.id === 49)) {
            flags = [
                [EnglishFlag, Language.en, EnglishFlagPng],
                [FrenchFlag, Language.fr, FrenchFlagPng],
            ];
        } else if (!currentSchool && schools.length > 0 && schools[0].version === 0) {
            flags = [
                [EnglishFlag, Language.en, EnglishFlagPng],
                [FrenchFlag, Language.fr, FrenchFlagPng],
                [GermanFlag, Language.de, GermanFlagPng],
                [ItalianFlag, Language.it, ItalianFlagPng],
            ];
        } else if (schools.length > 0 && schools[0].version === 1) {
            flags = [
                [EnglishFlag, Language.en, EnglishFlagPng],
                [FrenchFlag, Language.fr, FrenchFlagPng],
            ];
        } else if (getCurrentVersion() === VERSIONS.BookingFormation) {
            flags = [[FrenchFlag, Language.fr, FrenchFlagPng], [GermanFlag, Language.de, GermanFlagPng]];
        } else {
            flags = [
                [EnglishFlag, Language.en, EnglishFlagPng],
                [FrenchFlag, Language.fr, FrenchFlagPng],
                [GermanFlag, Language.de, GermanFlagPng],
            ];
        }
        if (JSON.stringify(flagsState) !== JSON.stringify(flags)) {
            this.setState({ flags, visibleFlags: flags.filter((f) => f[1] !== match.params.lang) });
        }
    }

    handleClickHamburger() {
        const { dispatch } = this.props;
        dispatch(toggleHamburger());
    }

    getLangLogo() {
        const {
            match, location, intl,
        } = this.props;
        const { flags, openLanguageMenu, visibleFlags } = this.state;
        const platform = getVersionName();

        const handleClick = () => {
            this.setState((state) => ({ openLanguageMenu: !state.openLanguageMenu }));
        };

        const handleChangeLanguage = (flag: any) => {
            // dismiss menu
            handleClick();

            const { lang } = match.params;
            const path = location.pathname + location.search;
            window.location.href = window.location.href.replace(path, path.replace(lang, flag[1]));
        };

        const onBlur = (event: any) => {
            if (event.currentTarget === document.getElementById('menubar-lang-menu-id')) return;
            handleClick();
        };

        let source = flags.filter((flag: any) => flag[1] === match.params.lang);
        let sourceFallback = source;

        if (source.length > 0) {
            source = source[0][0] ? source[0][0] : undefined;
            sourceFallback = sourceFallback[0][2] ? sourceFallback[0][2] : undefined;
        } else {
            source = undefined;
            sourceFallback = undefined;
        }

        // Prepare image to <ImgWithFallback /> (custom)
        // if (sourceFallbackFlag) {
        //     sourceFallback = sourceFallbackFlag;
        // } else {
        //     sourceFallback = undefined;
        // }

        const imageSource = source;
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(imageSource)![1];
        const imageSrc = imageSource;
        const imageFallback = ext === 'webp' ? sourceFallback : imageSource;
        // End of prepare image to <ImgWithFallback />

        return (
            <div className="__menubar-lang" id="menubar-lang-menu-id" onBlur={onBlur}>
                <IconButton
                    className="__menubar-lang-button"
                    aria-label="Lang"
                    onClick={handleClick}
                    size="large"
                >
                    <ImgWithFallback
                        src={imageSrc}
                        fallback={imageFallback}
                        className="__menubar-lang-logo"
                        alt={`${match.params.lang} - ${intl.formatMessage({ id: 'img.alt.lang' })} ${platform}`}
                    />
                    {/* <img
                        src={source}
                        className="__menubar-lang-logo"
                        alt={
                            match.params.lang + " - " + intl.formatMessage(
                                { id: 'img.alt.lang' }) + " " + platform}
                                /> */}
                </IconButton>
                <div className="__menubar-lang-menu">
                    {openLanguageMenu
                        ? visibleFlags.map((flag: any) => (
                            /* eslint-disable-next-line
                            jsx-a11y/click-events-have-key-events,
                            jsx-a11y/no-noninteractive-element-interactions */
                            <li key={`lang-glag-${flag[1]}`} className="__menubar-lang-menu-item" onClick={() => handleChangeLanguage(flag)}>
                                <img className="__menubar-lang-menu-flag" src={flag[0]} alt={`${flag[1]} - ${intl.formatMessage({ id: 'img.alt.lang' })} ${platform}`} />
                            </li>
                        ))
                        : null}
                </div>
            </div>
        );
    }

    handleClickMenu = (event: any, menu: any, index: number) => {
        const { match, dispatch, history } = this.props;
        const { params } = match;
        if (menu.submenus.length === 0) {
            if (menu.isLink) {
                const link = getTranslation(menu.translations, params.lang);
                if (link) this.lessonItemClick(link.page, params.lang);
            } else {
                dispatch(changeNavigationCurrentPage(menu));
                history.push(`/${params.lang}/pages/${menu.title?.replace(/ /g, '-').toLowerCase()}`);
            }
        } else {
            const { openMenus } = this.state;
            openMenus[index] = event.currentTarget;
            this.setState({ openMenus });
        }
    };

    handleHoverMenu = (event: any, menu: Menu, index: number) => {
        if (menu.submenus.length > 0) {
            const { openMenus } = this.state;
            openMenus[index] = event.currentTarget;
            this.setState({ openMenus });
        }
    };

    handleCloseMenu = (index: number) => {
        const { openMenus } = this.state;
        openMenus[index] = false;
        this.setState({ openMenus });
    };

    handleClickSubMenu = (menu: any, submenu: any, index: number) => {
        const { match, history, dispatch } = this.props;
        const { params } = match;
        if (menu.isLink) {
            const link = getTranslation(submenu.translations, params.lang);
            if (link) this.lessonItemClick(link.page, params.lang);
        } else {
            history.push(`/${params.lang}/pages/${menu.title?.replace(/ /g, '-').toLowerCase()}/${submenu.title?.replace(/ /g, '-').toLowerCase()}`);
            dispatch(changeNavigationCurrentPage(submenu));
            this.handleCloseMenu(index);
        }
    };

    lessonItemClick(path: string, lang: string) {
        const { history } = this.props;
        if (path) {
            // Maybe break (navigationPage l11)
            // window.scrollTo(0, 0);
            if (path.includes('http')) window.location.href = `${path}`;
            else history.push(`/${lang}/${path}`);
        }
    }

    goToCart() {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/cart`);
    }

    login() {
        const { history, match, dispatch } = this.props;
        const isLoggedIn = Authenticator.isLoggedIn();
        if (isLoggedIn) {
            history.push(`/${match.params.lang}/account/mylessons`);
            dispatch(changeMyAccountCurrentTab(Tabs.mylessons));
        } else {
            dispatch(openSignUpForm());
        }
    }

    redirectToHomePage() {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}`);
    }

    render() {
        const {
            intl,
            firstName,
            lastName,
            // companyName,
            currentSchool,
            generalMedia,
            menus,
            windowWidth,
            match,
            lang
        } = this.props;
        const { openMenus } = this.state;
        const accountText = Authenticator.isLoggedIn() ? (`${firstName} ${lastName}` /* + (this.props.companyName ? ' | ' + this.props.companyName : '') */) : intl.formatMessage({ id: 'header.connect' });
        const accountImage = Authenticator.isLoggedIn() ? AccountLogged : Account;
        // let cart = Cookie.get('cart');
        const jsonCart = localStorage?.getItem('cart');
        const cart: LessonCart[] = JSON.parse(jsonCart || '[]');
        let logo;

        if (currentSchool) {
            logo = currentSchool.logo;
        } else if (generalMedia) {
            logo = generalMedia.logo;
        } else {
            logo = undefined;
        }
        // Prepare image to <ImgWithFallback /> (custom)
        const accountImagePng = Authenticator.isLoggedIn() ? AccountLogged : AccountPng;
        const imageSource = accountImage;
        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(imageSource)![1];
        const imageSrc = imageSource;
        const imageFallback = ext === 'webp' ? accountImagePng : imageSource;
        // End of prepare image to <ImgWithFallback />

        // Prepare image to <ImgWithFallback /> (custom)
        let imageSrc1;
        let imageFallback1;
        if (logo) {
            const imageSource1 = logo;
            const re1 = /(?:\.([^.]+))?$/;
            const ext1 = re1.exec(imageSource1)![1];
            imageSrc1 = imageSource1;
            imageFallback1 = ext1 === 'webp' ? `${imageSource1.slice(0, -4)}png` : imageSource1;
        }
        // End of prepare image to <ImgWithFallback />
        const menusAvailableToLang = menus?.filter((m) => getTranslation(m.translations, (lang || 'fr')).display === true);
        return (
            <div className="__menubar-parent-main">
                {
                    logo
                    && (
                        <ImgWithFallback
                            src={imageSrc1}
                            fallback={imageFallback1}
                            className="__menubar-logo"
                            onClick={this.redirectToHomePage}
                            alt={`${intl.formatMessage({ id: 'img.alt.logo' })} ${getVersionName()}`}
                        />
                    )
                    /* <img
                        src={logo}
                        className="__menubar-logo"
                        onClick={this.redirectToHomePage}
                        alt={
                            `${intl.formatMessage({ id: 'img.alt.logo' })}
                            ${getCurrentVersion() === VERSIONS.SkiSchoolAlliance
                                ? 'Ski School Alliance' : 'Swiss Ski School'}`} /> */
                }
                <div className="__menubar-parent">
                    {this.getLangLogo()}
                    {
                        windowWidth > SMARTPHONE_WIDTH && menusAvailableToLang
                        && (
                            <div className={`__menubar-menus-left ${menusAvailableToLang.length > 4 ? '__menubar-menus-3' : '__menubar-menus-2'}`}>
                                <Menus
                                    posFirst={0}
                                    menusLength={menusAvailableToLang.length}
                                    openMenus={openMenus}
                                    lang={match.params.lang}
                                    menus={
                                        menusAvailableToLang.length > 4
                                            ? menusAvailableToLang.slice(0, 3)
                                            : menusAvailableToLang.slice(0, 2)
                                    }
                                    handleHoverMenu={this.handleHoverMenu}
                                    handleCloseMenu={this.handleCloseMenu}
                                    handleClickMenu={this.handleClickMenu}
                                    handleClickSubMenu={this.handleClickSubMenu}
                                    currentMenu={match.params.menu}
                                    currentSubMenu={match.params.submenu}
                                />
                            </div>
                        )
                    }
                    <div className="__menubar-central-div" />
                    {
                        windowWidth > SMARTPHONE_WIDTH
                        && menusAvailableToLang && menusAvailableToLang.length > 2
                        && (
                            <div className={`__menubar-menus-right ${menusAvailableToLang.length > 4 ? '__menubar-menus-3' : '__menubar-menus-2'}`}>
                                <Menus
                                    posFirst={3}
                                    menusLength={menusAvailableToLang.length}
                                    openMenus={openMenus}
                                    lang={match.params.lang}
                                    menus={
                                        menusAvailableToLang.length > 4
                                            ? menusAvailableToLang.slice(3)
                                            : menusAvailableToLang.slice(2)
                                    }
                                    handleHoverMenu={this.handleHoverMenu}
                                    handleCloseMenu={this.handleCloseMenu}
                                    handleClickMenu={this.handleClickMenu}
                                    handleClickSubMenu={this.handleClickSubMenu}
                                    currentMenu={match.params.menu}
                                    currentSubMenu={match.params.submenu}
                                />
                            </div>
                        )
                    }
                    {
                        windowWidth > SMARTPHONE_WIDTH
                            ? (
                                <div className="__menubar-right-div">
                                    {
                                        (currentSchool === undefined
                                            || (currentSchool && !currentSchool.online_purchase))
                                            ? <div className="__menubar-right-tooltip __menubar-right-tooltip-img" />
                                            : (
                                                <Tooltip className="__menubar-right-tooltip" title={intl.formatMessage({ id: 'header.cart' })}>
                                                    <IconButton onClick={this.goToCart} size="large">
                                                        <Badge badgeContent={cart ? cart.length : 0} color="primary">
                                                            <img src={Cart} className="__menubar-right-tooltip-img" alt={intl.formatMessage({ id: 'header.cart' })} />
                                                        </Badge>
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                    }
                                    {currentSchool && currentSchool.online_purchase && <div className="__menubar-right-separator" />}
                                    <Tooltip className="__menubar-right-tooltip" title={accountText}>
                                        <IconButton onClick={this.login} size="large">
                                            <ImgWithFallback
                                                src={imageSrc}
                                                fallback={imageFallback}
                                                className="__menubar-right-tooltip-img"
                                                alt={accountText}
                                            />
                                            {/* <img
                                            src={accountImage}
                                            className="__menubar-right-tooltip-img"
                                            alt={accountText} /> */}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )
                            : (
                                <IconButton aria-label="Menu Mobile" onClick={this.handleClickHamburger} size="large">
                                    <MenuIcon className="__menubar-hamburger-icon" />
                                </IconButton>
                            )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    schools: store.setting.schools,
    windowWidth: store.windowSize.width,
    firstName: store.setting.userData && store.setting.userData.user ? store.setting.userData.user.first_name : '',
    lastName: store.setting.userData && store.setting.userData.user ? store.setting.userData.user.last_name : '',
    companyName: store.setting.userData && store.setting.userData.company ? store.setting.userData.company.name : '',
    cartChanged: store.user.cartChanged,
    userData: store.setting.userData,
    menus: store.navigation.menus,
    currentSchool: store.navigation.currentSchool,
    generalMedia: store.navigation.generalMedia,
});
const connector = connect(mapStateToProps);

export default withRouter(injectIntl(connector(Menubar)));
