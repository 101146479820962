import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import '../../resources/css/sidebar.css';
import { ApplicationState } from '../../store/types';

/**
 * Component that represent an item of the side bar
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    classNameSVG?: string;
    isCurrentTab: boolean;
    image: string;
    text: any;
    onClick(): void;
}

type Props = IProps & ReduxProps;

//! STATE NEVER USED
// interface State {
//     isLoggedIn?: string;
//     lessonMaxLength: number;
//     isNewsFlash: boolean;
//     grimentzZinalModal: boolean;
//     neuchatelRegionModal: boolean;
//     path?: string;
// }

// const defaultProps = {
//     classNameSVG: '',
// };

function SideBarItem(props: Props) {
    const {
        onClick, classNameSVG, isCurrentTab, image, text,
    } = props;
    return (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-noninteractive-element-interactions */
        <li className="__sidebar-item-li" onClick={onClick}>
            <ReactSVG className={`__sidebar-item-img ${classNameSVG && isCurrentTab ? classNameSVG : ''}`} src={image} style={{ fill: isCurrentTab ? 'var(--primary-color)' : 'var(--ternary-color)' }} />
            <p className="__sidebar-item-text" style={{ color: isCurrentTab ? 'var(--primary-color)' : 'var(--ternary-color)' }}>{text}</p>
        </li>
    );
}

// SideBarItem.defaultProps = defaultProps;

const mapStateToProps = (state: ApplicationState) => ({
    windowWidth: state.windowSize.width,
});
const connector = connect(mapStateToProps);

export default connector(SideBarItem);
