/* eslint-disable no-nested-ternary */
import { SelectProps as MUISelectProps, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import '../../resources/css/fields.css';

/**
 * Component that represenet a selection field
 */

interface SelectIProps extends MUISelectProps {
    parentClasses?: string;
    containerClasses?: string;
    selectClasses?: string;
    fontSize?: string;
    height?: string | number;
    padding?: string;
    errorMessage?: string;
    displayLabel?: boolean;
    disabled?: boolean;
    border?: boolean;
    forceValue?: boolean;
}
interface SelectState {
    value?: any;
}
type SelectProps = SelectIProps;

class SelectField extends React.Component<SelectProps, SelectState> {
    constructor(props: SelectProps) {
        super(props);

        const { value, forceValue } = this.props;

        this.state = {
            value: value || (forceValue === false ? undefined : value),
        };
    }

    onChange = (event: SelectChangeEvent<typeof this.state.value>) => {
        const { onChange } = this.props;
        this.setState({ value: event.target.value }, () => {
            if (onChange) onChange(event, null);
        });
    };

    render() {
        const {
            containerClasses,
            parentClasses,
            className,
            selectClasses,
            value,
            fontSize,
            padding,
            children,
            displayLabel,
            disabled,
            border,
            height,
            forceValue,
            ...props
        } = this.props;
        const { value: valueState } = this.state;
        return (
            <Select
                className={`__select-selector ${selectClasses || ''} ${className || ''}`}
                // native
                disabled={disabled}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: border ? 'var(--font-input-color) 1px solid' : 'none',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: border ? 'var(--font-input-color) 1.5px solid' : 'none',
                    },
                    '& .Mui-disabled': {
                        color: 'var(--font-input-color)',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'var(--font-input-color)'
                    },
                    '& .Mui-error': {
                        border: '2px solid var(--error-color)',
                        position: 'absolute',
                        padding: '0px 0px 0px 16.5px',
                        paddingRight: '0px!important',
                        width: 'calc( 100% - 18.5px )',
                        borderRadius: 'var(--basic-border-radius)',
                        lineHeight: height,
                        zIndex: 99
                    },
                    '& .MuiSelect-select': {
                        WebkitTextFillColor: 'var(--font-input-color)'
                    },
                    color: 'var(--font-input-color)',
                    // border: '2px solid var(--border-color)',
                    width: displayLabel === true ? 'inherit' : '100%',
                    fontFamily: 'Montserrat',
                    fontSize: fontSize || '16px',
                    padding: padding || '0px 28px 0px 0px',
                    height: height || 'auto',

                    // color: disabled ? '#9C9C9D' : 'var(--font-input-color)',
                    // cursor: disabled ? 'not-allowed' : 'pointer',
                    // height: 'auto',
                }}
                value={value || (forceValue === false ? valueState : value)}
                onChange={this.onChange}
                // inputProps={{
                //     // disableUnderline: true,
                //     'aria-label': 'select',
                //     style: {
                //         // fontFamily: 'Montserrat',
                //         // fontSize: fontSize || '16px',
                //         // height: height || 'auto',
                //         // padding: padding || '0px 28px 0px 0px',
                //         // color: disabled ? '#9C9C9D' : 'var(--font-input-color)',
                //         // cursor: disabled ? 'not-allowed' : 'pointer',
                //     },
                // }}
                // style={{
                //     // fontSize: fontSize || '16px',
                //     // fontFamily: 'Montserrat',
                //     // width: '100%',
                //     // height: height || 'auto',
                //     // padding: '0px 0px 3px 0px',
                //     // color: disabled ? '#9C9C9D' : 'var(--font-input-color)',
                //     // cursor: disabled ? 'not-allowed' : 'pointer',
                // }}
                displayEmpty
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            >
                {children}
            </Select>
        );
    }
}
export default SelectField;
