import React from 'react';
import { Helmet } from 'react-helmet-async';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { VERSIONS } from '../../resources/constants';
import { getCurrentVersion } from '../../resources/utils';
import { changeCart } from '../../store/actions/user';
import { StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import Container from '../common/container';
import Title from '../common/title';

interface IProps {
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & WrappedComponentProps;

interface State {
    bookingCode: any;
}

class OrderSuccess extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { location, history, match } = this.props;
        const bookingCode = location.state && location.state.bookingCode
            ? location.state.bookingCode : undefined;

        if (!bookingCode) history.replace(`/${match.params.lang}`);

        this.state = {
            bookingCode,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { bookingCode } = this.state;
        if (bookingCode === undefined) return;

        localStorage?.setItem('cart', '[]');

        dispatch(changeCart());
    }

    render() {
        const currentVersion = getCurrentVersion();
        const {
            intl, history, match,
        } = this.props;
        const { bookingCode } = this.state;
        return (
            <Container headerBase={intl.formatMessage({ id: 'order.order' })}>
                {
                    currentVersion === VERSIONS.Swisskischool
                    && (
                        <Helmet>
                            <script>
                                {'window.dataLayer = window.dataLayer || []; dataLayer.push({ \'event\': \'Purchase\' });'}
                            </script>
                        </Helmet>
                    )
                }
                <div className="__order-parent">
                    <Title className="__order-title">{intl.formatMessage({ id: 'order.successTitle' })}</Title>
                    <span className="__order-text">
                        <p>{intl.formatMessage({ id: 'order.successText' })}</p>
                        <br />
                        <Button buttonClasses="__order-mylessons-button" onClick={() => history.push(`/${match.params.lang}/account/mylessons`)}>{intl.formatMessage({ id: 'order.goToMyLessons' })}</Button>
                        <br />
                        {bookingCode ? <p>{intl.formatMessage({ id: 'order.bookingCode' }, { code: bookingCode })}</p> : null}
                    </span>
                </div>
            </Container>
        );
    }
}

export default connect()(injectIntl(OrderSuccess));
