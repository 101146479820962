/* eslint-disable
jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions,
jsx-a11y/no-static-element-interactions */
import { Paper } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import CommentImage from '../../resources/img/myaccount/sidebar/message.svg';
import LikeImage from '../../resources/img/newsfeed/like.svg';
import SendImage from '../../resources/img/newsfeed/send.svg';
import { ApplicationState } from '../../store/types';
import { CommentType } from '../../types/newsfeed';
import { School } from '../../types/settings';
import Authenticator from '../authenticator';
import Expandable from '../common/expandable';
import TextField from '../common/textfield';
import Comment from './comment';

/**
 * Component that represent a News card
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    data: {
        user_image: string;
        company_id: number;
        like_name: string;
        aggregate: {
            like: number;
            comment: number;
        };
        user_name: string;
        created: string;
        title: string;
        res_url: string;
        like: boolean;
        has_liked: boolean;
        comment: string;
    };
    currentLang: string;
    comments?: CommentType[];
    listing?: boolean;
    commentValue?: string;
    onCommentSubmit?: () => void;
    onChange?: (e: any) => void;
    onCardClick?: (e: any) => void;
    onLike: () => void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    isLoggedIn?: string;
    comments: any;
    headerLogo?: string;
}

class NewsfeedCard extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     comments: undefined,
    //     listing: true,
    //     commentValue: '',
    //     onCommentSubmit: undefined,
    //     onChange: undefined,
    //     onCardClick: undefined,
    // };

    showComments: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoggedIn: Authenticator.isLoggedIn(),
            comments: [],
            headerLogo: undefined,
        };
    }

    componentDidMount() {
        const { data, schools } = this.props;
        if (data && schools.length > 0) {
            const headerLogo = data.user_image ? data.user_image
                : schools.find((s: School) => s.id === data.company_id)?.logo;
            this.setState({ headerLogo });
        }
    }

    componentDidUpdate() {
        const { data, schools } = this.props;
        const { headerLogo: headerLogoState } = this.state;
        if (headerLogoState === undefined && data && schools.length > 0) {
            const headerLogo = data.user_image ? data.user_image
                : schools.find((s: School) => s.id === data.company_id)?.logo;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ headerLogo });
        }
    }

    getNewsfeedDescription = (item: any) => {
        const { currentLang } = this.props;
        let { desc } = item.text;
        for (const index in item.translations) {
            if (item.translations[index].lang_key === currentLang) {
                desc = item.translations[index].desc;
            }
        }
        // for (const index in item.translations) {
        //     if (item.translations[index].lang_key === currentLang) {
        //         desc = item.translations[index].desc;
        //     }
        // }
        return desc;
    };

    getCommentList = () => {
        const { comments } = this.props;
        return comments?.map((item: any, index: number) => {
            const idx = index;
            return (
                <Comment user={item.user} comment={item.comment} key={idx.toString()} />
            );
        });
    };

    // showComments() {
    // if (commentsState.length === 0) {
    //   api.getOnly(newsfeedComments + "?news_id=" + data.id,
    //       response => {
    //           this.setState({comments: response});
    //       },
    //       error => {
    //           this.setState({comments: []});
    //       }
    //   )
    // }
    // }

    render() {
        const {
            comments: commentsProps,
            data,
            onCardClick,
            listing,
            onLike,
            commentValue,
            intl,
            onChange,
            onCommentSubmit,
        } = this.props;
        const { comments: commentsState, headerLogo, isLoggedIn } = this.state;
        const comments = commentsProps || commentsState;

        const likeName = data.like_name.toLowerCase() === 'like' && data.aggregate && data.aggregate.like > 0 ? 'Likes' : data.like_name;
        const commentText = data.aggregate && data.aggregate.comment > 0 ? <FormattedMessage id="newsfeed.commentPlural" /> : <FormattedMessage id="newsfeed.commentSingular" />;
        return (
            <Paper>
                <div className="__newsfeedcard-parent">
                    <div className="__newsfeedcard-header">
                        <div className="__newsfeedcard-header-left">
                            <img className="__newsfeedcard-header-logo" src={headerLogo} alt="header" />
                            <p className="__newsfeedcard-header-title">{data.user_name}</p>
                        </div>
                        <div className="__newsfeedcard-header-right">
                            <p className="__newsfeedcard-header-time">{moment(data.created).format('HH:mm:ss')}</p>
                            <p className="__newsfeedcard-header-time">{moment(data.created).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                    <div className="__newsfeedcard-body">
                        <p className="__newsfeedcard-body-title" onClick={onCardClick}>{data.title}</p>
                        {data.res_url && <img className={`__newsfeedcard-body-image ${listing ? '__newsfeedcard-body-image-plus' : ''}`} src={data.res_url} onClick={onCardClick} alt="newsfeed" />}
                        <p className="__newsfeedcard-body-text">{this.getNewsfeedDescription(data)}</p>
                    </div>
                    <div className="__newsfeedcard-divider" />
                    <div className="__newsfeedcard-footer">
                        {
                            isLoggedIn
                                ? (
                                    <div className="__newsfeedcard-footer-left">
                                        {data.like
                                            && (
                                                <div className={`${data.has_liked ? '__newsfeedcard-footer-left-container-on' : '__newsfeedcard-footer-left-container'}`} onClick={onLike}>
                                                    <ReactSVG
                                                        className="__newsfeedcard-footer-svg"
                                                        src={LikeImage}
                                                    />
                                                    <p>{likeName}</p>
                                                </div>
                                            )}
                                        {
                                            listing && data.aggregate?.comment > 0 && data.comment
                                                ? (
                                                    <div className="__newsfeedcard-footer-left-container" onClick={listing ? onCardClick : this.showComments}>
                                                        <ReactSVG className={`__newsfeedcard-footer-svg ${data.has_liked ? '__newsfeedcard-footer-svg-on' : ''}`} src={CommentImage} />
                                                        <p><FormattedMessage id="newsfeed.seeComments" /></p>
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                )
                                : null
                        }
                        <div className="__newsfeedcard-footer-text-container">
                            {data.aggregate && <p className="__newsfeedcard-footer-text">{`${data.aggregate.like} ${likeName}`}</p>}
                            {data.comment && data.aggregate && (
                                <p className={listing ? 'clickable' : ''} onClick={listing ? onCardClick : undefined}>
                                    {data.aggregate.comment}
                                    {' '}
                                    {commentText}
                                </p>
                            )}
                        </div>
                    </div>
                    {
                        (!listing) || (listing && commentsState.length > 0)
                            ? (
                                <div className="__newsfeedcard-comment-parent">
                                    {
                                        isLoggedIn
                                            ? (
                                                <div className="__newsfeed-detail-comment-parent">
                                                    <TextField textarea type="text" value={commentValue} placeholder={intl.formatMessage({ id: 'newsfeed.commentPlaceholder' })} image={SendImage} buttonHandler={onCommentSubmit} onChange={onChange} />
                                                </div>
                                            )
                                            : null
                                    }
                                    {
                                        comments.length > 3
                                            ? (
                                                <Expandable containerClasses="__newsfeed-detail-expandable-container" collapseClasses="__newsfeed-detail-expandable-collapse" headerClasses="__newsfeed-detail-expandable" title={intl.formatMessage({ id: 'newsfeed.seeComments' })} unmountOnExit>
                                                    {this.getCommentList()}
                                                </Expandable>
                                            )
                                            : this.getCommentList()
                                    }
                                </div>
                            )
                            : null
                    }
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    schools: store.setting.schools,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(NewsfeedCard));
