import { SwipeableDrawer } from '@mui/material/';
import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import FilterImage from '../../resources/img/products/filter.svg';
import { Param } from '../../types/products';
import ProductsFilters from './productsFilters';

interface Props {
    params: Param;
    handleChangeLocation: (e: any) => void;
    updatePath: (params: Param) => void;
    minDate: Moment;
}

interface State {
    open: boolean;
}

export default class ProductsFiltersSmartphone extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState((state) => ({ open: !state.open }));
    }

    render() {
        const {
            handleChangeLocation, params, updatePath, minDate,
        } = this.props;
        const { open } = this.state;
        return (
            <div className="__products-filter-sp-parent" style={{ zIndex: 200 }}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */}
                <div className="__products-filter-sp-filter-button" onClick={this.toggleOpen}>
                    <ReactSVG className="__products-filter-sp-svg" src={FilterImage} />
                    <p className="__products-filter-sp-filter"><FormattedMessage id="products.filter" /></p>
                </div>
                <SwipeableDrawer
                    anchor="right"
                    disableSwipeToOpen
                    open={open}
                    onOpen={this.toggleOpen}
                    onClose={this.toggleOpen}
                    PaperProps={{
                        style: { maxWidth: '90%', minWidth: '80%' },
                    }}
                >
                    <div className="__products-filter-sp-drawer">
                        <ProductsFilters
                            handleChangeLocation={handleChangeLocation}
                            toggleOpen={this.toggleOpen}
                            params={params}
                            updatePath={updatePath}
                            minDate={minDate}
                        />
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}
