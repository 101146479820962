import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeErrorCatched } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Snackbar from './snackbar';
import Title from './title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State { }

class ErrorSnackbar extends React.Component<Props, State> {
    onClose = () => {
        const { runChangeErrorCatched } = this.props;
        runChangeErrorCatched(false, '');
        // dispatch(changeErrorCatched(false, null)); KROK Changed null to empty string
    };

    render() {
        const { errorCatched, intl, errorMessage } = this.props;
        return (
            <Snackbar
                open={errorCatched}
                variant="error"
                timer={5000}
                onClose={this.onClose}
                message={(
                    <div>
                        <Title className="__error-snackbar-title">{intl.formatMessage({ id: 'error.title' })}</Title>
                        <p className="__error-snackbar-text">
                            {intl.formatMessage({ id: errorMessage || 'error.text' })}
                        </p>
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    errorCatched: store.user.errorCatched,
    errorMessage: store.user.errorMessage,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    // runSetImagesForLessons: (image: string[]) => dispatch(setImagesForLessons(image)),
    // runChangeMyAccountCurrentTab: (currentTab: string) => dispatch(changeMyAccountCurrentTab(currentTab)),
    // runOpenSignUpForm: () => dispatch(openSignUpForm()),
    runChangeErrorCatched: (bool: boolean, message: string) => dispatch(changeErrorCatched(bool, message)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(withRouter(connector(ErrorSnackbar)));
