import React from 'react';
import '../../resources/css/fields.css';

interface IProps {
    regExp?: string;
    defaultValue?: any;
    forceValue?: boolean;
    value?: any;
    asText?: boolean;
    min?: number;
    max?: number;
    className?: string;
    containerClasses?: string;
    placeholder?: string;
    error?: string;
    right?: boolean;
    disabled?: boolean;
    required?: boolean;
    updateValue?: (value: any) => void;
    onClick?: () => void;
    onFocus?: () => void;
    onChange?: (e: any) => void;
}

type Props = IProps;

interface State {
    value?: any;
}

export default class Numberfield extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     regExp: '',
    //     defaultValue: undefined,
    //     forceValue: false,
    //     value: undefined,
    //     asText: false,
    //     min: 0,
    //     max: 100,
    //     className: '',
    //     containerClasses: '',
    //     placeholder: '',
    //     error: '',
    //     right: true,
    //     disabled: false,
    //     required: false,
    //     updateValue: undefined,
    //     onClick: undefined,
    //     onFocus: undefined,
    //     onChange: undefined,
    // };

    regexp: RegExp;

    regExp?: RegExp;

    constructor(props: Props) {
        super(props);

        const {
            regExp, defaultValue, forceValue, value,
        } = this.props;

        this.regexp = /^[0-9]+$/;
        this.regExp = regExp ? new RegExp(regExp) : undefined;

        this.state = {
            value: defaultValue || (forceValue ? value : undefined),
        };
    }

    onChange = (event: any) => {
        const { value } = event.target;
        const {
            asText, min, max, updateValue, value: valueProps,
        } = this.props;

        if (asText) {
            if (value !== '' && !this.regexp.test(value)) return;
            if (value !== '' && ((min && value < min) || (max && value > max))) return;
        } else if (((min && value < min) || (max && value > max)
            || (this.regExp && !this.regExp.test(value)))) return;

        this.setState({ value: event.target.value }, () => {
            if (updateValue) updateValue(valueProps);
        });
    };

    render() {
        const {
            className,
            containerClasses,
            value,
            onChange,
            placeholder,
            min,
            max,
            onClick,
            onFocus,
            error,
            defaultValue,
            right,
            asText,
            forceValue,
            regExp,
            disabled,
            ...props
        } = this.props;
        const { value: valueState } = this.state;
        return (
            <div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */}
                <div className={`__textfield-parent ${containerClasses || ''}`} onClick={onClick} onFocus={onFocus}>
                    <div className={`__textfield-content ${error ? '__textfield-content-error' : ''} ${className || ''}`}>
                        <input
                            disabled={disabled}
                            className="__textfield-input-no-svg __numberfield-input"
                            value={value || (forceValue ? value : valueState)}
                            onChange={(e) => {
                                this.onChange(e);
                                if (onChange) onChange(e);
                            }}
                            type={asText ? 'text' : 'number'}
                            placeholder={placeholder}
                            min={min}
                            max={max}
                            style={right ? { textAlign: 'right' } : undefined}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                        />
                    </div>
                    {
                        error
                            ? <p className="__textfield-error">{error}</p>
                            : null
                    }
                </div>
            </div>
        );
    }
}
