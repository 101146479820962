import {
    ClickAwayListener, Fade, Popper as MaterialPopper, PopperPlacementType
} from '@mui/material';

interface IProps {
    open: boolean;
    anchorEl: any;
    placement?: PopperPlacementType;
    arrowRef?: any;
    onClose(): void;
    containerClasses?: string;
    children: any;
    position?: string;
}

type Props = IProps;

// const defaultProps = {
//     placement: undefined,
//     arrowRef: undefined,
//     containerClasses: '',
//     position: '',
// };

function Popper(props: Props) {
    const {
        open, anchorEl, placement, arrowRef, onClose, containerClasses, children,
    } = props;
    return (
        <MaterialPopper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
            modifiers={[
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                    },
                },
            ]}

        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={onClose}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={`__popper-parent ${containerClasses || ''}`}>
                            {children}
                        </div>
                    </Fade>
                </ClickAwayListener>
            )}
        </MaterialPopper>
    );
}

// Popper.defaultProps = defaultProps;

export default Popper;
