import { FormControl, MenuItem } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VERSIONS } from '../../resources/constants';
import { getCurrentVersion } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { School } from '../../types/settings';
import DatePickerField from '../common/datePickerField';
import SelectField from '../common/selectField';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    selectedSchool?: string | number;
    schools: School[];
    clear: boolean;
    minDate: Moment;
    onChangeSchool: (t: any, b: boolean) => void;
    onChangeDate: (e: any) => void;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    selectedSchool?: any;
}

function shouldDisableDate(day: Moment) {
    return moment().startOf('day').diff(day, 'day') >= 1;
}

class ProductsDate extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     selectedSchool: undefined,
    // };

    constructor(props: Props) {
        super(props);

        const { selectedSchool } = this.props;

        this.state = {
            selectedSchool: selectedSchool || undefined,
        };

        this.handleChangeSchool = this.handleChangeSchool.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const { clear } = this.props;

        if (prevProps.clear !== clear) {
            if (clear) {
                this.handleClear();
            }
        }
    }

    handleClear() {
        const { selectedSchool } = this.props;
        this.setState({ selectedSchool });
    }

    handleChangeSchool(event: any) {
        const { onChangeSchool } = this.props;
        const { selectedSchool } = this.state;
        this.setState({ selectedSchool: event.target.value });
        onChangeSchool(event.target, (event.target.value === 3 || selectedSchool === 3));
    }

    getSchoolSelection = () => {
        const { currentSchool, schools, intl } = this.props;
        if (currentSchool) {
            const school = schools.find((s: School) => s.external_id === currentSchool.int_id
                && s.department_id === currentSchool.department_id);
            return school ? school.name : '';
        }
        const schoolsItems = schools.filter((s: School) => s.online_purchase).sort((a, b) => (a.name > b.name ? 1 : -1)).map((school) => <MenuItem key={`school_${school.name}_${school.id}`} value={school.id}>{school.name}</MenuItem>);
        schoolsItems.unshift(<MenuItem key="school_default" value="">{intl.formatMessage({ id: 'products.all' })}</MenuItem>);
        return schoolsItems;
    };

    render() {
        const schoolSelection = this.getSchoolSelection();
        const {
            selectedProductsDate, onChangeDate, minDate, currentSchool,
        } = this.props;
        const { selectedSchool } = this.state;
        return (
            <div className="__products-date-frame">
                <p className="__products-date-title"><FormattedMessage id="products.date" /></p>
                <div className="__products-date-picker">
                    <DatePickerField
                        value={moment(selectedProductsDate)}
                        onChange={onChangeDate}
                        shouldDisableDate={shouldDisableDate}
                        disablePast
                        minDate={minDate}
                        noKeyboard
                    />
                </div>
                {

                    (getCurrentVersion() !== VERSIONS.BookingSport && getCurrentVersion() !== VERSIONS.BookingFormation)
                    && (
                        <FormControl style={{ width: '100%' }}>
                            <p className="__products-date-title"><FormattedMessage id="products.selectSchool" /></p>
                            <div className="__products-date-select">
                                {
                                    currentSchool
                                        ? <p className="__product-date-selected-school">{schoolSelection}</p>
                                        : (
                                            <SelectField
                                                value={selectedSchool}
                                                onChange={this.handleChangeSchool}
                                            >
                                                {schoolSelection}
                                            </SelectField>
                                        )
                                }
                            </div>
                        </FormControl>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    selectedProductsDate: store.products.selectedProductsDate,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default withRouter(injectIntl(connector(ProductsDate)));
