import { Moment } from 'moment';
import { getCurrentVersion } from '../../resources/utils';
import {
    TYPE_GET_LANDING_LESSON,
    TYPE_GET_LANDING_LESSON_FAILURE,
    TYPE_GET_LANDING_LESSON_SUCCESS,
    TYPE_GET_LANDING_NEWSFEED,
    TYPE_GET_LANDING_NEWSFEED_FAILURE,
    TYPE_GET_LANDING_NEWSFEED_SUCCESS,
    TYPE_GET_LANDING_NEWSFLASH,
    TYPE_GET_LANDING_NEWSFLASH_FAILURE,
    TYPE_GET_LANDING_NEWSFLASH_SUCCESS,
    TYPE_GET_SCHOOL_SITE_CONTENT,
    TYPE_GET_SCHOOL_SITE_CONTENT_FAILURE,
    TYPE_GET_SCHOOL_SITE_CONTENT_SUCCESS,
    TYPE_SEARCH_CHANGE_LESSON_TYPE,
    TYPE_SEARCH_CHANGE_SELECTED_SCHOOL,
    TYPE_SEARCH_CHANGE_START_DATE
} from '../reducers/landing';
import { TYPE_CHANGE_ERROR_CATCHED, TYPE_HIDE_LOADER, TYPE_SHOW_LOADER } from '../reducers/user';
import { API_ROUTES, post } from './api';

export const fetchNewsfeed = (companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_LANDING_NEWSFEED,
    });
    dispatch({
        type: TYPE_SHOW_LOADER,
    });

    const url = `${API_ROUTES.getNewsfeed}?page=0&is_client=True&version=${getCurrentVersion()}`;

    dispatch(post(
        url,
        { company_id: companyId },
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_LANDING_NEWSFEED_SUCCESS,
                data: responseJson,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_LANDING_NEWSFEED_FAILURE,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const fetchNewsflash = (companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_LANDING_NEWSFLASH,
    });

    const url = `${API_ROUTES.getNewsfeed}?page=0&is_newsflash=true&version=${getCurrentVersion()}`;

    dispatch(post(
        url,
        { company_id: companyId },
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_LANDING_NEWSFLASH_SUCCESS,
                data: responseJson,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_LANDING_NEWSFLASH_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const fetchSchoolSiteContent = (companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_SCHOOL_SITE_CONTENT,
    });
    dispatch({
        type: TYPE_SHOW_LOADER,
    });

    const url = API_ROUTES.getSchoolSiteContent;

    dispatch(post(
        `${url}?version=${getCurrentVersion()}`,
        { company_id: companyId },
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_SCHOOL_SITE_CONTENT_SUCCESS,
                data: responseJson,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_SCHOOL_SITE_CONTENT_FAILURE,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const fetchLandingLesson = (companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_LANDING_LESSON,
    });
    dispatch({
        type: TYPE_SHOW_LOADER,
    });

    const url = API_ROUTES.getLandingLesson;
    let companyUrl = '';

    if (companyId !== undefined && companyId !== 0) {
        companyUrl = `&company_id=${companyId}`;
    }

    dispatch(post(
        `${url}?version=${getCurrentVersion()}${companyUrl}`,
        { company_id: companyId },
        (responseJson: any) => {
            if (responseJson.data) {
                dispatch({
                    type: TYPE_GET_LANDING_LESSON_SUCCESS,
                    data: responseJson.data,
                });
            } else {
                dispatch({
                    type: TYPE_GET_LANDING_LESSON_FAILURE,
                });
            }
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_LANDING_LESSON_FAILURE,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const changeSearchSelectedSchool = (value: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SEARCH_CHANGE_SELECTED_SCHOOL,
        data: value,
    });
};

export const changeSearchLessonType = (value: string) => (dispatch: any) => {
    dispatch({
        type: TYPE_SEARCH_CHANGE_LESSON_TYPE,
        data: value,
    });
};

export const changeSearchStartDate = (value: Moment) => (dispatch: any) => {
    dispatch({
        type: TYPE_SEARCH_CHANGE_START_DATE,
        data: value,
    });
};
