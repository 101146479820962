class RequestLimiter {
    static count = 0;

    static Incr() {
        RequestLimiter.count++;
    }

    static Reset() {
        RequestLimiter.count = 0;
    }

    static CanSend() {
        return RequestLimiter.count < 10;
    }
}

export default RequestLimiter;
