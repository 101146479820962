import { Paper } from '@mui/material';
import React from 'react';
import '../../resources/css/bottombar.css';
import UserDefault from '../../resources/img/userdefault.png';

interface Props {
    user: {
        image: string;
        first_name: string;
    };
    comment: string;
}

interface State { }

export default class Comment extends React.Component<Props, State> {
    componentDidMount() {

    }

    render() {
        const { user, comment } = this.props;
        return (
            <Paper className="__newsfeed-comment-paper">
                <div className="__newdfeed-comment-parent">
                    <img src={user.image ? user.image : UserDefault} className="__newsfeed-comment-image" alt="user" />
                    <div className="__newdfeed-comment-user-detail-container">
                        <p className="__newdfeed-comment-user-name">{user.first_name ? user.first_name : 'You'}</p>
                        <p className="__newdfeed-comment-text">{comment}</p>
                    </div>
                </div>
            </Paper>
        );
    }
}
