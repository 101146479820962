/* eslint-disable no-nested-ternary */
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { connect, ConnectedProps } from 'react-redux';
import { GOOGLE_STORAGE_API_CGA, PAGES_ADDRESS, VERSIONS } from '../../resources/constants';
import { getCurrentVersion } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import Container from '../common/container';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * Component that render the joomla pages
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    url: string;
    section: string;
    numPages?: number;
    pageNumber: number;
}

class Help extends React.Component<Props, State> {
    helpParent: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            url: '',
            section: '',
            numPages: undefined,
            pageNumber: 1,
        };
        this.helpParent = React.createRef();
    }

    componentDidMount() {
        const top = document.getElementById('top');
        if (top) top.scrollIntoView();
    }

    onPdfLoadSuccess = ({ numPages }: any) => {
        const { numPages: numPagesState, pageNumber } = this.state;
        if (numPages !== numPagesState || pageNumber !== 1) {
            this.setState({ numPages, pageNumber: 1 });
        }
    };

    onLoad = () => {
        const { url } = this.state;
        const iframe: any = document.getElementById('help_iframe');
        iframe.contentWindow.postMessage('help', url);
    };

    loadAddress = () => {
        const { intl } = this.props;
        const { url: urlState } = this.state;
        let url: any = window.location.href.split('/');
        const section = url[url.length - 1];
        url = `${PAGES_ADDRESS[getCurrentVersion()]}${intl.formatMessage({ id: `help.${section}URL` })}`;
        if (urlState !== url) {
            this.setState({ url, section });
            const top = document.getElementById('top');
            if (top) top.scrollIntoView();
        }
    };

    renderDynamicCga = () => {
        const { currentSchool } = this.props;
        const { numPages } = this.state;
        return (
            <Document
                file={{ url: currentSchool?.tnc_path }}
                onLoadSuccess={this.onPdfLoadSuccess}
            >
                {
                    Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={this.helpParent.current?.offsetWidth} />
                    ))
                }
            </Document>
        );
    };

    renderDynamicContact = () => this.renderExternPage();

    renderDynamicGr = () => {
        const { numPages } = this.state;
        return (
            <Document
                file={{ url: `${GOOGLE_STORAGE_API_CGA}Reglement-General-sur-la-Protection-des-Donnees.pdf` }}
                onLoadSuccess={this.onPdfLoadSuccess}
            // options={{ workerSrc: "//unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js" }}
            >
                {
                    Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={this.helpParent.current?.offsetWidth} />
                    ))
                }
            </Document>
        );
    };

    renderExternPage = () => {
        const { contentHeight } = this.props;
        const { url } = this.state;
        return (<iframe id="help_iframe" title="Help iFrame" className="__help-iframe" src={url} onLoad={this.onLoad} style={{ height: contentHeight }} />);
    };

    render() {
        this.loadAddress();
        const { intl, currentSchool } = this.props;
        const { section } = this.state;

        return (
            <Container headerBase={`${intl.formatMessage({ id: 'help.help' })} > ${section.length > 0 ? intl.formatMessage({ id: `help.${section}` }) : ''}`}>
                <div className="__help-parent" ref={this.helpParent}>
                    {
                        currentSchool && getCurrentVersion() === VERSIONS.BookingSport
                            ? section === 'cga' && currentSchool.tnc_path ? this.renderDynamicCga()
                                : section === 'contact' ? this.renderDynamicContact()
                                    : section === 'gr' ? this.renderDynamicGr()
                                        : <p>Not found</p>
                            : this.renderExternPage()
                    }

                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    contentHeight: store.help.contentHeight,
    currentSchool: store.navigation.currentSchool,

    windowWidth: store.windowSize.width,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Help));
