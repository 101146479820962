import { Paper } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import moment, { Moment } from 'moment';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { GOOGLE_STORAGE_API_MEDALS } from '../../resources/constants';
import { Medal } from '../../types/user';

interface IProps {
    medal: Medal;
    handleOpenDetails(medal: Medal): void;
}

type Props = IProps & WrappedComponentProps;

interface State {
    errorImage: boolean;
    date: Moment;
    link: string;
}

class MedalCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { medal } = this.props;

        this.state = {
            errorImage: false,
            date: moment(medal.Date),
            link: medal.Picture.replace('https://www.booking-corner.com/medals/', GOOGLE_STORAGE_API_MEDALS),
        };
    }

    render() {
        const { errorImage, link, date } = this.state;
        const { handleOpenDetails, medal, intl } = this.props;
        return (
            <Paper className="__lessons-card-upcoming" elevation={0} square>
                {
                    errorImage
                        ? (
                            <div
                                className="__lessons-card-image"
                                style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'not-allowed',
                                }}
                            >
                                <CropOriginalIcon />
                            </div>
                        )
                        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-noninteractive-element-interactions */
                        : <img onError={() => this.setState({ errorImage: true })} className="__lessons-card-image" src={link} onClick={() => handleOpenDetails(medal)} alt={medal.Text} title={medal.Text} />

                }
                <p className="__lessons-card-title">{medal.Text}</p>
                <p className="__lessons-card-text">
                    {intl.formatDate(date.toDate(), { year: 'numeric', month: 'short', day: '2-digit' })}
                    ,
                    {' '}
                    {date.format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}
                </p>
            </Paper>
        );
    }
}

export default injectIntl(MedalCard);
