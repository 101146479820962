/* eslint-disable no-nested-ternary */
import { Badge } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import _, { cloneDeep, toInteger } from 'lodash';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React from 'react';
import isEqual from 'react-fast-compare';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { LESSON_FILTERS } from '../../resources/constants';
import '../../resources/css/booking.css';
import {
    domaineBaseHasSubdomainAlready, isNullOrUndefined, removeInlineStyles, replaceHtmlTag
} from '../../resources/utils';
import withIsMobile from '../../resources/withIsMobile';
import axiosapi from '../../store/actions/axiosapi';
import {
    fetchFreeInstructors,
    fetchInputParticipant,
    fetchLesson, fetchLessonDetails, fetchOptions, fetchQuestions,
    fetchSchoolInstructorsConfiguration,
    getSchoolsList,
    initBooking,
    setLesson,
    setParticipantsInfos, setRemarks,
    setSchoolInstructorsConfiguration,
    setSelectedInstructor,
    setSelectedLessonDetail,
    setSelectedOptions
} from '../../store/actions/booking';
import { getBCClient } from '../../store/actions/settings';
import { changeCart, openSignUpForm } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import {
    BodyFreeInstructors,
    BodyLessonDetails,
    BodyOptions,
    BodyParticipant,
    BodyQuestions,
    Instructor,
    IsMobile,
    Lesson,
    LessonCart, LessonDetail, Level
} from '../../types/booking';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Button from '../common/button';
import Container from '../common/container';
import Expandable from '../common/expandable';
import InternalDateCalendar from '../common/internalDateCalendar';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import Title from '../common/title';
import OrderApi from '../order/api/order_api';
import AlertModal from './alertModal';
import BookingHeader from './bookingHeader';
import DetailsBar from './detailsbar';
import FreeInstructors from './freeInstructors';
import GroupLessonDetails from './groupLessonDetails';
import Options from './options';
import Participants from './participants';
import PrivateLessonDetails from './privateLessonDetails';
import Questions from './questions';
import Remarks from './remarks';
import Selection from './selection';
import SelectionDrawer from './selectionDrawer';

type ReduxProps = ConnectedProps<typeof connector>;

interface Props extends WrappedComponentProps, ReduxProps, RouterProps, IsMobile {}

interface State {
    fromDate: Moment;

    minParticipants: number;
    maxParticipants: number;
    nbParticipants: number;

    levelId?: number;

    page?: number;

    detailsBarOpen: boolean;

    participantsOpen: boolean;
    participantsVisible: boolean;

    optionsOpen: boolean;
    optionsVisible: boolean;

    freeInstructorsOpen: boolean;
    freeInstructorsVisible: boolean;

    questionsOpen: boolean;
    questionsVisible: boolean;

    remarksOpen: boolean;
    remarksVisible: boolean;

    openAlert: boolean;
    alertSchool?: any;

    isCheckAvailabilityLoading: boolean;
    openSnackbar: boolean;
    openSuccessAddToCard: boolean;

    minHoursBeforeBooking?: number;
}
class Booking extends React.Component<Props, State> {
    static resetExpandableState = ({
        detailsBarOpen: true,
        participantsOpen: false,
        participantsVisible: false,
        optionsOpen: false,
        optionsVisible: false,
        questionsOpen: false,
        questionsVisible: false,
        remarksOpen: false,
        remarksVisible: false,
        freeInstructorsOpen: false,
        freeInstructorsVisible: false
    });

    static cartToBooking = (gotCart: LessonCart[]) => {
        const cart = gotCart || [];
        return cart.filter((l) => l.instructor !== undefined && l.instructor !== null).map((lesson) => {
            const fromTime = moment(lesson.time.from_time);
            const toTime = moment(lesson.time.to_time.time);

            return {
                ProductId: lesson.external_id,
                ActivityId: lesson.activity_id,
                CategoryId: lesson.level?.external_id,
                From: moment(lesson.date.from_date).hours(fromTime.hours()).minutes(fromTime.minutes()).seconds(fromTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(lesson.date.to_date).hours(toTime.hours()).minutes(toTime.minutes()).seconds(toTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: lesson.meeting_point.id,
                NbDays: lesson.days,
                NbMinutes: toTime.diff(fromTime, 'minutes'),
                NbParticipants: lesson.participants,
                InstructorId: lesson.instructor ? lesson.instructor.Id : undefined,
                InstructorPriority: lesson.instructor ? lesson.instructor.priority : undefined,
                Price: lesson.price + (typeof lesson.totalOptionsPrice === 'string' ? parseFloat(lesson.totalOptionsPrice) : lesson.totalOptionsPrice),
            };
        });
    };

    static renderCalendarDay = (props: PickersDayProps<Moment> & { daysWithLessons?: number[] }) => {
        const {
            daysWithLessons = [], day, outsideCurrentMonth, ...other
        } = props;

        const countLessons = !props.outsideCurrentMonth ? daysWithLessons.filter((e) => e === props.day.date()).length : 0;

        return (
            <Badge
                key={`badge-${props.day.toString()}`}
                overlap="circular"
                badgeContent={countLessons}
                variant="dot"
                color="success"
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    };

    static replaceSubdomain = (urlParam: string, toSubdomain: any) => {
        const values = domaineBaseHasSubdomainAlready();
        const replace = `://${toSubdomain}.`;
        let url = urlParam;
        // Prepend http://
        if (!/^\w*:\/\//.test(url)) {
            url = `http://${url}`;
        }

        // Check if we got a subdomain in url
        const match = url.match(/\.\w*\b/g);
        if (match && match.length > 1 && match.length > (values.max - 1)) {
            return url.replace(/(:\/\/\w+\.)/, replace);
        }

        return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
    };

    static toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
        if (typeof state[key] === 'boolean') {
            if (value !== undefined) {
                return { [key]: value } as Pick<State, T>;
            }

            return { [key]: !state[key] } as Pick<State, T>;
        }
        throw new Error(`Property ${key} is not a boolean`);
    }

    constructor(props: Props) {
        super(props);

        const {
            runFetchLesson, runGetBCClient, history, location, match, bcClient
        } = this.props;

        if (!bcClient && Authenticator.isLoggedIn()) runGetBCClient(match.params.lang);

        const fromDate = history.location.state?.selectedLessonDate && moment(history.location.state.selectedLessonDate).isValid() ? moment(history.location.state.selectedLessonDate) : moment();

        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
            comma: true
        });

        let qDate = typeof query.d === 'string' ? query.d : undefined;
        let qNbParticipants = typeof query.p === 'string' ? query.p : undefined;
        let qLevel = typeof query.l === 'string' ? query.l : undefined;
        const qProductCategory = typeof query.id === 'string' ? query.id : undefined;

        if (qProductCategory) {
            const params = qProductCategory.split('-');
            if (params.length !== 2) {
                this.props.history.push(`/${this.props.language}/products`);
            } else {
                runFetchLesson(params[0], params[1], match.params.lang);
            }
        }

        if (qNbParticipants && Number.isNaN(qNbParticipants)) {
            this.removeQueryParam('p');
            qNbParticipants = undefined;
        }

        if (qLevel && Number.isNaN(qLevel)) {
            this.removeQueryParam('l');
            qLevel = undefined;
        }

        if (qDate && !moment(qDate, 'YYYYMMDD').isValid()) {
            this.removeQueryParam('d');
            qDate = undefined;
        }

        this.state = {
            fromDate: qDate ? moment(qDate, 'YYYYMMDD') : fromDate,
            minParticipants: 1,
            maxParticipants: 10,
            nbParticipants: qNbParticipants ? parseInt(qNbParticipants, 10) : 1,
            levelId: qLevel ? parseInt(qLevel, 10) : undefined,
            page: 0,
            openAlert: false,
            alertSchool: null,
            isCheckAvailabilityLoading: false,
            openSnackbar: false,
            openSuccessAddToCard: false,
            minHoursBeforeBooking: undefined,
            ...Booking.resetExpandableState,
        };
    }

    componentDidMount() {
        document.getElementById('top')?.scrollIntoView();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        const {
            lessonsDetailsFirstDate, currentSchool, history, match, isLessonsDetailsLoading, isLessonLoading, currentLesson
        } = this.props;
        const {
            levelId, page, fromDate, nbParticipants
        } = this.state;

        if (!isLessonsDetailsLoading && !isLessonLoading && !isEqual(currentLesson, prevProps.currentLesson)) {
            this.currentLessonChanged();
        }

        // Load periods
        if (!isLessonsDetailsLoading && !isLessonLoading && (
            levelId !== prevState.levelId
            || page !== prevState.page
            || (currentLesson?.isCalendar ? !fromDate.isSame(prevState.fromDate, 'month') : !fromDate.isSame(prevState.fromDate, 'date'))
            || nbParticipants !== prevState.nbParticipants
        )) {
            this.fetchLessonsDetails();
        }

        // Change current fromDate to first available period
        if (currentLesson?.isCalendar && !isLessonsDetailsLoading && prevProps.isLessonsDetailsLoading !== this.props.isLessonsDetailsLoading) {
            if (!isNullOrUndefined(lessonsDetailsFirstDate) && lessonsDetailsFirstDate.isAfter(this.state.fromDate, 'date')) this.changeDateToFirst(lessonsDetailsFirstDate);
        }

        // if the lesson's school is not the same as the current school go back to products page
        if (currentSchool && currentLesson && currentLesson.school && currentSchool.int_id !== currentLesson.school.external_id) {
            history.push(`/${match.params.lang}/products`);
        }

        if (currentLesson && currentLesson.school && currentLesson.school.id) {
            // const destination = destinations.find(d => d.companyId === currentLesson.school.id);

            const subdomain = window.location.host.split('.')[2] ? window.location.host.split('.')[0] : false;
            let nextSubdomain = currentLesson.school.name.toLowerCase().replace('ess ', '').replace(/ /g, '').replace(/'/g, '')
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '');
            let url = Booking.replaceSubdomain(window.location.href, nextSubdomain);
            if (currentLesson.school.name.toLowerCase().indexOf('diablerets') > 0) {
                (url = Booking.replaceSubdomain(window.location.href, 'diablerets'));
                nextSubdomain = 'diablerets';
            }
            if (currentLesson.school.name.toLowerCase().indexOf('villars-sur-ollon') > 0) {
                (url = Booking.replaceSubdomain(window.location.href, 'villars'));
                nextSubdomain = 'villars';
            }
            if (subdomain === false || subdomain !== nextSubdomain) {
                window.location.href = url;
            }
        }
    }

    componentWillUnmount() {
        this.props.runInitBooking();
    }

    resetSelection = () => {
        this.props.runSetParticipantsInfos([]);
        this.props.runSetSelectedInstructor({});
        this.props.runSetRemarks([]);
        this.props.runSetSelectedOptions([]);
        this.props.runSetSelectedLessonDetail(undefined);
    };

    currentLessonChanged = () => {
        const {
            currentLesson, runFetchSchoolInstructorsConfiguration, runGetSchoolsList
        } = this.props;

        this.resetSelection();

        let partialState:any = { page: 0, ...Booking.resetExpandableState };

        if (currentLesson) {
            let level = currentLesson.levels?.find((l) => parseInt(l.id, 10) === this.state.levelId);
            if (!level) level = currentLesson.levels?.at(0);

            partialState = { ...partialState, ...this.handleChangeLevel(level, false) };

            if (currentLesson.lesson_type === LESSON_FILTERS.PRIVATE_LESSON) {
                runFetchSchoolInstructorsConfiguration(currentLesson.school.external_id, currentLesson.department_id);
                runGetSchoolsList();
            }

            this.translateLevels();
        }

        this.setState({ ...partialState }, () => this.fetchLessonsDetails());
    };

    getInitialPage = () => {
        const { currentLesson } = this.props;
        if (currentLesson && currentLesson.isCalendar) return undefined;

        return 0;
    };

    onCartAdded = () => {
        this.resetSelection();

        this.setState({
            openSuccessAddToCard: true,
            page: this.getInitialPage(),
            ...Booking.resetExpandableState
        });

        this.props.runChangeCart();
    };

    changeDateToFirst = (lessonsDetailsFirstDate?: Moment) => {
        if (!lessonsDetailsFirstDate) return;
        const shortFromDate = lessonsDetailsFirstDate.format('YYYYMMDD');
        this.addQueryParam('d', shortFromDate);
        this.setState({ fromDate: lessonsDetailsFirstDate.clone() });
    };

    handleMonthChange = (dateFrom: Moment | null) => {
        const newFromDate = (dateFrom ?? moment());
        if (this.state.fromDate && newFromDate.isSame(this.state.fromDate, 'month')) return;

        this.handleDateFrom(dateFrom, true);
    };

    handleYearChange = (dateFrom: Moment | null) => {
        const newFromDate = (dateFrom ?? moment());
        if (this.state.fromDate && newFromDate.isSame(this.state.fromDate, 'year')) return;

        this.handleDateFrom(dateFrom, true);
    };

    handleDateFrom = (fromDate: Moment | null, newPage = false, saveState = true) => {
        if (!fromDate || (fromDate.isSame(this.state.fromDate, 'date'))) return undefined;

        this.addQueryParam('d', fromDate.format('YYYYMMDD'));

        const partialState = {
            fromDate: fromDate.clone(),
            page: newPage ? 0 : this.state.page,
            ...Booking.resetExpandableState
        };

        this.resetSelection();

        if (saveState) this.setState({ ...partialState });
        return partialState;
    };

    handleParticipant = (nbParticipants: number, saveState = true) => {
        this.addQueryParam('p', nbParticipants);

        const partialState = {
            nbParticipants,
            page: 0,
            ...Booking.resetExpandableState
        };

        this.resetSelection();

        if (saveState) this.setState({ ...partialState });
        return partialState;
    };

    handleChangeLevel = (level: Level | undefined, saveState = true) => {
        if (!level) return undefined;

        const newLevelId = level.id;
        this.addQueryParam('l', newLevelId);

        let { nbParticipants } = this.state;
        const minParticipants = level.min_participant >= 1 ? level.min_participant : 1;
        const maxParticipants = this.props.currentLesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON ? 6 : 10;
        if (nbParticipants < minParticipants) nbParticipants = minParticipants;
        if (nbParticipants > maxParticipants) nbParticipants = maxParticipants;

        const partialState = {
            page: 0,
            levelId: newLevelId,
            minParticipants,
            maxParticipants,
            nbParticipants,
            ...Booking.resetExpandableState
        };

        this.resetSelection();

        if (saveState) this.setState({ ...partialState });
        return partialState;
    };

    handleClickMore = () => {
        this.setState((state) => ({ page: state.page !== undefined ? state.page + 1 : 0 }));
    };

    addQueryParam = (key: any, value: any) => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        history.replace({
            search: searchParams.toString(),
        });
    };

    removeQueryParam = (key: any) => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has(key)) {
            searchParams.delete('error');
            history.replace({
                search: searchParams.toString(),
            });
        }
    };

    getNoAvailabilitySnackbarMessage = () => {
        const { intl, currentSchool, currentLesson } = this.props;
        const { minHoursBeforeBooking } = this.state;
        return (
            <div>
                {
                    minHoursBeforeBooking && minHoursBeforeBooking > 0
                        ? (
                            <>
                                <p>{intl.formatMessage({ id: 'booking.minHoursBeforeBooking' }, { minHoursBeforeBooking, school: currentLesson && currentLesson.school.name })}</p>
                                {
                                    currentSchool && currentSchool.email && currentSchool.email.length > 0
                                        ? (
                                            <p style={{ marginLeft: '10px' }}>
                                                {' '}
                                                <a title={`Mail ${currentSchool?.name}`} href={`mailto:${currentSchool?.email}`}>{currentSchool?.email}</a>
                                            </p>
                                        )
                                        : null
                                }
                            </>
                        )
                        : <p>{intl.formatMessage({ id: 'booking.noMoreAvailability' }, { school: currentLesson && currentLesson.school.name })}</p>
                }
            </div>
        );
    };

    checkAvailability = async (detailLesson: LessonDetail, fromTime: Moment, toTime: Moment) => {
        this.setState({ isCheckAvailabilityLoading: true });
        const { currentLesson } = this.props;
        const { levelId } = this.state;
        const currentLevel = currentLesson?.levels?.find((lvl) => lvl.id === levelId);
        if (!levelId || !currentLevel || !currentLesson) return false;
        const days = currentLesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? detailLesson.days : detailLesson.days[detailLesson.selectedDayIndex];
        // message d'erreur pour indiquer que la période n'a pas de meeting points
        if (detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex] === undefined) return false;
        const payload = {
            bookings: [
                {
                    From: moment(detailLesson.startDate).hours(fromTime.hours()).minutes(fromTime.minutes()).seconds(fromTime.seconds())
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    To: moment(detailLesson.endDate).hours(toTime.hours()).minutes(toTime.minutes()).seconds(toTime.seconds())
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    lessonId: currentLesson.id,
                    ProductId: currentLesson.external_id,
                    ActivityId: currentLesson.external_activity_id,
                    CategoryId: currentLevel?.external_id,
                    meetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
                    nbDays: days,
                    nbParticipants: detailLesson.participants,
                    nbMinutes: toTime.diff(fromTime, 'minutes'),
                    participants: [],
                },
            ],
        };

        const response = await OrderApi.checkAvailability(payload).catch((error) => {
            throw error;
        });

        return response;
    };

    checkFreeInstructors = async (detailLesson: LessonDetail, start: Moment, end: Moment, cart: any) => {
        const { currentLesson } = this.props;
        const { levelId } = this.state;
        const currentLevel = currentLesson?.levels?.find((lvl) => lvl.id === levelId);
        if (!levelId || !currentLevel || !currentLesson) return false;

        const body = {
            lessonId: currentLesson ? currentLesson.id : undefined,
            LanguageId: '',
            CheckLanguage: false,
            Bookings: cart,
            Booking: {
                ProductId: currentLesson ? currentLesson.external_id : undefined,
                ActivityId: currentLesson ? currentLesson.external_activity_id : undefined,
                CategoryId: currentLevel?.external_id,
                From: moment(detailLesson.startDate).hours(start.hours()).minutes(start.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(detailLesson.endDate).hours(end.hours()).minutes(end.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
            },
        };

        const response = await axiosapi.post('/booking-corner/check-free-instructors/', body);

        return response.data.length !== 0;
    };

    translateLevels = () => {
        const { match } = this.props;
        let currentLesson = cloneDeep(this.props.currentLesson);
        if (!currentLesson) return;

        const headers = {
            headers: {
                'Accept-Language': match.params.lang,
            },
        };

        axiosapi.post('/booking-corner/product/', { ProductId: currentLesson.external_id, ActivityId: currentLesson.external_activity_id, From: currentLesson.fromDate }, headers)
            .then((response) => {
                const categories = response.data.Categories;
                if (currentLesson && categories && categories.length > 0) {
                    currentLesson = {
                        ...currentLesson,
                        levels: currentLesson?.levels?.map((lvl) => {
                            const lvlTranslation = lvl ? categories.find((c:any) => c.Id === lvl.external_id) : undefined;
                            return {
                                ...lvl,
                                memo: lvlTranslation && lvlTranslation.Memo ? replaceHtmlTag(removeInlineStyles(lvlTranslation.Memo), 'em', 'span') : lvl.memo,
                                name: lvlTranslation && lvlTranslation.Description ? lvlTranslation.Description : lvl.name,

                            };
                        })
                    };
                    this.props.runSetLesson(currentLesson);
                }
            })
            .catch((error) => {
                console.log(`Missing translations: ${error}`);
            });
    };

    handleClickSelectDetailLesson = async (detailLessonParam: any) => {
        const {
            currentLesson, schoolInstructorsConfiguration, runOpenSignUpForm, language,
            runSetParticipantsInfos,
            runSetRemarks,
            runSetSelectedOptions,
            runSetSelectedLessonDetail,
            runFetchInputParticipant,
            runFetchOptions,
            runFetchQuestions,
            runFetchFreeInstructors,
            runSetSelectedInstructor,
        } = this.props;
        const { nbParticipants, levelId } = this.state;
        const currentLevel = currentLesson?.levels?.find((lvl) => lvl.id === levelId);
        if (!levelId || !currentLevel) return;
        const detailLesson = cloneDeep(detailLessonParam);
        this.setState({ openSnackbar: false, minHoursBeforeBooking: undefined, openSuccessAddToCard: false });
        let stop = false;
        const jsonCart = localStorage?.getItem('cart');

        const cart: LessonCart[] = JSON.parse(jsonCart || '[]');
        const freeInstructorCart = Booking.cartToBooking(cart);

        // check that the cart contains only lesson from same school (or is empty)
        if (currentLesson == null) {
            stop = true;
        } else if (cart && cart.length > 0) {
            const school = cart[0].school_id.id;
            if (school !== currentLesson.school.id) {
                stop = true;
                this.setState({
                    openAlert: true,
                    alertSchool: cart[0].school_id.name,
                });
            }
        }

        if (stop || currentLesson == null) return;

        // get start and end date
        const start = moment(detailLesson.times[detailLesson.selectedStartTimeIndex].startTime);
        const end = moment(detailLesson.times[detailLesson.selectedStartTimeIndex].endTimes[detailLesson.selectedEndTimeIndex].time);
        detailLesson.nbMinutes = end.diff(start, 'minutes');

        // check availability for the choosen lesson
        const school = currentLesson.school.external_id;
        let successData: any;
        let success = true;
        try {
            successData = await this.checkAvailability(detailLesson, start, end);
        } catch (error) {
            this.setState({ isCheckAvailabilityLoading: false, openSnackbar: true, minHoursBeforeBooking: undefined });
            return;
        }
        success = successData.data.unavailableLessonsId.length === 0;

        if (!success) {
            // open modal
            let minHoursBeforeBooking;
            if (successData.data.messages.length > 0 && successData.data.messages[0].includes('MinHoursBeforeBooking')) {
                let minHoursBeforeBookingMessage = successData.data.messages[0];
                minHoursBeforeBookingMessage = minHoursBeforeBookingMessage.split(' : ');
                if (minHoursBeforeBookingMessage.length === 2) {
                    minHoursBeforeBooking = toInteger(minHoursBeforeBookingMessage[1]);
                }
            }
            this.setState({ isCheckAvailabilityLoading: false, openSnackbar: true, minHoursBeforeBooking });
            return;
        }
        this.setState({ isCheckAvailabilityLoading: false });

        // Check it was free instructors before load lesson details for availability
        if (currentLesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON
            && (Number(school) === 2 || Number(school) === 6 || Number(school) === 8 || Number(school) === 5 || schoolInstructorsConfiguration?.mustSelectInstructor)) {
            if (success) await this.checkFreeInstructors(detailLesson, start, end, freeInstructorCart);
        }

        // Temporary moved to componentDidUpdate
        if (!Authenticator.isLoggedIn()) {
            runOpenSignUpForm();
            return;
        }

        // continue

        detailLesson.level = currentLevel;
        const bodyParticipant: BodyParticipant = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
            fromDate: detailLesson.startDate,
        };

        let days = 0;

        if (currentLesson?.lesson_type === LESSON_FILTERS.GROUP_LESSON) days = detailLesson.days;
        else days = detailLesson.days[detailLesson.selectedDayIndex];

        let startTimeWorkaround;
        const detailLessonStartTime = detailLesson.times[detailLesson.selectedStartTimeIndex].startTime[0];

        // workaround startTime
        if (typeof detailLesson.times[detailLesson.selectedStartTimeIndex].startTime === 'string'
            || detailLesson.times[detailLesson.selectedStartTimeIndex].startTime instanceof String) {
            startTimeWorkaround = detailLesson.times[detailLesson.selectedStartTimeIndex].startTime;
        } else {
            startTimeWorkaround = detailLessonStartTime;
        }

        const bodyOptions: BodyOptions = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
            fromDate: detailLesson.startDate,
            toDate: detailLesson.endDate,
            startTime: startTimeWorkaround,
            endTime: detailLesson.times[detailLesson.selectedStartTimeIndex].endTimes[detailLesson.selectedEndTimeIndex].time,
            nbDays: days,
            nbParticipants,
        };

        const bodyQuestions: BodyQuestions = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
        };

        const bodyFreeInstructors: BodyFreeInstructors = {
            lessonId: currentLesson.id,
            LanguageId: '',
            CheckLanguage: true,
            Bookings: freeInstructorCart,
            Booking: {
                ProductId: currentLesson.external_id,
                ActivityId: currentLesson.external_activity_id,
                CategoryId: currentLevel?.external_id,
                From: moment(detailLesson.startDate).hours(start.hours()).minutes(start.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(detailLesson.endDate).hours(end.hours()).minutes(end.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
            },
        };

        // reset if a lesson has already been choosen
        this.setState({
            participantsOpen: false,
            participantsVisible: false,
            optionsOpen: false,
            optionsVisible: false,
            questionsOpen: false,
            questionsVisible: false,
            remarksOpen: false,
            remarksVisible: false,
            freeInstructorsVisible: false,
            freeInstructorsOpen: false,
            detailsBarOpen: true,
        }, () => {
            this.toggleExpandable('detailsBarOpen', 'participantsOpen');
            this.toggleVisibility('participantsVisible');
        });
        runSetParticipantsInfos([]);

        runSetSelectedInstructor({});
        runSetRemarks([]);
        runSetSelectedOptions([]);

        const detailsbar = document.getElementById('detailsbar');
        if (detailsbar) detailsbar.scrollIntoView();
        runSetSelectedLessonDetail(detailLesson);
        runFetchInputParticipant(bodyParticipant, language);
        runFetchOptions(bodyOptions, language);
        runFetchQuestions(bodyQuestions, language);

        if (currentLesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON && schoolInstructorsConfiguration
            && schoolInstructorsConfiguration.canSelectInstructor) {
            runFetchFreeInstructors(bodyFreeInstructors, language);
        }
    };

    fetchLessonsDetails = () => {
        const { language, currentLesson, runFetchLessonDetails } = this.props;
        const {
            fromDate, nbParticipants, page, levelId
        } = this.state;
        if (currentLesson == null || !fromDate || isNullOrUndefined(levelId)) return;

        const body: BodyLessonDetails = {
            lessonId: currentLesson.id,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            nbParticipants,
            levelId
        };

        const params = { page: currentLesson?.isCalendar ? undefined : page };
        runFetchLessonDetails(body, params, language);
    };

    toggleExpandable = (param: string, optional?: string) => {
        this.setState((prevState) => Booking.toggleStateKey(param as keyof State, prevState));

        if (optional) {
            this.setState(
                (prevState) => Booking.toggleStateKey(
                    optional as keyof State,
                    prevState,
                    true,
                ),
            );
        }
    };

    toggleVisibility = (param: string) => {
        this.setState((prevState) => Booking.toggleStateKey(param as keyof State, prevState, true));
    };

    render() {
        const {
            windowWidth,
            currentLesson,
            lessonsDetails,
            isLessonLoading,
            intl,
            isLessonsDetailsLoading,
            options,
            questions,
            nextPage,
            freeInstructors,
            schoolInstructorsConfiguration,
            isMobile,
            lessonDetail
        } = this.props;
        const {
            isCheckAvailabilityLoading,
            openAlert,
            alertSchool,
            openSnackbar,
            openSuccessAddToCard,
            participantsVisible,
            detailsBarOpen,
            fromDate,
            nbParticipants,
            minParticipants,
            maxParticipants,
            levelId,
            participantsOpen,
            freeInstructorsVisible,
            freeInstructorsOpen,
            optionsVisible,
            optionsOpen,
            questionsOpen,
            questionsVisible,
            remarksVisible,
            remarksOpen
        } = this.state;
        const bigScreen = windowWidth > 1000;

        const daysWithLessons:number[] = [];
        const currentLevel = currentLesson?.levels?.find((lvl) => lvl.id === levelId);

        lessonsDetails.map((l) => daysWithLessons.push(moment(l.startDate).date()));
        daysWithLessons.sort((a, b) => a - b);

        let parsedLessonsDetails = cloneDeep(lessonsDetails);
        if (currentLesson?.isCalendar) {
            parsedLessonsDetails = parsedLessonsDetails.filter((l) => moment(l.startDate).isSame(fromDate, 'date'));
        }

        const lessonsDetailsContent = parsedLessonsDetails.map((ld, idx) => {
            const i = idx;
            if (currentLevel === undefined) return null;
            return (
                <div key={`lesson-details-${ld.startDate}-${i}`}>
                    {
                        currentLesson?.lesson_type === LESSON_FILTERS.GROUP_LESSON
                            ? (
                                <GroupLessonDetails
                                    lessonDetail={ld}
                                    currentLevel={currentLevel}
                                    onClickSelect={this.handleClickSelectDetailLesson}
                                    bigScreen={bigScreen}
                                    isCheckAvailabilityLoading={isCheckAvailabilityLoading}
                                />
                            )
                            : (
                                <PrivateLessonDetails
                                    lessonDetail={ld}
                                    currentLevel={currentLevel}
                                    onClickSelect={this.handleClickSelectDetailLesson}
                                    bigScreen={bigScreen}
                                    isCheckAvailabilityLoading={isCheckAvailabilityLoading}
                                />
                            )
                    }
                </div>
            );
        });

        const detailsEmpty = _.isEmpty(lessonDetail);

        return (
            <Container
                headerBase={currentLesson && Object.keys(currentLesson).length > 0 ? intl.formatMessage({ id: `products.${currentLesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? 'group' : 'private'}` }) : intl.formatMessage({ id: 'booking.booking' })}
            >
                <div className="__booking-parent">
                    <AlertModal open={openAlert} school={alertSchool} />
                    <Snackbar
                        variant="warning"
                        open={openSnackbar}
                        onClose={() => this.setState({ openSnackbar: false, minHoursBeforeBooking: undefined })}
                        message={this.getNoAvailabilitySnackbarMessage}
                    />
                    <Snackbar
                        variant="success"
                        open={openSuccessAddToCard}
                        onClose={() => this.setState({ openSuccessAddToCard: false })}
                        message={intl.formatMessage({ id: 'booking.successAddToCart' })}
                        timer={2000}
                    />
                    <div className={(bigScreen && participantsVisible === true) ? '__booking-left-small' : '__booking-left-big'}>
                        {
                            bigScreen || detailsEmpty ? null
                                : <SelectionDrawer detailsEmpty={detailsEmpty} />
                        }
                        <BookingHeader currentLevel={currentLevel} />
                        <div style={{ display: 'flex', flexWrap: windowWidth < 1200 ? 'wrap' : undefined, gap: '10px' }}>

                            {
                                currentLesson?.isCalendar
                                    ? (
                                        <div style={{
                                            height: 'fit-content',
                                            width: windowWidth < 1200 ? '100%' : 'fit-content',
                                            position: isMobile ? undefined : 'sticky',
                                            top: isMobile ? undefined : 'calc(var(--menubar-height) + 8px)',
                                            backgroundColor: 'var(--expandable-header-color)',
                                            borderRadius: 'var(--basic-border-radius)',
                                            zIndex: '5'
                                        }}
                                        >
                                            <InternalDateCalendar
                                                value={moment(fromDate)}
                                                onChange={(e) => this.handleDateFrom(e, false)}
                                                onMonthChange={this.handleMonthChange}
                                                onYearChange={this.handleYearChange}
                                                slots={{
                                                    day: Booking.renderCalendarDay,
                                                }}
                                                slotProps={{
                                                    day: {
                                                        daysWithLessons,
                                                    } as any,
                                                }}
                                                loading={isLessonsDetailsLoading}
                                            />
                                        </div>
                                    )
                                    : null
                            }
                            <div style={{ flexGrow: '1', minWidth: '0', minHeight: '0' }}>
                                {
                                    (isLessonLoading || currentLesson == null)
                                        ? <LoadingCircle />
                                        : (
                                            <div className="__booking-left-content" id="detailsbar">
                                                <DetailsBar
                                                    hideDatePicker={currentLesson.isCalendar}
                                                    title={intl.formatMessage({ id: 'booking.title' })}
                                                    open={detailsBarOpen}
                                                    handleExpandable={() => this.toggleExpandable('detailsBarOpen')}
                                                    fromDate={fromDate}
                                                    onChangeDateFrom={(e) => this.handleDateFrom(e, true)}
                                                    selectedParticipant={nbParticipants}
                                                    onChangeParticipant={this.handleParticipant}
                                                    minParticipants={minParticipants}
                                                    maxParticipants={maxParticipants}
                                                    selectedLevel={levelId}
                                                    onChangeLevel={this.handleChangeLevel}
                                                    lesson={currentLesson}
                                                    disabled={isLessonsDetailsLoading}
                                                >
                                                    {
                                                        currentLesson
                                                            ? (
                                                                <div>
                                                                    {lessonsDetailsContent}
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="__booking-left-no-lesson">
                                                                    <Title>{intl.formatMessage({ id: 'booking.selectLesson' })}</Title>
                                                                </div>
                                                            )
                                                    }
                                                    {
                                                        isLessonsDetailsLoading
                                                            ? <LoadingCircle />
                                                            : !currentLesson.isCalendar && currentLesson && (nextPage && nextPage > 0)
                                                                ? (
                                                                    <div className="__booking-more-lessons">
                                                                        <Button buttonClasses="__booking-more-lessons-button" onClick={this.handleClickMore}>
                                                                            {intl.formatMessage({ id: 'booking.moreLessons' })}
                                                                        </Button>
                                                                    </div>
                                                                )
                                                                : lessonsDetailsContent.length === 0
                                                                    ? (
                                                                        <div className="__booking-left-no-lesson">
                                                                            <Title>{intl.formatMessage({ id: 'booking.noLesson' })}</Title>
                                                                        </div>
                                                                    )
                                                                    : null
                                                    }
                                                </DetailsBar>
                                                {
                                                    participantsVisible
                                                        ? (
                                                            <Expandable title={intl.formatMessage({ id: 'booking.Participants' })} open={participantsOpen} handleExpandable={() => this.toggleExpandable('participantsOpen')}>
                                                                <Participants
                                                                    onChangeIfEmpty={() => {
                                                                        this.setState({
                                                                            optionsOpen: false,
                                                                            optionsVisible: false,
                                                                            questionsOpen: false,
                                                                            questionsVisible: false,
                                                                            remarksOpen: false,
                                                                            remarksVisible: false,
                                                                            freeInstructorsVisible: false,
                                                                            freeInstructorsOpen: false,
                                                                        });
                                                                    }}
                                                                    nbParticipants={nbParticipants}
                                                                    handleExpandable={() => {
                                                                        if (currentLesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON && (freeInstructors.length > 0 || schoolInstructorsConfiguration?.mustSelectInstructor)) {
                                                                            this.toggleVisibility('freeInstructorsVisible');
                                                                            this.toggleExpandable('participantsOpen', 'freeInstructorsOpen');
                                                                        } else if (options.length > 0) {
                                                                            this.toggleVisibility('optionsVisible');
                                                                            this.toggleExpandable('participantsOpen', 'optionsOpen');
                                                                        } else if (questions.length > 0) {
                                                                            this.toggleVisibility('questionsVisible');
                                                                            this.toggleExpandable('participantsOpen', 'questionsOpen');
                                                                        } else {
                                                                            this.toggleVisibility('remarksVisible');
                                                                            this.toggleExpandable('participantsOpen', 'remarksOpen');
                                                                        }
                                                                    }}
                                                                />
                                                            </Expandable>
                                                        )
                                                        : null
                                                }
                                                {
                                                    freeInstructorsVisible
                                                        ? (
                                                            <Expandable title={intl.formatMessage({ id: 'booking.freeInstructorsTitle' })} open={freeInstructorsOpen} handleExpandable={() => this.toggleExpandable('freeInstructorsOpen')}>
                                                                <FreeInstructors
                                                                    handleExpandable={() => {
                                                                        if (options.length > 0) {
                                                                            this.toggleVisibility('optionsVisible');
                                                                            this.toggleExpandable('freeInstructorsOpen', 'optionsOpen');
                                                                        } else if (questions.length > 0) {
                                                                            this.toggleVisibility('questionsVisible');
                                                                            this.toggleExpandable('freeInstructorsOpen', 'questionsOpen');
                                                                        } else {
                                                                            this.toggleVisibility('remarksVisible');
                                                                            this.toggleExpandable('freeInstructorsOpen', 'remarksOpen');
                                                                        }
                                                                    }}
                                                                />
                                                            </Expandable>
                                                        )
                                                        : null
                                                }
                                                {
                                                    optionsVisible
                                                        ? (
                                                            <Expandable title={intl.formatMessage({ id: 'booking.options' })} open={optionsOpen} handleExpandable={() => this.toggleExpandable('optionsOpen')}>
                                                                <Options
                                                                    handleExpandable={() => {
                                                                        if (questions.length > 0) {
                                                                            this.toggleVisibility('questionsVisible');
                                                                            this.toggleExpandable('optionsOpen', 'questionsOpen');
                                                                        } else {
                                                                            this.toggleVisibility('remarksVisible');
                                                                            this.toggleExpandable('optionsOpen', 'remarksOpen');
                                                                        }
                                                                    }}
                                                                />
                                                            </Expandable>
                                                        )
                                                        : null
                                                }
                                                {
                                                    questionsVisible
                                                        ? (
                                                            <Expandable title={intl.formatMessage({ id: 'booking.questions' })} open={questionsOpen} handleExpandable={() => this.toggleExpandable('questionsOpen')}>
                                                                <Questions
                                                                    handleExpandable={() => {
                                                                        this.toggleVisibility('remarksVisible');
                                                                        this.toggleExpandable('questionsOpen', 'remarksOpen');
                                                                    }}
                                                                />
                                                            </Expandable>
                                                        )
                                                        : null
                                                }
                                                {
                                                    remarksVisible
                                                        ? (
                                                            <Expandable title={intl.formatMessage({ id: 'booking.remarks' })} open={remarksOpen} handleExpandable={() => this.toggleExpandable('remarksOpen')}>
                                                                <Remarks onCartAdded={this.onCartAdded} />
                                                            </Expandable>
                                                        )
                                                        : null
                                                }
                                            </div>
                                        )
                                }
                            </div>

                        </div>
                    </div>
                    {
                        bigScreen
                            ? (
                                <div className={participantsVisible ? '__booking-right-small' : '__booking-right-big'}>
                                    {detailsEmpty ? null : <Selection />}
                                </div>
                            )
                            : null
                    }
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentSchool: store.navigation.currentSchool,
    currentLesson: store.booking.currentLesson,
    lessonsDetails: store.booking.lessonsDetails,
    lessonsDetailsFirstDate: store.booking.lessonsDetailsFirstDate,
    nextPage: store.booking.nextPage,
    isLessonsDetailsLoading: store.booking.isLessonsDetailsLoading,
    options: store.booking.options,
    questions: store.booking.questions,
    lessonDetail: store.booking.selectedLessonDetail,
    isLessonLoading: store.booking.isFetchLessonLoading,
    windowWidth: store.windowSize.width,
    selectedProductsDate: store.products.selectedProductsDate, // TODO WHY
    language: store.translation.language,
    bcClient: store.setting.bcClient,
    freeInstructors: store.booking.freeInstructors,
    schoolsList: store.booking.schoolsList, // TODO WHY
    schoolInstructorsConfiguration: store.booking.schoolInstructorsConfiguration,
    destinations: store.navigation.destinations, // TODO WHY
    isSignUpVisible: store.user.isSignUpVisible, // TODO WHY
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runFetchLessonDetails: (body: BodyLessonDetails, params: any, language: string) => dispatch(fetchLessonDetails(body, params, language)),
    runSetSchoolInstructorsConfiguration: (data?: { mustSelectInstructor: boolean; canSelectInstructor: boolean; } | undefined) => dispatch(setSchoolInstructorsConfiguration(data)), // TODO WHY
    runGetSchoolsList: () => dispatch(getSchoolsList()),
    runGetBCClient: (language: string) => dispatch(getBCClient(language)),
    runInitBooking: () => dispatch(initBooking()),
    runFetchLesson: (externalId: string, activityId: string, language: string) => dispatch(fetchLesson(externalId, activityId, language)),
    runSetLesson: (lesson:Lesson) => dispatch(setLesson(lesson)),
    runFetchSchoolInstructorsConfiguration: (externalId: string, department_id: string) => dispatch(fetchSchoolInstructorsConfiguration(externalId, department_id)),
    runOpenSignUpForm: () => dispatch(openSignUpForm()),
    runSetParticipantsInfos: (participantsInfos: any) => dispatch(setParticipantsInfos(participantsInfos)),
    runSetSelectedInstructor: (data: Instructor) => dispatch(setSelectedInstructor(data)),
    runSetRemarks: (remarks: string | any[]) => dispatch(setRemarks(remarks)),
    runSetSelectedOptions: (options: any) => dispatch(setSelectedOptions(options)),
    runSetSelectedLessonDetail: (electedLessonDetail: any) => dispatch(setSelectedLessonDetail(electedLessonDetail)),
    runFetchInputParticipant: (body: BodyParticipant, language: string) => dispatch(fetchInputParticipant(body, language)),
    runFetchOptions: (body: BodyOptions, language: string) => dispatch(fetchOptions(body, language)),
    runFetchQuestions: (body: BodyQuestions, language: string) => dispatch(fetchQuestions(body, language)),
    runFetchFreeInstructors: (body: BodyFreeInstructors, language: string) => dispatch(fetchFreeInstructors(body, language)),
    runChangeCart: () => dispatch(changeCart()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withIsMobile(injectIntl(connector(Booking)));
