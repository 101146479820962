import { SwipeableDrawer } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import selectionImage from '../../resources/img/products/selection.svg';
import Selection from './selection';

interface IProps {
    detailsEmpty: boolean;
}

type Props = IProps & WrappedComponentProps;

interface State {
    open: boolean;
}

class SelectionDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState((state) => ({ open: !state.open }));
    }

    render() {
        const { detailsEmpty, intl } = this.props;
        const { open } = this.state;
        return (
            <div className={detailsEmpty ? undefined : '__booking-sp-parent'}>
                {
                    detailsEmpty ? undefined
                        : (
                            /* eslint-disable-next-line
                            jsx-a11y/no-static-element-interactions,
                            jsx-a11y/click-events-have-key-events */
                            <div className="__booking-sp-button" onClick={this.toggleOpen}>
                                <ReactSVG className="__booking-sp-svg" src={selectionImage} />
                                <p>{intl.formatMessage({ id: 'booking.yourSelection' })}</p>
                            </div>
                        )
                }
                <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={this.toggleOpen}
                    onOpen={this.toggleOpen}
                    disableSwipeToOpen
                    PaperProps={{
                        style: { maxWidth: '90%', backgroundColor: '#fbfbfc' },
                    }}
                >
                    <div className="__booking-right-big">
                        <Selection />
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}
const connector = connect();

export default injectIntl(connector(SelectionDrawer));
