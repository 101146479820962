import { MenuItem } from '@mui/material';
import moment from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { LESSON_FILTERS } from '../../resources/constants';
import { decimalToTime } from '../../resources/utils';
import axiosapi from '../../store/actions/axiosapi';
import { ApplicationState } from '../../store/types';
import {
    EndTime,
    LessonDetail,
    Level,
    MeetingPoint,
    Time
} from '../../types/booking';
import BookingLessonDetails from './bookingLessonDetails';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    lessonDetail: LessonDetail;
    currentLevel: Level;
    bigScreen: boolean;
    isCheckAvailabilityLoading: boolean;
    onClickSelect(state: State): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    days: number;
    startDate: string;
    endDate: string;
    infoDays: string;
    infoBeginDays: string;
    infoEndDays: string;
    infoNumberDaysMin: string;
    infoNumberDaysMax: string;
    participants: string;
    price: number;
    times: any;
    meetingPoints: MeetingPoint[];
    endTimes: EndTime[];
    nbMinutes: number;
    endTimesOption: any;
    meetingPointsOption: any;
    hours: {
        hoursLesson: number;
        totalHoursLesson: number;
    };
    selectedEndTimeIndex: number;
    selectedStartTimeIndex: number;
    selectedMeetingPointIndex: number;
}

class GroupLessonDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { lessonDetail } = this.props;

        let meetingPointIndexTemp;

        if (lessonDetail.meetingPoints.length > 1) {
            meetingPointIndexTemp = -1;
        } else if (lessonDetail.meetingPoints.length === 1) {
            meetingPointIndexTemp = 0;
        } else {
            meetingPointIndexTemp = -2;
        }

        this.state = {
            days: lessonDetail.nb_days,
            startDate: lessonDetail.startDate,
            endDate: lessonDetail.endDate,
            infoDays: lessonDetail.info_days,
            infoBeginDays: lessonDetail.info_begin_days,
            infoEndDays: lessonDetail.info_end_days,
            infoNumberDaysMin: lessonDetail.info_number_days_min,
            infoNumberDaysMax: lessonDetail.info_number_days_max,
            participants: lessonDetail.participants,
            price: lessonDetail.price,
            times: lessonDetail.times,
            meetingPoints: lessonDetail.meetingPoints,
            endTimes: lessonDetail.times[0].endTimes,
            nbMinutes: lessonDetail.nb_minutes,
            endTimesOption: [],
            meetingPointsOption: [],
            hours: {
                hoursLesson: 0,
                totalHoursLesson: 0
            },
            selectedEndTimeIndex: 0,
            selectedStartTimeIndex: 0,
            selectedMeetingPointIndex: meetingPointIndexTemp,
        };

        this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
        this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
        this.handleChangeMeetingPoint = this.handleChangeMeetingPoint.bind(this);
        this.handleClickButtonSelect = this.handleClickButtonSelect.bind(this);
        this.calculatingTotalHoursLesson = this.calculatingTotalHoursLesson.bind(this);
    }

    componentDidMount() {
        this.setState((state) => ({
            endTimesOption: this.formatEndTimesOptions(state.endTimes),
            meetingPointsOption: this.formatMeetingPointsOptions(),
            hours: this.calculatingTotalHoursLesson(),
        }), () => {
            this.getNewPrice();
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { lessonDetail } = this.props;
        if (!isEqual(prevProps.lessonDetail, lessonDetail)) {
            let meetingPointIndexTemp;

            if (lessonDetail.meetingPoints.length > 1) {
                meetingPointIndexTemp = -1;
            } else if (lessonDetail.meetingPoints.length === 1) {
                meetingPointIndexTemp = 0;
            } else {
                meetingPointIndexTemp = -2;
            }

            this.setState({
                days: lessonDetail.nb_days,
                startDate: lessonDetail.startDate,
                endDate: lessonDetail.endDate,
                infoDays: lessonDetail.info_days,
                infoBeginDays: lessonDetail.info_begin_days,
                infoEndDays: lessonDetail.info_end_days,
                infoNumberDaysMin: lessonDetail.info_number_days_min,
                infoNumberDaysMax: lessonDetail.info_number_days_max,
                participants: lessonDetail.participants,
                price: lessonDetail.price,
                times: lessonDetail.times,
                meetingPoints: lessonDetail.meetingPoints,
                endTimes: lessonDetail.times[0].endTimes,
                nbMinutes: lessonDetail.nb_minutes,
                endTimesOption: [],
                meetingPointsOption: [],
                hours: {
                    hoursLesson: 0,
                    totalHoursLesson: 0
                },
                selectedEndTimeIndex: 0,
                selectedStartTimeIndex: 0,
                selectedMeetingPointIndex: meetingPointIndexTemp,
            }, () => {
                this.setState((state) => ({
                    endTimesOption: this.formatEndTimesOptions(state.endTimes),
                    meetingPointsOption: this.formatMeetingPointsOptions(),
                    hours: this.calculatingTotalHoursLesson(),
                }), () => {
                    this.getNewPrice();
                });
            });
        } else if (!isEqual(prevProps.currentLevel, this.props.currentLevel)) {
            this.getNewPrice();
        }
    }

    handleChangeStartTime(event: any) {
        const timeSetIndex = parseInt(event.target.value, 10);

        // set price to -1 to activate spinner
        this.setState((state) => ({
            selectedStartTimeIndex: timeSetIndex,
            selectedEndTimeIndex: 0,
            endTimesOption: this.formatEndTimesOptions(state.times[timeSetIndex].endTimes),
            endTimes: state.times[timeSetIndex].endTimes,
            price: -1,
        }), () => {
            this.getNewPrice();
            this.setState({ hours: this.calculatingTotalHoursLesson() });
        });
    }

    handleChangeEndTime(event: any) {
        // set price to -1 to activate spinner
        this.setState({
            selectedEndTimeIndex: parseInt(event.target.value, 10),
            price: -1,
        }, () => {
            this.getNewPrice();
            this.setState({ hours: this.calculatingTotalHoursLesson() });
        });
    }

    handleChangeMeetingPoint(event: any) {
        this.setState({ selectedMeetingPointIndex: parseInt(event.target.value, 10) });
    }

    handleClickButtonSelect() {
        const { onClickSelect } = this.props;
        onClickSelect(this.state);
    }

    getNewPrice() {
        const { currentLesson, currentLevel } = this.props;
        const {
            times,
            selectedEndTimeIndex,
            selectedStartTimeIndex,
            startDate,
            endDate,
            days,
            participants,
        } = this.state;
        const startTime = moment(times[selectedStartTimeIndex].startTime).format('[0001-01-01T]HH:mm:ss');
        const endTime = moment(times[selectedStartTimeIndex].endTimes[selectedEndTimeIndex].time).format('[0001-01-01T]HH:mm:ss');

        const body = {
            lessonId: currentLesson?.id,
            levelId: currentLevel.id,
            fromDate: startDate,
            toDate: endDate,
            startTime,
            endTime, // TODO: confirm utility of endTime??
            nbDays: days,
            nbParticipants: participants,
        };

        axiosapi.post('/booking-corner/price/', body)
            .then((response) => {
                this.setState({ price: response.data === 'price_undefined' ? -2 : response.data });
            })
            .catch(() => this.setState({ price: -2 }));
    }

    calculatingTotalHoursLesson() {
        const { lessonDetail } = this.props;
        const {
            times: timesState, selectedStartTimeIndex, selectedEndTimeIndex, days, endTimes,
        } = this.state;
        const { startTime } = timesState[selectedStartTimeIndex];
        const endTime = endTimes[selectedEndTimeIndex].time;

        let calculatedHours = 0;

        // try to get split time (lunch break)
        try {
            const periods = lessonDetail.times[selectedStartTimeIndex].endTimes[selectedEndTimeIndex].split.split(' + ');
            for (const period of periods) {
                const times = period.split('-');
                calculatedHours += moment(times[1], 'HH:mm').diff(moment(times[0], 'HH:mm'), 'hours', true);
            }
        } catch (error) {
            calculatedHours = moment(endTime).diff(startTime, 'hours', true);
        }

        const hours = {
            hoursLesson: calculatedHours,
            totalHoursLesson: (calculatedHours * days),
        };

        return hours;
    }

    // TODO : Refactor => unified this tree methods format options
    formatEndTimesOptions(endTimes: EndTime[]) {
        const { intl } = this.props;
        return endTimes.map((time: EndTime, i: number) => {
            const idx = i;
            const endTime = moment(time.time).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`);
            return (<MenuItem key={`end_times_${idx}`} value={idx}>{endTime}</MenuItem>);
        });
    }

    formatMeetingPointsOptions() {
        const { meetingPoints } = this.state;
        const { intl } = this.props;
        const meetingPointsTmp = meetingPoints.map((meeting, i) => {
            const idx = i;
            return (<MenuItem key={`meeting${idx}`} value={idx}>{meeting.name}</MenuItem>);
        });
        if (meetingPointsTmp.length > 1) meetingPointsTmp.unshift(<MenuItem key="meeting-disabled" value={-1} disabled>{intl.formatMessage({ id: 'booking.meetingSmall' })}</MenuItem>);
        return meetingPointsTmp;
    }

    render() {
        const {
            days,
            meetingPointsOption,
            startDate,
            endDate,
            times,
            endTimes,
            selectedEndTimeIndex,
            infoDays,
            infoBeginDays,
            infoEndDays,
            infoNumberDaysMax,
            infoNumberDaysMin,
            hours,
            participants,
            endTimesOption,
            meetingPoints,
            price,
            selectedMeetingPointIndex,
            selectedStartTimeIndex,
        } = this.state;
        const {
            intl, currentLesson, currentLevel, bigScreen, isCheckAvailabilityLoading,
        } = this.props;
        const startDateTmp = moment(startDate).format('DD.MM.YYYY');
        const endDateTmp = moment(endDate).format('DD.MM.YYYY');

        const startTimesOption = times.map((time: Time, i: number) => {
            const idx = i;
            const startTime = moment(time.startTime).format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`);
            return (<MenuItem key={`start_times_${idx}`} value={i}>{startTime}</MenuItem>);
        });

        let durationSplit = null;
        if (endTimes) {
            durationSplit = endTimes[selectedEndTimeIndex].split;
            if (durationSplit) durationSplit = durationSplit.substring(6, 19).replace('+', ' - ');
        }

        return (
            currentLesson
                ? (
                    <BookingLessonDetails
                        lesson={{
                            id: currentLesson.id,
                            type: LESSON_FILTERS.GROUP_LESSON,
                            titleNumber: days + intl.formatMessage({ id: `booking.day${days > 1 ? 's' : ''}` }),
                            startDate: startDateTmp,
                            endDate: endDateTmp,
                            infoDays,
                            infoBeginDays,
                            infoEndDays,
                            infoNumberDaysMin,
                            infoNumberDaysMax,
                            hours: decimalToTime(hours.totalHoursLesson, intl.formatMessage, false),
                            participants,
                            firstParameters: times.map((t: any) => t.startTime),
                            secondParameters: endTimes.map((t) => t.time),
                            level: currentLevel,
                            meetingPoints,
                            firstParameterOptions: startTimesOption,
                            secondParameterOptions: endTimesOption,
                            firstParameterText: intl.formatMessage({ id: 'booking.startTime' }),
                            secondParameterText: intl.formatMessage({ id: 'booking.endTime' }),
                            meetingPointOptions: meetingPointsOption,
                            price,
                            durationSplit,
                        }}
                        selectedFirstParameter={selectedStartTimeIndex}
                        selectedSecondParameter={selectedEndTimeIndex}
                        selectedMeetingPointIndex={selectedMeetingPointIndex}
                        handleChangeFirstParameter={this.handleChangeStartTime}
                        handleChangeSecondParameter={this.handleChangeEndTime}
                        handleChangeMeetingPoint={this.handleChangeMeetingPoint}
                        handleClickButtonSelect={this.handleClickButtonSelect}
                        bigScreen={bigScreen}
                        isCheckAvailabilityLoading={isCheckAvailabilityLoading}
                    />
                )
                : null

        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentLesson: store.booking.currentLesson,
});

const connector = connect(mapStateToProps);

export default injectIntl(connector(GroupLessonDetail));
