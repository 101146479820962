/* eslint-disable react/default-props-match-prop-types */
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import {
    changeSignupVisibleForm,
    closeSignUpForm,
    forgotMyPassword,
    initializeRegisteration,
    loginUser,
    registerUser
} from '../../store/actions/user';
import { SignupForms } from '../../store/reducers/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import Title from '../common/title';
import ForgotPasswordForm from './forgotPasswordForm';
import LoginForm from './loginForm';
import SignUpForm from './signupform';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & WrappedComponentProps & ReduxProps & RouterProps;

interface State { }

class SignUp extends React.Component<Props, State> {
    // static defaultProps = {
    //     intl: undefined,
    //     showSignUp: false,
    //     signupVisibleForm: false,
    //     language: undefined,
    //     onClose: undefined,
    // };

    componentDidMount() {
        const { runInitializeRegisteration } = this.props;
        runInitializeRegisteration();
    }

    onClose = () => {
        const { runCloseSignUpForm } = this.props;
        runCloseSignUpForm();
    };

    handleFormSubmit = (values: any) => {
        const { runRegisterUser, language } = this.props;
        runRegisterUser(
            values.firstName,
            values.lastName,
            values.email,
            values.birthday,
            values.password,
            language,
        );
    };

    handleLoginFormSubmit = (values: any) => {
        const { runLoginUser, language, intl } = this.props;
        runLoginUser(values.email, values.password, language, intl.formatMessage({ id: 'login.error' }));
    };

    handleForgotPasswordFormSubmit = (values: any) => {
        const { runChangeSignupVisibleForm, runForgotMyPassword, language } = this.props;
        runChangeSignupVisibleForm(SignupForms.forgotMessage);
        runForgotMyPassword(values.email, language);
    };

    showLogin = () => {
        const { runChangeSignupVisibleForm } = this.props;
        runChangeSignupVisibleForm(SignupForms.login);
    };

    hideLogin = () => {
        const { runChangeSignupVisibleForm } = this.props;
        runChangeSignupVisibleForm(SignupForms.signup);
    };

    showForgotPassword = () => {
        const { runChangeSignupVisibleForm } = this.props;
        runChangeSignupVisibleForm(SignupForms.forgotPassword);
    };

    closeForgotMessage = () => {
        const { runCloseSignUpForm } = this.props;
        runCloseSignUpForm();
    };

    getTitle = () => {
        const { signupVisibleForm } = this.props;
        switch (signupVisibleForm) {
            case SignupForms.signup:
                return 'login.signup';
            case SignupForms.login:
                return 'login.login';
            default:
                return 'login.forgotPasswordTitle';
        }
    };

    renderForm = () => {
        const { signupVisibleForm } = this.props;
        switch (signupVisibleForm) {
            case SignupForms.signup:
                return <SignUpForm onSubmit={this.handleFormSubmit} />;
            case SignupForms.login:
                return <LoginForm onSubmit={this.handleLoginFormSubmit} />;
            case SignupForms.forgotPassword:
                return <ForgotPasswordForm onSubmit={this.handleForgotPasswordFormSubmit} />;
            case SignupForms.forgotMessage:
                return (
                    <div className="__signup-form">
                        <p className="__signup-forgot-message-text"><FormattedMessage id="login.forgotPasswordMessage" /></p>
                        <Button containerClasses="__signup-forgot-message-button" onClick={this.closeForgotMessage}>OK</Button>
                    </div>
                );
            default:
                return null;
        }
    };

    render() {
        const {
            showSignUp, signupVisibleForm, intl
        } = this.props;

        return (
            <Modal className="__signup-modal" open={Boolean(showSignUp)} onClose={this.onClose}>
                <div className="__signup-parent">
                    <div className="__signup-header">
                        <Title>{intl.formatMessage({ id: this.getTitle() })}</Title>
                        <CloseIcon className="__signup-header-close" onClick={this.onClose} />
                        <div className="divider __signup-divider" />
                    </div>
                    <div className="__signup-content">
                        {
                            this.renderForm()
                        }
                    </div>
                    {
                        signupVisibleForm === SignupForms.forgotMessage ? null
                            : (
                                <div className="__signup-footer">
                                    <div className="divider __signup-divider-bottom" />
                                    {signupVisibleForm === SignupForms.login
                                        ? (
                                            <div className="__signup-footer-content">
                                                <p onClick={this.showForgotPassword} className="__signup-login-forgot"><FormattedMessage id="login.forgotPassword" /></p>
                                                <span className="__signup-login-text">
                                                    <FormattedMessage id="login.dontHaveAccount" />
                                                    <p onClick={this.hideLogin.bind(this)} className="__signup-login-button">
                                                        <FormattedMessage id="login.signup" />
                                                    </p>
                                                </span>
                                            </div>
                                        )
                                        : (
                                            <span className="__signup-login-text">
                                                <FormattedMessage id="login.alreadyHaveAccount" />
                                                <p onClick={this.showLogin.bind(this)} className="__signup-login-button"><FormattedMessage id="login.login" /></p>
                                            </span>
                                        )}
                                </div>
                            )
                    }
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    showSignUp: store.user.isSignUpVisible,
    signupVisibleForm: store.user.signupVisibleForm,
    language: store.translation.language,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runCloseSignUpForm: () => dispatch(closeSignUpForm()),
    runInitializeRegisteration: () => dispatch(initializeRegisteration()),
    runRegisterUser: (firstName: string, lastName: string, email: string, dob?: moment.Moment | undefined, password?: string | undefined, language?: string | undefined) => dispatch(registerUser(firstName, lastName, email, dob, password, language)),
    runLoginUser: (email: string, password: string, language?: string, defaultErrorMessage?: string) => dispatch(loginUser(email, password, language, defaultErrorMessage)),
    runChangeSignupVisibleForm: (current: any) => dispatch(changeSignupVisibleForm(current)),
    runForgotMyPassword: (email: string, language: string) => dispatch(forgotMyPassword(email, language)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(injectIntl(connector(SignUp)));
