import qs from 'qs';
import axiosapi from './axiosapi';

import {
    TYPE_GET_ALL_PRODUCTS,
    TYPE_GET_ALL_PRODUCTS_FAILURE,
    TYPE_GET_ALL_PRODUCTS_SUCCESS,
    TYPE_GET_CODE_DISCOUNT_FAILURE,
    TYPE_GET_CODE_DISCOUNT_SUCCESS,
    TYPE_GET_MORE_PRODUCTS,
    TYPE_GET_MORE_PRODUCTS_FAILURE,
    TYPE_GET_MORE_PRODUCTS_SUCCESS,
    TYPE_GET_SCHOOLS,
    TYPE_GET_SCHOOLS_FAILURE,
    TYPE_GET_SCHOOLS_SUCCESS,
    TYPE_INIT_PRODUCTS,
    TYPE_RESET_NO_MORE_PRODUCTS,
    TYPE_SET_SELECTED_PRODUCTS_DATE
} from '../reducers/products';

import { getCurrentVersion } from '../../resources/utils';
import { Param } from '../../types/products';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';

export const initProducts = () => (dispatch: any) => {
    dispatch({
        type: TYPE_INIT_PRODUCTS,
    });
};

export const fetchAllProducts = (got_params: Param, language: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_ALL_PRODUCTS,
    });

    const params = JSON.parse(JSON.stringify(got_params));
    params.offset = 0;
    params.school__version = getCurrentVersion();

    // if the school is a department use department id instead of school id
    if (params.department_id) params.school_id = undefined;
    // fetch lessons
    axiosapi.get('/lessons/', {
        headers: {
            'Accept-Language': language,
        },
        params,
        paramsSerializer: (paramS) => qs.stringify(paramS, { arrayFormat: 'comma', encode: false, skipNulls: true }),
    })
        .then((response) => {
            dispatch({
                type: TYPE_GET_ALL_PRODUCTS_SUCCESS,
                products: response.data.results,
            });
        })
        .catch((error) => {
            dispatch({
                type: TYPE_GET_ALL_PRODUCTS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchMoreProducts = (xParams: Param, offset: any, language: any) => (dispatch: any) => {
    let params = xParams;
    dispatch({
        type: TYPE_GET_MORE_PRODUCTS,
    });

    params.offset = offset;
    params = JSON.parse(JSON.stringify(params));
    params.school__version = getCurrentVersion();

    // if the school is a department use department id instead of school id
    if (params.department_id) params.school_id = undefined;

    axiosapi.get('/lessons/', {
        headers: {
            'Accept-Language': language,
        },
        params,
        paramsSerializer: (Sparams) => qs.stringify(Sparams, { arrayFormat: 'comma', encode: false }),
    })
        .then((response) => {
            dispatch({
                type: TYPE_GET_MORE_PRODUCTS_SUCCESS,
                products: response.data.results,
            });
        })
        .catch((error) => {
            dispatch({
                type: TYPE_GET_MORE_PRODUCTS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchCransMontanaProducts = (xParams: Param, language: any) => (dispatch: any) => {
    const params = xParams;
    dispatch({
        type: TYPE_GET_ALL_PRODUCTS,
    });

    params.offset = 0;

    axiosapi.get('/lessons-crans/', {
        headers: {
            'Accept-Language': language,
        },
        params,
        paramsSerializer: (Sparams) => qs.stringify(Sparams, { arrayFormat: 'comma', encode: false }),
    })
        .then((response) => {
            dispatch({
                type: TYPE_GET_ALL_PRODUCTS_SUCCESS,
                products: response.data.results,
            });
        })
        .catch((error) => {
            dispatch({
                type: TYPE_GET_ALL_PRODUCTS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchMoreCransMontanaProducts = (
    xParams: any,
    offset: any,
    language: any,
) => (dispatch: any) => {
    const params = xParams;
    dispatch({
        type: TYPE_GET_MORE_PRODUCTS,
    });

    params.offset = offset;

    axiosapi.get('/lessons-crans/', {
        headers: {
            'Accept-Language': language,
        },
        params,
        paramsSerializer: (Sparams) => qs.stringify(Sparams, { arrayFormat: 'comma', encode: false }),
    })
        .then((response) => {
            dispatch({
                type: TYPE_GET_MORE_PRODUCTS_SUCCESS,
                products: response.data.results,
            });
        })
        .catch((error) => {
            dispatch({
                type: TYPE_GET_MORE_PRODUCTS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchSchools = (params?: Param) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_SCHOOLS,
    });

    axiosapi.get(`/schools/?version=${getCurrentVersion()}`, {
        params,
        paramsSerializer: (Sparams) => qs.stringify(Sparams, { arrayFormat: 'comma', encode: false }),
    })
        .then((response) => {
            if (response !== undefined) {
                dispatch({
                    type: TYPE_GET_SCHOOLS_SUCCESS,
                    schools: response.data.results,
                });
            } else {
                dispatch({
                    type: TYPE_GET_SCHOOLS_FAILURE,
                });
                dispatch({
                    type: TYPE_CHANGE_ERROR_CATCHED,
                    data: true,
                });
            }
        })
        .catch((error) => {
            // dispatch({
            //     type: TYPE_GET_SCHOOLS_FAILURE
            // });
            // dispatch({
            //     type: TYPE_CHANGE_ERROR_CATCHED,
            //     data: true,
            // });
            // console.error(error);
        });
};

export const testCodeDiscount = (params: Param) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_SCHOOLS,
    });

    axiosapi.get('/code_discount/', {
        params,
        paramsSerializer: (Sparams) => qs.stringify(Sparams, { arrayFormat: 'comma', encode: false }),
    })
        .then((response) => {
            dispatch({
                type: TYPE_GET_CODE_DISCOUNT_SUCCESS,
                codeDiscount: response.data.results[0],
            });
        })
        .catch((error) => {
            dispatch({
                type: TYPE_GET_CODE_DISCOUNT_FAILURE,
            });
        });
};

export const setSelectedProductsDate = (selectedProductsDate: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SELECTED_PRODUCTS_DATE,
        selectedProductsDate,
    });
};

export const resetHasNoMoreProducts = () => (dispatch: any) => dispatch(
    { type: TYPE_RESET_NO_MORE_PRODUCTS },
);
