import moment from 'moment';
import {
    TYPE_FETCH_FREE_INSTRUCTORS,
    TYPE_FETCH_FREE_INSTRUCTORS_FAILED,
    TYPE_FETCH_FREE_INSTRUCTORS_SUCCESS,
    TYPE_FETCH_LESSON,
    TYPE_FETCH_LESSON_FAILURE,
    TYPE_FETCH_LESSON_SUCCESS,
    TYPE_GET_INPUT_PARTICIPANT,
    TYPE_GET_INPUT_PARTICIPANT_FAILURE,
    TYPE_GET_INPUT_PARTICIPANT_SUCCESS,
    TYPE_GET_LESSONS_DETAILS,
    TYPE_GET_LESSONS_DETAILS_FAILURE,
    TYPE_GET_LESSONS_DETAILS_SUCCESS,
    TYPE_GET_OPTIONS,
    TYPE_GET_OPTIONS_FAILURE,
    TYPE_GET_OPTIONS_SUCCESS,
    TYPE_GET_QUESTIONS,
    TYPE_GET_QUESTIONS_FAILURE,
    TYPE_GET_QUESTIONS_SUCCESS,
    TYPE_INIT_BOOKING,
    TYPE_SET_CUSTOMER_LOYALTY,
    TYPE_SET_LESSON,
    TYPE_SET_PARTICIPANTS_INFOS,
    TYPE_SET_REMARKS,
    TYPE_SET_SCHOOLS_LIST,
    TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION,
    TYPE_SET_SELECTED_INSTRUCTOR,
    TYPE_SET_SELECTED_LESSON_DETAIL,
    TYPE_SET_SELECTED_OPTIONS,
    TYPE_SET_SELECTED_QUESTIONS
} from '../reducers/booking';

import {
    BodyFreeInstructors,
    BodyLessonDetails,
    BodyOptions, BodyParticipant, BodyQuestions, Instructor, Lesson, ParticipantInfo
} from '../../types/booking';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';

import { API_ROUTES, get, post } from './api';
import axiosapi from './axiosapi';

export const initBooking = () => (dispatch: any) => {
    dispatch({
        type: TYPE_INIT_BOOKING,
    });
};

export const setLesson = (currentLesson: Lesson) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_LESSON,
        currentLesson,
    });
};

export const setSelectedOptions = (options: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SELECTED_OPTIONS,
        selectedOptions: options,
    });
};

export const setSelectedQuestions = (questions: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SELECTED_QUESTIONS,
        selectedQuestions: questions,
    });
};

export const setSelectedLessonDetail = (selectedLessonDetail: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SELECTED_LESSON_DETAIL,
        selectedLessonDetail,
    });
};

export const setParticipantsInfos = (participantsInfos: ParticipantInfo[]) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_PARTICIPANTS_INFOS,
        participantsInfos,
    });
};

export const setRemarks = (remarks: string | any[]) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_REMARKS,
        remarks,
    });
};

export const fetchLesson = (
    externalId: string,
    activityId: string,
    language: string,
) => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_LESSON,
    });

    const headers = {
        headers: {
            'Accept-Language': language,
        },
    };

    axiosapi.get(`/lessons/?external_id=${externalId}&external_activity_id=${activityId}`, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_FETCH_LESSON_SUCCESS,
                lesson: response.data.results[0],
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_FETCH_LESSON_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchLessonDetails = (
    body: BodyLessonDetails,
    params: any,
    language: string,
) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_LESSONS_DETAILS,
    });

    const headers = {
        headers: { 'Accept-Language': language },
        params,
    };

    axiosapi.post('/booking-corner/lesson_details/', body, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_GET_LESSONS_DETAILS_SUCCESS,
                lessonsDetails: response.data.results,
                lessonsDetailsFirstDate: response.data.firstLessonDate ? moment.utc(response.data.firstLessonDate) : undefined,
                nextPage: response.data.nextPage,
                add: params.page > 0
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_GET_LESSONS_DETAILS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchInputParticipant = (
    body: BodyParticipant,
    language: string,
) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_INPUT_PARTICIPANT,
    });

    const headers = {
        headers: { 'Accept-Language': language },
    };

    axiosapi.post('/booking-corner/input_participant/', body, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_GET_INPUT_PARTICIPANT_SUCCESS,
                inputParticipant: response.data,
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_GET_INPUT_PARTICIPANT_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchOptions = (body: BodyOptions, language: string) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_OPTIONS,
    });

    const headers = {
        headers: { 'Accept-Language': language },
    };

    axiosapi.post('/booking-corner/options/', body, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_GET_OPTIONS_SUCCESS,
                options: response.data,
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_GET_OPTIONS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchQuestions = (body: BodyQuestions, language: string) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_QUESTIONS,
    });

    const headers = {
        headers: { 'Accept-Language': language },
    };

    axiosapi.post('/booking-corner/questions/', body, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_GET_QUESTIONS_SUCCESS,
                questions: response.data,
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_GET_QUESTIONS_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchFreeInstructors = (
    body: BodyFreeInstructors,
    language: string,
) => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_FREE_INSTRUCTORS,
    });

    const headers = {
        headers: { 'Accept-Language': language },
    };

    axiosapi.post('/booking-corner/free-instructors/', body, headers)
        .then((response: any) => {
            dispatch({
                type: TYPE_FETCH_FREE_INSTRUCTORS_SUCCESS,
                data: response.data,
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_FETCH_FREE_INSTRUCTORS_FAILED,
            });
        });
};

export const setSelectedInstructor = (data: Instructor) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SELECTED_INSTRUCTOR,
        data,
    });
};

export const getSchoolsList = () => (dispatch: any) => {
    axiosapi.post('/booking-corner/schools-list/', {})
        .then((response: any) => {
            dispatch({
                type: TYPE_SET_SCHOOLS_LIST,
                data: response.data,
            });
        })
        .catch((error: any) => {
            dispatch({
                type: TYPE_SET_SCHOOLS_LIST,
                data: [],
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        });
};

export const fetchSchoolInstructorsConfiguration = (
    externalId: string,
    department_id: string,
) => (dispatch: any) => {
    dispatch(get(
        `${API_ROUTES.companyInstructorsConfiguration}?external_id=${externalId}${department_id ? `&department_id=${department_id}` : ''}`,
        (responseJson: {
            mustSelectInstructor: boolean;
            canSelectInstructor: boolean;
        }) => {
            dispatch({
                type: TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION,
                data: responseJson,
            });
        },
        () => {
            dispatch({
                type: TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION,
                data: undefined,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const setCustomerLoyalty = (body: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_CUSTOMER_LOYALTY,
    });

    const url = API_ROUTES.customerLoyalty;
    dispatch(post(
        url,
        body,
        (responseJson: any) => {
            // dispatch({
            //     type: TYPE_SET_CUSTOMER_LOYALTY_SUCCESS,
            //     data: responseJson
            // })
        },
        (error: any) => {
            throw new Error(`${error}`);
            // dispatch({
            //     type: TYPE_SET_CUSTOMER_LOYALTY_FAILURE
            // });
        },
    ));
};

export const setSchoolInstructorsConfiguration = (data?: {
    mustSelectInstructor: boolean;
    canSelectInstructor: boolean;
}) => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION,
        data,
    });
};
