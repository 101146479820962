import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VERSIONS } from '../../resources/constants';
import calendar from '../../resources/img/myaccount/sidebar/calendar.svg';
import logout from '../../resources/img/myaccount/sidebar/logout.svg';
import medals from '../../resources/img/myaccount/sidebar/medals.svg';
import pictures from '../../resources/img/myaccount/sidebar/photo.svg';
import settings from '../../resources/img/myaccount/sidebar/settings.svg';
import star from '../../resources/img/myaccount/sidebar/star.svg';
import { getCurrentVersion } from '../../resources/utils';
import { logoutUser } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { Tabs } from '../account/constants';
import SideBarItem from './sideBatItem';

/**
 * Component that represent the my account page side bar
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    onClick(tab: string): void;
}

type Props = IProps & RouterProps & ReduxProps;

interface State { }

class SideBar extends React.Component<Props, State> {
    /**
     * Log out
     */
    logout = () => {
        const { runLogoutUser } = this.props;
        runLogoutUser();
    };

    render() {
        const {
            location, currentSchool, onClick, currentTab,
        } = this.props;
        const newCurrentTab = location.pathname.includes('account');

        return (
            <div className="__sidebar-parent">
                <ul className="__sidebar-ul">

                    <div className="__sidebar-content">
                        {
                            getCurrentVersion() === VERSIONS.BookingFormation
                                ? <SideBarItem image={calendar} text={<FormattedMessage id="account.mylessons" />} onClick={() => onClick(Tabs.mylessons)} isCurrentTab={newCurrentTab && currentTab === Tabs.mylessons} />

                                : (
                                    <>
                                        <SideBarItem image={calendar} text={<FormattedMessage id="account.mylessons" />} onClick={() => onClick(Tabs.mylessons)} isCurrentTab={newCurrentTab && currentTab === Tabs.mylessons} />
                                        {/* <SideBarItem image={messages} text={<FormattedMessage id='account.messages'/>} onClick={() => onClick(Tabs.messages)} isCurrentTab={currentTab && currentTab == Tabs.messages}/> */}
                                        <SideBarItem image={pictures} text={<FormattedMessage id="account.photos" />} onClick={() => onClick(Tabs.photos)} isCurrentTab={newCurrentTab && currentTab === Tabs.photos} />
                                        <SideBarItem image={medals} text={<FormattedMessage id="account.medals" />} onClick={() => onClick(Tabs.medals)} isCurrentTab={newCurrentTab && currentTab === Tabs.medals} classNameSVG="__account-sidebar-medals-icon" />
                                        {
                                            currentSchool?.loyalty
                                        && <SideBarItem image={star} text={<FormattedMessage id="account.customerLoyalty" />} onClick={() => onClick(Tabs.loyalty)} isCurrentTab={newCurrentTab && currentTab === Tabs.loyalty} />
                                        }
                                    </>
                                )
                        }
                    </div>
                    <div className="__sidebar-bottom">
                        <SideBarItem image={settings} text={<FormattedMessage id="account.settings" />} onClick={() => onClick(Tabs.settings)} isCurrentTab={newCurrentTab && currentTab === Tabs.settings} />
                        <SideBarItem image={logout} text={<FormattedMessage id="account.logout" />} onClick={() => this.logout()} isCurrentTab={false} />
                    </div>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentTab: store.user.myAccountCurrentTab,
    currentSchool: store.navigation.currentSchool,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runLogoutUser: () => dispatch(logoutUser()),
    // runOpenSignUpForm: () => dispatch(openSignUpForm()),
    // runSetParticipantsInfos: (participantsInfos: any) => dispatch(setParticipantsInfos(participantsInfos)),
    // runSetRemarks: (remarks: string | any[]) => dispatch(setRemarks(remarks)),
    // runSetSelectedOptions: (options: any) => dispatch(setSelectedOptions(options)),
    // runSetSelectedLessonDetail: (electedLessonDetail: any) => dispatch(setSelectedLessonDetail(electedLessonDetail)),
    // runFetchInputParticipant: (body: BodyParticipant, language: string) => dispatch(fetchInputParticipant(body, language)),
    // runFetchOptions: (body: BodyOptions, language: string) => dispatch(fetchOptions(body, language)),
    // runFetchQuestions: (body: BodyQuestions, language: string) => dispatch(fetchQuestions(body, language)),
    // runFetchFreeInstructors: (body: BodyFreeInstructors, language: string) => dispatch(fetchFreeInstructors(body, language)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(SideBar));
