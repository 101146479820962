import axiosapi from '../../../store/actions/axiosapi';
import { TotalPriceRequest } from '../../../types/cart';

const OrderApi = {

    initializePayment: async (
        email: any,
        bookings: any,
        client: any,
        language: any,
        schoolId: any,
        departmentId: any,
        fidelityDiscount: any,
        codeDiscountCode: any,
        codeDiscountAmount: any,
        couponCode: any,
        couponAmount: any,
    ) => {
        const response = await axiosapi.post('/order/init/', {
            buyerEmail: email,
            bookings,
            language,
            origin: window.location.origin,
            schoolId,
            departmentId,
            client,
            fidelityDiscount,
            codeDiscountCode,
            codeDiscountAmount,
            couponCode,
            couponAmount,
        });
        return response.data;
    },

    confirmPayment: async (sessionId: any, fidelityDiscount = 0, codeDiscountAmount = 0, codeDiscount = '', clientId = 0) => {
        const response = await axiosapi.post('/order/confirm/', {
            sessionId,
            fidelityDiscount,
            codeDiscountAmount,
            codeDiscount,
            clientId,
        });

        return response.data;
    },

    checkPayment: async (sessionId: any) => {
        const response = await axiosapi.post('/order/check/', {
            sessionId,
        });

        return response.data;
    },

    checkBooking: async (data: any) => {
        const response = await axiosapi.post('/booking-corner/check_booking/', data);
        return response.data;
    },

    claimPayment: async (data: any) => {
        const response = await axiosapi.post('/booking-corner/claim-payment/', data);
        return response.data;
    },

    coupon: async (data: any) => {
        const response = await axiosapi.post('/order/coupon/', data);
        return response.data;
    },

    claimPaymentNoLogin: async (data: any) => {
        const response = await axiosapi.post('/booking-corner/claim-payment-no-login/', data);
        return response.data;
    },

    checkNewPayment: async (data: any) => {
        const response = await axiosapi.post('/booking-corner/check-new-payment/', data);
        return response.data;
    },

    initializeClaimPayment: async (
        email: any,
        client: any,
        toPay: any,
        code: any,
        schoolId: any,
        departmentId: any,
        noLogin: any,
        language: any,
    ) => {
        const response = await axiosapi.post('/order/claim/', {
            buyerEmail: email,
            language,
            origin: window.location.origin,
            schoolId,
            departmentId,
            client,
            toPay,
            code,
            noLogin,
        });
        return response.data;
    },

    checkAvailability: async (bookings: any) => axiosapi.post('/booking-corner/check_availability/', bookings),

    totalPrice: async (bookings: TotalPriceRequest, language: string) => {
        const response = await axiosapi.post('/booking-corner/total-price/', bookings, { headers: { 'Accept-Language': language } });
        return response.data;
    },

    bookingCheckOnly: async (request: any) => {
        const response = await axiosapi.post('/booking-corner/booking-check-only/', request);
        return response.data;
    },

    cancelTransaction: async (sessionId: any) => {
        axiosapi.post('/order/cancel/', {
            sessionId,
        });
    },
};

export default OrderApi;
