import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import {
    fetchNewsfeed,
    getPostComments,
    getPostLikes,
    initNewsfeed,
    likeCurrentPost,
    postComment
} from '../../store/actions/newsfeed';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Container from '../common/container';
import NewsfeedCard from './newsfeedCard';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    commentText: string;
}

class NewsfeedDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { commentText: '' };
    }

    componentDidMount() {
        const { history, dispatch } = this.props;
        const isLoggedIn = Authenticator.isLoggedIn();
        let id: any = history.location.pathname.split('/');
        id = id[id.length - 1];

        dispatch(initNewsfeed());
        dispatch(fetchNewsfeed(0, id));

        if (isLoggedIn) {
            dispatch(getPostComments(id));
            dispatch(getPostLikes(id));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(initNewsfeed());
    }

    onCommentChange = (event: any) => {
        this.setState({ commentText: event.target.value });
    };

    onCommentSubmit = () => {
        const { dispatch, data } = this.props;
        const { commentText } = this.state;
        if (commentText && commentText.length > 0) {
            dispatch(postComment(data[0].id, commentText));
            this.setState({ commentText: '' });
        }
    };

    onLike = (postId: number, like_active: any) => {
        const { dispatch } = this.props;
        dispatch(likeCurrentPost(postId, like_active));
    };

    getNewsFeedItems = () => {
        const { data, currentLang, comments } = this.props;
        const { commentText } = this.state;
        if (data.length > 0) {
            const item = data[0];
            return (
                <NewsfeedCard
                    currentLang={currentLang}
                    data={item}
                    onLike={() => this.onLike(item.id, !item.has_liked)}
                    comments={comments}
                    onCommentSubmit={this.onCommentSubmit}
                    commentValue={commentText}
                    onChange={(e: any) => this.onCommentChange(e)}
                />
            );
        }
        return null;
    };

    render() {
        const { intl } = this.props;

        return (
            <Container headerBase={intl.formatMessage({ id: 'newsfeed.newsfeed' })}>
                <div className="__newsfeed-detail-parent">
                    {this.getNewsFeedItems()}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    data: store.newsfeed.data,
    comments: store.newsfeed.comments,
    likes: store.newsfeed.likes,
    currentLang: store.translation.language,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    dispatch
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(withRouter(connector(NewsfeedDetail)));
