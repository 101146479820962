import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import emailImage from '../../resources/img/global/email.svg';
import { hideSignupError } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import Button from '../common/button';
import TextField from '../common/textfield';

// const validate = (values: any) => {
//     const errors: any = {};

//     if (!values.email) {
//         errors.email = 'Email is required';
//     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
//         errors.email = 'Enter a valid Email';
//     }
//     return errors;
// };

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    onSubmit(e: any): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    email?: string;
    error: any;
    loading: boolean;
}

class ForgotPasswordForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: undefined,
            error: null,
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.checkInputs = this.checkInputs.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
    }

    handleFocus() {
        const { dispatch } = this.props;
        dispatch(hideSignupError());
        this.setState({ error: null });
    }

    handleChange(event: any) {
        this.setState({ email: event.target.value });
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        if (!this.checkInputs()) {
            this.setState({ loading: true });
            onSubmit(this.state);
        }
    }

    onKeyDown = (event: any) => {
        if (event.key === 'Enter') this.handleSubmit();
    };

    checkInputs() {
        let error = null;
        const { intl } = this.props;
        const { email } = this.state;
        if (!email || email.length <= 0) {
            error = intl.formatMessage({ id: 'login.emailRequired' });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            error = intl.formatMessage({ id: 'login.emailNotValid' });
        }

        this.setState({ error });

        return Boolean(error);
    }

    render() {
        const { isErrorVisible, errorText, intl } = this.props;
        const { email, error, loading } = this.state;
        return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div className="__signup-form" onKeyDown={this.onKeyDown}>
                <span className="__signup-textfield-container">
                    <TextField
                        value={email}
                        type="email"
                        placeholder={intl.formatMessage({ id: 'email' })}
                        image={emailImage}
                        onFocus={() => this.handleFocus()}
                        onChange={(value) => this.handleChange(value)}
                        error={error}
                    />
                </span>
                {
                    isErrorVisible
                        ? <span className="__signup-error">{errorText}</span>
                        : null
                }
                <span className="__signup-button-container">
                    <Button onClick={this.handleSubmit} loading={loading}>
                        <FormattedMessage id="login.forgotPasswordButton" />
                    </Button>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    isErrorVisible: store.user.isErrorVisible,
    errorText: store.user.errorText,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(ForgotPasswordForm));
