import { Skeleton } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { SMARTPHONE_WIDTH } from '../../resources/constants';
import { getLanguages } from '../../store/actions/settings';
import { fetchMyLessons } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Lesson } from '../../types/booking';
import Title from '../common/title';
import MyLessonDetails from './myLessonDetails';
import MyLessonsSection from './myLessonsSection';

/**
 * Component that represent the my lessons tab of the my account page
 */

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    currentLesson?: Lesson;
    upcomingLessons: Lesson[];
    completedLessons: Lesson[];
    myLessons?: Lesson[];
}
class MyLessons extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentLesson: undefined,
            upcomingLessons: [],
            completedLessons: [],
        };
    }

    componentDidMount() {
        const { dispatch, language } = this.props;
        dispatch(fetchMyLessons(language));
        dispatch(getLanguages(language));
    }

    componentDidUpdate(prevProps: Props) {
        const { myLessons } = this.props;
        if (prevProps.myLessons !== myLessons) {
            this.setUpcommingAndCompletedLessons(myLessons);
        }
    }

    setUpcommingAndCompletedLessons = (myLessons: Lesson[]) => {
        const upcomingLessons: Lesson[] = [];
        const completedLessons: Lesson[] = [];
        myLessons.forEach((lesson: Lesson) => (moment().diff(lesson.To, 'days') < 0 ? upcomingLessons.push(lesson) : completedLessons.push(lesson)));
        this.setState({ upcomingLessons, completedLessons });
    };

    handleCloseDetails = () => this.setState({ currentLesson: undefined });

    handleOpenDetails = (lesson: Lesson) => this.setState({ currentLesson: lesson });

    static getMyLessonSkeletons = (index: number) => (
        <div className="__lessons-card-upcoming" key={`skeleton_mylessons_parent_${index}`}>
            <Skeleton key={`skeleton_mylessons_img_${index}`} variant="rectangular" className="__lessons-skeleton-img" style={{ cursor: 'default' }} />
            <Skeleton key={`skeleton_mylessons_title_${index}`} variant="text" className="__lessons-skeleton-title" />
            <Skeleton key={`skeleton_mylessons_from_${index}`} variant="text" className="__lessons-skeleton-text" />
            <Skeleton key={`skeleton_mylessons_to_${index}`} variant="text" className="__lessons-skeleton-text" />
            <Skeleton key={`skeleton_mylessons_school_${index}`} variant="text" className="__lessons-skeleton-text" />
            <Skeleton key={`skeleton_mylessons_link_${index}`} variant="text" className="__lessons-skeleton-link" style={{ cursor: 'default' }} />
        </div>
    );

    render() {
        const { isMyLessonsLoading, intl, windowWidth } = this.props;
        const { upcomingLessons, currentLesson, completedLessons } = this.state;
        return (
            isMyLessonsLoading
                ? (
                    <div>
                        <Title className="__lessons-content-title">{intl.formatMessage({ id: 'account.upcomingLessons' })}</Title>
                        <div className="__lessons-content-listing">
                            {[...Array(windowWidth > SMARTPHONE_WIDTH ? 4 : 1).keys()].map((index) => MyLessons.getMyLessonSkeletons(index))}
                        </div>
                    </div>
                )
                : (
                    <div>
                        <MyLessonsSection
                            lessons={upcomingLessons}
                            title={intl.formatMessage({ id: 'account.upcomingLessons' })}
                            handleOpenDetails={this.handleOpenDetails}
                            isMyLessonsLoading={isMyLessonsLoading}
                            isUpcoming
                        />
                        <MyLessonsSection
                            lessons={completedLessons}
                            title={intl.formatMessage({ id: 'account.completedLessons' })}
                            handleOpenDetails={this.handleOpenDetails}
                            isMyLessonsLoading={isMyLessonsLoading}
                            isUpcoming={false}
                        />
                        {
                            currentLesson
                                ? <MyLessonDetails isDetailsOpen={Boolean(currentLesson)} onClose={this.handleCloseDetails} lesson={currentLesson} />
                                : null
                        }
                    </div>
                )
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    isMyLessonsLoading: store.user.isMyLessonsLoading,
    myLessons: store.user.myLessons,
    language: store.translation.language,
    windowWidth: store.windowSize.width,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(MyLessons));
