import React from 'react';
import { WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import { getTranslation } from '../../resources/utils';
import { changeNavigationCurrentPage } from '../../store/actions/navigation';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { ShopNavigationMenu, ShopNavigationSubMenu } from '../../types/settings';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State { }

class NavigationPage extends React.Component<Props, State> {
    componentDidMount() {
        const {
            match, menus, runChangeNavigationCurrentPage, history,
        } = this.props;
        const {
            lang, school, menu, submenu,
        } = match.params;
        window.scrollTo(0, 0);

        if (menus) {
            const navigationMenu = menus.find((m: ShopNavigationMenu) => m.title.replace(' ', '-') === menu);

            if (navigationMenu) {
                if (navigationMenu.submenus.length === 0 && !submenu) {
                    runChangeNavigationCurrentPage(navigationMenu);
                    return;
                }
                const navigationSubMenu = navigationMenu.submenus.find((s: ShopNavigationSubMenu) => s.title.replace(' ', '-') === submenu);
                if (navigationSubMenu) {
                    runChangeNavigationCurrentPage(navigationSubMenu);
                    return;
                }
            }
            history.replace(`/${lang}/${school}`);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            currentPage, menus, runChangeNavigationCurrentPage, history, match,
        } = this.props;
        // if the user load the page or change the current page
        if ((currentPage === undefined && menus !== undefined)
            || (prevProps.currentPage !== currentPage)) {
            const {
                lang, school, menu, submenu,
            } = match.params;

            const navigationMenu = menus ? menus.find((m: ShopNavigationMenu) => m.title.replace(' ', '-') === menu) : undefined;

            if (navigationMenu) {
                if (navigationMenu.submenus.length === 0 && !submenu) {
                    runChangeNavigationCurrentPage(navigationMenu);
                    return;
                }
                const navigationSubMenu = navigationMenu.submenus.find((s: ShopNavigationSubMenu) => s.title.replace(' ', '-') === submenu);
                if (navigationSubMenu) {
                    runChangeNavigationCurrentPage(navigationSubMenu);
                    return;
                }
            }
            history.replace(`/${lang}/${school}`);
        }
    }

    getTitle = () => {
        const { currentPage, match } = this.props;
        if (currentPage && !currentPage.translations) {
            const translation = getTranslation(currentPage.translations, match.params.lang);
            return translation ? translation.title : '';
        }
        return '';
    };

    onLoad = () => {
        const iframe: any = document.getElementById('navigation-page-iframe');
        iframe.contentWindow.postMessage('navigation', this.getPage());
    };

    getPage = () => {
        const { currentPage, match } = this.props;
        if (currentPage) {
            const translation = getTranslation(currentPage.translations, match.params.lang).page;
            return translation;
        }
        return undefined;
    };

    render() {
        const { contentHeight } = this.props;
        const page = this.getPage();
        const headerBase: any = document.getElementsByClassName('__header-base')[0];
        if (headerBase && headerBase.style) headerBase.style.display = 'none';
        return (
            <Container
                headerBase={this.getTitle()}
                noPadding
            >
                <div>
                    {
                        page
                            ? (
                                <iframe
                                    title="Navigation iFrame"
                                    id="navigation-page-iframe"
                                    className="__help-iframe"
                                    style={{ height: contentHeight || '100%', overflow: 'hidden' }}
                                    src={page}
                                    onLoad={this.onLoad}
                                />
                            )
                            : <LoadingCircle />
                    }
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    menus: store.navigation.menus,
    currentPage: store.navigation.currentPage,
    contentHeight: store.navigation.contentHeight,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runChangeNavigationCurrentPage: (page: ShopNavigationMenu | ShopNavigationSubMenu) => dispatch(changeNavigationCurrentPage(page)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(NavigationPage));
