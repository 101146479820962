import CloseIcon from '@mui/icons-material/Close';
import { Modal, Skeleton } from '@mui/material';
import moment from 'moment';
import QRCode from 'qrcode.react';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { fetchMyLessonDetails } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Lesson } from '../../types/booking';
import { Participant } from '../../types/user';

/**
 * Component that represent the modal displaying a lesson's details
 */

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    lesson: Lesson;
    isDetailsOpen: boolean;
    onClose(): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    qrcodeFullScreen?: string;
}

class MyLessonDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            qrcodeFullScreen: undefined,
        };
    }

    componentDidMount() {
        const { dispatch, lesson, language } = this.props;
        dispatch(fetchMyLessonDetails(lesson, language));
    }

    static getSkeleton = () => (
        <div>
            <Skeleton className="__lessons-details-skeleton-title" variant="rectangular" />
            <Skeleton className="__lessons-details-skeleton-overview" variant="rectangular" />
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
            <Skeleton className="__lessons-details-skeleton-overview" variant="rectangular" />
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
            <span className="__lessons-details-skeleton-line">
                <Skeleton className="__lessons-details-skeleton-key" variant="text" />
                <Skeleton className="__lessons-details-skeleton-value" variant="text" />
            </span>
        </div>
    );

    formatParticipant = (participant: Participant) => {
        const createInfo = (key: string, value: any) => ({
            key: key === 'LanguageId' ? 'language' : key.toLowerCase(),
            value,
        });

        const { intl, languages } = this.props;

        return Object.keys(participant).map((key: string) => {
            if (key === 'Id' || key.includes('Medal') || key === 'AddressLocal' || key === 'Sex') return null;

            const value: any = participant[key];
            let info: any = {};

            switch (typeof value) {
                case 'string':
                    if (value.length === 0) { return null; }
                    if (key === 'Birthdate') {
                        if (value === '0001-01-01T00:00:00') return null;
                        info = createInfo(key, intl.formatDate(moment(value).toDate(), { year: 'numeric', month: 'long', day: '2-digit' }));
                    } else if (key === 'LanguageId' && languages.length > 0) { info = createInfo(key, languages.filter((l: any) => l.Id === value)[0].Text); } else { info = createInfo(key, value); }
                    break;
                case 'number':
                    if (value > 0) info = createInfo(key, value);
                    else return null;
                    break;
                default:
                    return null;
            }

            return (
                <span className="__lessons-details-overview-span" key={`mylessons-formated-participants-${participant.Id}`}>
                    <p className="__lessons-details-key">{intl.formatMessage({ id: info.key })}</p>
                    <p>
                        {info.value}
                        {info.key === 'age' ? ` ${intl.formatMessage({ id: 'years' })}` : ''}
                    </p>
                </span>
            );
        }).filter((el) => el !== null);
    };

    computeTotalPrice = () => {
        const { myLessonDetails } = this.props;
        // Prix retourné contient déjà les options
        // let reducer = (acc, option) => acc + option.Price;
        // return myLessonDetails.Options.reduce(reducer, myLessonDetails.Price);
        return myLessonDetails.Price;
    };

    render() {
        const { myLessonDetails, lesson } = this.props;

        let from; let
            to;
        if (myLessonDetails) {
            from = moment(myLessonDetails.From);
            to = moment(myLessonDetails.To);
        }

        const {
            isDetailsOpen, onClose, isMyLessonDetailsLoading, intl,
        } = this.props;
        const { qrcodeFullScreen } = this.state;

        return (
            <Modal className="__lessons-details-modal" open={isDetailsOpen} onClose={onClose}>
                {
                    Object.keys(myLessonDetails).length > 0 && !isMyLessonDetailsLoading
                        ? (
                            <div className="__lessons-details-parent">
                                <div className="__lessons-details-section">
                                    <div className="__lessons-details-header">
                                        <p className="__lessons-details-header-title">{myLessonDetails.Prestation}</p>
                                        {
                                            lesson && lesson.School && lesson.School.length > 0
                                            && <p className="__lessons-details-header-text">{`${intl.formatMessage({ id: 'products.location' })}: ${lesson.School}`}</p>
                                        }
                                        <CloseIcon className="__lessons-details-cross-image" onClick={onClose} />
                                    </div>
                                </div>

                                <div className="__lessons-details-section">
                                    <p className="__lessons-details-title">{intl.formatMessage({ id: 'account.overview' })}</p>
                                    <div className="__lessons-details-overview">
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.startDate' })}</p>
                                            <p>{`${intl.formatDate(from?.toDate(), { year: 'numeric', month: 'long', day: '2-digit' })}`}</p>
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.endDate' })}</p>
                                            <p>{`${intl.formatDate(to?.toDate(), { year: 'numeric', month: 'long', day: '2-digit' })}`}</p>
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'account.mylessons.nbdays' })}</p>
                                            <p>
                                                {myLessonDetails.NbDays}
                                                {' '}
                                                {intl.formatMessage({ id: `booking.day${myLessonDetails.NbDays > 1 ? 's' : ''}` })}
                                            </p>
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.startTime' })}</p>
                                            <p>{`${from?.format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}`}</p>
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.endTime' })}</p>
                                            <p>{`${to?.format(`HH[${intl.formatMessage({ id: 'booking.h' })}]mm`)}`}</p>
                                            {' '}
                                            {/* TODO to.format => to?.format because can be undefined */}
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'account.meetingPoint' })}</p>
                                            <p>{myLessonDetails.MeetingPoint}</p>
                                        </span>
                                        <span className="__lessons-details-overview-span">
                                            <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.total' })}</p>
                                            <p>
                                                {this.computeTotalPrice()}
                                                {' '}
                                                CHF
                                            </p>
                                        </span>
                                        {/* <span className="__lessons-details-overview-span">
                                    <p className="__lessons-details-key">{this.props.intl.formatMessage({id: 'account.level'})}</p>
                                    <p>{myLessonDetails.level}</p>
                                </span>
                                <span className="__lessons-details-overview-span">
                                    <p className="__lessons-details-key">{this.props.intl.formatMessage({id: 'account.type'})}</p>
                                    <p>{myLessonDetails.type}</p>
                                </span>
                                <span className="__lessons-details-overview-span">
                                    <p className="__lessons-details-key">{this.props.intl.formatMessage({id: 'account.activity'})}</p>
                                    <p>{myLessonDetails.activity}</p>
                                </span>
                                <span className="__lessons-details-overview-span">
                                    <p className="__lessons-details-key">{this.props.intl.formatMessage({id: 'language'})}</p>
                                    <p>{myLessonDetails.language}</p>
                                </span> */}
                                    </div>
                                </div>
                                {
                                    myLessonDetails.Instructor
                                        ? (
                                            <div className="__lessons-details-section">
                                                <p className="__lessons-details-title">{intl.formatMessage({ id: 'booking.freeInstructors' })}</p>
                                                <div className="__lessons-details-overview">
                                                    <span className="__lessons-details-overview-span">
                                                        <p className="__lessons-details-key">{intl.formatMessage({ id: 'booking.freeInstructors' })}</p>
                                                        <p>{myLessonDetails.Instructor}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                        : null
                                }
                                {
                                    myLessonDetails.NbParticipants > 0
                                        ? (
                                            <div className="__lessons-details-section">
                                                <span className="__lesson-details-participants">
                                                    <p>{intl.formatMessage({ id: 'booking.participants' })}</p>
                                                    <p>{myLessonDetails.NbParticipants}</p>
                                                </span>
                                                {
                                                    lesson.ProductId != null && myLessonDetails.Participants && myLessonDetails.Participants.length > 0
                                                        ? (
                                                            <div className="__lessons-details-overview">
                                                                {myLessonDetails.Participants.map((p, index) => {
                                                                    const i = index;
                                                                    return (
                                                                        <div key={`lesson-detail-participant-${i}-${p.Id}`}>
                                                                            {index !== 0 ? <div className="__lesson-details-participant-divider" /> : null}
                                                                            {this.formatParticipant(p)}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )
                                                        : null
                                                }
                                            </div>
                                        )
                                        : null
                                }
                                {
                                    myLessonDetails.Options && myLessonDetails.Options.length > 0
                                        ? (
                                            <div className="__lessons-details-section">
                                                <p className="__lessons-details-title">{intl.formatMessage({ id: 'booking.options' })}</p>
                                                <div className="__lessons-details-overview">
                                                    {
                                                        lesson.ProductId != null && myLessonDetails.Options && myLessonDetails.Options.length > 0
                                                            ? myLessonDetails.Options.map((o: any, index: number) => {
                                                                const idx = index;
                                                                return (
                                                                    <span key={`lesson-detail-options-${idx}`} className="__lessons-details-overview-span">
                                                                        <p className="__lessons-details-key">{o.Text}</p>
                                                                        <span className="__lessons-details-options-values">
                                                                            <p className="__lessons-details-options-values-quantity">
                                                                                {o.Quantity === 0 ? 1 : o.Quantity}
                                                                                {' '}
                                                                                {intl.formatMessage({ id: `account.mylessons.optionsTime${o.Quantity > 1 ? 's' : ''}` })}
                                                                            </p>
                                                                            <p>
                                                                                (
                                                                                {o.Price}
                                                                                {' '}
                                                                                CHF)
                                                                            </p>
                                                                        </span>
                                                                    </span>
                                                                );
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                        : null
                                }
                                {
                                    myLessonDetails.Remark && myLessonDetails.Remark.length > 0
                                        ? (
                                            <div className="__lessons-details-section">
                                                <p className="__lessons-details-title">{intl.formatMessage({ id: 'booking.remarks' })}</p>
                                                <div>
                                                    <p className="__lessons-details-overview">{myLessonDetails.Remark}</p>
                                                </div>
                                            </div>
                                        )
                                        : null
                                }
                                <div className="__lessons-details-section">
                                    <p className="__lessons-details-title">QR Codes</p>
                                    <div className="__lessons-details-overview">
                                        {
                                            myLessonDetails.Participants?.map((p: any) => (
                                                <div className="__lessons-details-overview-span __lessons-details-overview-qrcode" key={`mylessons-qrcodes-${p.Id}`}>
                                                    <p className="__lessons-details-key">{`${p.Firstname} ${p.Name}`}</p>
                                                    <QRCode onClick={() => this.setState({ qrcodeFullScreen: p.Id })} value={p.Id} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* {
                                myLessonDetails.teacher ?
                                    <div className="__lessons-details-section">
                                        <p className="__lessons-details-title">{this.props.intl.formatMessage({ id: 'account.yourTeacher' })}</p>
                                        <div className="__lessons-details-teacher">
                                            <img className="__lessons-details-teacher-avatar" src={myLessonDetails.teacherAvatar} alt="Teacher's avatar"/>
                                            <div className="__lessons-details-teacher-infos">
                                                <p className="__lessons-details-teacher-infos-name">{myLessonDetails.teacherName}</p>
                                                <p className="__lessons-details-teacher-infos-info">{myLessonDetails.teacherInfo}</p>
                                                <div className="__lessons-details-teacher-image-container">
                                                    <ReactSVG className="__lessons-details-teacher-image" src={phoneImage} />
                                                    <ReactSVG className="__lessons-details-teacher-image" src={messageImage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            } */}
                                {/* {
                                myLessonDetails.checklist ?
                                    <div className="__lessons-details-section">
                                        <p className="__lessons-details-title">{this.props.intl.formatMessage({ id: 'account.checklist' })}</p>
                                        <div className="__lessons-details-checklist" onScroll={this.handleScroll}>
                                            <More bound={5} className="__lessons-details-more">
                                                {myLessonDetails.checklist.map((el, index) => <p className="__lessons-details-checklist-el" key={index.toString()}>{el}</p>)}
                                            </More>
                                        </div>
                                    </div>
                                    : null
                            } */}
                                {
                                    qrcodeFullScreen
                                        ? (
                                            <div className="__lessons-details-qrcode-fullscreen-content" onClick={() => this.setState({ qrcodeFullScreen: undefined })}>
                                                <QRCode value={qrcodeFullScreen} className="__lessons-details-qrcode-fullscreen" />
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                        )
                        : (
                            <div className="__lessons-details-parent">
                                {MyLessonDetails.getSkeleton()}
                            </div>
                        )
                }
            </Modal>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    languages: store.setting.languages,
    language: store.translation.language,
    isMyLessonDetailsLoading: store.user.isMyLessonDetailsLoading,
    myLessonDetails: store.user.myLessonDetails,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(MyLessonDetails));
