import React from 'react';
import CheckboxControl from '../common/checkboxControl';

interface IProps {
    onChange: (e: any) => void;
    checkboxesArray: any;
    defaultFilters?: string[];
    clear: boolean;
    title: string;
}

type Props = IProps;

interface State {

}

class CheckboxFilter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const { onChange } = this.props;
        onChange(event);
    }

    render() {
        const {
            checkboxesArray, defaultFilters, clear, title,
        } = this.props;
        const checkboxes = checkboxesArray.map((checkbox: any, i: number) => {
            const key = Object.keys(checkbox)[0];
            const value: any = Object.values(checkbox)[0];
            const id = key + i;

            // check if filter already defined as checked
            let defaultChecked = false;
            if (defaultFilters !== undefined) {
                defaultChecked = defaultFilters.includes(key);
            }

            const idx = i;

            return (
                <div className="__products-checkboxfilter-checkbox-container" key={`cb_filter_${idx}`}>
                    <CheckboxControl
                        classNameCheckBox="__products-checkboxfilter-checkbox"
                        onChange={this.handleChange}
                        clear={clear}
                        defaultChecked={defaultChecked}
                        label={value}
                        name={key}
                        id={id}
                    />
                </div>
            );
        });

        return (
            <div className="__products-checkboxfilter-parent">
                <p className="__products-checkboxfilter-title">{title}</p>
                {checkboxes}
            </div>
        );
    }
}

export default CheckboxFilter;
