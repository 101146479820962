import {
    TYPE_WINDOW_HAMBURGER,
    TYPE_WINDOW_SIZE_HEIGHT,
    TYPE_WINDOW_SIZE_WIDTH,
} from '../reducers/windowSize';

export const changeWidth = (width: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_WINDOW_SIZE_WIDTH, data: width,
    });
};

export const changeHeight = (height: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_WINDOW_SIZE_HEIGHT,
        data: height,
    });
};

export const toggleHamburger = () => (dispatch: any) => {
    dispatch({
        type: TYPE_WINDOW_HAMBURGER,
    });
};
