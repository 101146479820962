import { injectIntl, WrappedComponentProps } from 'react-intl';
import { VERSIONS } from '../../resources/constants';
import { getCurrentVersion, getVersionName, ImgWithFallback } from '../../resources/utils';

interface IProps {
    image: string;
    texts: string[];
    schoolName?: string;
}

type Props = IProps & WrappedComponentProps;

// const defaultProps = {
//     texts: [''],
//     schoolName: '',
// };

function HeaderImage(props: Props) {
    const {
        intl, image, texts, schoolName,
    } = props;
    const platform = getVersionName();
    // Prepare image to <ImgWithFallback />
    const imageSource = image;
    const re = /(?:\.([^.]+))?$/;
    const imageSourceExploded = re.exec(imageSource);
    const ext = imageSourceExploded != null ? imageSourceExploded[1] : '';
    const imageSrc = imageSource;
    const imageFallback = ext === 'webp' ? `${imageSource.slice(0, -4)}jpg` : imageSource;
    // End of prepare image to <ImgWithFallback />
    return (
        <div className="__header-parent">
            <ImgWithFallback
                src={imageSrc}
                fallback={imageFallback}
                className="__header-image"
                alt={`${intl.formatMessage({ id: 'img.alt.logo' })} ${platform}`}
            />
            {/* <img src={this.props.image} className="__header-image" alt={`${this.props.intl.formatMessage({ id: 'img.alt.logo' })} ${platform}`} /> */}
            {texts && texts.length > 0
                ? (
                    <div>
                        {
                            getCurrentVersion() === VERSIONS.BookingFormation
                                ? null
                                : (
                                    <h1 className="__header-image-label-1">
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {`${schoolName
                                            ? texts[0]
                                            : getCurrentVersion() === VERSIONS.BookingSport
                                                ? intl.formatMessage({ id: 'landing.bookingsport' })
                                                : texts[0].substr(0, texts[0].lastIndexOf(' '))
                                        } ${schoolName || ''}`}
                                    </h1>
                                )
                        }
                        <h2 className="__header-image-label-2">{texts[1]}</h2>
                    </div>
                )
                : null}
        </div>
    );
}

// HeaderImage.defaultProps = defaultProps;

export default injectIntl(HeaderImage);
