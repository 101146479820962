/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    LESSON_FILTERS,
    LESSON_KEYS,
    SMARTPHONE_WIDTH,
    URL_ACTIVITY,
    URL_AGE, URL_LESSON,
    URL_RESORT, VERSIONS
} from '../../resources/constants';
import { cransParamsToBody, getCurrentVersion } from '../../resources/utils';
import { fetchAllProducts, fetchCransMontanaProducts, setSelectedProductsDate } from '../../store/actions/products';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { Param } from '../../types/products';
import { School } from '../../types/settings';
import Button from '../common/button';
import CheckboxFilter from './chexboxFilter';
import ProductsDate from './productsDate';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    params: Param;
    minDate: Moment;
    handleChangeLocation: (e?: any) => void;
    updatePath: (params: Param) => void;
    toggleOpen?: () => void;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    params: {
        lesson_type_in?: string[];
        activity_type_in?: string[];
        school_id?: string;
        levels__ages?: string[];
        department_id?: string
        resort_in?: string[];
        offset?: number;
        date?: Moment;
    };
    cransmontana: {
        lesson_type_in?: string[];
        activity_type_in?: string[];
        school_id?: string;
    };
    clear: boolean;
}

class ProductsFilters extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     toggleOpen: undefined,
    // };

    static filterBy = (checkbox: any, params: Param, value: string, name: string) => {
        const newParams = params;
        if (checkbox.checked) {
            if (newParams[name]) {
                newParams[name].push(value);
            } else {
                newParams[name] = [value];
            }
        } else if (params[name]) {
            newParams[name] = params[name].filter((e: any) => e !== value);
        } else {
            newParams[name] = [];
        }
        return newParams;
    };

    static filterByActivityOther = (checkbox: any, params: Param, values: string[], name: string) => {
        let newParams = params;
        values.forEach((v) => {
            newParams = ProductsFilters.filterBy(checkbox, newParams, v, name);
        });
        return newParams;
    };

    constructor(props: Props) {
        super(props);

        const { params } = this.props;

        let paramsLessonTypeIn:string[] = [];
        let paramsActivityTypeIn:string[] = [];
        let paramsLevelsAges:string[] = [];
        let paramsResortIn:string[] = [];
        let cmLessonTypeIn:string[] = [];
        let cmActivityTypeIn:string[] = [];
        if (params.lesson_type_in) {
            if (Array.isArray(params.lesson_type_in)) {
                paramsLessonTypeIn = params.lesson_type_in;
            } else {
                paramsLessonTypeIn = [params.lesson_type_in];
            }
        }

        if (params.activity_type_in) {
            if (Array.isArray(params.activity_type_in)) {
                paramsActivityTypeIn = params.activity_type_in;
            } else {
                paramsActivityTypeIn = [params.activity_type_in];
            }
        }

        if (params.levels__ages) {
            if (Array.isArray(params.levels__ages)) {
                paramsLevelsAges = params.levels__ages;
            } else {
                paramsLevelsAges = [params.levels__ages];
            }
        }

        if (params.resort_in) {
            if (Array.isArray(params.resort_in)) {
                paramsResortIn = params.resort_in;
            } else {
                paramsResortIn = [params.resort_in];
            }
        }

        if (params.lesson_type_in) {
            if (Array.isArray(params.lesson_type_in)) {
                cmLessonTypeIn = params.lesson_type_in;
            } else {
                cmLessonTypeIn = [params.lesson_type_in];
            }
        }

        if (params.activity_type_in) {
            if (Array.isArray(params.activity_type_in)) {
                cmActivityTypeIn = params.activity_type_in;
            } else {
                cmActivityTypeIn = [params.activity_type_in];
            }
        }

        this.state = {
            params: {
                lesson_type_in: paramsLessonTypeIn,
                activity_type_in: paramsActivityTypeIn,
                levels__ages: paramsLevelsAges,
                school_id: params.school_id ? params.school_id : '',
                department_id: params.department_id,
                resort_in: paramsResortIn,
            },
            cransmontana: {
                lesson_type_in: cmLessonTypeIn,
                activity_type_in: cmActivityTypeIn,
                school_id: '3',
            },
            clear: false,
        };
    }

    handleChange = (checkbox: any, resort = false) => {
        // eslint-disable-next-line react/destructuring-assignment
        const params = _.cloneDeep(this.state.params);
        const {
            handleChangeLocation, dispatch, language, updatePath,
        } = this.props;

        let parameters = params;

        switch (checkbox.name) {
            case LESSON_FILTERS.SKIING:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SKIING, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.SNOWBOARD:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SNOWBOARD, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.TELEMARK:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.TELEMARK, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.OTHER:
                parameters = ProductsFilters.filterByActivityOther(checkbox, parameters, [LESSON_FILTERS.OTHER], URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.SUMMER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SUMMER, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.ROCKER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.ROCKER, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.MOUNTAINEERING:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.MOUNTAINEERING, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.ICE:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.ICE, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.TRAIL:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.TRAIL, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.FORMATION:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.FORMATION, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.GROUP_LESSON:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.GROUP_LESSON, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.PRIVATE_LESSON:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.PRIVATE_LESSON, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.MINI_KID:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.MINI_KID, URL_AGE.FILTER);
                break;
            case LESSON_FILTERS.CHILD:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CHILD, URL_AGE.FILTER);
                break;
            case LESSON_FILTERS.TEENAGER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.TEENAGER, URL_AGE.FILTER);
                break;
            case LESSON_FILTERS.ADULT:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.ADULT, URL_AGE.FILTER);
                break;
            case LESSON_FILTERS.GRIMENTZ:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.GRIMENTZ, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.ZINAL:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.ZINAL, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.CORBATIERE:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CORBATIERE, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.BUGNENETS:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.BUGNENETS, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.VUE_DES_ALPES:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.VUE_DES_ALPES, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.CRET_DU_PUY:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRET_DU_PUY, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.SOMMARTEL:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SOMMARTEL, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.LAUCHERALP:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.LAUCHERALP, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.RIEDERALP:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.RIEDERALP, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.BELALP:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.BELALP, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.UNTERBACH:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.UNTERBACH, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.GRACHEN:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.GRACHEN, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.SAAS_FEE:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.SAAS_FEE, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.ZERMATT:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.ZERMATT, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.GRIMENTZ_BF:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.GRIMENTZ_BF, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.LES_CROSETS:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.LES_CROSETS, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.NENDAZ:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.NENDAZ, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.VEYSONNAZ:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.VEYSONNAZ, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.VERBIER:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.VERBIER, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.CRANS_MONTANA:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.CRANS_MONTANA, URL_RESORT.FILTER);
                break;
            case LESSON_FILTERS.DAVOS:
                parameters = ProductsFilters.filterBy(checkbox, params, LESSON_FILTERS.DAVOS, URL_RESORT.FILTER);
                break;
            default:
                parameters = {};
        }

        this.setState({
            params: parameters,
        });

        if (resort) {
            handleChangeLocation(checkbox);
        } else {
            dispatch(fetchAllProducts(parameters, language));
        }

        updatePath(parameters);
    };

    handleChangeSchool = (target: any, fromToCran: any) => {
        const { params } = this.state;
        const {
            schools, updatePath, dispatch, language,
        } = this.props;
        let parameters = params;
        let cransEmpty: any;

        if (fromToCran) {
            parameters = {
                lesson_type_in: [],
                activity_type_in: [],
                levels__ages: [],
                school_id: '',
                offset: 0,
            };

            cransEmpty = {
                lesson_type_in: [],
                activity_type_in: [],
                school_id: '3',
            };
        }

        parameters.school_id = target.value;
        const currentSchool = schools.find((s: School) => s.id === Number(target.value));
        parameters.department_id = currentSchool ? currentSchool.department_id : undefined;

        this.setState((state) => (
            { params: parameters, cransmontana: cransEmpty || state.cransmontana }
        ));
        updatePath(parameters);
        dispatch(fetchAllProducts(parameters, language));
    };

    handleChangeDate = (event: Moment) => {
        const { params } = this.state;
        const {
            updatePath, dispatch, language,
        } = this.props;
        const parameters = _.cloneDeep(params);
        const selectedDate = event.format('YYYY-MM-DDT00:00:00');
        dispatch(setSelectedProductsDate(selectedDate));
        // const params = { ...this.state.params };
        parameters.date = event;
        this.setState({ params: parameters });
        updatePath(parameters);
        dispatch(fetchAllProducts(parameters, language));
    };

    onClickCleanFilter = () => {
        const { params } = this.state;
        const {
            updatePath, dispatch, language, toggleOpen, handleChangeLocation,
        } = this.props;

        const paramsEmpty = {
            lesson_type_in: [],
            activity_type_in: [],
            levels__ages: [],
            school_id: params.school_id,
            offset: 0,
        };

        const cransEmpty = {
            lesson_type_in: [],
            activity_type_in: [],
            school_id: '3',
        };

        this.setState((state) => (
            { clear: !state.clear, params: paramsEmpty, cransmontana: cransEmpty }
        ), () => {
            this.setState((state) => ({ clear: !state.clear }));
        });

        updatePath(paramsEmpty);
        dispatch(fetchAllProducts(paramsEmpty, language));
        handleChangeLocation();

        if (toggleOpen) toggleOpen();
    };

    handleChangeCransMontana = (checkbox: any) => {
        const { cransmontana } = this.state;
        const {
            updatePath, dispatch, intl, language,
        } = this.props;

        let parameters = cransmontana;

        switch (checkbox.name) {
            case LESSON_FILTERS.SKIING:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SKIING, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.SNOWBOARD:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SNOWBOARD, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.TELEMARK:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.TELEMARK, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.OTHER:
                parameters = ProductsFilters.filterByActivityOther(checkbox, parameters, [LESSON_FILTERS.OTHER], URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.SUMMER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.SUMMER, URL_ACTIVITY.FILTER);
                break;
            case LESSON_FILTERS.GROUP_LESSON:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.GROUP_LESSON, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.PRIVATE_LESSON:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.PRIVATE_LESSON, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_KIDS_CRANS:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_KIDS_CRANS, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_KIDS_GOLF:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_KIDS_GOLF, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_KIDS_MONTANA:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_KIDS_MONTANA, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_SPECIAL:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_SPECIAL, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_PRIVATE:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_PRIVATE, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_SNOWBOARD_G:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_SNOWBOARD_G, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_SNOWBOARD:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_SNOWBOARD, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_GROUP:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_GROUP, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_OTHER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_OTHER, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_KIDS_SUMMER:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_KIDS_SUMMER, URL_LESSON.FILTER);
                break;
            case LESSON_FILTERS.CRANS_BIKE:
                parameters = ProductsFilters.filterBy(checkbox, parameters, LESSON_FILTERS.CRANS_BIKE, URL_LESSON.FILTER);
                break;
            default:
                parameters = {};
        }

        this.setState({
            cransmontana: parameters,
        });

        updatePath(parameters);

        dispatch(fetchCransMontanaProducts(
            cransParamsToBody(
                parameters,
                intl.formatMessage,
            ),
            language,
        ));
    };

    render() {
        const { params, clear, cransmontana } = this.state;
        const {
            intl, schools, minDate, windowWidth, toggleOpen,
        } = this.props;
        const grimentzZinal = [
            { [LESSON_FILTERS.GRIMENTZ]: 'Grimentz' },
            { [LESSON_FILTERS.ZINAL]: 'Zinal' },
        ];

        const neuchatelRegion = [
            { [LESSON_FILTERS.BUGNENETS]: 'Bugnenets' },
            { [LESSON_FILTERS.CORBATIERE]: 'Corbatière' },
            { [LESSON_FILTERS.VUE_DES_ALPES]: 'Vue-des-Alpes' },
            { [LESSON_FILTERS.CRET_DU_PUY]: 'Crêt-du-Puy' },
            { [LESSON_FILTERS.SOMMARTEL]: 'Sommartel' },
        ];
        const sommetEtNeige = [
            { [LESSON_FILTERS.NENDAZ]: 'Nendaz' },
            { [LESSON_FILTERS.CRANS_MONTANA]: 'Crans Montana' },
            { [LESSON_FILTERS.DAVOS]: 'Davos' },
        ];
        const bookingFormation = [
            // { [LESSON_FILTERS.LAUCHERALP]: 'Laucheralp' },
            { [LESSON_FILTERS.RIEDERALP]: 'Riederalp' },
            { [LESSON_FILTERS.BELALP]: 'Belalp' },
            // { [LESSON_FILTERS.GRACHEN]: 'Grächen' },
            { [LESSON_FILTERS.UNTERBACH]: 'Unterbach' },
            { [LESSON_FILTERS.SAAS_FEE]: 'Saas Fee' },
            { [LESSON_FILTERS.ZERMATT]: 'Zermatt' },
            { [LESSON_FILTERS.GRIMENTZ_BF]: 'Grimentz' },
            { [LESSON_FILTERS.LES_CROSETS]: 'Les Crosets' },
            { [LESSON_FILTERS.NENDAZ]: 'Nendaz' },
            { [LESSON_FILTERS.VEYSONNAZ]: 'Veysonnaz' },
            { [LESSON_FILTERS.VERBIER]: 'Verbier' },
        ];
        let activityCheckboxes = [];
        if (getCurrentVersion() === VERSIONS.BookingSport && Number(params.school_id) !== 28 && Number(params.school_id) !== 36 && Number(params.school_id) !== 37) {
            activityCheckboxes = [
                { [LESSON_FILTERS.SKIING]: intl.formatMessage({ id: 'products.ski' }) },
                { [LESSON_FILTERS.SUMMER]: intl.formatMessage({ id: 'products.summer' }) },
                { [LESSON_FILTERS.ROCKER]: intl.formatMessage({ id: 'products.rocker' }) },
                { [LESSON_FILTERS.MOUNTAINEERING]: intl.formatMessage({ id: 'products.mountaineering' }) },
                { [LESSON_FILTERS.ICE]: intl.formatMessage({ id: 'products.ice' }) },
                { [LESSON_FILTERS.TRAIL]: intl.formatMessage({ id: 'products.trail' }) },
                { [LESSON_FILTERS.FORMATION]: intl.formatMessage({ id: 'products.formation' }) },
                { [LESSON_FILTERS.OTHER]: intl.formatMessage({ id: 'products.other' }) },
            ];
        } else {
            activityCheckboxes = [
                { [LESSON_FILTERS.SKIING]: intl.formatMessage({ id: 'products.ski' }) },
                { [LESSON_FILTERS.SNOWBOARD]: intl.formatMessage({ id: 'products.snowboard' }) },
                { [LESSON_FILTERS.TELEMARK]: intl.formatMessage({ id: 'products.telemark' }) },
                { [LESSON_FILTERS.SUMMER]: intl.formatMessage({ id: 'products.summer' }) },
                { [LESSON_FILTERS.OTHER]: intl.formatMessage({ id: getCurrentVersion() === VERSIONS.BookingFormation ? 'products.bookingformation.other' : 'products.other' }) }];
        }

        const typeLabelCheckboxes = [
            { [LESSON_FILTERS.GROUP_LESSON]: intl.formatMessage({ id: 'products.group' }) },
            { [LESSON_FILTERS.PRIVATE_LESSON]: intl.formatMessage({ id: 'products.private' }) },
        ];
        const ageLabelCheckboxes = [
            { [LESSON_FILTERS.MINI_KID]: intl.formatMessage({ id: `products.${LESSON_KEYS.MINI_KID}` }) },
            { [LESSON_FILTERS.CHILD]: intl.formatMessage({ id: `products.${LESSON_KEYS.CHILD}` }) },
            { [LESSON_FILTERS.TEENAGER]: intl.formatMessage({ id: `products.${LESSON_KEYS.TEENAGER}` }) },
            { [LESSON_FILTERS.ADULT]: intl.formatMessage({ id: `products.${LESSON_KEYS.ADULT}` }) },
        ];
        const cransMontanaCheckboxes = [
            { [LESSON_FILTERS.CRANS_KIDS_MONTANA]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_KIDS_MONTANA}` }) },
            { [LESSON_FILTERS.CRANS_KIDS_GOLF]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_KIDS_GOLF}` }) },
            { [LESSON_FILTERS.CRANS_KIDS_CRANS]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_KIDS_CRANS}` }) },
            { [LESSON_FILTERS.CRANS_GROUP]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_GROUP}` }) },
            { [LESSON_FILTERS.CRANS_PRIVATE]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_PRIVATE}` }) },
            { [LESSON_FILTERS.CRANS_SNOWBOARD_G]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_SNOWBOARD_G}` }) },
            { [LESSON_FILTERS.CRANS_SNOWBOARD]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_SNOWBOARD}` }) },
            { [LESSON_FILTERS.CRANS_OTHER]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_OTHER}` }) },
            { [LESSON_FILTERS.CRANS_SPECIAL]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_SPECIAL}` }) },
            { [LESSON_FILTERS.CRANS_KIDS_SUMMER]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_KIDS_SUMMER}` }) },
            { [LESSON_FILTERS.CRANS_BIKE]: intl.formatMessage({ id: `crans.${LESSON_FILTERS.CRANS_BIKE}` }) },
        ];

        // delete "other" for Nendaz
        if (Number(params.school_id) === 4) activityCheckboxes.pop();
        return (
            <div className="__products-filter-parent">
                <div>
                    <ProductsDate
                        onChangeDate={this.handleChangeDate}
                        clear={clear}
                        onChangeSchool={this.handleChangeSchool}
                        schools={schools}
                        selectedSchool={params.school_id}
                        minDate={minDate}
                    />
                </div>
                {
                    Number(params.school_id) === 3
                        ? (
                            <div className="__products-checkboxfilter-group">
                                <CheckboxFilter
                                    onChange={this.handleChangeCransMontana}
                                    clear={clear}
                                    title={intl.formatMessage({ id: 'products.service' })}
                                    checkboxesArray={cransMontanaCheckboxes}
                                    defaultFilters={cransmontana.lesson_type_in}
                                />
                            </div>
                        )
                        : Number(params.school_id) === 1
                            ? (
                                <div className="__products-checkboxfilter-group">

                                    <CheckboxFilter
                                        onChange={(e) => this.handleChange(e, true)}
                                        clear={clear}
                                        title={intl.formatMessage({ id: 'products.location2' })}
                                        checkboxesArray={grimentzZinal}
                                        defaultFilters={params.resort_in}
                                    />
                                    <CheckboxFilter
                                        onChange={this.handleChange}
                                        clear={clear}
                                        title={intl.formatMessage({ id: 'products.activity' })}
                                        checkboxesArray={activityCheckboxes}
                                        defaultFilters={params.activity_type_in}
                                    />
                                    <CheckboxFilter
                                        onChange={this.handleChange}
                                        clear={clear}
                                        title={intl.formatMessage({ id: 'products.type' })}
                                        checkboxesArray={typeLabelCheckboxes}
                                        defaultFilters={params.lesson_type_in}
                                    />
                                    <CheckboxFilter
                                        onChange={this.handleChange}
                                        clear={clear}
                                        title={intl.formatMessage({ id: 'age' })}
                                        checkboxesArray={ageLabelCheckboxes}
                                        defaultFilters={params.levels__ages}
                                    />
                                </div>
                            )
                            : Number(params.school_id) === 34
                                ? (
                                    <div className="__products-checkboxfilter-group">
                                        <CheckboxFilter
                                            onChange={(e) => this.handleChange(e, true)}
                                            clear={clear}
                                            title={intl.formatMessage({ id: 'products.location2' })}
                                            checkboxesArray={bookingFormation}
                                            defaultFilters={params.resort_in}
                                        />
                                        <CheckboxFilter
                                            onChange={this.handleChange}
                                            clear={clear}
                                            title={intl.formatMessage({ id: 'products.activity' })}
                                            checkboxesArray={activityCheckboxes}
                                            defaultFilters={params.activity_type_in}
                                        />
                                    </div>
                                )
                                : Number(params.school_id) === 29
                                    ? (
                                        <div className="__products-checkboxfilter-group">
                                            <CheckboxFilter
                                                onChange={(e) => this.handleChange(e, true)}
                                                clear={clear}
                                                title={intl.formatMessage({ id: 'products.location2' })}
                                                checkboxesArray={neuchatelRegion}
                                                defaultFilters={params.resort_in}
                                            />
                                            <CheckboxFilter
                                                onChange={this.handleChange}
                                                clear={clear}
                                                title={intl.formatMessage({ id: 'products.activity' })}
                                                checkboxesArray={activityCheckboxes}
                                                defaultFilters={params.activity_type_in}
                                            />
                                            <CheckboxFilter
                                                onChange={this.handleChange}
                                                clear={clear}
                                                title={intl.formatMessage({ id: 'products.type' })}
                                                checkboxesArray={typeLabelCheckboxes}
                                                defaultFilters={params.lesson_type_in}
                                            />
                                            <CheckboxFilter
                                                onChange={this.handleChange}
                                                clear={clear}
                                                title={intl.formatMessage({ id: 'age' })}
                                                checkboxesArray={ageLabelCheckboxes}
                                                defaultFilters={params.levels__ages}
                                            />
                                        </div>
                                    )
                                    : Number(params.school_id) === 37
                                        ? (
                                            <div className="__products-checkboxfilter-group">
                                                <CheckboxFilter
                                                    onChange={(e) => this.handleChange(e, true)}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'products.location2' })}
                                                    checkboxesArray={sommetEtNeige}
                                                    defaultFilters={params.resort_in}
                                                />
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'products.activity' })}
                                                    checkboxesArray={activityCheckboxes}
                                                    defaultFilters={params.activity_type_in}
                                                />
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'products.type' })}
                                                    checkboxesArray={typeLabelCheckboxes}
                                                    defaultFilters={params.lesson_type_in}
                                                />
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'age' })}
                                                    checkboxesArray={ageLabelCheckboxes}
                                                    defaultFilters={params.levels__ages}
                                                />
                                            </div>
                                        )
                                        : (
                                            <div className="__products-checkboxfilter-group">
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'products.activity' })}
                                                    checkboxesArray={activityCheckboxes}
                                                    defaultFilters={params.activity_type_in}
                                                />
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'products.type' })}
                                                    checkboxesArray={typeLabelCheckboxes}
                                                    defaultFilters={params.lesson_type_in}
                                                />
                                                <CheckboxFilter
                                                    onChange={this.handleChange}
                                                    clear={clear}
                                                    title={intl.formatMessage({ id: 'age' })}
                                                    checkboxesArray={ageLabelCheckboxes}
                                                    defaultFilters={params.levels__ages}
                                                />
                                            </div>
                                        )
                }
                <div className="__products-filter-button-parent">
                    <div className="__products-filter-button-background" />
                    <div className="__products-filter-button-container">
                        <Button buttonClasses="__products-filter-button" containerClasses="__products_filter-button-border" onClick={this.onClickCleanFilter}>
                            {intl.formatMessage({ id: 'products.clearButton' })}
                        </Button>
                        {
                            windowWidth > SMARTPHONE_WIDTH ? null
                                : (
                                    <Button containerClasses="__products_filter-button-border" onClick={() => toggleOpen && toggleOpen()}>
                                        {intl.formatMessage({ id: 'done' })}
                                    </Button>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    schools: store.products.schools,
    windowWidth: store.windowSize.width,
    language: store.translation.language,
});
const connector = connect(mapStateToProps);

export default withRouter(injectIntl(connector(ProductsFilters)));
