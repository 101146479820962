import { Skeleton } from '@mui/material';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { fetchMedalsDetails } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { Medal } from '../../types/user';
import Modal from '../common/modal';
import Title from '../common/title';
import MedalCard from './medalCard';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    currentMedal?: Medal;
}

class Medals extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            currentMedal: undefined,
        };
    }

    componentDidMount() {
        const { language, runFetchMedalsDetails } = this.props;
        runFetchMedalsDetails(language);
    }

    handleOpenDetails = (medal: Medal) => this.setState({ currentMedal: medal });

    handleCloseDetails = () => this.setState({ currentMedal: undefined });

    /* eslint-disable-next-line class-methods-use-this */
    getMedalSkeletons = () => (
        <div className="__account-medals-section">
            <Skeleton variant="text" className="__account-medals-skeleton-title" />
            <div className="__lessons-card-upcoming">
                <Skeleton variant="rectangular" className="__lessons-skeleton-img" />
                <Skeleton variant="text" className="__lessons-skeleton-title" />
                <Skeleton variant="text" className="__lessons-skeleton-text" />
            </div>
            <div className="__lessons-card-upcoming">
                <Skeleton variant="rectangular" className="__lessons-skeleton-img" />
                <Skeleton variant="text" className="__lessons-skeleton-title" />
                <Skeleton variant="text" className="__lessons-skeleton-text" />
            </div>
        </div>
    );

    render() {
        const { currentMedal } = this.state;
        const {
            isMedalsLoading, medals, intl,
        } = this.props;

        // ? Avoid nested ternary

        let returnElement;

        if (isMedalsLoading) {
            returnElement = this.getMedalSkeletons();
        } else if (medals && medals.length > 0) {
            returnElement = (
                medals.map((medal: Medal, index: number) => {
                    const idx = index;
                    return (
                        <div key={`account-medals-${idx}`} className="__account-medals-section">
                            <Title className="__account-medals-section-title">
                                {medal.Participant.Firstname}
                                {' '}
                                {medal.Participant.Name}
                            </Title>
                            <div className="__account-medals-listing">
                                {medal.Medals.map((m) => <MedalCard key={`account-medals-medals-${m.Id}`} medal={m} handleOpenDetails={this.handleOpenDetails} />)}
                            </div>
                        </div>
                    );
                })
            );
        } else {
            returnElement = (
                <p className="__lessons-no-lesson">{intl.formatMessage({ id: 'account.medals.noMedal' })}</p>
            );
        }

        return (
            <div className="__account-medals-parent">
                {
                    currentMedal
                        ? (
                            <Modal
                                containerClasses="__account-medals-detail"
                                open={Boolean(currentMedal)}
                                onClose={this.handleCloseDetails}
                            >
                                <img className="__account-medals-detail-image" src={currentMedal.Picture} alt={currentMedal.Text} title={currentMedal.Text} />
                            </Modal>
                        )
                        : null
                }
                {
                    returnElement
                }
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    language: store.translation.language,
    medals: store.user.medals,
    isMedalsLoading: store.user.isMedalsLoading,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runFetchMedalsDetails: (language: string) => dispatch(fetchMedalsDetails(language)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(connector(Medals));
