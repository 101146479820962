import { Modal as MaterialModal } from '@mui/material';

/**
 * Component that represent a Modal
*/
interface IProps {
    open: boolean;
    containerClasses?: string;
    children: any;
    onKeyDown?: (event: any) => void;
    onClose?: () => void;
}

type Props = IProps;

// const defaultProps = {
//     containerClasses: '',
//     onKeyDown: (event: any) => event,
//     onClose: () => { },
// };

function Modal(props: Props) {
    const {
        open, onClose, containerClasses, onKeyDown, children,
    } = props;
    // "disableBackdropClick={true}"" removed
    return (
        <MaterialModal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div className={`__modal-parent ${containerClasses || ''}`} onKeyDown={onKeyDown}>
                {children}
            </div>
        </MaterialModal>
    );
}

// Modal.defaultProps = defaultProps;

export default Modal;
