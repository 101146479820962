import { MenuItem } from '@mui/material';
import * as Sentry from '@sentry/browser';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { isNumber, removeDuplicatesByKey } from '../../resources/utils';
import { setParticipantsInfos } from '../../store/actions/booking';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { LessonCart, Participant, ParticipantInfo } from '../../types/booking';
import Button from '../common/button';
import LoadingCircle from '../common/loadingCircle';
import SelectField from '../common/selectField';
import ParticipantField from './participantField';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
    nbParticipants: number;
    handleExpandable(): void;
    onChangeIfEmpty(): void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    formatedInputParticipant: any;
    selectedParticipants: any;
    participants: any[];
}

class Participants extends Component<Props, State> {
    fields: {
        [key: string]: any
    };

    constructor(props:Props) {
        super(props);

        this.fields = {};
        const participants = this.getParticipants();
        this.state = {
            formatedInputParticipant: [],
            selectedParticipants: this.getSelectedParticipants(participants),
            participants
        };

        this.formatParticipant = this.formatParticipant.bind(this);
        this.handleClickNext = this.handleClickNext.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const { inputParticipant, nbParticipants, dispatch } = this.props;
        if (prevProps.inputParticipant !== inputParticipant) {
            // construct refs
            for (let i = 0; i < nbParticipants; i++) {
                inputParticipant.forEach((input: any) => {
                    this.fields[`${input.fieldName}_${i}`] = React.createRef();
                });
            }
            this.setState({ formatedInputParticipant: this.formatParticipant(nbParticipants) });
        }

        if (!isEqual(prevProps.bcClient.Participants, this.props.bcClient.Participants)) {
            const participants = this.getParticipants();
            this.setState({ participants, selectedParticipants: this.getSelectedParticipants(participants) });
        }

        if (prevProps.nbParticipants !== nbParticipants) {
            dispatch(setParticipantsInfos([]));
            this.setState({
                formatedInputParticipant: [],
            }, () => {
                this.setState({ formatedInputParticipant: this.formatParticipant(nbParticipants) });
            });
        }
    }

    handleClickNext() {
        const {
            intl, details, handleExpandable, dispatch,
        } = this.props;
        const { formatedInputParticipant, selectedParticipants } = this.state;
        let hasError = false;
        const allParticipantsInfos: ParticipantInfo[] = [];
        formatedInputParticipant.forEach((participant: any, i: number) => {
            const participantInfos: any = {};
            participant.forEach((field: any) => {
                const refField = this.fields[field.key].current;
                const { required } = refField.props.participant;
                let fieldName = refField.props.participant.fieldName.toLowerCase();
                let { value } = refField.state;
                let error = false;

                if (fieldName === 'birthdate' && !value._isAMomentObject) {
                    value = moment(value, 'YYYY-MM-DDTHH:mm:ss', true);
                }

                if (value && value._isAMomentObject) {
                    if ((value._d && value._d.toString() === 'Invalid Date') || moment().startOf('day').diff(value, 'day') <= -1 || moment('01/01/1900', 'DD/MM/YYYY').startOf('day').diff(value, 'day') >= 1) {
                        refField.setError(intl.formatMessage({ id: 'settings.dateNotValid' }));
                        error = true;
                        hasError = true;
                    } else if (value.isSame(moment(), 'day')) {
                        refField.setError(intl.formatMessage({ id: 'settings.dateNotToday' }));
                        error = true;
                        hasError = true;
                    } else if (fieldName === 'birthdate' && refField.props.participant.minAge && refField.props.participant.maxAge) {
                        const age = moment(details?.startDate).diff(value._d, 'month');
                        if (age > refField.props.participant.maxAge * 12
                            || age < refField.props.participant.minAge * 12) {
                            refField.setError(intl.formatMessage({ id: 'booking.participantMinMaxAge' }, { minAge: Math.ceil(refField.props.participant.minAge * 10) / 10, maxAge: Math.floor(refField.props.participant.maxAge * 10) / 10 }));
                            hasError = true;
                            error = true;
                        }
                    } else if (fieldName === 'birthdate' && refField.props.participant.minAge) {
                        const age = moment(details?.startDate).diff(value._d, 'month');
                        if (age < refField.props.participant.minAge * 12) {
                            refField.setError(intl.formatMessage({ id: 'booking.participantMinMaxAge' }, { minAge: Math.ceil(refField.props.participant.minAge * 10) / 10, maxAge: 100 }));
                            hasError = true;
                            error = true;
                        }
                    } else if (fieldName === 'birthdate' && refField.props.participant.maxAge) {
                        const age = moment(details?.startDate).diff(value._d, 'month');
                        if (age > refField.props.participant.maxAge * 12) {
                            refField.setError(intl.formatMessage({ id: 'booking.participantMinMaxAge' }, { minAge: 0, maxAge: Math.floor(refField.props.participant.maxAge * 10) / 10 }));
                            hasError = true;
                            error = true;
                        }
                    }
                }

                if (required && !error) {
                    if (refField.state.error) {
                        hasError = true;
                    } else {
                        refField.unSetError();
                    }

                    if (!value || value.length <= 0) {
                        hasError = true;
                        refField.setError(intl.formatMessage({ id: 'booking.error' }));
                    }
                }

                if (hasError === false && value) {
                    refField.unSetError();
                    if (value._isAMomentObject) value = value.format('YYYY-MM-DDT00:00:00');
                    if (fieldName === 'language') fieldName = 'languageId';
                    if (fieldName === 'country') fieldName = 'countryId';
                    if (refField.props.participant.fieldType === 'select') {
                        try {
                            try {
                                value = {
                                    value: refField.props.participant.values[value].value,
                                    name: refField.props.participant.values[value].label,
                                };
                            } catch (error2) {
                                value = {
                                    value: refField.props.participant.values.find(
                                        (v: any) => v.value === value,
                                    ).value,
                                    name: refField.props.participant.values.find(
                                        (v: any) => v.value === value,
                                    ).label,
                                };
                            }
                        } catch (error1) {
                            refField.setError(intl.formatMessage({ id: 'booking.error' }));
                            hasError = true;
                        }
                    }
                    if (refField.props.participant.fieldType === 'text') value = value.trim();
                    participantInfos[`${fieldName}`] = value;
                }
            });
            if (selectedParticipants[i] !== '-100000' && selectedParticipants[i] !== 'y') {
                participantInfos.p_id = selectedParticipants[i];
                participantInfos.Id = participantInfos.p_id;
            }

            allParticipantsInfos.push(participantInfos);
        });

        if (hasError === false) {
            handleExpandable();
            dispatch(setParticipantsInfos(allParticipantsInfos));
        }
    }

    getSelectedParticipants = (participants:any[]) => {
        const { nbParticipants } = this.props;
        return [...Array(nbParticipants)].map(() => (participants.length > 0 ? '-200000' : '-100000'));
    };

    getParticipants = () => {
        const participants = this.props.bcClient?.Participants ? cloneDeep(this.props.bcClient?.Participants) : [];
        const jsonCurrentCart = localStorage?.getItem('cart');
        const currentCart: LessonCart[] = JSON.parse(jsonCurrentCart || '[]');
        if (currentCart !== null) {
            currentCart.forEach((c) => c.participants_infos.forEach((p) => {
                if (isNumber(p.p_id) && Number(p.p_id) < 0) {
                    const part = {
                        Id: p.Id,
                        p_id: p.p_id,
                        Name: p.name,
                        Firstname: p.firstname,
                        Address: p.address,
                        AddressLocal: p.addresslocal,
                        Zip: p.zip,
                        Town: p.town,
                        Email: p.email,
                        Phone: p.phone,
                        Mobile: p.mobile,
                        Sex: p.sex ? p.sex.value : undefined,
                        Age: p.age,
                        Birthdate: p.birthdate,
                        LanguageId: p.languageid ? p.languageid.value : undefined,
                        CountryId: p.countryid ? p.countryid.value : undefined,
                        Weight: p.weight,
                        Height: p.height,
                        FootSize: p.footsize,
                        Avs: p.avs,
                        Medals: []
                    };
                    participants.push(part);
                }
            }));
        }

        return removeDuplicatesByKey(participants, 'Id');
    };

    formatSelectParticipant = () => {
        const { intl } = this.props;
        const { participants } = this.state;

        const options = participants.map((participant: Participant, index: number) => {
            const idx = index;
            return (<MenuItem key={`select_participant_${idx}_${participant.Id}`} value={participant.Id}>{`${participant.Firstname} ${participant.Name}`}</MenuItem>);
        });
        options.unshift(<MenuItem key="select_participant_disabled" value="-100000">{intl.formatMessage({ id: 'booking.newParticipant' })}</MenuItem>);
        options.unshift(<MenuItem disabled key="select_participant_none" value="-200000">{intl.formatMessage({ id: 'booking.pickParticipant' })}</MenuItem>);
        return options;
    };

    handleChangeParticipant = (event: any, i: string) => {
        const { selectedParticipants, participants } = this.state;
        selectedParticipants[i] = event.target.value;
        // Reset fields content
        Object.keys(this.fields).forEach((key) => {
            const [, id] = key.split('_');
            if (id !== i) return;
            if (this.fields[key].current && this.fields[key].current.props.participant.fieldType === 'select') {
                this.fields[key].current.setValue('-1', 'select', 'value');
            } else if (this.fields[key].current) {
                this.fields[key].current.setValue('', this.fields[key].current.props.participant.fieldType);
            } else {
                Sentry.captureMessage(`handleChangeParticipant ${key} : ${String(this.fields[key].current)}`);
            }
        });
        if (event.target.value !== '-100000' && event.target.value !== '-200000') {
            const selection = participants.find((p:any) => p.Id === event.target.value);
            Object.keys(this.fields).forEach((key) => {
                const [name, id] = key.split('_');
                if (id !== i) return;

                if (selection[name] !== '0001-01-01T00:00:00') {
                    selection[name] && this.fields[key].current.setValue(selection[name], this.fields[key].current.props.participant.fieldType, 'value');
                }
            });
        }
        this.setState({ selectedParticipants });
    };

    formatParticipant(nbParticipants: number) {
        const participants = [];
        const { inputParticipant, lesson, onChangeIfEmpty } = this.props;
        for (let i = 0; i < nbParticipants; i++) {
            const participant = inputParticipant.map((pi) => {
                const participnt = pi;
                if (participnt.fieldType === 'text' && participnt.values && participnt.values.length > 0) participnt.fieldType = 'select';
                if (lesson?.school.id === 3) participnt.required = true;
                return (
                    <div key={`${participnt.fieldName}_${i}`} className="__booking-participants-field">
                        <ParticipantField
                            onChangeIfEmpty={onChangeIfEmpty}
                            participantID={i}
                            participant={participnt}
                            ref={this.fields[`${participnt.fieldName}_${i}`]}
                        />
                    </div>
                );
            });
            participants.push(participant);
        }
        return participants;
    }

    render() {
        const { inputParticipant, intl } = this.props;
        const { formatedInputParticipant, selectedParticipants, participants } = this.state;
        return (
            <div className="__booking-participants-parent">
                <div className="__booking-participants-fields">
                    {
                        inputParticipant.length > 0
                            ? formatedInputParticipant.map((component: JSX.Element, i: number) => {
                                const idx = i;
                                return (
                                    <div className="__booking-participants-fields-content" key={`participant_section_${idx}`}>
                                        {
                                            idx !== 0
                                                ? <div className="__booking-participants-divider" />
                                                : null
                                        }
                                        <div className="__booking-participants-header">
                                            <p className="__booking-participants-title">{`${intl.formatMessage({ id: 'booking.Participant' })} ${idx + 1}:`}</p>
                                            {
                                                participants.length > 0

                                                    ? (
                                                        <SelectField
                                                            border
                                                            // sx={{
                                                            //     border: selectedParticipants[idx] === '-200000' ? '2px solid red' : 'inherit',
                                                            //     maxWidth: '40%',
                                                            //     height: 44,
                                                            //     fontSize: 16
                                                            // }}
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'rgba(0, 0, 0, 0.23) 1px solid',
                                                                },
                                                                '& .Mui-disabled': {
                                                                    color: 'white',
                                                                    cursor: 'pointer',
                                                                },
                                                                maxWidth: '100%',
                                                                width: '40%',
                                                                fontFamily: 'Montserrat',
                                                                fontSize: '16px',
                                                                padding: '0px 28px 0px 0px',
                                                                height: 44,
                                                                // color: disabled ? '#9C9C9D' : 'var(--font-input-color)',
                                                                // cursor: disabled ? 'not-allowed' : 'pointer',
                                                                // height: 'auto',
                                                            }}
                                                            fontSize="16px"
                                                            height={44}
                                                            parentClasses={`__booking-participants-select ${selectedParticipants[idx] === '-200000' ? '__booking-participants-select-must' : ''}`}
                                                            onChange={(e:any) => this.handleChangeParticipant(e, String(idx))}
                                                            value={selectedParticipants[idx] || '-200000'}
                                                        >
                                                            {this.formatSelectParticipant()}
                                                        </SelectField>
                                                    )
                                                    : null
                                            }
                                        </div>
                                        <div style={selectedParticipants[idx] === '-200000' ? { display: 'None' } : {}}>
                                            <div className="__booking-participants-component">
                                                {component}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            : <LoadingCircle />
                    }
                </div>
                <div className="__booking-participants-button">
                    <Button
                        disabled={inputParticipant.length <= 0 || selectedParticipants.findIndex((f: any) => f === '-200000') !== -1}
                        onClick={this.handleClickNext}
                    >
                        {intl.formatMessage({ id: 'booking.next' })}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    details: store.booking.selectedLessonDetail,
    inputParticipant: store.booking.inputParticipant,
    bcClient: store.setting.bcClient,
    lesson: store.booking.currentLesson,
    isFetchFreeInstructorsLoading: store.booking.isFetchFreeInstructorsLoading,
});

const connector = connect(mapStateToProps);

export default injectIntl(connector(Participants));
