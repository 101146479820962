import axios from 'axios';
import Cookie from 'js-cookie';
import moment from 'moment';
import { getDomain } from '../../resources/utils';

let Token = 'undefined';

let API = process.env.REACT_APP_BACKENDS_PRODUCTS_URL;
if (!API) {
    API = 'https://product.swisskischool.ch';
}

const getPublicToken = async (): Promise<string> => {
    const loginaxiosapi = axios.create({
        baseURL: API,
        timeout: 40000,
        headers: { 'Content-Type': 'application/json' },
    });
    const response = await loginaxiosapi.post(`${API}/api/token`, {
        username: 'front',
        password: 'gE6WU8kA',
    });
    if (response.data && response.data.access && typeof response.data.access === 'string') {
        const accessToken = response.data.access;
        Cookie.set('authentication', JSON.stringify(accessToken), { domain: getDomain(), expires: moment().add(59, 'minutes').toDate() });
        return accessToken;
    } return 'undefined';
};

if (Cookie.get('authentication') !== undefined) {
    Token = JSON.parse(Cookie.get('authentication') || 'undefined');
} else {
    Token = await getPublicToken();
}

const axiosapi = axios.create({
    baseURL: API,
    timeout: 40000,
    headers: { 'Content-Type': 'application/json' },
});

axiosapi.defaults.headers.common.Authorization = `Bearer ${Token}`;

axiosapi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const {
            config,
        } = error;
        const {
            response,
        } = error;

        const { status, data } = response;

        if (status && status === 401 && (data.code === 'bad_authorization_header' || data.code === 'token_not_valid')) {
            const accessToken = await getPublicToken();
            config.headers.Authorization = `Bearer ${accessToken}`;
            axiosapi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return axiosapi.request(config);
        }
        return Promise.reject(error);
    },
);

export default axiosapi;
