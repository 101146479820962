import { ImageList, ImageListItem, Modal } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { GOOGLE_STORAGE_API, SMARTPHONE_WIDTH } from '../../resources/constants';
import { fetchImagesForLessons } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import Expandable from '../common/expandable';
import LoadingCircle from '../common/loadingCircle';

/**
 * Component that represent the photos library tab of the my account page
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    fullscreenPhoto?: string;
}

class PhotosLibrary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fullscreenPhoto: undefined
        };
    }

    componentDidMount() {
        const { lessonsImages, runFetchImagesForLessons } = this.props;
        if (Object.keys(lessonsImages).length === 0) { runFetchImagesForLessons(); }
    }

    openPhoto = (photo: string) => {
        this.setState({ fullscreenPhoto: photo });
    };

    closePhoto = () => {
        this.setState({ fullscreenPhoto: undefined });
    };

    render() {
        const {
            lessonsImages, intl, isLoadingImages, windowWidth,
        } = this.props;
        const { fullscreenPhoto } = this.state;
        const years = Object.keys(lessonsImages).reverse();

        // ? Avoid nested ternary

        let returnElement;

        if (isLoadingImages) {
            returnElement = (
                <LoadingCircle />
            );
        } else if (years.length > 0) {
            returnElement = (
                years.map((year) => (
                    <Expandable title={year} key={`account_library_${year}`}>
                        <ImageList sx={{ margin: '10px' }} rowHeight={200} cols={windowWidth > SMARTPHONE_WIDTH ? 3 : 2} gap={8}>
                            {
                                lessonsImages[year].map((image: any) => (
                                    <ImageListItem key={`account_library_tile_${image}`}>
                                        {/* eslint-disable-next-line
                                jsx-a11y/click-events-have-key-events,
                                jsx-a11y/no-noninteractive-element-interactions */}
                                        <img className="__account-photos-photo" src={GOOGLE_STORAGE_API + image} onClick={() => this.openPhoto(GOOGLE_STORAGE_API + image)} alt="account" />
                                    </ImageListItem>
                                ))
                            }
                        </ImageList>
                    </Expandable>
                ))
            );
        } else {
            returnElement = (
                <p className="__account-photos-no-image">{intl.formatMessage({ id: 'account.photos.noImage' })}</p>
            );
        }

        return (
            <div>
                <div className="__account-photos-header">
                    <p className="__account-photos-title">{intl.formatMessage({ id: 'account.photosTitle' })}</p>
                    <p className="__account-photos-text">{intl.formatMessage({ id: 'account.photosText' })}</p>
                </div>
                {
                    returnElement
                }
                <Modal open={fullscreenPhoto !== undefined} onClose={this.closePhoto}>
                    <div className="__account-photos-fullscreen-container">
                        <img className="__account-photos-fullscreen" src={fullscreenPhoto} alt="account" />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
    isLoadingImages: store.user.isLoadingImages,
    lessonsImages: store.user.lessonsImages,
    myLessons: store.user.myLessons,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runFetchImagesForLessons: () => dispatch(fetchImagesForLessons()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(connector(PhotosLibrary));
