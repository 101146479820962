import { CircularProgress } from '@mui/material';

interface IProps {
    width?: string;
    height?: string;
}

type Props = IProps;

// const defaultProps = {
//     width: '',
//     height: '',
// };

function LoadingCircle(props: Props) {
    const { width, height } = props;
    return (
        <div className="circular-progress">
            <CircularProgress style={{ color: '#3d84c3', width: width || '40px', height: height || '40px' }} />
        </div>
    );
}

// LoadingCircle.defaultProps = defaultProps;

export default LoadingCircle;
