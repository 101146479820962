import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import { openSignUpForm } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import SearchBar from '../landing/searchBar';
import SignUp from '../login/signup';
import ErrorSnackbar from './errorSnackbar';
import Footer from './footer';
import Hamburger from './hamburger';
import Header from './header';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    headerBase?: any;
    mainHeader?: boolean;
    noPadding?: boolean;
    withPlaceholder?: any;
    children: ReactNode;
    image?: string;
    texts?: string[];
    schoolName?: string;
}

type Props = IProps & ReduxProps & RouterProps;

interface State { }

class Container extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     headerBase: undefined,
    //     mainHeader: false,
    //     noPadding: false,
    //     withPlaceholder: undefined,
    //     image: '',
    //     texts: [],
    //     schoolName: '',
    // };

    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        const { match, runOpenSignUpForm } = this.props;
        if (!Authenticator.isLoggedIn() && match && match.params.login) {
            runOpenSignUpForm();
        }
    }

    render() {
        const {
            headerBase,
            mainHeader,
            withPlaceholder,
            noPadding,
            children,
            image,
            texts,
            schoolName,
        } = this.props;

        let headerBaseTemp = null;

        if (headerBase) {
            if (headerBase.type === SearchBar) {
                headerBaseTemp = <div className="__header-base-search-bar">{headerBase}</div>;
            } else {
                headerBaseTemp = <p className="__header-base-text">{headerBase}</p>;
            }
        }

        return (
            <div className="main-container">
                <div id="top" />
                <Header className={`__header-parent-with${headerBase ? '' : 'out'}-search  ${mainHeader === true ? '__header-parent-main-header' : ''}`} withPlaceholder={withPlaceholder} image={image!} texts={texts} schoolName={schoolName}>
                    {
                        headerBaseTemp
                    }
                </Header>
                <Hamburger />
                {
                    noPadding ? (
                        <div className="container-content" style={{ padding: '0px' }}>
                            {children}
                        </div>
                    )
                        : (
                            <div className="container-content">
                                {children}
                            </div>
                        )
                }
                <SignUp />
                {/* <PWAModal /> */}
                <ErrorSnackbar />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    showSignUp: store.user.isSignUpVisible,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    // runSetImagesForLessons: (image: string[]) => dispatch(setImagesForLessons(image)),
    // runChangeMyAccountCurrentTab: (currentTab: string) => dispatch(changeMyAccountCurrentTab(currentTab)),
    runOpenSignUpForm: () => dispatch(openSignUpForm()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Container));
