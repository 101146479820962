import { List, ListItem } from '@mui/material';
import { Moment } from 'moment';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import '../../resources/css/settings.css';
import { SettingsItem } from '../../types/account';
import SettingsCard from './settingsCard';
/**
 * Component that represent a settings section
 */
interface IProps {
    items: SettingsItem[] | null;
    function(
        value: string[] | string | Moment | undefined,
        category: string
    ): void;
}

type Props = IProps & WrappedComponentProps;

const SettingsSection = injectIntl((props: Props) => (
    <List>
        {
            props.items && props.items.map((item, index) => {
                const idx = index;
                return (
                    <span key={`${item.title}-${idx}`}>
                        {idx === 0 ? null : <div className="__settings-section-divider" />}
                        <ListItem className="__settings-section-item">
                            <SettingsCard item={item} awesomeFunction={props.function} />
                        </ListItem>
                    </span>
                );
            })
        }
    </List>
));

export default SettingsSection;
