import { TextareaAutosize } from '@mui/material';
import React from 'react';
import { ReactSVG } from 'react-svg';
import '../../resources/css/fields.css';
import visibilityImage from '../../resources/img/global/visibility.svg';
import visibilityOffImage from '../../resources/img/global/visibility_off.svg';

/**
* Component that represent a text field
*
* Props:
*  - type: the type of the input
*  - image: the svg image to use
*  - placeholder: the palceholder text
*  - textarea: to indicate if the textfield must be extandable /!\ doesn't work with type='password'
*  - onChange: a function called everytime the input change
*  - buttonHandler: a function if the svg is also a button
*  - touched:
*  - error: the error message
*/
interface IProps {
    type?: string;
    defaultValue?: string;
    forceValue?: boolean;
    value?: string;
    image?: string;
    containerClasses?: string;
    className?: string;
    error?: string;
    textarea?: boolean;
    title?: string;
    placeholder?: string;
    input?: any;
    disabled?: boolean;
    buttonHandler?: any;
    style?: any;
    right?: boolean;
    id?: string;
    asText?: boolean;
    required?: boolean;
    name?: string;
    onClick?: () => void;
    onFocus?: () => void;
    updateValue?: (value?: string) => void;
    onChange?: (e: any) => void;
}

type Props = IProps;

interface State {
    type?: string;
    visibility: boolean;
    value?: string;
}

export default class TextField extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     type: '',
    //     defaultValue: '',
    //     forceValue: false,
    //     value: '',
    //     image: '',
    //     containerClasses: '',
    //     className: '',
    //     error: '',
    //     textarea: false,
    //     title: '',
    //     placeholder: '',
    //     input: undefined,
    //     disabled: false,
    //     buttonHandler: undefined,
    //     style: undefined,
    //     right: false,
    //     id: '',
    //     asText: false,
    //     required: false,
    //     name: '',
    //     onClick: undefined,
    //     onFocus: undefined,
    //     updateValue: undefined,
    //     onChange: undefined,
    // };

    constructor(props: Props) {
        super(props);

        const {
            type, defaultValue, forceValue, value,
        } = this.props;

        this.state = {
            type,
            visibility: false,
            value: defaultValue || (forceValue ? value : undefined),
        };

        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    onChange = (event: any) => {
        const { value } = event.target;
        const { updateValue } = this.props;
        const { value: valueState } = this.state;

        this.setState({ value }, () => {
            if (updateValue) updateValue(valueState);
        });
    };

    toggleVisibility() {
        this.setState((state) => ({
            type: state.type === 'text' ? 'password' : 'text',
            visibility: !state.visibility,
        }));
    }

    render() {
        const {
            image,
            containerClasses,
            onClick,
            onFocus,
            error,
            textarea,
            title,
            forceValue,
            placeholder,
            input,
            disabled,
            value,
            type,
            className,
            onChange,
            buttonHandler,
        } = this.props;
        const { type: typeState, visibility } = this.state;
        const hasNoSVG = !image && type !== 'password';
        return (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */
            <div className={`__textfield-parent ${containerClasses || ''}`} onClick={onClick} onFocus={onFocus}>
                <div className={`__textfield-content ${error ? '__textfield-content-error' : ''} ${className || ''}`}>
                    {
                        textarea
                            ? (
                                <TextareaAutosize
                                    title={title}
                                    value={value || (forceValue ? value : value)}
                                    onChange={(e) => {
                                        this.onChange(e);
                                        if (onChange) onChange(e);
                                    }}
                                    className={`${hasNoSVG ? '__textfield-textarea-no-svg' : '__textfield-textarea'}`}
                                    type={typeState}
                                    placeholder={placeholder}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...input}
                                />
                            )
                            : (
                                <input
                                    title={title}
                                    value={value || (forceValue ? value : value)}
                                    onChange={(e) => {
                                        this.onChange(e);
                                        if (onChange) onChange(e);
                                    }}
                                    disabled={disabled}
                                    className={`${hasNoSVG ? '__textfield-input-no-svg' : '__textfield-input'}`}
                                    type={typeState}
                                    placeholder={placeholder}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...input}
                                />
                            )
                    }
                    {
                        hasNoSVG
                            ? null
                            : (
                                <div className="__textfield-icon-container">
                                    {
                                        type === 'password'
                                            ? <ReactSVG className="__textfield-icon-image __textfield-icon-button-image" src={visibility ? visibilityImage : visibilityOffImage} onClick={this.toggleVisibility} />
                                            : <ReactSVG className={`__textfield-icon-image ${buttonHandler ? '__textfield-icon-button-image' : ''}`} src={image!} onClick={buttonHandler} />
                                    }
                                </div>
                            )
                    }
                </div>
                {
                    error
                        ? <p className="__textfield-error">{error}</p>
                        : null
                }
            </div>
        );
    }
}
