import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchMessages } from '../../store/actions/message';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import BottomBar from '../common/bottombar';
import Menubar from '../common/menubar';
import MessageItemCard from './card';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch
}

type Props = IProps & ReduxProps & RouterProps;

interface State { }

class MessageList extends React.Component<Props, State> {
    componentDidMount() {
        const { dispatch, history } = this.props;
        const isLoggedIn = Authenticator.isLoggedIn();

        if (isLoggedIn) {
            dispatch(fetchMessages(0));
        } else {
            history.replace('/');
        }
    }

    getNewsFeedItems() {
        const listItems: any = [];
        const { data } = this.props;
        for (const item of data) {
            listItems.push(
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                jsx-a11y/no-noninteractive-element-interactions */
                <li onClick={(e) => this.itemClickHandler(e, item.id)} key={item.id}>
                    <MessageItemCard />
                </li>,
            );
        }
        if (listItems.length > 0) {
            return listItems;
        }
        return (<div>There are no messages for you</div>);
    }

    itemClickHandler(event: any, id: number) {
        const { location, history } = this.props;
        const href = location.pathname;
        if (href[href.length - 1] === '/') {
            history.push(href + id);
        } else {
            history.push(`${href}/${id}`);
        }
    }

    render() {
        const { history } = this.props;
        return (
            <section className="newsfeed-container is-fullheight">
                <Menubar />
                <ul className="__newsfeed-list">{this.getNewsFeedItems()}</ul>
                <BottomBar history={history} />
            </section>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    data: store.message.messages,
    page: store.message.page,
    isNewsfeedLoading: store.message.isNewsfeedLoading,
    loading: store.message.onEndReached,
    pageEnd: store.message.pageEnd,
});
const connector = connect(mapStateToProps);

export default connector(MessageList);
