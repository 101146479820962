import '../../resources/css/fields.css';

interface IProps {
    children: any;
    className?: string;
    notH?: boolean;
    onClick?: () => void;
}

type Props = IProps;

// const defaultProps = {
//     className: '',
//     notH: false,
//     onClick: () => { },
// };

function Title(props: Props) {
    const {
        className, onClick, notH, children,
    } = props;
    return (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-static-element-interactions */
        <div className={`__title ${className || ''}`} onClick={onClick}>
            {
                notH
                    ? <p>{children}</p>
                    : <h2>{children}</h2>
            }
        </div>
    );
}

// Title.defaultProps = defaultProps;

export default Title;
