import { FormControl } from '@mui/material';
import React, { Component } from 'react';
import Checkbox from './checkbox';

interface IProps {
    inputRef?: any;
    defaultChecked?: boolean;
    clear?: boolean;
    id?: string;
    classNameCheckBox: string;
    inputClasses?: string;
    labelClasses?: string;
    name?: string;
    label: string;
    inputFirst?: any;
    error?: string;
    onChange(checkbox: any): void;
}

type Props = IProps;

interface State {
    checked: boolean;
}

class CheckboxControl extends Component<Props, State> {
    ref: any;

    constructor(props: Props) {
        super(props);

        const { inputRef } = this.props;

        this.ref = inputRef || React.createRef();
        this.state = { checked: false };
    }

    componentDidMount() {
        const { defaultChecked } = this.props;
        if (defaultChecked) {
            this.handleChange();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { clear, defaultChecked } = this.props;
        if (prevProps.clear !== clear) {
            if (clear) {
                this.handleClear();
            }
        }
        if (prevProps.defaultChecked !== defaultChecked) {
            this.setState({ checked: !!defaultChecked });
        }
    }

    handleChange = () => {
        const { checked } = this.state;
        const { onChange } = this.props;
        const checkbox = {
            checked: !checked,
            name: this.ref.current.props.name,
            label: this.ref.current.props.label,
        };
        this.setState((state) => ({ checked: !state.checked }));
        onChange(checkbox);
    };

    handleClear = () => {
        this.setState({ checked: false });
    };

    render() {
        const {
            id, classNameCheckBox, inputClasses, labelClasses, label, inputFirst, error, name
        } = this.props;
        const { checked } = this.state;
        return (
            <FormControl key={id} style={{ width: '100%' }}>
                <Checkbox
                    className={classNameCheckBox}
                    inputClasses={inputClasses}
                    labelClasses={labelClasses}
                    checked={checked}
                    onChange={this.handleChange}
                    name={name}
                    ref={this.ref}
                    label={label}
                    inputFirst={inputFirst}
                />
                {
                    error
                        ? <p className="__textfield-error">{error}</p>
                        : null
                }
            </FormControl>
        );
    }
}

export default CheckboxControl;
