import { Modal } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouterProps } from '../../types/generalTypes';
import { School } from '../../types/settings';
import Button from '../common/button';
import Title from '../common/title';

interface IProps {
    open: boolean;
    school: School;
}

type Props = IProps & RouterProps & WrappedComponentProps;

interface State {
    open: boolean;
}

class AlertModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { open } = this.props;

        this.state = {
            open,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.goToCart = this.goToCart.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const { open } = this.props;
        if (prevProps !== this.props) {
            this.setState({ open });
        }
    }

    toggleOpen() {
        this.setState((state) => ({ open: !state.open }));
    }

    goToCart() {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/cart`);
    }

    render() {
        const { intl, school } = this.props;
        const { open } = this.state;
        return (
            <Modal open={open} onClose={this.toggleOpen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="__booking-alert-parent">
                    <Title className="__booking-alert-title">{intl.formatMessage({ id: 'booking.alert.title' })}</Title>
                    <div className="__booking-alert-text">
                        <p>{intl.formatMessage({ id: 'booking.alert.firstText' })}</p>
                        <p className="__booking-alert-school">{` ${school}.`}</p>
                        <p className="__booking-alert-second">{intl.formatMessage({ id: 'booking.alert.secondText' })}</p>
                    </div>
                    <div className="__booking-alert-button-container">
                        <Button buttonClasses="__booking-alert-button" onClick={this.goToCart}>{intl.formatMessage({ id: 'booking.goToCart' })}</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const connector = connect();

export default injectIntl(withRouter(connector(AlertModal)));
