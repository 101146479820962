import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LESSON_FILTERS } from '../../resources/constants';
import { setRemarks, setSelectedLessonDetail } from '../../store/actions/booking';
import { ApplicationState, StoreDispatch } from '../../store/types';
import {
    LessonCart,
    Option
} from '../../types/booking';
import { RouterProps } from '../../types/generalTypes';
import Button from '../common/button';
import TextField from '../common/textfield';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    onCartAdded: () => void;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State { }

function calculateTotalOptionsPrice(options: Option[]): number {
    const initialPrice = 0;
    const totalOptionsPrice = options.reduce((
        accumulator,
        currentValue,
    ) => {
        if (currentValue.qty !== -1) {
            return accumulator + (currentValue.qty * (typeof currentValue.price === 'string' ? parseFloat(currentValue.price) : currentValue.price));
        }
        return accumulator + (typeof currentValue.total === 'string' ? parseFloat(currentValue.total) : currentValue.total);
    }, initialPrice);
    return totalOptionsPrice;
}

class Remarks extends React.Component<Props, State> {
    handleChangeRemarks = (event: any) => {
        const { dispatch } = this.props;
        const text = event.target.value;
        dispatch(setRemarks(text));
    };

    handleAddToCart = (goCart:boolean = true) => {
        const {
            lesson,
            lessonDetail,
            participantsInfos,
            options,
            questions,
            remarks,
            selectedInstructor,
            dispatch,
            onCartAdded,
            history,
            match,
        } = this.props;

        let days = 0;
        const jsonCart = localStorage?.getItem('cart');
        let cart: LessonCart[] = JSON.parse(jsonCart || '[]');

        if (lessonDetail && lesson) {
            if (lesson?.lesson_type === LESSON_FILTERS.GROUP_LESSON) {
                days = lessonDetail?.days;
            } else {
                days = lessonDetail?.days[lessonDetail.selectedDayIndex];
            }

            const startTimeIndex = lessonDetail.selectedStartTimeIndex;
            const endTimeIndex = lessonDetail.selectedEndTimeIndex;
            const participants = participantsInfos?.map((p) => {
                if (!p.p_id) {
                    const minIdentifier = localStorage ? Number(localStorage.getItem('minIdentifier')) - 1 : -1;
                    localStorage.setItem('minIdentifier', String(minIdentifier));
                    return {
                        ...p,
                        p_id: String(minIdentifier),
                        Id: String(minIdentifier),
                    };
                }

                return p;
            });
            const product: LessonCart = {
                days,
                participants: lessonDetail.participants,
                price: lessonDetail.price,
                totalOptionsPrice: calculateTotalOptionsPrice(options),
                date: {
                    from_date: moment(lessonDetail.startDate),
                    to_date: moment(lessonDetail.endDate),
                },
                time: {
                    from_time: lessonDetail.times[startTimeIndex].startTime,
                    to_time: lessonDetail.times[startTimeIndex].endTimes[endTimeIndex],
                },
                level: lessonDetail.level,
                meeting_point: lessonDetail.meetingPoints[lessonDetail.selectedMeetingPointIndex],
                title: lesson?.title,
                school_id: lesson?.school,
                external_id: lesson?.external_id,
                activity_type: lesson?.activity_type,
                activity_id: lesson?.external_activity_id,
                lesson_type: lesson?.lesson_type,
                lesson_id: lesson.id,
                participants_infos: participants || [],
                options,
                questions,
                remarks: remarks && remarks.length > 0 ? remarks.trim() : '',
                hours: lessonDetail?.hours,
                instructor: Object.keys(selectedInstructor).length > 0 ? selectedInstructor : null,
            };

            if (cart !== null) {
                cart.push(product);
            } else {
                cart = [product];
            }
        }

        // let cart = Cookie.get('cart');

        // Cookie.set('cart', JSON.stringify(cart), { domain: getDomain() });
        localStorage?.setItem('cart', JSON.stringify(cart));

        dispatch(setSelectedLessonDetail({}));
        if (goCart) {
            history.push(`/${match.params.lang}/cart`);
        } else {
            onCartAdded();
        }
    };

    render() {
        const { intl } = this.props;
        return (
            <div className="__booking-remarks-parent">
                <div className="__booking-remarks-textfield">
                    <TextField
                        onChange={this.handleChangeRemarks}
                        placeholder={intl.formatMessage({ id: 'booking.remarksPlaceholder' })}
                        textarea
                    />
                </div>
                <div className="__booking-remarks-button-container">
                    <Button
                        onClick={this.handleAddToCart}
                        buttonClasses="__booking-remarks-button"
                        containerClasses="__booking-remarks-button-container2"
                    >
                        {intl.formatMessage({ id: 'booking.addToCart' })}
                    </Button>
                    <Button
                        onClick={() => this.handleAddToCart(false)}
                        buttonClasses="__booking-remarks-button"
                        containerClasses="__booking-remarks-button-container2"
                    >
                        {intl.formatMessage({ id: 'booking.addToCartAndContinue' })}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    lesson: store.booking.currentLesson,
    lessonDetail: store.booking.selectedLessonDetail,
    participantsInfos: store.booking.participantsInfos,
    options: store.booking.selectedOptions,
    questions: store.booking.selectedQuestions,
    remarks: store.booking.remarks,
    selectedInstructor: store.booking.selectedInstructor,
});
const connector = connect(mapStateToProps);

export default withRouter(injectIntl(connector(Remarks)));
