import {
    TYPE_MESSAGES_LIST_FAILURE,
    TYPE_MESSAGES_LIST_INIT,
    TYPE_MESSAGES_LIST_SUCCESS,
    TYPE_MESSAGE_SEND_FAILURE,
    TYPE_MESSAGE_SEND_INIT,
    TYPE_MESSAGE_SEND_SUCCESS
} from '../reducers/message';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';

import { get, post } from './api';

export const fetchMessages = (page: any) => (dispatch: any) => {
    if (!page || Number(page) === 0) {
        dispatch({
            type: TYPE_MESSAGES_LIST_INIT,
        });
    }
    const url = 'notification/message/';
    dispatch(get(
        url,
        (response: any) => {
            dispatch({
                type: TYPE_MESSAGES_LIST_SUCCESS,
                data: response,
                page,
            });
        },
        () => {
            dispatch({
                type: TYPE_MESSAGES_LIST_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const sendMessage = (message: any) => (dispatch: any) => {
    dispatch({
        type: TYPE_MESSAGE_SEND_INIT,
    });

    const url = 'notification/message/';
    dispatch(post(
        url,
        message,
        () => {
            dispatch({
                type: TYPE_MESSAGE_SEND_SUCCESS,
                data: message,
            });
        },
        () => {
            dispatch({
                type: TYPE_MESSAGE_SEND_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};
