import { getCurrentVersion } from '../../resources/utils';
import {
    TYPE_COMMENTS_INIT,
    TYPE_COMMENT_FAILURE,
    TYPE_COMMENT_SEND_FAILURE,
    TYPE_COMMENT_SEND_INIT,
    TYPE_COMMENT_SEND_SUCCESS,
    TYPE_COMMENT_SUCCESS,
    TYPE_GET_NEWSFEED,
    TYPE_GET_NEWSFEED_FAILURE,
    TYPE_GET_NEWSFEED_SUCCESS,
    TYPE_INIT_NEWSFEED,
    TYPE_LIKE_FAILURE,
    TYPE_LIKE_INIT,
    TYPE_LIKE_POST,
    TYPE_LIKE_POST_FAILURE,
    TYPE_LIKE_POST_SUCCESS,
    TYPE_LIKE_SUCESS,
} from '../reducers/newsfeed';
import { TYPE_CHANGE_ERROR_CATCHED } from '../reducers/user';
import { API_ROUTES, get, post } from './api';

export const initNewsfeed = () => (dispatch: any) => {
    dispatch({
        type: TYPE_INIT_NEWSFEED,
    });
};

export const fetchNewsfeed = (page: number, id: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_NEWSFEED,
    });

    let url = `${API_ROUTES.getNewsfeed}?page=${page}&is_client=True`;
    if (id && id > 0) {
        url += `&id=${id}`;
    }

    dispatch(get(
        url,
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_NEWSFEED_SUCCESS,
                data: responseJson,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_NEWSFEED_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const fetchNewsfeeds = (page: number, companyId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_NEWSFEED,
    });

    const url = `${API_ROUTES.getNewsfeed}?page=${page}&is_client=True&version=${getCurrentVersion()}`;

    dispatch(post(
        url,
        { company_id: companyId },
        (responseJson: any) => {
            dispatch({
                type: TYPE_GET_NEWSFEED_SUCCESS,
                data: responseJson,
            });
        },
        () => {
            dispatch({
                type: TYPE_GET_NEWSFEED_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const likeCurrentPost = (postId: number, likeActive: any) => (dispatch: any) => {
    const requestData = {
        news_id: postId,
        like_active: likeActive,
    };

    dispatch({
        type: TYPE_LIKE_POST,
    });

    dispatch(post(
        API_ROUTES.newsfeedLike,
        requestData,
        () => {
            dispatch({
                type: TYPE_LIKE_POST_SUCCESS,
                postId,
                likeActive,
            });
        },
        () => {
            dispatch({
                type: TYPE_LIKE_POST_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const getPostComments = (postId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_COMMENTS_INIT,
    });

    dispatch(get(
        `${API_ROUTES.newsfeedComments}?news_id=${postId}`,
        (response: any) => {
            dispatch({
                type: TYPE_COMMENT_SUCCESS,
                data: response,
            });
        },
        () => {
            dispatch({
                type: TYPE_COMMENT_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const getPostLikes = (postId: number) => (dispatch: any) => {
    dispatch({
        type: TYPE_LIKE_INIT,
    });

    dispatch(get(
        `${API_ROUTES.newsfeedLike}?pid=${postId}`,
        (response: any) => {
            dispatch({
                type: TYPE_LIKE_SUCESS,
                data: response,
            });
        },
        () => {
            dispatch({
                type: TYPE_LIKE_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};

export const postComment = (postId: number, text: string) => (dispatch: any) => {
    dispatch({
        type: TYPE_COMMENT_SEND_INIT,
    });

    const data = {
        news_id: postId,
        text,
    };
    dispatch(post(
        API_ROUTES.sendComment,
        data,
        (response: any) => {
            dispatch({
                type: TYPE_COMMENT_SEND_SUCCESS,
                data: response,
                postId,
            });
        },
        () => {
            dispatch({
                type: TYPE_COMMENT_SEND_FAILURE,
            });
            dispatch({
                type: TYPE_CHANGE_ERROR_CATCHED,
                data: true,
            });
        },
    ));
};
