import React from 'react';
import { withRouter } from 'react-router';
import { RouterProps } from '../../types/generalTypes';

/**
 * Component that add the language if missing
 */

type Props = RouterProps;

interface State { }

class AddLanguage extends React.Component<Props, State> {
    componentDidMount() {
        const { match, location, history } = this.props;
        // check if there is a language, if not add french as default
        const { lang } = match.params;
        if (!lang) {
            const path = location.pathname + location.search;
            const final = (path === '' ? '/fr/' : '/fr') + path;
            history.push(final);
            window.location.reload();
        }
    }

    render() {
        return null;
    }
}

export default withRouter(AddLanguage);
