import { StyledEngineProvider } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {
    deDE, enUS, frFR, itIT
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import '../../resources/css/fields.css';
import { getTheme } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import LoadingCircle from './loadingCircle';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

/**
 * Component that represent a date picker field
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    renderDay?: (day: Moment, _value: Moment | null, DayComponentProps: PickersDayProps<Moment>) => JSX.Element;

}

type Props = IProps & ReduxProps & DateCalendarProps<Moment>;

interface State {
    theme: Theme;
}

class InternalDateCalendar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const cssVar = (name: keyof ReturnType<typeof getTheme>) => {
            const theme = getTheme();
            if (name in theme) {
                return theme[name] ?? '#111111';
            }
            return '#111111';
        };

        this.state = {
            theme: createTheme({
                palette: {
                    primary: { main: cssVar('--font-input-color') },
                },

            }),
        };
    }

    onChange = (value: Moment) => {
        const { onChange } = this.props;
        if (onChange) onChange(value);
    };

    onMonthChange = (value: Moment) => {
        const { onMonthChange } = this.props;
        if (onMonthChange) onMonthChange(value);
    };

    onYearChange = (value: Moment) => {
        const { onMonthChange } = this.props;
        if (onMonthChange) onMonthChange(value);
    };

    render() {
        const {
            language,
            renderDay,
            loading,
            ...dateCalendarProps
        } = this.props;
        const { theme } = this.state;

        moment.locale(language);

        let localText = frFR;
        switch (language) {
            case 'de':
            case 'de-CH':
            case 'de-DE':
            case 'de-AU':
                localText = deDE;
                break;
            case 'fr':
            case 'fr-CH':
            case 'fr-FR':
            case 'fr-BE':
                localText = frFR;
                break;
            case 'en':
            case 'en-GB':
            case 'en-US':
                localText = enUS;
                break;
            case 'it':
            case 'it-IT':
            case 'it-CH':
                localText = itIT;
                break;
            default:
                localText = frFR;
        }
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} localeText={localText.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DateCalendar
                            {...dateCalendarProps}
                            renderLoading={() => <LoadingCircle />}
                            disablePast
                            onChange={(e) => this.onChange(e)}
                            onMonthChange={(e) => this.onMonthChange(e)}
                            onYearChange={(e) => this.onYearChange(e)}
                            loading={loading}
                            shouldDisableDate={() => loading ?? false}
                            shouldDisableMonth={() => loading ?? false}
                            shouldDisableYear={() => loading ?? false}
                            disabled={loading}
                            sx={{
                                ...dateCalendarProps.sx,
                                '.MuiPickersDay-root': {
                                    color: 'var(--font-input-color)',
                                },
                                '.MuiPickersDay-root:not(.Mui-selected)': {
                                    borderColor: 'var(--font-input-color)',
                                },
                                '.MuiPickersCalendarHeader-label': {
                                    fontSize: '90%',
                                    color: 'var(--font-input-color)',
                                },
                                '.MuiSvgIcon-root': {
                                    color: 'var(--font-input-color)'
                                },
                                '.MuiPickersYear-root': {
                                    color: 'var(--font-input-color)',
                                },
                                '.MuiPickersMonth-root': {
                                    color: 'var(--font-input-color)',
                                }
                            }}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    language: store.translation.language,
});
const connector = connect(mapStateToProps);

export default connector(InternalDateCalendar);
