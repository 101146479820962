import * as Sentry from '@sentry/browser';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
// import * as firebase from "firebase";
import configureStore from './store/store';

// const api = require("./actions/api");

Sentry.init(
    {
        dsn: 'https://af62b20837224d26a160c86ce0493402@sentry.io/1861049',
        beforeSend(event) {
            if (event.request && event.request.url?.includes('help')
                && event.exception?.values && event.exception.values[0]
                && event.exception.values[0].value?.includes('cross-origin')) {
                return null;
            } if (event.request && event.request.url && event.request.url.includes(':3000')) {
                return null;
            }
            return event;
        },
        release: `snowpros@${process.env.REACT_APP_VERSION}`,
    }
);

const store = configureStore();

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Retrieve Firebase Messaging object.
// const firebaseConfig = {
//     apiKey: "AIzaSyDHvf7xDHy5P5MX2Rb5L2I29lfLL12IlyM",
//     authDomain: "badisay-844b6.firebaseapp.com",
//     databaseURL: "https://badisay-844b6.firebaseio.com",
//     projectId: "badisay-844b6",
//     storageBucket: "badisay-844b6.appspot.com",
//     messagingSenderId: "182039356902",
//     appId: "1:182039356902:web:6bc305237949da1e"
// };
