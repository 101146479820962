import {
    ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper
} from '@mui/material';
import React from 'react';
import { getTranslation } from '../../resources/utils';
import { ShopNavigationMenu } from '../../types/settings';

interface IProps {
    lang: string;
    openMenus: (HTMLElement | false)[];
    posFirst: number;
    currentMenu: string;
    menus: any;
    menusLength: number;
    currentSubMenu?: any;
    handleHoverMenu(e: any, m: any, i: number): void;
    handleCloseMenu(i: number): void;
    handleClickMenu(e: any, m: any, i: number): void;
    handleClickSubMenu(m: any, s: any, i: number): void;
}

type Props = IProps;

interface State { }

class Menus extends React.Component<Props, State> {
    // // eslint-disable-next-line react/static-property-placement
    // static defaultProps = {
    //     currentSubMenu: undefined,
    // };

    render() {
        const {
            menus,
            lang,
            openMenus,
            posFirst,
            currentMenu,
            handleHoverMenu,
            handleCloseMenu,
            handleClickMenu,
            menusLength,
            handleClickSubMenu,
            currentSubMenu,
        } = this.props;

        return menus.map((m: ShopNavigationMenu, i: number) => {
            const translation = getTranslation(m.translations, lang);
            const titleEN = getTranslation(m.translations, 'en');
            const title = translation ? translation.title : undefined;
            const menuDisplay = m.translations.find((t: any) => t.lang === lang);
            return menuDisplay && menuDisplay.display && (
                /* eslint-disable-next-line
                jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */
                <div
                    className={`__menubar-menus-div-container ${openMenus[posFirst + i] ? '__menubar-menus-div-container-hover' : ''} ${currentMenu?.replace(/ /g, '-').toLowerCase() === titleEN.title?.replace(/ /g, '-').toLowerCase() ? '__menubar-menus-div-container-active' : ''}`}
                    key={`menubar-menu-${m.id}`}
                    style={m.submenus.length === 0 ? { cursor: 'pointer' } : { cursor: 'default' }}
                    onMouseEnter={(e) => handleHoverMenu(e, m, posFirst + i)}
                    onMouseLeave={() => handleCloseMenu(posFirst + i)}
                    onClick={(e) => handleClickMenu(e, m, posFirst + i)}
                >
                    <p
                        className="__menubar-menu-button"
                        style={currentMenu?.replace(/ /g, '-').toLowerCase() === titleEN.title?.replace(/ /g, '-').toLowerCase() ? { fontWeight: 'bold', color: 'white', textAlign: 'center' } : { textAlign: 'center' }}
                    >
                        {title}
                    </p>
                    {m.submenus.length > 0 && (
                        <Popper
                            sx={{
                                '& .MuiList-root': {
                                    marginTop: 0
                                },
                                marginTop: 20,
                                zIndex: 55555555
                            }}
                            open={Boolean(openMenus[posFirst + i])}
                            anchorEl={openMenus[posFirst + i] || undefined}
                            transition
                            placement="bottom"
                        // elevation={0} KROK Property does not exist on element
                        >
                            {({ TransitionProps, placement }) => (
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                    <Paper className="__menubar-menu-paper" style={menusLength > 4 && posFirst === 3 ? { marginLeft: '55px' } : {}}>
                                        <ClickAwayListener onClickAway={
                                            () => handleCloseMenu(posFirst + i)
                                        }
                                        >
                                            <MenuList>
                                                {m.submenus.map((s: any) => {
                                                    const subMenuDisplay = s.translations.find(
                                                        (t: any) => t.lang === lang,
                                                    );

                                                    let returnClassname = '';

                                                    if (getTranslation(s.translations, lang).page !== '#') {
                                                        if (s.isChild) {
                                                            returnClassname = '__menunbar-menu-item-child';
                                                        } else {
                                                            returnClassname = '__menunbar-menu-item';
                                                        }
                                                    } else {
                                                        returnClassname = '__menunbar-menu-item-none';
                                                    }

                                                    return subMenuDisplay
                                                        && subMenuDisplay.display
                                                        && (
                                                            <MenuItem
                                                                className={`${returnClassname} ${s.isParent ? '__menunbar-menu-item-parent' : ''} ${currentSubMenu?.replace(/ /g, '-').toLowerCase() === s.title?.replace(/ /g, '-').toLowerCase() ? '__menubar-submenus-hover' : ''}`}
                                                                key={`menubar-submenu-${s.id}`}
                                                                disabled={s.isParent}
                                                                onClick={() => !s.isParent && getTranslation(s.translations, lang).page !== '#' && handleClickSubMenu(m, s, posFirst + i)}
                                                            >
                                                                {getTranslation(
                                                                    s.translations,
                                                                    lang,
                                                                ).title}
                                                            </MenuItem>
                                                        );
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    )}
                </div>
            );
        });
    }
}

export default Menus;
