import { injectIntl, WrappedComponentProps } from 'react-intl';
import '../../resources/css/nomatch.css';
import Container from '../common/container';
import Title from '../common/title';

type Props = WrappedComponentProps;

function NoMatch(props: Props) {
    const { intl } = props;
    return (
        <Container headerBase={intl.formatMessage({ id: 'nomatch.header' })}>
            <div className="__nomatch-parent">
                <Title className="__nomatch-title">{intl.formatMessage({ id: 'nomatch.title' })}</Title>
                <p className="__nomatch-text">{intl.formatMessage({ id: 'nomatch.text' })}</p>
            </div>
        </Container>
    );
}

export default injectIntl(NoMatch);
