import Cookie from 'js-cookie';
import { Moment } from 'moment';
import { ThunkAction } from 'redux-thunk';
import { getCurrentVersion, getDomain } from '../../resources/utils';
import {
    TYPE_INIT_LANDING
} from '../reducers/landing';
import { TYPE_SETTING_GET_BC_CLIENT, TYPE_SETTING_GET_USER_DATA_SUCCESS } from '../reducers/settings';
import {
    SignupForms,
    TYPE_CHANGE_CART,
    TYPE_CHANGE_ERROR_CATCHED,
    TYPE_CHANGE_SIGNUP_FORM,
    TYPE_DECREMENT,
    TYPE_FETCH_LESSONS_IMAGES,
    TYPE_FETCH_MEDALS,
    TYPE_FETCH_MEDALS_SUCCESS,
    TYPE_FETCH_MY_LESSONS,
    TYPE_FETCH_MY_LESSONS_SUCCESS,
    TYPE_FETCH_MY_LESSON_DETAILS,
    TYPE_FETCH_MY_LESSON_DETAILS_FAILURE,
    TYPE_FETCH_MY_LESSON_DETAILS_SUCCESS,
    TYPE_GET_RESET_PASSWORD_TOKEN,
    TYPE_GET_RESET_PASSWORD_TOKEN_ERROR,
    TYPE_HIDE_LOADER,
    TYPE_HIDE_SIGNUP_ERROR,
    TYPE_HIDE_SIGN_UP,
    TYPE_INCREMENT,
    TYPE_INIT_REGISTER,
    TYPE_LOGIN_USER,
    TYPE_LOGIN_USER_FAILURE,
    TYPE_LOGIN_USER_SUCCESS,
    TYPE_MESSAGE,
    TYPE_MY_ACCOUNT_CURRENT_TAB,
    TYPE_REGISTER_USER,
    TYPE_REGISTER_USER_FAILURE,
    TYPE_REGISTER_USER_SUCCESS,
    TYPE_SET_COME_FROM_SKI_RESORT_COUNTER,
    TYPE_SET_LESSONS_IMAGES,
    TYPE_SET_RESET_PASSWORD_MESSAGE,
    TYPE_SHOW_LOADER,
    TYPE_SHOW_SIGNUP_ERROR,
    TYPE_SHOW_SIGN_UP,
    TYPE_TOGGLE_OPEN_PWA_MODAL
} from '../reducers/user';
import { StoreAction, UserState } from '../types';
import { API_ROUTES, get, post } from './api';

export type Effect = ThunkAction<any, UserState, any, StoreAction>;

export const incrementCounter = () => ({
    type: TYPE_INCREMENT,
});

export const changeMyAccountCurrentTab = (currentTab: string): Effect => (dispatch: any) => {
    dispatch({ type: TYPE_MY_ACCOUNT_CURRENT_TAB, currentTab });
};

export const decrementCounter = (): Effect => ((dispatch: any) => {
    dispatch({
        type: TYPE_DECREMENT,
    });
});

export const updateMessage = (msg: string): Effect => (dispatch: any) => {
    dispatch({ type: TYPE_MESSAGE, message: msg });
};

export const initializeRegisteration = (): Effect => ((dispatch: any) => {
    dispatch({
        type: TYPE_INIT_REGISTER,
    });
});

export const openSignUpForm = (): Effect => ((dispatch: any) => {
    dispatch({
        type: TYPE_INIT_REGISTER,
    });
    dispatch({
        type: TYPE_CHANGE_SIGNUP_FORM,
        data: SignupForms.login,
    });
    dispatch({
        type: TYPE_SHOW_SIGN_UP,
    });
});

export const closeSignUpForm = (): Effect => ((dispatch: any) => {
    dispatch({
        type: TYPE_HIDE_SIGN_UP,
    });
});

export const hideSignupError = (): Effect => ((dispatch: any) => {
    dispatch({
        type: TYPE_HIDE_SIGNUP_ERROR,
    });
});

export const changeSignupVisibleForm = (current: any): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_CHANGE_SIGNUP_FORM,
        data: current,
    });
};

export const loginUser = (email: string, password: string, language: string = 'fr', defaultErrorMessage = ''): Effect => (dispatch: any) => {
    const requestData = {
        email: email.toLowerCase(),
        password,
        language,
    };

    dispatch({
        type: TYPE_LOGIN_USER,
    });
    dispatch({
        type: TYPE_SHOW_LOADER,
    });

    dispatch(post(
        API_ROUTES.login,
        requestData,
        (responseJson: any) => {
            Cookie.set('token', responseJson.token, { domain: getDomain(), expires: 25 });
            dispatch({
                type: TYPE_LOGIN_USER_SUCCESS,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
            dispatch({
                type: TYPE_HIDE_SIGN_UP,
            });
            dispatch({
                type: TYPE_HIDE_SIGNUP_ERROR,
            });
            if (responseJson.from_counter) {
                dispatch({ type: TYPE_SET_COME_FROM_SKI_RESORT_COUNTER, data: true });
            } else {
                dispatch({
                    type: TYPE_SETTING_GET_USER_DATA_SUCCESS,
                    userData: responseJson.user
                });

                dispatch({
                    type: TYPE_SETTING_GET_BC_CLIENT,
                    data: responseJson.client,
                });
            }
        },
        () => {
            dispatch({
                type: TYPE_SHOW_SIGNUP_ERROR,
                error: defaultErrorMessage
            });
            dispatch({
                type: TYPE_LOGIN_USER_FAILURE
            });
            dispatch({
                type: TYPE_HIDE_LOADER
            });
        }
    ));
};

export const registerUser = (
    firstName: string,
    lastName: string,
    email: string,
    dob?: Moment,
    password?: string,
    language?: string,
): Effect => (dispatch: any) => {
    const requestData: {
        first_name: string,
        last_name: string,
        dob?: Moment,
        email: string,
        password: string,
        language?: string,
        version: number
    } = {
        first_name: firstName,
        last_name: lastName,
        dob,
        email: email.toLowerCase(),
        password: password || 'unknown',
        language,
        version: getCurrentVersion(),
    };

    dispatch({
        type: TYPE_REGISTER_USER,
    });
    dispatch({
        type: TYPE_SHOW_LOADER,
    });
    dispatch(post(
        API_ROUTES.register,
        requestData,
        () => {
            dispatch(loginUser(email, password || 'unknown'));
            dispatch({
                type: TYPE_REGISTER_USER_SUCCESS,
            });
            dispatch({
                type: TYPE_HIDE_SIGNUP_ERROR,
            });
        },
        (error: any) => {
            dispatch({
                type: TYPE_SHOW_SIGNUP_ERROR,
                error,
            });
            dispatch({
                type: TYPE_REGISTER_USER_FAILURE,
            });
            dispatch({
                type: TYPE_HIDE_LOADER,
            });
        },
    ));
};

export const logoutUser = (): Effect => (dispatch: any, getState: any) => {
    dispatch({
        type: TYPE_INIT_LANDING,
    });
    const { language } = getState().translation;
    Cookie.remove('token', { domain: getDomain() });
    window.location.href = window.location.href.slice(
        0,
        window.location.href.indexOf(language) + 2,
    );
};

export const toggleOpenPWAModal = (): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_TOGGLE_OPEN_PWA_MODAL,
    });
};

export const changeCart = (): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_CHANGE_CART,
    });
};

export const forgotMyPassword = (email: string, language: string): Effect => (dispatch: any) => {
    dispatch(
        post(
            API_ROUTES.forgotPassword,
            { email, language, version: getCurrentVersion() },
            () => { },
            (error: any) => {
                if (error) {
                    const errorParsed = JSON.parse(error);
                    if (errorParsed.message && errorParsed.message === 'User not found') {
                        return null;
                    }
                }
                throw new Error(`${error}`);
            },
        ),
    );
};

export const changeErrorCatched = (bool: boolean, message: string): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_CHANGE_ERROR_CATCHED,
        data: bool,
        message,
    });
};

export const resetPassword = (
    email: string,
    password: string,
    token: string,
): Effect => (dispatch: any) => {
    dispatch(
        post(
            API_ROUTES.passwordReset,
            { email, password, token },
            (responseJson: any) => {
                dispatch({
                    type: TYPE_SET_RESET_PASSWORD_MESSAGE,
                    data: responseJson.success,
                });
            },
            (error: any) => {
                dispatch({
                    type: TYPE_SET_RESET_PASSWORD_MESSAGE,
                    data: 'error',
                });
            },
        ),
    );
};

export const getResetPasswordToken = (token: string): Effect => (dispatch: any) => {
    dispatch(
        post(
            API_ROUTES.resetPasswordToken,
            { token },
            (responseJson: any) => dispatch({
                type: TYPE_GET_RESET_PASSWORD_TOKEN,
                data: responseJson,
            }),
            (error: any) => dispatch({
                type: TYPE_GET_RESET_PASSWORD_TOKEN_ERROR,
                data: JSON.parse(error),
            }),
        ),
    );
};

export const changeResetPasswordToken = (): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_GET_RESET_PASSWORD_TOKEN,
        data: null,
    });
};

export const changeResetPasswordMessage = (): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_RESET_PASSWORD_MESSAGE,
        data: '',
    });
};

export const fetchMyLessons = (language: string): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_MY_LESSONS,
    });

    dispatch(
        get(
            `${API_ROUTES.mylessons}?lang=${language}&version=${getCurrentVersion()}`,
            (responseJson: any) => {
                dispatch({
                    type: TYPE_FETCH_MY_LESSONS_SUCCESS,
                    data: responseJson.my_lessons,
                });
            },
            (error: any) => {
                throw new Error(`${error}`);
            },
        ),
    );
};

export const fetchMyLessonDetails = (lesson: any, language: string): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_MY_LESSON_DETAILS,
    });

    if (lesson.ProductId == null) {
        dispatch({
            type: TYPE_FETCH_MY_LESSON_DETAILS_SUCCESS,
            data: { ...lesson }
        });
        return;
    }

    dispatch(
        post(
            `${API_ROUTES.mylessons}?lang=${language}`,
            { lesson },
            (responseJson: any) => {
                dispatch({
                    type: TYPE_FETCH_MY_LESSON_DETAILS_SUCCESS,
                    data: responseJson.details,
                });
            },
            (error: any) => {
                dispatch({
                    type: TYPE_FETCH_MY_LESSON_DETAILS_FAILURE,
                });
                dispatch({
                    type: TYPE_CHANGE_ERROR_CATCHED,
                    data: true,
                });
            },
        ),
    );
};

export const comeFromSkiResortCounterChangePassword = (
    oldPassword: string,
    newPassword: string,
): Effect => (dispatch: any) => {
    const requestData = {
        old_password: oldPassword,
        new_password: newPassword,
    };

    dispatch(post(
        API_ROUTES.changePassword,
        requestData,
        () => {
            dispatch({
                type: TYPE_SET_COME_FROM_SKI_RESORT_COUNTER,
                data: false,
            });
            window.location.reload();
        },
        (error: any) => {
            dispatch({
                type: TYPE_SHOW_SIGNUP_ERROR,
                error,
            });
        },
    ));
};

export const fetchMedalsDetails = (language: string) => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_MEDALS,
    });

    dispatch(
        get(
            `${API_ROUTES.medals}?lang=${language}`,
            (responseJson: any) => {
                dispatch({
                    type: TYPE_FETCH_MEDALS_SUCCESS,
                    data: responseJson.medals,
                });
            },
            (error: any) => {
                throw new Error(`${error}`);
            },
        ),
    );
};

export const fetchImagesForLessons = (): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_FETCH_LESSONS_IMAGES,
    });

    dispatch(
        get(
            API_ROUTES.lessonImageAll,
            (responseJson: any) => {
                dispatch({
                    type: TYPE_SET_LESSONS_IMAGES,
                    data: responseJson.images,
                });
            },
            (error: any) => {
                throw new Error(`${error}`);
            },
        ),
    );
};

export const setImagesForLessons = (images: string[]): Effect => (dispatch: any) => {
    dispatch({
        type: TYPE_SET_LESSONS_IMAGES,
        data: images,
    });
};
